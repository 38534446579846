import { createSlice } from '@reduxjs/toolkit';

const shortedDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedData: (state , action ) => {
      return action.payload
    },
  },
});

const BarChartDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addDataToBarChart: (state , action ) => {
      return action.payload
    },
  },
});

export const { addToshortedData } = shortedDataSlice.actions;
export const { addDataToBarChart } = BarChartDataSlice.actions;

export { shortedDataSlice,BarChartDataSlice};



const shortedXaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedXaxisData: (state , action ) => {
      return action.payload
    },
  },
});

const shortedYaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedYaxisData: (state , action ) => {
      return action.payload
    },
  },
});

const shortedZaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedZaxisData: (state , action ) => {
      return action.payload
    },
  },
});




const selectedAttributesSlice = createSlice({
  name: 'selectedAttributes',
  initialState: [],
  reducers: {
    setSelectedAttributesHeading: (state, action) => {
      return action.payload
    },
  },
});

export const { setSelectedAttributesHeading} = selectedAttributesSlice.actions;


const selectedQuerysSlice = createSlice({
  name: 'selectedQuerys',
  initialState: {},
  reducers: {
    addSelectedQuery: (state, action) => {
      const { key, nametoDisplay, value } = action.payload;
      if (value.length > 0) {
        state[key] = { nametoDisplay, value };
      } else {
        delete state[key];
      }
    },
    removeSelectedQuery: (state, action) => {
      const { key } = action.payload;
      delete state[key];
    },
    clearAllSelectedQuerys: () => {
      return {};
    },
  },
});
export const { addSelectedQuery, removeSelectedQuery , clearAllSelectedQuerys} = selectedQuerysSlice.actions;




const DataSelectionNamesSlice = createSlice({
  name: 'stringState',
  initialState :  {
    tableName: '',  
    groupName: '',  
    productGroupNameForProgram: '',  
  },
  reducers: {
    setToGraphPageTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setToGraphPageGroupName: (state, action) => {
      state.groupName = action.payload;
    },
    setToGraphPageProductGroupNameForProgram: (state, action) => {
      state.productGroupNameForProgram = action.payload;
    },
    // Optional: add a reducer to set all three at once
    // setAllVars: (state, action) => {
    //   const { varName1, varName2, varName3 } = action.payload;
    //   state.varName1 = varName1;
    //   state.varName2 = varName2;
    //   state.varName3 = varName3;
    // }
  },
});

export const { setToGraphPageTableName, setToGraphPageGroupName, setToGraphPageProductGroupNameForProgram } = DataSelectionNamesSlice.actions;



export const { addToshortedXaxisData } = shortedXaxisDataSlice.actions;
export const { addToshortedYaxisData } = shortedYaxisDataSlice.actions;
export const { addToshortedZaxisData } = shortedZaxisDataSlice.actions;

export { shortedXaxisDataSlice,shortedYaxisDataSlice , shortedZaxisDataSlice ,selectedQuerysSlice ,selectedAttributesSlice ,DataSelectionNamesSlice};
import React, { useEffect } from "react";
import "./AllEmployee.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers, resetUpdateUserRole, updateUserRole } from '../../../../store/Slices/userAuthSlice.js';
import STATUSES from "../../../../store/Statuses.js";
import ErrorBox from "../../ErrorBox/ErrorBox.jsx";
import Loader from "../../Loader/Loader.jsx";
import { toast } from "react-toastify";
import AccessTableList from "./AccessTableList/AccessTableList.jsx";

const roleMapping = {
  ADMIN: 1,
  MANAGER: 2,
  USER: 3,
};

const AllEmployee = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers({}));
  }, [dispatch]);

  const { data, status, error } = useSelector((state) => state.AllUsers);

  const handleRoleChange = (employee, newRoleName) => {
    const roleId = roleMapping[newRoleName];

    const { password, productGroup, supervisor, status, ...employeeWithoutPassword } = employee;

    const updatedEmployee = {
      ...employeeWithoutPassword,
      role: {
        name: newRoleName,
        id: roleId,
      },
      productGroup: productGroup !== null ? productGroup : '',
      supervisor: supervisor !== null ? supervisor : '',
      status: status
    };

    dispatch(updateUserRole(updatedEmployee));
  };

  const handleSupervisorChange = (employee, newSupervisor) => {
    
    const { password, productGroup, supervisor, status,role , ...employeeWithoutPassword } = employee;

    const updatedEmployee = {
      ...employeeWithoutPassword,
      productGroup: productGroup !== null ? productGroup : '',
      supervisor: newSupervisor ,
      status: status,
      role :role
    };

    dispatch(updateUserRole(updatedEmployee));
  };

  const handleDepartmentChange = (employee, newProductGroup) => {
    
    const { password, productGroup, supervisor, status,role , ...employeeWithoutPassword } = employee;

    const updatedEmployee = {
      ...employeeWithoutPassword,
      productGroup: newProductGroup,
      supervisor: supervisor !== null ? supervisor : '',
      status: status,
      role : role
    };

    dispatch(updateUserRole(updatedEmployee));
  };

  const handleStatusChange = (employee, newStatus) => {
    
    const { password, productGroup, supervisor, status,role , ...employeeWithoutPassword } = employee;

    const updatedEmployee = {
      ...employeeWithoutPassword,
      productGroup: productGroup !== null ? productGroup : '',
      supervisor: supervisor !== null ? supervisor : '',
      status: newStatus,
      role : role
    };

    dispatch(updateUserRole(updatedEmployee));
  };

  const { data: UpdatedData, status: UpdatedStatus, error: UpdatedError } = useSelector((state) => state.UpdateUserRole);

  useEffect(() => {
    if (UpdatedStatus === STATUSES.ERROR) {
      dispatch(resetUpdateUserRole({}));
      toast.error("Something went wrong");
    }
    if (UpdatedStatus === STATUSES.SUCCESS) {
      dispatch(resetUpdateUserRole({}));
      toast.success(UpdatedData ? UpdatedData : "Role updated");
      dispatch(getAllUsers({}));
    }
  }, [dispatch, UpdatedData, UpdatedStatus]);

  return (
    <div className="allEmployee">
      <div className="allEmployee_header">
        <div>All Employees</div>
      </div>

      <div className="all_employees_list">
        <table>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Employee ID</th>
              <th>Department</th>
              <th>Role</th>
              <th>Supervisor</th>
              <th>Status</th>
            </tr>
          </thead>
          {
            status === STATUSES.LOADING ? (
              <Loader />
            ) : status === STATUSES.ERROR ? (
              <ErrorBox message={error?.message ? error?.message : "Something Went Wrong"} />
            ) : status === STATUSES.SUCCESS ? (
              <tbody>
                {data && data.length > 0 ? (
                  data.map((employee) => (
                    <tr key={employee.id}>
                      <td className="employee_info">
                        <div className="employee_img_box">
                          <i>
                            <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                          </i>
                        </div>
                        <div className="employee_info_box">
                          <span>{employee?.name}</span>
                        </div>
                      </td>
                      <td>{employee?.email}</td>
                      <td className="employee_action">
                        <select
                          value={employee.productGroup || ''}
                          onChange={(e) => handleDepartmentChange(employee, e.target.value)}
                        >
                          <option value="">Select Department</option>
                          <option value="Sheeting">Sheeting</option>
                          <option value="Bath Linen">Bath Linen</option>
                        </select>
                      </td>
                      <td className="employee_action">
                        <select
                          value={employee.role.roleName}
                          onChange={(e) => handleRoleChange(employee, e.target.value)}
                        >
                          <option value="ADMIN">Admin</option>
                          <option value="MANAGER">Manager</option>
                          <option value="USER">User</option>
                        </select>
                      </td>
                      <td className="employee_action">
                        <select
                          value={employee.supervisor || ''}
                          onChange={(e) => handleSupervisorChange(employee, e.target.value)}
                        >
                          <option value="">Select Supervisor</option>
                          {data
                            .filter(emp => emp.role.roleName === "MANAGER")
                            .map(manager => (
                              <option key={manager.id} value={manager.email}>
                                {manager.email}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="employee_action">
                        <select
                          value={employee.status}
                          onChange={(e) => handleStatusChange(employee, e.target.value)}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No employees found.</td>
                  </tr>
                )}
              </tbody>
            ) : null
          }
        </table>
      </div>

      <AccessTableList/>
    </div>
  );
};

export default AllEmployee;

import React, { useEffect, useState } from "react";
import "./CreateAttributeGroup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCircleInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import STATUSES from "../../../../store/Statuses";
import NoDataInAttributes from "../../MarketMapsComponents/NoDataInAttributes/NoDataInAttributes";
import Loader, { LoaderCircle } from "../../../Common/Loader/Loader";
import importicon from "../../../../assets/Images/importicon.svg";
import mains from "../../../../assets/Images/mains.svg";

const CreateAttributeGroup = ({
  handelCreateAttributeGroupToggle,
  createAttributeGroupToggle,
  groupHeadingName,
  SaveGroupAPIName,
  sendAdditionalAttributesAs,
  ResetGroupAPIName,
  getGroupAPIName,
  TableMainAttributes,
  sendGroupName,
  GetSingleGroupAPI,
  GetSingleGroupDataAPI,
}) => {
  const dispatch = useDispatch();

  // Getting Saved Group Data
  useEffect(() => {
    if (sendGroupName.length > 0) {
      dispatch(GetSingleGroupAPI(sendGroupName));
    }
  }, [dispatch, sendGroupName, GetSingleGroupAPI]);

  const {
    data: savedGroupData,
    status: savedGroupStatus,
    error: savedGroupError,
  } = useSelector(GetSingleGroupDataAPI);
  // console.log(savedGroupData);
  const {
    groupAttributes: savedGroupAttributes,
    [sendAdditionalAttributesAs]: savedAdditionalAttributes,
    imagesAttributes: savedImagesAttributes,
  } = savedGroupData;

  // console.log("Saved Data ==", savedGroupAttributes,savedAdditionalAttributes, savedImagesAttributes);

  const [mainAttributes, setMainAttributes] = useState(TableMainAttributes);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleAddAttribute = (key) => {
    const selectedAttribute = { [key]: mainAttributes[key] };
    const updatedMainAttributes = { ...mainAttributes };
    delete updatedMainAttributes[key];

    setMainAttributes(updatedMainAttributes);
    setSelectedAttributes({ ...selectedAttributes, ...selectedAttribute });
  };

  const handleRemoveAttribute = (key) => {
    const removedAttribute = { [key]: selectedAttributes[key] };
    const updatedSelectedAttributes = { ...selectedAttributes };
    delete updatedSelectedAttributes[key];

    setSelectedAttributes(updatedSelectedAttributes);
    setMainAttributes({ ...mainAttributes, ...removedAttribute });
  };

  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [newAttributeInput, setnewAttributeInput] = useState({
    nametoDisplay: "",
    nametoDisplay2: "",
    type: "",
    varDescription: "",
    mandatory: true,
    unique: false,
    childDataType: "",
    repeat: false,
  });
  const [selectedNewAttributes, setSelectedNewAttributes] = useState({});

  const handleNewAttributeInputChange = (e) => {
    const { name, value } = e.target;
    setnewAttributeInput({
      ...newAttributeInput,
      [name]: value,
    });
  };

  // console.log(selectedNewAttributes)

  const [newOption, setNewOption] = useState("");
  const [options, setOptions] = useState([]);

  const [newParentOption, setNewParentOption] = useState("");
  const [newNestedOptions, setNewNestedOptions] = useState([]);
  const [nestedOptions, setNestedOptions] = useState([]);

  // console.log("newParentOption", newParentOption);
  // console.log("newNestedOptions", newNestedOptions)
  // console.log("nestedOptions", nestedOptions)

  const handleAddNewAttributes = () => {
    const {
      nametoDisplay,
      nametoDisplay2,
      type,
      varDescription,
      mandatory,
      repeat,
      unique,
      childDataType,
    } = newAttributeInput;

    if (
      nametoDisplay.trim().length === 0 ||
      type.trim().length === 0 ||
      varDescription.trim().length === 0
    ) {
      toast.warning("Please fill in all fields to add new attribute");
      return;
    }

    const key = nametoDisplay.trim();
    const newAttribute = {
      nametoDisplay: nametoDisplay.trim(),
      varDescription: varDescription.trim(),
      type: type,
      mandatory: mandatory,
      repeat: repeat,
      unique: unique,
    };

    if (type === "Dropdown" || type === "MultiSelect") {
      if (options.length === 0) {
        toast.warning("Please add at least one option");
        return;
      }
      const newAttributeWithOptions = {
        ...newAttribute,
        options: options
          .map((option) => option.trim())
          .filter((option) => option.length > 0),
      };
      const newSelectedAttributes = {
        ...selectedNewAttributes,
        [key]: newAttributeWithOptions,
      };
      setSelectedNewAttributes(newSelectedAttributes);
    } else if (type === "NestedDropdown") {
      if (!childDataType) {
        toast.warning("plaese select the data type of the child attribute");
        return;
      }

      if (childDataType.trim() === "Dropdown") {
        if (options.length === 0) {
          toast.warning("Please add at least one parent option");
          return;
        }
        if (!nametoDisplay2) {
          toast.warning("Please fill in the child attribute name");
          return;
        }
        if (nametoDisplay2 && nametoDisplay2.trim().length === 0) {
          toast.warning("Please fill in the child attribute name");
          return;
        }
        if (nestedOptions.some((option) => option.length === 0)) {
          toast.warning(
            "Each parent option must have at least one nested option"
          );
          return;
        }
        const newAttribute = {
          nametoDisplay: nametoDisplay.trim(),
          nametoDisplay2: nametoDisplay2.trim(),
          varDescription: varDescription.trim(),
          type: type,
          mandatory: mandatory,
          repeat: repeat,
          unique: unique,
          childDataType: childDataType,
          nestedOptions: nestedOptions.map((option) =>
            option
              .map((nestedOption) => nestedOption.trim())
              .filter((nestedOption) => nestedOption.length > 0)
          ),
          options: options
            .map((option) => option.trim())
            .filter((option) => option.length > 0),
        };

        const newSelectedAttributes = {
          ...selectedNewAttributes,
          [key]: newAttribute,
        };
        setSelectedNewAttributes(newSelectedAttributes);

        // Clear the input fields for new attribute
        setnewAttributeInput({
          nametoDisplay: "",
          nametoDisplay2: "",
          type: "",
          varDescription: "",
          mandatory: true,
          repeat: false,
          unique: false,
          childDataType: "",
        });

        // Clear options and nested options
        setOptions([]);
        setNestedOptions([]);
        setNewParentOption("");
        setNewNestedOptions([]);
      } else {
        if (options.length === 0) {
          toast.warning("Please add at least one parent option");
          return;
        }
        if (!nametoDisplay2) {
          toast.warning("Please fill in the child attribute name");
          return;
        }
        if (nametoDisplay2 && nametoDisplay2.trim().length === 0) {
          toast.warning("Please fill in the child attribute name");
          return;
        }

        const newAttribute = {
          nametoDisplay: nametoDisplay.trim(),
          nametoDisplay2: nametoDisplay2.trim(),
          varDescription: varDescription.trim(),
          type: type,
          mandatory: mandatory,
          repeat: repeat,
          unique: unique,
          childDataType: childDataType,
          options: options
            .map((option) => option.trim())
            .filter((option) => option.length > 0),
        };

        const newSelectedAttributes = {
          ...selectedNewAttributes,
          [key]: newAttribute,
        };
        setSelectedNewAttributes(newSelectedAttributes);

        // Clear the input fields for new attribute
        setnewAttributeInput({
          nametoDisplay: "",
          nametoDisplay2: "",
          type: "",
          varDescription: "",
          mandatory: true,
          repeat: false,
          unique: false,
          childDataType: "",
        });

        // Clear options and nested options
        setOptions([]);
        setNestedOptions([]);
        setNewParentOption("");
        setNewNestedOptions([]);
      }
    } else {
      const newSelectedAttributes = {
        ...selectedNewAttributes,
        [key]: newAttribute,
      };
      setSelectedNewAttributes(newSelectedAttributes);
    }

    // Clear the input fields for new attribute
    setnewAttributeInput({
      nametoDisplay: "",
      nametoDisplay2: "", // Clear the input field for nametoDisplay2
      type: "",
      varDescription: "",
      mandatory: true,
      repeat: false,
      unique: false,
      childDataType: "",
    });
  };

  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      setOptions([...options, newOption]);
      setNewOption("");
    }
  };

  // Clear options when the type changes
  useEffect(() => {
    setOptions([]);
    setNewOption("");
    setNestedOptions([]);
    setNewParentOption("");
    setNewNestedOptions([]);
  }, [newAttributeInput.type]);

  // Clear options and nested options when childDataType changes
  useEffect(() => {
    setOptions([]);
    setNewOption("");
    setNestedOptions([]);
    setNewParentOption("");
    setNewNestedOptions([]);
  }, [newAttributeInput.childDataType]);

  const handleRemoveOption = (optionToRemove) => {
    setOptions(options.filter((option) => option !== optionToRemove));
  };

  const handleRemoveNewAttribute = (key) => {
    const updatedAttributes = { ...selectedNewAttributes };
    delete updatedAttributes[key];
    setSelectedNewAttributes(updatedAttributes);
  };

  // Handlers for adding and removing parent options
  const handleAddParentOption = () => {
    if (newParentOption.trim() !== "") {
      setOptions([...options, newParentOption]);
      setNestedOptions([...nestedOptions, []]);
      setNewParentOption("");
    }
  };

  const handleRemoveParentOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    const updatedNestedOptions = nestedOptions.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    setNestedOptions(updatedNestedOptions);
  };

  // Handlers for adding and removing nested options
  const handleAddNestedOption = (index) => {
    if (newNestedOptions[index].trim() !== "") {
      const updatedNestedOptions = [...nestedOptions];
      updatedNestedOptions[index].push(newNestedOptions[index]);
      setNestedOptions(updatedNestedOptions);
      setNewNestedOptions(
        newNestedOptions.map((option, i) => (i === index ? "" : option))
      );
    }
  };

  const handleRemoveNestedOption = (parentIndex, nestedIndex) => {
    const updatedNestedOptions = [...nestedOptions];
    updatedNestedOptions[parentIndex] = updatedNestedOptions[
      parentIndex
    ].filter((_, i) => i !== nestedIndex);
    setNestedOptions(updatedNestedOptions);
  };

  const handleNewNestedOptionChange = (e, parentIndex) => {
    const { value } = e.target;
    const updatedNestedOptions = [...newNestedOptions];
    updatedNestedOptions[parentIndex] = value;
    setNewNestedOptions(updatedNestedOptions);
  };

  // Set of Attributes =======================

  const [groupAtt, setGroupAtt] = useState({});
  const [groupNewAtt, setGroupNewAtt] = useState({});

  const handleAddAttributeToGroup = (key) => {
    const selectedAttribute = selectedAttributes[key];
    const updatedSelectedAttributes = { ...selectedAttributes };
    delete updatedSelectedAttributes[key];

    setSelectedAttributes(updatedSelectedAttributes);

    // Add the selected attribute to the groupAttributes object
    setGroupAtt((prevAttributes) => ({
      ...prevAttributes,
      [key]: selectedAttribute,
    }));
  };

  const handleAddNewAttributeToGroup = (key) => {
    const selectedAttribute = selectedNewAttributes[key];
    const updatedSelectedNewAttributes = { ...selectedNewAttributes };
    delete updatedSelectedNewAttributes[key];

    setSelectedNewAttributes(updatedSelectedNewAttributes);

    // Add the selected new attribute to the groupAttributes object
    setGroupNewAtt((prevNewAttributes) => ({
      ...prevNewAttributes,
      [key]: selectedAttribute,
    }));
  };

  const handleRemoveAttributeFromGroup = (key) => {
    setGroupAtt((prevAttributes) => {
      const updatedAttributes = { ...prevAttributes };

      // Check if the attribute exists in groupAtt
      if (updatedAttributes.hasOwnProperty(key)) {
        // If it exists, remove it
        const removedAttribute = updatedAttributes[key];
        delete updatedAttributes[key];

        // Add the removed attribute back to selectedAttributes
        setSelectedAttributes((prevSelectedAttributes) => ({
          ...prevSelectedAttributes,
          [key]: removedAttribute,
        }));
      }

      return updatedAttributes;
    });
  };

  const handleRemoveNewAttributeFromGroup = (key) => {
    setGroupNewAtt((prevAttributes) => {
      const updatedAttributes = { ...prevAttributes };

      // Check if the attribute exists in groupAtt
      if (updatedAttributes.hasOwnProperty(key)) {
        // If it exists, remove it
        const removedAttribute = updatedAttributes[key];
        delete updatedAttributes[key];

        // Add the removed attribute back to selectedNewAttributes
        setSelectedNewAttributes((prevSelectedNewAttributes) => ({
          ...prevSelectedNewAttributes,
          [key]: removedAttribute,
        }));
      }

      return updatedAttributes;
    });
  };

  //attributes group Stet save =====
  // Set of Attributes =======================
  // const [groupAtt, setGroupAtt] = useState({});
  // const [groupNewAtt, setGroupNewAtt] = useState({});

  // attributes group Set save
  const [attSets, setAttSets] = useState({});

  // groupName add for attributes  ====
  const [groupAttNames, setGroupAttNames] = useState({});
  const [inputValue, setInputValue] = useState("");

  // Function to handle adding group names
  const handleAddGroupNames = () => {
    const names = inputValue
      .split(",")
      .map((name) => name.trim())
      .filter((name) => name !== "");
    const updatedNames = {};
    names.forEach((name) => {
      updatedNames[name] = { nametoDisplay: name };
    });
    setGroupAttNames((prev) => ({ ...prev, ...updatedNames }));
    setInputValue("");
  };

  // Function to handle removing a group name
  const handleRemoveGroupName = (name) => {
    const updatedNames = { ...groupAttNames };
    delete updatedNames[name];
    setGroupAttNames(updatedNames);
  };

  // Function to handle adding attributes to sets
  const handleAddToAttSets = () => {
    if (Object.keys(groupAttNames).length === 0) {
      toast.warning("Please add at least one set name.");
      return;
    } else {
      setAttSets((prev) => {
        const itemKey = `item${Object.keys(groupAttNames)[0]}`;
        const newItem = {
          attgroups: { ...groupAtt, ...groupNewAtt },
          groupsName: groupAttNames,
        };
        return {
          ...prev,
          [itemKey]: newItem,
        };
      });
      setGroupAtt({});
      setGroupNewAtt({});
      setGroupAttNames({});
    }
  };

  // Function to handle editing a set
  const handleEditSet = (itemName, value) => {
    setGroupAtt({});
    setGroupNewAtt(value.attgroups);
    setGroupAttNames(value.groupsName);

    // Remove the selected set from attSets
    setAttSets((prevAttSets) => {
      const updatedAttSets = { ...prevAttSets };
      delete updatedAttSets[itemName];
      return updatedAttSets;
    });
  };

  // Function to delete a group name from attSets
  // const deleteGroupName = (itemName, groupName) => {
  //   setAttSets((prevAttSets) => {
  //     const updatedAttSets = { ...prevAttSets };
  //     if (updatedAttSets[itemName]) {
  //       const updatedGroupsName = { ...updatedAttSets[itemName].groupsName };
  //       delete updatedGroupsName[groupName];
  //       updatedAttSets[itemName].groupsName = updatedGroupsName;
  //       if (Object.keys(updatedGroupsName).length === 0) {
  //         delete updatedAttSets[itemName];
  //       }
  //     }
  //     return updatedAttSets;
  //   });
  // };

  // console.log(groupAttNames)

  // console.log("attSets===", attSets)

  useEffect(() => {
    if (savedGroupStatus === STATUSES.SUCCESS) {
      if (
        savedGroupAttributes &&
        Object.keys(savedGroupAttributes).length > 0
      ) {
        setSelectedAttributes({ ...savedGroupAttributes });
      } else {
        setSelectedAttributes({});
      }
      if (
        savedAdditionalAttributes &&
        Object.keys(savedAdditionalAttributes).length > 0
      ) {
        setSelectedNewAttributes({ ...savedAdditionalAttributes });
      } else {
        setSelectedNewAttributes({});
      }
      if (
        savedImagesAttributes &&
        Object.keys(savedImagesAttributes).length > 0
      ) {
        setAttSets({ ...savedImagesAttributes });
      } else {
        setAttSets({});
      }
    } else {
      setSelectedAttributes({});
      setSelectedNewAttributes({});
      setAttSets({});
    }
  }, [
    savedGroupAttributes,
    savedAdditionalAttributes,
    savedImagesAttributes,
    savedGroupStatus,
  ]);

  useEffect(() => {
    // Filter out common fields between mainAttributes and savedGroupAttributes
    if (savedGroupStatus === STATUSES.SUCCESS) {
      if (
        savedGroupAttributes &&
        Object.keys(savedGroupAttributes).length > 0
      ) {
        const filteredMainAttributes = Object.fromEntries(
          Object.entries(mainAttributes).filter(
            ([key]) => !savedGroupAttributes.hasOwnProperty(key)
          )
        );
        // Update the mainAttributes state
        setMainAttributes(filteredMainAttributes);
      } else {
        setMainAttributes(TableMainAttributes);
      }
    }
  }, [savedGroupAttributes, savedGroupStatus]);

  const submitAttributesData = (e) => {
    e.preventDefault();
    if (
      Object.keys(selectedAttributes).length === 0 &&
      Object.keys(selectedNewAttributes).length === 0
    ) {
      toast.warning("Please select some attributes to create group");
    } else {
      const dataToSave = {
        groupName: sendGroupName,
        groupAttributes: selectedAttributes,
        [sendAdditionalAttributesAs]: selectedNewAttributes,
        imagesAttributes: attSets,
      };
      dispatch(SaveGroupAPIName(dataToSave));
      console.log(dataToSave);
    }
  };

  const handelClose = () => {
    handelCreateAttributeGroupToggle();
    document.body.style.overflow = "unset";
  };

  // Cancel button of create attributes
  // const handleCancle = () => {
  //   // ShowHideAttributesBox();
  //   setGroupName("");
  //   setGroupDescription("");
  //   setMainAttributes({ ...mainAttributes, ...selectedAttributes });
  //   setSelectedAttributes({});
  //   setSelectedNewAttributes({});
  //   setnewAttributeInput({
  //     nametoDisplay: "",
  //     nametoDisplay2: "", // Clear the input field for nametoDisplay2
  //     type: "",
  //     varDescription: "",
  //     mandatory: true,
  //     repeat: false,
  //     unique: false,
  //   });
  //   setOptions([]);
  //   setNestedOptions([]);
  //   setNewOption("");
  //   setNewParentOption("");
  //   setNewNestedOptions([]);
  //   setGroupAtt({}); // Clear groupAtt
  //   setGroupNewAtt({}); // Clear groupNewAtt
  //   setGroupAttNames([]); // Clear groupAttNames
  //   setInputValue(""); // clear groupAttNamesInput
  //   setAttSets({});
  // };

  const { data, status, error } = useSelector(getGroupAPIName);

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      toast.success("Added Successfully");
      dispatch(ResetGroupAPIName({}));
    }
    if (status === STATUSES.ERROR) {
      toast.error(error ? error : "Something Went Wrong");
      dispatch(ResetGroupAPIName({}));
    }
  }, [status, error, dispatch, ResetGroupAPIName]);

  //   console.log("selectedAttributes ==", selectedAttributes)
  //   console.log("selectedNewAttributes ==", selectedNewAttributes)

  //   console.log("options ===",options)
  //   console.log("newOption ===", newOption)
  //   console.log({...savedImagesAttributes,...attSets})

  console.log("newAttributeInput ===", newAttributeInput);

  return (
    <div className="create_attributes">
      <div
        className={
          createAttributeGroupToggle ? "all_attributes" : "all_attributes close"
        }
      >
        {status === STATUSES.LOADING ? <Loader /> : null}
        <h6>
          Create list of attributes for {groupHeadingName}'s {sendGroupName}
        </h6>
        <div className="all_attributes_container">
          <div className="fileds_container">
            <div className="col1">
              <h5>Select From here</h5>
              <div className="all_list">
                {Object.keys(mainAttributes).map((key, index) => (
                  <div key={index} className="single_attribute black_icon">
                    {mainAttributes[key].nametoDisplay}
                    <div className="details">
                      {/* <i title={mainAttributes[key].varDescription}><FontAwesomeIcon icon ={faCircleInfo} /></i> */}
                      {/* <i onClick={() => handleAddAttribute(key)} style={{paddingLeft:10, paddingRight:10}}>G</i> */}
                      <i onClick={() => handleAddAttribute(key)}>
                        <FontAwesomeIcon icon={faAdd} />
                      </i>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col1">
              <h5>All Selected Attributes</h5>
              <div className="all_list">
                <div className="main_attribute">
                  <h6>Main Attributes</h6>
                  {Object.keys(selectedAttributes).length === 0 ? (
                    <NoDataInAttributes NodataHeading="Please select Attributes" />
                  ) : (
                    Object.keys(selectedAttributes).map((key, index) => (
                      <div key={index} className="single_attribute">
                        {selectedAttributes[key].nametoDisplay}

                        <div className="details">
                          {selectedAttributes[key].repeat ? (
                            <RepetableIcon />
                          ) : (
                            <NonRepetableIcon />
                          )}

                          {selectedAttributes[key].unique ? (
                            <UniqueIcon />
                          ) : (
                            <NonUniqueIcon />
                          )}

                          <div className="checkbox-wrapper-3">
                            <input
                              type="checkbox"
                              id={`cbx-${index}`}
                              checked={selectedAttributes[key].mandatory}
                              onChange={() => {
                                const updatedAttributes = {
                                  ...selectedAttributes,
                                  [key]: {
                                    ...selectedAttributes[key],
                                    mandatory:
                                      !selectedAttributes[key].mandatory,
                                  },
                                };
                                setSelectedAttributes(updatedAttributes);
                              }}
                            />
                            <label htmlFor={`cbx-${index}`} className="toggle">
                              <span></span>
                            </label>
                          </div>
                          <i
                            title={`Data type- ${selectedAttributes[key].type}\nDescription- ${selectedAttributes[key].varDescription}`}
                          >
                            {/* <FontAwesomeIcon icon={faCircleInfo} /> */}
                            <img src={importicon} />
                          </i>
                          <i
                            onClick={() => handleAddAttributeToGroup(key)}
                            style={{ paddingLeft: 5, paddingRight: 5 }}
                          >
                            <img src={mains} />
                          </i>
                          <i onClick={() => handleRemoveAttribute(key)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </i>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className="new_attributes">
                  <h6>New added Attributes</h6>
                  {Object.keys(selectedNewAttributes).length === 0 ? (
                    <NoDataInAttributes NodataHeading="Please Add new Attributes" />
                  ) : (
                    <>
                      {Object.keys(selectedNewAttributes).map((key, index) => (
                        <div key={index} className="single_attribute">
                          {selectedNewAttributes[key].nametoDisplay}
                          <div className="details">
                            {selectedNewAttributes[key].repeat ? (
                              <RepetableIcon />
                            ) : (
                              <NonRepetableIcon />
                            )}

                            {selectedNewAttributes[key].unique ? (
                              <UniqueIcon />
                            ) : (
                              <NonUniqueIcon />
                            )}

                            <div className="checkbox-wrapper-3">
                              <input
                                type="checkbox"
                                id={`cbx-new-${index}`}
                                checked={selectedNewAttributes[key].mandatory}
                                onChange={() => {
                                  const updatedAttributes = {
                                    ...selectedNewAttributes,
                                  };
                                  updatedAttributes[key] = {
                                    ...updatedAttributes[key],
                                    mandatory:
                                      !updatedAttributes[key].mandatory,
                                  };
                                  setSelectedNewAttributes(updatedAttributes);
                                }}
                              />
                              <label
                                htmlFor={`cbx-new-${index}`}
                                className="toggle"
                              >
                                <span></span>
                              </label>
                            </div>

                            <i
                              title={`Data type- ${
                                selectedNewAttributes[key].type
                              }\nDescription-${
                                selectedNewAttributes[key].varDescription.trim()
                                  .length === 0
                                  ? "No description found"
                                  : selectedNewAttributes[key].varDescription
                              }`}
                            >
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </i>

                            <i
                              onClick={() => handleAddNewAttributeToGroup(key)}
                              style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                              S
                            </i>

                            <i onClick={() => handleRemoveNewAttribute(key)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </i>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {/* Displaying the sets of attributes */}
                {Object.keys(attSets).length > 0 ? (
                  <>
                    <h6>Sets of Attributes</h6>
                    {Object.entries(attSets).map(([itemName, value]) => (
                      <div key={itemName} className="att_set_container">
                        {Object.keys(value.groupsName).map((name, index) => (
                          <div className="att_set_item" key={index}>
                            {value.groupsName[name].nametoDisplay}
                          </div>
                        ))}
                        {Object.keys(value.attgroups).map((key, index) => (
                          <div className="att_sets_attribute" key={index}>
                            {value.attgroups[key].nametoDisplay}
                          </div>
                        ))}
                        <div className="add_att_box">
                          <button
                            onClick={() => handleEditSet(itemName, value)}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {/* Groups of the attributes ===== */}
          {/* Groups of the attributes ===== */}
          {(Object.keys(groupAtt).length > 0 ||
            Object.keys(groupNewAtt).length > 0) && (
            <div className="sets_of_selected">
              <div className="col1">
                <h6>Add Attributes Set</h6>
                <h4>All selected attributes for set</h4>
                {Object.keys(groupAtt).map((key, index) => (
                  <div key={index} className="selected_for_group">
                    <span>{groupAtt[key].nametoDisplay}</span>
                    <i onClick={() => handleRemoveAttributeFromGroup(key)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </i>
                    <i
                      title={`Data type- ${groupAtt[key].type}\nDescription-${
                        groupAtt[key].varDescription.trim().length === 0
                          ? "No description found"
                          : groupAtt[key].varDescription
                      }`}
                    >
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </i>
                  </div>
                ))}
                {Object.keys(groupNewAtt).map((key, index) => (
                  <div key={index} className="selected_for_group">
                    <span>{groupNewAtt[key].nametoDisplay}</span>
                    <i onClick={() => handleRemoveNewAttributeFromGroup(key)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </i>
                    <i
                      title={`Data type- ${
                        groupNewAtt[key].type
                      }\nDescription-${
                        groupNewAtt[key].varDescription.trim().length === 0
                          ? "No description found"
                          : groupNewAtt[key].varDescription
                      }`}
                    >
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </i>
                  </div>
                ))}
              </div>
              <div className="col1">
                <h4>Attributes set names</h4>
                <div className="sets_box">
                  {Object.keys(groupAttNames).map((name, index) => (
                    <div className="selected_for_group" key={index}>
                      {groupAttNames[name].nametoDisplay}
                      <i onClick={() => handleRemoveGroupName(name)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </i>
                    </div>
                  ))}
                </div>
                <div className="search_box">
                  <input
                    className="table_input"
                    placeholder="Enter set name"
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span onClick={handleAddGroupNames}>Set name</span>
                </div>
              </div>
              <div className="save_btn_box">
                <button
                  onClick={handleAddToAttSets}
                  className="add_new_attribute_btn_"
                >
                  Add set
                </button>
              </div>
            </div>
          )}

          <div className="add_new">
            <div className="new_field_container">
              <h6>Add New Attribute</h6>
              <div className="col">
                <div className="_input_box">
                  <input
                    className="table_input"
                    type="text"
                    required
                    name="nametoDisplay"
                    value={newAttributeInput.nametoDisplay}
                    onChange={handleNewAttributeInputChange}
                    placeholder="Attribute name"
                  />
                  <span>Attribute name</span>
                </div>

                <div className="_input_box">
                  <input
                    className="table_input"
                    type="text"
                    required
                    name="varDescription"
                    value={newAttributeInput.varDescription}
                    onChange={handleNewAttributeInputChange}
                    placeholder="Attribute description"
                  />
                  <span>Attribute description</span>
                </div>
              </div>
              <div className="col">
                <select
                  className="data_type_select"
                  type="text"
                  name="type"
                  value={newAttributeInput.type}
                  onChange={handleNewAttributeInputChange}
                >
                  <option value="" className="select_step_value">
                    Select the attribute data type
                  </option>
                  <option value="String" className="select_step_value">
                    Text - single line
                  </option>
                  <option value="Number" className="select_step_value">
                    Number
                  </option>
                  <option value="Decimal" className="select_step_value">
                    Decimal
                  </option>
                  <option value="Dropdown" className="select_step_value">
                    Dropdown
                  </option>
                  <option value="NestedDropdown" className="select_step_value">
                    Nested Dropdown
                  </option>
                  <option value="MultiSelect" className="select_step_value">
                    Multi-select
                  </option>
                  <option value="Image" className="select_step_value">
                    Image
                  </option>
                  <option value="Percent" className="select_step_value">
                    Percent
                  </option>
                  <option value="Date" className="select_step_value">
                    Date
                  </option>
                  <option value="DateTime" className="select_step_value">
                    Date and Time
                  </option>
                  <option value="Email" className="select_step_value">
                    Email
                  </option>
                  <option value="URL" className="select_step_value">
                    URL
                  </option>
                  <option value="Phone" className="select_step_value">
                    Phone
                  </option>
                </select>

                <div>
                  <div className="checkbox-wrapper-3 toggle_">
                    <input
                      type="checkbox"
                      id="cbx-new-mandatory"
                      onChange={(e) =>
                        handleNewAttributeInputChange({
                          target: {
                            name: "mandatory",
                            value: e.target.checked,
                          },
                        })
                      }
                      checked={newAttributeInput.mandatory === true}
                    />
                    <label htmlFor="cbx-new-mandatory" className="toggle">
                      <span></span>
                    </label>
                    <span className="custom-radio-button">
                      {newAttributeInput.mandatory
                        ? "Mandatory"
                        : "Non Mandatory"}
                    </span>
                  </div>
                  <div className="checkbox-wrapper-3 toggle_">
                    <input
                      type="checkbox"
                      id="cbx-new-repeat"
                      onChange={(e) =>
                        handleNewAttributeInputChange({
                          target: { name: "repeat", value: e.target.checked },
                        })
                      }
                      checked={newAttributeInput.repeat === true}
                    />
                    <label htmlFor="cbx-new-repeat" className="toggle">
                      <span></span>
                    </label>
                    <span className="custom-radio-button">
                      {newAttributeInput.repeat ? (
                        <span>
                          <RepetableIcon /> <div>Repeatable</div>
                        </span>
                      ) : (
                        <span>
                          <NonRepetableIcon />
                          <div>Non repeatable</div>{" "}
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="checkbox-wrapper-3 toggle_">
                    <input
                      type="checkbox"
                      id="cbx-new-unique"
                      onChange={(e) =>
                        handleNewAttributeInputChange({
                          target: { name: "unique", value: e.target.checked },
                        })
                      }
                      checked={newAttributeInput.unique === true}
                    />
                    <label htmlFor="cbx-new-unique" className="toggle">
                      <span></span>
                    </label>
                    <span className="custom-radio-button">
                      {newAttributeInput.unique ? (
                        <span>
                          <UniqueIcon /> <div>Unique</div>
                        </span>
                      ) : (
                        <span>
                          <NonUniqueIcon />
                          <div>Non Unique</div>{" "}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              {newAttributeInput.type === "Dropdown" ||
              newAttributeInput.type === "MultiSelect" ? (
                <div className="options-container">
                  <h6>Add the options</h6>
                  {options.map((option, index) => (
                    <div key={index} className="option-item">
                      <span>{option}</span>
                      <i onClick={() => handleRemoveOption(option)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </i>
                    </div>
                  ))}
                  <div className="search_box">
                    <input
                      className="table_input"
                      type="text"
                      placeholder="Option"
                      value={newOption}
                      onChange={(e) => setNewOption(e.target.value)}
                    />
                    <span onClick={handleAddOption}>Add option</span>
                  </div>
                </div>
              ) : null}

              {/* ======================================= nested ================================ */}

              {newAttributeInput.type === "NestedDropdown" && (
                <>
                  <div className="options-container">
                    <div className="child_attribute_box">
                      <div className="_input_box">
                        <input
                          className="table_input"
                          type="text"
                          required
                          name="nametoDisplay2"
                          value={newAttributeInput.nametoDisplay2}
                          onChange={handleNewAttributeInputChange}
                        />
                        <span>Add child attribute name</span>
                      </div>
                      <select
                        className="data_type_select"
                        value={newAttributeInput.childDataType}
                        onChange={(e) =>
                          handleNewAttributeInputChange({
                            target: {
                              name: "childDataType",
                              value: e.target.value,
                            },
                          })
                        }
                      >
                        <option value="" className="select_step_value">
                          Select the child attribute data type
                        </option>
                        <option value="String" className="select_step_value">
                          Text - single line
                        </option>
                        <option value="Number" className="select_step_value">
                          Number
                        </option>
                        <option value="Decimal" className="select_step_value">
                          Decimal
                        </option>
                        <option value="Dropdown" className="select_step_value">
                          Dropdown
                        </option>
                        <option value="Percent" className="select_step_value">
                          Percent
                        </option>
                        <option value="Date" className="select_step_value">
                          Date
                        </option>
                        <option value="DateTime" className="select_step_value">
                          Date and Time
                        </option>
                        <option value="Email" className="select_step_value">
                          Email
                        </option>
                        <option value="URL" className="select_step_value">
                          URL
                        </option>
                        <option value="Phone" className="select_step_value">
                          Phone
                        </option>
                      </select>
                    </div>

                    {newAttributeInput.childDataType === "Dropdown" && (
                      <>
                        <h6>Add the options</h6>
                        <div className="nested_option_container">
                          {options.map((parentOption, index) => (
                            <div key={index} className="nested-option-item">
                              <div className="nested-option-header">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    paddingRight: 10,
                                  }}
                                >
                                  {parentOption}
                                </span>
                                <i
                                  onClick={() =>
                                    handleRemoveParentOption(index)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </i>
                              </div>
                              <div className="nested-options">
                                {nestedOptions[index].map(
                                  (nestedOption, nestedIndex) => (
                                    <div
                                      key={nestedIndex}
                                      className="nested-option"
                                    >
                                      <span
                                        style={{
                                          fontSize: 13,
                                          paddingRight: 10,
                                        }}
                                      >
                                        {nestedOption}
                                      </span>
                                      <i
                                        onClick={() =>
                                          handleRemoveNestedOption(
                                            index,
                                            nestedIndex
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </i>
                                    </div>
                                  )
                                )}
                                <div className="search_box">
                                  <input
                                    type="text"
                                    placeholder="Add child option"
                                    value={newNestedOptions[index]}
                                    onChange={(e) =>
                                      handleNewNestedOptionChange(e, index)
                                    }
                                  />
                                  <span
                                    onClick={() => handleAddNestedOption(index)}
                                  >
                                    Add
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="search_box">
                          <input
                            type="text"
                            placeholder=" Add parent option"
                            value={newParentOption}
                            onChange={(e) => setNewParentOption(e.target.value)}
                          />
                          <span onClick={handleAddParentOption}>
                            Parent Option
                          </span>
                        </div>
                      </>
                    )}
                    {newAttributeInput.childDataType !== "Dropdown" && (
                      <>
                        <h6>Add the options</h6>
                        <div className="nested_option_container">
                          {options.map((parentOption, index) => (
                            <div key={index} className="nested-option-item">
                              <div className="nested-option-header">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    paddingRight: 10,
                                  }}
                                >
                                  {parentOption}
                                </span>
                                <i
                                  onClick={() =>
                                    handleRemoveParentOption(index)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </i>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="search_box">
                          <input
                            type="text"
                            placeholder=" Add parent option"
                            value={newParentOption}
                            onChange={(e) => setNewParentOption(e.target.value)}
                          />
                          <span onClick={handleAddParentOption}>
                            Parent Option
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              <div className="add_attribute_btn_container">
                <button
                  className="add_new_attribute_btn_"
                  onClick={handleAddNewAttributes}
                >
                  Add Attribute
                </button>
              </div>
            </div>

            {/* <div className="group_info">
                        <div className='_input_box'>
                            <input className='table_input' type="text" required  value={groupName} onChange={(e)=>setGroupName(e.target.value)}/>
                            <span>Give the list name</span>
                        </div>
                        <div className='_input_box'>
                            <input className='table_input' type="text" required value={groupDescription} onChange={(e)=> setGroupDescription(e.target.value)}/>
                            <span>Give the list description</span>
                        </div>
                    </div> */}
          </div>
        </div>

        <div className="attributes_action_container">
          <button
            className={
              status === STATUSES.LOADING ? "common_btn disabled" : "common_btn"
            }
            onClick={submitAttributesData}
            disabled={status === STATUSES.LOADING}
          >
            {status === STATUSES.LOADING ? <LoaderCircle /> : "Save"}
          </button>

          {/* <button onClick={submitAttributesData} className="common_btn" >Save</button> */}
          {/* <button onClick={handleCancle} className="common_btn2" >Cancel</button> */}
          <button onClick={handelClose} className="common_btn3">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAttributeGroup;

export const RepetableIcon = () => {
  return (
    <em className="repetable_icon addfa">
      <FontAwesomeIcon icon={faAdd} />
    </em>
  );
};
export const NonRepetableIcon = () => {
  return (
    <em className="repetable_icon non addfa">
      <FontAwesomeIcon icon={faAdd} />
    </em>
  );
};

export const UniqueIcon = () => {
  return (
    <em className="repetable_icon starfa">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
      </svg>
    </em>
  );
};
export const NonUniqueIcon = () => {
  return (
    <em className="repetable_icon non starfa">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
      </svg>
    </em>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import "./AdminHomeData.css";
import { BarChart,Bar,  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer, ComposedChart, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, PieChart, Pie, LineChart, Line, LabelList, Rectangle } from 'recharts';
import 'animate.css';
import { getAllProgram } from "../../../../../store/Slices/marketMapProgram";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import STATUSES from "../../../../../store/Statuses";
import Loader from "../../../../Common/Loader/Loader";
import img from '../../../../../assets/Images/MarketMaps/AddData.png'
import { getAllFilteredProgram } from "../../../../../store/Slices/marketMapGetAllFilter";

const AdminHomeData = () => {

  const dispatch = useDispatch();
  // const navigate = useNavigate();


  // const { token, userDetails } = useSelector((state) => state.auth);
  // useEffect(()=>{
  //   if(!token && !userDetails){
  //     navigate('/login');
  //   }
  // }, [])

  useEffect(()=>{
    dispatch(getAllProgram("Program"));
    dispatch(getAllFilteredProgram({classNameToFetch :"Program2"}));
  },[]);

  // Fetch data status and error for selected table
  const { data :tableData, status :tableStatus, error:tableError} = useSelector((state) => state.AllPrograms);
  const { data :tableData2, status :tableStatus2, error:tableError2} = useSelector((state) => state.GetAllFilteredProgram);

  console.log(tableData2);
  console.log("tableData IN HOME", tableData);

  const [barChartData, setBarChartData] = useState([]);
  const [areaChartData, setAreaChartData] = useState([]);
  const [vBarChartData, setvBarChartData] = useState([]);
  const [radarChartData, setRadarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData2, setBarChartData2] = useState([]);


  const filterData = useCallback(()=>{
    if(tableData && tableData.length>0 && typeof tableData !== "string"){
      const aggregatedData = tableData.reduce((acc, item) => {
        const retailerName = item.retailers.length > 0 ? item.retailers[0].groupAttributes["Retailer name"] || "Unknown Retailer" : "Unknown Retailer";
        const yearlyVolume = Number(item.groupAttributes["Yearly Volume of the program"]) || 0;

        if (!acc[retailerName]) {
          acc[retailerName] = { "Retailer name": retailerName, "Yearly Volume of the program": 0 };
        }

        acc[retailerName]["Yearly Volume of the program"] += yearlyVolume;

        return acc;
      }, {});

      const newDataArray = Object.values(aggregatedData);
      const sortedData = newDataArray.sort((a, b) => b["Yearly Volume of the program"] - a["Yearly Volume of the program"]);
      const top10Data = sortedData.slice(0, 10);

      setBarChartData(top10Data);

      // const newDataArray2 = tableData.map(item => ({
      //   "Program Name": item.groupAttributes["Program name"] || "",
      //   "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
      //   "Retailer name": item.retailers.length > 0 ? item.retailers[0].groupAttributes["Retailer name"] || "" : "",
      // }));
      // setAreaChartData(newDataArray2);

      // const newDataArray3 = tableData.map(item => ({
      //   "Program Name": item.groupAttributes["Program name"] || "",
      //   "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
      //   "Supplier name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
      // }));
      // setvBarChartData(newDataArray3);

      // const newDataArray4 = tableData.map(item => ({
      //   "Program Name": item.groupAttributes["Program name"] || "",
      //   "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
      //   "Annual sale":  Number(item.groupAttributes["Annual sale"]) || 0,
      //   "Supplier name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
      //   "Manufacturer name": item.manufacturers.length > 0 ? item.manufacturers[0].groupAttributes["Manufacturer name"] || "" : "",
      // }));
      // setRadarChartData(newDataArray4);

      const newDataArray5 = tableData.map(item => ({
        "name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
      }));
      setPieChartData(newDataArray5);

      const aggregatedSupplierData = tableData.reduce((acc, item) => {
        const supplierName = item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "Unknown Supplier" : "Unknown Supplier";
        const yearlyVolume = Number(item.groupAttributes["Yearly Volume of the program"]) || 0;

        if (!acc[supplierName]) {
          acc[supplierName] = { "Supplier name": supplierName, "Yearly Volume of the program": 0 };
        }

        acc[supplierName]["Yearly Volume of the program"] += yearlyVolume;

        return acc;
      }, {});

      const newDataArray2 = Object.values(aggregatedSupplierData);
      const sortedData2 = newDataArray2.sort((a, b) => b["Yearly Volume of the program"] - a["Yearly Volume of the program"]);
      const top10Suppliers = sortedData2.slice(0, 10);

      setBarChartData2(top10Suppliers);
    }

  },[tableData]) 

  useEffect(()=>{
    if(tableStatus === STATUSES.SUCCESS){
      filterData();
    }
  },[filterData,tableStatus]);

  console.log("barChartData", barChartData)
  // console.log("area", areaChartData)
  // console.log("v Bar", vBarChartData)


  const [sheetbarChartData, setsheetBarChartData] = useState([]);

  const [sheetbarChartData2, setsheetBarChartData2] = useState([]);


  const filterData2 = useCallback(()=>{
    if(tableData2 && tableData2.length>0 && typeof tableData2 !== "string"){
      const aggregatedData = tableData2.reduce((acc, item) => {
        const retailerName = item.retailers.length > 0 ? item.retailers[0].groupAttributes["Retailer name"] || "Unknown Retailer" : "Unknown Retailer";
        const yearlyVolume = Number(item.groupAttributes["Yearly Volume of the program"]) || 0;

        if (!acc[retailerName]) {
          acc[retailerName] = { "Retailer name": retailerName, "Yearly Volume of the program": 0 };
        }

        acc[retailerName]["Yearly Volume of the program"] += yearlyVolume;

        return acc;
      }, {});

      const newDataArray = Object.values(aggregatedData);
      const sortedData = newDataArray.sort((a, b) => b["Yearly Volume of the program"] - a["Yearly Volume of the program"]);
      const top10Data = sortedData.slice(0, 10);

      setsheetBarChartData(top10Data);

      const aggregatedSupplierData = tableData2.reduce((acc, item) => {
        const supplierName = item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "Unknown Supplier" : "Unknown Supplier";
        const yearlyVolume = Number(item.groupAttributes["Yearly Volume of the program"]) || 0;

        if (!acc[supplierName]) {
          acc[supplierName] = { "Supplier name": supplierName, "Yearly Volume of the program": 0 };
        }

        acc[supplierName]["Yearly Volume of the program"] += yearlyVolume;

        return acc;
      }, {});

      const newDataArray2 = Object.values(aggregatedSupplierData);
      const sortedData2 = newDataArray2.sort((a, b) => b["Yearly Volume of the program"] - a["Yearly Volume of the program"]);
      const top10Suppliers = sortedData2.slice(0, 10);

      setsheetBarChartData2(top10Suppliers);
    }

  },[tableData2]) 

  useEffect(()=>{
    if(tableStatus2 === STATUSES.SUCCESS){
      filterData2();
    }
  },[filterData2,tableStatus2]);

  
  return <div className="admin_home_data">

    <div className="col">
      <h6> Towel - Top 10 Retailers and Yearly volume in USD million</h6>
      {
        tableStatus === STATUSES.LOADING ?(
          <Loader/>
        ) : tableStatus === STATUSES.ERROR ? (
          <div className="error_in_home_map">Error - Something went wrong</div>
        ): tableStatus === STATUSES.SUCCESS ?(<>{
          barChartData?.length >0 ?(
            <ResponsiveContainer width="100%" height="95%">
              
            <BarChart data={barChartData} margin = {{top: 15,right: 30,left: 0,bottom: 10}}>

              <XAxis dataKey={"Retailer name"} stroke="#000000" tick={<CustomXAxisTick width={barChartData.length ? 100 / barChartData.length : 0} />} interval={0} angle={-15}  textAnchor="end" />
              <YAxis stroke="#000000" tick={{ fontSize: 11 }} />
              <Tooltip />
             
              <Bar dataKey="Yearly Volume of the program" fill="#ff6384" activeBar={<Rectangle fill="#ff1345" />}>
                <LabelList dataKey={"Yearly Volume of the program"} position="top" style={{ fontSize: 11 }} formatter={(value) => { if (typeof value === 'number') { return value.toFixed(0); } else if (typeof value === 'string') { return null; } return value; }} />
              </Bar>
              
            </BarChart>
            </ResponsiveContainer>
          ): <NoDataAtHomeMap/>
        }</>):null
      }
    </div>
    <div className="col">
        <h6>Towel - Top 10 Suppliers and Yearly volume in USD million</h6>
        {
          tableStatus === STATUSES.LOADING ? (
            <Loader />
          ) : tableStatus === STATUSES.ERROR ? (
            <div className="error_in_home_map">Error - Something went wrong</div>
          ) : tableStatus === STATUSES.SUCCESS ? (
            <>
              {
                barChartData2.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={barChartData2} layout="vertical" margin={{top: 5,right: 25,left: 10,bottom: 5}} >
                      <YAxis dataKey="Supplier name" type="category" tick={{ fontSize: 9 }} />
                       <XAxis type="number" stroke="#000000" tick={{ fontSize: 11 }}/>
                      <Tooltip />
                      <Bar dataKey="Yearly Volume of the program" fill="#36a2eb" activeBar={<Rectangle fill="blue" />}>
                        <LabelList dataKey={"Yearly Volume of the program"} position="right" style={{ fontSize: 11 }} formatter={(value) => { if (typeof value === 'number') { return value.toFixed(0); } else if (typeof value === 'string') { return null; } return value; }} />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : <NoDataAtHomeMap />
              }
            </>
          ) : null
        }
     
    </div>


  <div className="col">
  <h6> Sheeting - Top 10 Retailers and Yearly volume in USD million</h6>
  {
    tableStatus2 === STATUSES.LOADING ?(
      <Loader/>
    ) : tableStatus2 === STATUSES.ERROR ? (
      <div className="error_in_home_map">Error - Something went wrong</div>
    ): tableStatus2 === STATUSES.SUCCESS ?(<>{
      sheetbarChartData?.length >0 ?(
        <ResponsiveContainer width="100%" height="95%">
        <BarChart data={sheetbarChartData}  margin = {{top: 15,right: 30,left: 0,bottom: 10}}>

          <XAxis dataKey={"Retailer name"} stroke="#000000" tick={<CustomXAxisTick width={barChartData.length ? 100 / barChartData.length : 0} />} interval={0} angle={-15} textAnchor="end"/>
          <YAxis stroke="#000000" tick={{ fontSize: 11 }} />
          <Tooltip />

          <Bar dataKey="Yearly Volume of the program"  fill="#36a2eb" activeBar={<Rectangle fill="blue" />}>
            <LabelList dataKey={"Yearly Volume of the program"} position="top" style={{ fontSize: 11 }} formatter={(value) => { if (typeof value === 'number') { return value.toFixed(0); } else if (typeof value === 'string') { return null; } return value; }} />
          </Bar>
        </BarChart>
        </ResponsiveContainer>
      ): <NoDataAtHomeMap/>
    }</>):null
  }
  </div>
  <div className="col">
    <h6>Sheeting - Top 10 Suppliers and Yearly volume in USD million</h6>
    {
      tableStatus2 === STATUSES.LOADING ? (
        <Loader />
      ) : tableStatus2 === STATUSES.ERROR ? (
        <div className="error_in_home_map">Error - Something went wrong</div>
      ) : tableStatus2 === STATUSES.SUCCESS ? (
        <>
          {
            sheetbarChartData2.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={sheetbarChartData2} layout="vertical" margin={{top: 5,right: 25,left: 10,bottom: 5}} >
                 <YAxis dataKey="Supplier name" type="category" tick={{ fontSize: 9 }} />
                  <XAxis type="number" stroke="#000000" tick={{ fontSize: 11 }}/>
                <Tooltip />
                <Bar dataKey="Yearly Volume of the program" fill="#ff6384" activeBar={<Rectangle fill="#ff1345" />} >
                  <LabelList dataKey={"Yearly Volume of the program"} position="right" style={{ fontSize: 11 }} formatter={(value) => { if (typeof value === 'number') { return value.toFixed(0); } else if (typeof value === 'string') { return null; } return value; }} />
                </Bar>
                </ComposedChart>
              </ResponsiveContainer>
            ) : <NoDataAtHomeMap />
          }
        </>
      ) : null
    }

  </div>
 


  </div>;
};

export default AdminHomeData;


export const NoDataAtHomeMap = () =>{
  return (
    <div className="no_data_at_home_maps">
      <img src={img} alt="No data" />
      <h6>No data found in the program to display in chart</h6>
    </div>
  )
}


export const CustomXAxisTick = ({ x, y, payload, width }) => {
  const text = payload.value;
  const words = text.split(' ');

  return (
    <g transform={`translate(${x + width / 2},${y + 10})`}>
      <text textAnchor="middle" fontSize={9}>
        {words.map((word, index) => (
          <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
            {word}
          </tspan>
        ))}
      </text>
    </g>
  );
};

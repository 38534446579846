import React from 'react';
import './AccessTableList.css';

const rolePermissions = {
    // Authorization: {
    //   User: { View: "Yes", Edit: "" },
    //   Manager: { View: "Yes", Edit: "" },
    //   Admin: { View: "Yes", Edit: "" }
    // },
    Dashboards: {
      User: { View: "Yes (Self)", Edit: "" },
      Manager: { View: "All", Edit: "" },
      Admin: { View: "All", Edit: "" }
    },
    Analytics: {
      User: { View: "", Edit: "" },
      Manager: { View: "All", Edit: "" },
      Admin: { View: "All", Edit: "All" }
    },
    Product: {
      User: { View: "Yes (Self)", Edit: "Yes (Self)" },
      Manager: { View: "All", Edit: "Yes (Team)" },
      Admin: { View: "All", Edit: "All" }
    },
    Retailer: {
      User: { View: "All", Edit: "Yes (Self)" },
      Manager: { View: "All", Edit: "Yes (Team)" },
      Admin: { View: "All", Edit: "All" }
    },
    Manufacturer: {
      User: { View: "All", Edit: "Yes (Self)" },
      Manager: { View: "All", Edit: "Yes (Team)" },
      Admin: { View: "All", Edit: "All" }
    },
    Supplier: {
      User: { View: "All", Edit: "Yes (Self)" },
      Manager: { View: "All", Edit: "Yes (Team)" },
      Admin: { View: "All", Edit: "All" }
    },
    Program: {
      User: { View: "All (Self)", Edit: "Yes (Self)" },
      Manager: { View: "All", Edit: "Yes (Team)" },
      Admin: { View: "All", Edit: "All" }
    },
    Settings: {
      User: { View: "All", Edit: "" },
      Manager: { View: "All", Edit: "" },
      Admin: { View: "All", Edit: "All" }
    },
    UserManagement: {
      User: { View: "", Edit: "" },
      Manager: { View: "All", Edit: "" },
      Admin: { View: "All", Edit: "All" }
    }
  };

const AccessTableList = () => {
  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <th rowSpan="2">ROLE</th>
            <th colSpan="2">User</th>
            <th colSpan="2">Manager</th>
            <th colSpan="2">Admin</th>
          </tr>
          <tr>
            <th>View</th>
            <th>Edit</th>
            <th>View</th>
            <th>Edit</th>
            <th>View</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(rolePermissions).map(([role, permissions]) => (
            <tr key={role}>
              <td>{role}</td>
              <td>{permissions.User.View}</td>
              <td>{permissions.User.Edit}</td>
              <td>{permissions.Manager.View}</td>
              <td>{permissions.Manager.Edit}</td>
              <td>{permissions.Admin.View}</td>
              <td>{permissions.Admin.Edit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccessTableList;

import React from 'react';
import './ProgramHome.css'
import bgimg from '../../../../../assets/Images/program.jpg';
import { Link } from 'react-router-dom';

const ProgramHome = () => {
  return (
    <div className='common_program_space' style={{backgroundColor:"white"}}>
        <h4 className='program_heading'>Programs</h4>
        <div className="program_home">
            <div className="col1">
                <img src={bgimg} alt="program" />
            </div>
            <div className="col1">
                <Link to={'view-All-Programs'} style={{textDecoration:"none"}}><button className='common_btn'>View All Programs</button></Link>
                <Link to={'add-New-Program'} style={{textDecoration:"none"}}><button className='common_btn2'>Add New Program</button></Link>
                {/* <Link to={'edit-All-Programs'} style={{textDecoration:"none"}}><button className='common_btn3'>Edit Program</button></Link> */}
            </div>
        
        </div>
    </div>
  )
}

export default ProgramHome;

// import home_img from '../../assets/Images/home.png';
import analysis_img from '../../assets/Images/analysis.png';
import allData_img from '../../assets/Images/allData.png';
import program_img from '../../assets/Images/program.png';
import setting_img from '../../assets/Images/sett.png';
import users_img from '../../assets/Images/users-solid.svg';
import dash_img from '../../assets/Images/dash.svg';
import program_img2 from '../../assets/Images/program2.svg';
// import lock_img from '../../assets/Images/lock.svg';


export const table_data = [
    {
      "Retailer" : "Retailer1",
      "Product" : "Towel",
      "Brand" : "Silver Range",
      "Region" : "ROW",
      "Country" : "South Africa",
      "Program" : "Silver Range",
      "No_of_Store" : "700",
      "No_of_Stores_for_Program" : "700",
      "Brand_Type" : "International Brand",
      "Product_Segmentation" : "",
      "TC_or_GSM" : "450",
      "Fiber" : "100% Cotton",
      "Blend_Details" : "100% Cotton",
      "Process_Type" : "PDD",
      "Marketing_Callout" : "",
      "Product_Size" : "Bath,Wash,Bathsheet",
      "Weave_or_Quality" : "Carded,Single Ply",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "",
      "PPI" : "",
      "No_of_picks" : "",
      "Product_Category" : "Sets (Towel)",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "",
      "Hem_type_for_sheets_and_border_for_towel" : "",
      "Program_Type" : "Replenishment",
      "Certification" : "Others",
      "Launch_Date" : "",
      "Program_Status" : "Running",
      "End_Month" : "",
      "Size_in_USD_Million" : "1.25",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "Carded,Single Ply",
      "Pile_Count" : "",
      "Selling_Channel" : "In-store,Retail",
      "Currency" : "USD",
      "Retail_Price" : "9.76",
      "Sales_Price" : "5.74",
      "EBIDTA" : "",
      "No_of_shades" : "8",
      "No_of_designs" : "1",
      "Manufacturer" : "Trident",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "India",
      "Customer_Rating" : "",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Rohit Mathur",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "200",
      "P_State" : "",


    },


    {
      "Retailer" : "Retailer2",
      "Product" : "Towel",
      "Brand" : "Ackermans Home",
      "Region" : "ROW",
      "Country" : "South Africa",
      "Program" : "Beach Towels",
      "No_of_Store" : "700",
      "No_of_Stores_for_Program" : "700",
      "Brand_Type" : "International Brand",
      "Product_Segmentation" : "Beach Towels",
      "TC_or_GSM" : "450",
      "Fiber" : "100% Cotton",
      "Blend_Details" : "100% Cotton",
      "Process_Type" : "Yarn Dyed",
      "Marketing_Callout" : "soft",
      "Product_Size" : "Bath,Bathsheet",
      "Weave_or_Quality" : "Carded,2 Ply,Jacquard",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "",
      "PPI" : "",
      "No_of_picks" : "",
      "Product_Category" : "Sets (Towel)",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "",
      "Hem_type_for_sheets_and_border_for_towel" : "",
      "Program_Type" : "Replenishment",
      "Certification" : "",
      "Launch_Date" : "",
      "Program_Status" : "Running",
      "End_Month" : "",
      "Size_in_USD_Million" : "0.25",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "Backwall",
      "Pile_Count" : "",
      "Selling_Channel" : "In-store,Retail",
      "Currency" : "USD",
      "Retail_Price" : "9.99",
      "Sales_Price" : "",
      "EBIDTA" : "",
      "No_of_shades" : "2",
      "No_of_designs" : "1",
      "Manufacturer" : "Others",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "Botswana",
      "Customer_Rating" : "",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Rohit Mathur",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "50",
      "P_State" : "",


    },


    {
      "Retailer" : "Retailer3",
      "Product" : "Towel",
      "Brand" : "Gold",
      "Region" : "EU",
      "Country" : "Denmark",
      "Program" : "Malung",
      "No_of_Store" : "2905",
      "No_of_Stores_for_Program" : "",
      "Brand_Type" : "Private",
      "Product_Segmentation" : "MDi",
      "TC_or_GSM" : "500",
      "Fiber" : "BCI Cotton",
      "Blend_Details" : "100% Cotton",
      "Process_Type" : "PDD",
      "Marketing_Callout" : "Washed,Easy Care",
      "Product_Size" : "Hand,Bath",
      "Weave_or_Quality" : "Dobby,Carded,Single Ply",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "56",
      "PPI" : "42",
      "No_of_picks" : "",
      "Product_Category" : "Good",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "Fashion Colours",
      "Hem_type_for_sheets_and_border_for_towel" : "Embroidery Hem",
      "Program_Type" : "Replenishment",
      "Certification" : "Oeko Tex,BCI",
      "Launch_Date" : "",
      "Program_Status" : "Running",
      "End_Month" : "",
      "Size_in_USD_Million" : "1.14",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "",
      "Pile_Count" : "",
      "Selling_Channel" : "In-store,Online,Retail",
      "Currency" : "USD",
      "Retail_Price" : "8.92",
      "Sales_Price" : "",
      "EBIDTA" : "",
      "No_of_shades" : "4",
      "No_of_designs" : "1",
      "Manufacturer" : "indus",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "pakistan",
      "Customer_Rating" : "4.1",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Madhur kumar",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "300",
      "P_State" : "",
    },


    {
      "Retailer" : "Retailer4",
      "Product" : "Towel",
      "Brand" : "Gold",
      "Region" : "EU",
      "Country" : "Denmark",
      "Program" : "Skagersvik",
      "No_of_Store" : "2905",
      "No_of_Stores_for_Program" : "",
      "Brand_Type" : "Private",
      "Product_Segmentation" : "High",
      "TC_or_GSM" : "500",
      "Fiber" : "BCI Cotton",
      "Blend_Details" : "100% BCI",
      "Process_Type" : "YDD",
      "Marketing_Callout" : "Easy Care",
      "Product_Size" : "Hand,Guest,Bath",
      "Weave_or_Quality" : "Carded,Single Ply",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "",
      "PPI" : "",
      "No_of_picks" : "",
      "Product_Category" : "Good",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "Special Yarn,Speciality Cotton",
      "Hem_type_for_sheets_and_border_for_towel" : "",
      "Program_Type" : "Promo",
      "Certification" : "Oeko Tex,Made In Green,BCI",
      "Launch_Date" : "",
      "Program_Status" : "Closed",
      "End_Month" : "",
      "Size_in_USD_Million" : "0.2",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "",
      "Pile_Count" : "",
      "Selling_Channel" : "In-store,Online,Retail",
      "Currency" : "USD",
      "Retail_Price" : "11.43",
      "Sales_Price" : "",
      "EBIDTA" : "",
      "No_of_shades" : "6",
      "No_of_designs" : "2",
      "Manufacturer" : "Trident",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "India",
      "Customer_Rating" : "4.5",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Madhur kumar",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "50",
      "P_State" : "",
    },


    {
      "Retailer" : "Retailer5",
      "Product" : "Towel",
      "Brand" : "Recznik",
      "Region" : "EU",
      "Country" : "Denmark",
      "Program" : "Backberg",
      "No_of_Store" : "2905",
      "No_of_Stores_for_Program" : "",
      "Brand_Type" : "Private",
      "Product_Segmentation" : "MID",
      "TC_or_GSM" : "500",
      "Fiber" : "100% Cotton",
      "Blend_Details" : "100% Cotton",
      "Process_Type" : "PDD",
      "Marketing_Callout" : "",
      "Product_Size" : "Hand,Guest,Bath",
      "Weave_or_Quality" : "Combed",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "",
      "PPI" : "",
      "No_of_picks" : "",
      "Product_Category" : "Good",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "",
      "Hem_type_for_sheets_and_border_for_towel" : "",
      "Program_Type" : "Replenishment",
      "Certification" : "Oeko Tex",
      "Launch_Date" : "",
      "Program_Status" : "Closed",
      "End_Month" : "",
      "Size_in_USD_Million" : "",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "",
      "Pile_Count" : "",
      "Selling_Channel" : "Retail",
      "Currency" : "USD",
      "Retail_Price" : "14.81",
      "Sales_Price" : "",
      "EBIDTA" : "",
      "No_of_shades" : "6",
      "No_of_designs" : "",
      "Manufacturer" : "",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "",
      "Customer_Rating" : "",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Madhur kumar",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "",
      "P_State" : "",
    },


    {
      "Retailer" : "Retailer6",
      "Product" : "Towel",
      "Brand" : "Plus",
      "Region" : "EU",
      "Country" : "Denmark",
      "Program" : "Uppsala",
      "No_of_Store" : "2905",
      "No_of_Stores_for_Program" : "",
      "Brand_Type" : "Private",
      "Product_Segmentation" : "LOW",
      "TC_or_GSM" : "400",
      "Fiber" : "BCI Cotton",
      "Blend_Details" : "100% BCI Cotton",
      "Process_Type" : "PDD",
      "Marketing_Callout" : "Easy Care",
      "Product_Size" : "HHand,Guest,Bath,Wash",
      "Weave_or_Quality" : "Combed",
      "Warp_Count" : "",
      "Weft_Count" : "",
      "Finished_EPI" : "52",
      "PPI" : "37",
      "No_of_picks" : "",
      "Product_Category" : "Good",
      "Fitted_Sheet_depth_or_border_inches_from_hem" : "",
      "Attributes_Category" : "Special Yarn,Speciality Cotton",
      "Hem_type_for_sheets_and_border_for_towel" : "",
      "Program_Type" : "Replenishment",
      "Certification" : "Oeko Tex,BCI",
      "Launch_Date" : "",
      "Program_Status" : "Running",
      "End_Month" : "",
      "Size_in_USD_Million" : "1.5",
      "Exposure_in_Feet_in_width" : "",
      "Exposure_in_Feet_in_height" : "",
      "Product_Placement" : "",
      "Pile_Count" : "",
      "Selling_Channel" : "Retail",
      "Currency" : "USD",
      "Retail_Price" : "4.4",
      "Sales_Price" : "",
      "EBIDTA" : "",
      "No_of_shades" : "7",
      "No_of_designs" : "",
      "Manufacturer" : "Afroze",
      "Program_Breakup" : "",
      "Manufacturer_Country" : "Pakistan",
      "Customer_Rating" : "4",
      "Top_3_Positive_Comments" : "",
      "Top_3_Negative_Comments" : "",
      "Sample_available" : "",
      "Product_pic" : "",
      "Packed_product_picture" : "",
      "Hem_pic_for_sheets_or_border_pic_for_Towel" : "",
      "Account_Manager" : "Madhur kumar",
      "Marketing_Callout_Picture" : "" ,
      "Store_Display_Picture" : "",
      "P_MOP" : "",
      "P_Brightness_Percentage" : "",
      "P_Bulk_CCG" : "",
      "P_Volume_MT" : "590",
      "P_State" : "",
    },    
];

export const t_data =  [
    
    "No_of_Store",
    "No_of_Stores_for_Program",
    "Brand_Type",
    "Product_Segmentation",
    "TC_or_GSM",
    "Fiber",
    "Blend_Details",
    "Process_Type",
    "Marketing_Callout",
    "Product_Size",
    "Weave_or_Quality",
    "Warp_Count",
    "Weft_Count",
    "Finished_EPI",
    "PPI",
    "No_of_picks",
    "Product_Category",
    "Fitted_Sheet_depth_or_border_inches_from_hem",
    "Attributes_Category",
    "Hem_type_for_sheets_and_border_for_towel",
    "Program_Type",
    "Certification",
    "Launch_Date",
    "Program_Status",
    "End_Month",
    "Size_in_USD_Million",
    "Exposure_in_Feet_in_width",
    "Exposure_in_Feet_in_height",
    "Product_Placement",
    "Pile_Count",
    "Selling_Channel",
    "Currency",
    "Retail_Price",
    "Sales_Price",
    "EBIDTA",
    "No_of_shades",
    "No_of_designs",
    "Manufacturer",
    "Program_Breakup",
    "Manufacturer_Country",
    "Customer_Rating",
    "Top_3_Positive_Comments",
    "Top_3_Negative_Comments",
    "Sample_available",
    "Product_pic",
    "Packed_product_picture",
    "Hem_pic_for_sheets_or_border_pic_for_Towel",
    "Account_Manager",
    "Marketing_Callout_Picture",
    "Store_Display_Picture",
    "P_MOP",
    "P_Brightness_Percentage",
    "P_Bulk_CCG",
    "P_Volume_MT",
    "P_State",
 ]

export const MarketSidebarData = [
  {
    title: "Dashboard",
    path: "",
    title_icon :  <img src={dash_img} alt={"home"} id="dashboard_img"  />
  },
 
  {
    title: "Analytics",
    path: "",
    title_icon :  <img src={analysis_img} alt={"Analysis"} id="analysis_img"  />,
    subNav: [
      {
        title: "Bar Chart",
        path: "Bar-Chart",
      },
      {
        title: "Area Chart",
        path: "Area-Chart",
      },
      {
        title: "Vertical Bar",
        path: "Vertical-Bar-Chart",
      },
      {
        title: "Line Chart",
        path: "Line-Chart",
      },
      {
        title: "Stacked Bar",
        path: "Stacked-Bar-Chart",
      },
      {
        title: "Radar Chart",
        path: "Radar-Chart",
      },
      {
        title: "Pie Chart",
        path: "Pie-Chart",
      },
      {
        title: "Bubble Chart",
        path: "Bubble-Chart",
      },
    ],
  },

  {
    title: "All Data",
    path: "/Tables",
    title_icon : <img src={allData_img} alt={"All Data"} id="allData_img"  />,

    subNav: [
      {
        title: "All Tables",
        path: "Tables",
      },
      {
        title: "Products",
        path: "Tables/Product-Table",
      },
      {
        title: "Retailers",
        path: "Tables/Retailer-Table",
      },
      {
        title: "Manufacturer",
        path: "Tables/Manufacturer-Table",
      },
      {
        title: "Supplier",
        path: "Tables/Supplier-Table",
      },
      // {
      //   title: "Packaging Details",
      //   path: "Tables/Packaging-Table",
      // },
      // {
      //   title: "Marketing Callout",
      //   path: "Tables/Promotion-Table",
      // },
      // // {
      // //   title: "Program",
      // //   path: "Tables/Program-Table",
      // // },
      // {
      //   title: "Region",
      //   path: "Tables/Region-Table",
      // },
      // {
      //   title: "Customized Group",
      //   path: "Tables/Group-Table",
      // },
     
    ],
  },
 
  {
    title: "Program",
    path: "programs",
    title_icon : <img src={program_img2} alt={"Program"} id="program_img"  />,
  },
  {
    title: "Settings",
    path: "market-Map-Settings",
    title_icon : <img src={setting_img} alt={"Settings"} id="setting_img"  />,
  },
  {
    title: "Users management",
    path: "market-Map-Settings/users-management",
    title_icon : <img src={users_img} alt={"users_"} id="setting_img"  />,
  },
  // {
  //   title: "Data and security",
  //   path: "market-Map-Settings/users-management",
  //   title_icon : <img src={lock_img} alt={"data"} id="setting_img"  />,
  // },
];



export const ProductTableMainAttributes = {
  "Product name" : {
      type : "String",
      nametoDisplay : "Product name",
      varDescription : "This attribute is Product name and the data type is text",
      mandatory : true,
      repeat : false
  }
}

export const RetailerTableMainAttributes = {
  "Retailer name" : {
    type : "String",
    nametoDisplay : "Retailer name",
    varDescription : "This attribute is Retailer name and the data type is text",
    mandatory : true,
    repeat : false
  }
}

export const ManufacturerTableMainAttributes = {
  "Manufacturer name": {
    type : "String",
    nametoDisplay : "Manufacturer name",
    varDescription : "This attribute is manufacturer name and the data type is text",
    mandatory : true,
    repeat : false
  }
}

export const SupplierTableMainAttributes = {
  "Supplier name": {
    type : "String",
    nametoDisplay : "Supplier name",
    varDescription : "This attribute is Supplier name and the data type is text",
    mandatory : true,
    repeat : false
  }
}

export const PackagingTableMainAttributes = {
  "Packaging detail" : {
    type : "String",
    nametoDisplay : "Packaging material",
    varDescription : "This attribute is Packaging detail and the data type is text",
    mandatory : true,
    repeat : false
  }
}

export const PromotionTableMainAttributes = {
  "Marketing Callout" : {
    type : "String",
    nametoDisplay : "Promotion Name",
    varDescription : "This attribute is Marketing Callout Name and the data type is text",
    mandatory : true,
    repeat : false
  },
}

export const ProgramTableMainAttributes = {
  
  "Program name" : {
    type : "String",
    nametoDisplay : "Program name",
    varDescription : "This attribute is Program name and the data type is text",
    mandatory : true,
    repeat : false
  }
}

export const RegionTableMainAttributes = {
  
  "Region name" : {
    type : "String",
    nametoDisplay : "Region name",
    varDescription : "This attribute is Region name and the data type is text",
    mandatory : true,
    repeat : false
  },
}

export const GroupTableMainAttributes = {
  
  "Group name" : {
    type : "String",
    nametoDisplay : "Group name",
    varDescription : "This attribute is Group name and the data type is text",
    mandatory : true,
    repeat : false
  },
  
}
 







// import React, { useEffect, useState } from 'react';
// import './TableAddCommon.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import Loader from '../../../Common/Loader/Loader';
// import RenderWarning from '../RenderWarning/RenderWarning';
// import STATUSES from '../../../../store/Statuses';
// import Multiselect from 'multiselect-react-dropdown';

// const TableAddCommon = ({ 
//   title,
//   getAllAttributesLink,
//   getOneAttributesLink,
//   createTablelink,
//   resetTableState,
//   tableDataSelector,
//   allAttributesGroupSelector,
//   oneAttributeGroupSelector,
//   classNamePrefix,
//   linkForRedirectSetting,
//   additionalAttributesName,
//   getAllDataForReferenceLink,
//   getAllDataForReference
// }) => {
  
//   const dispatch = useDispatch();
 
//   //[1 ]Get All Attributes Group 
//   useEffect(()=>{
//     dispatch(getAllAttributesLink({}));
//   },[dispatch]);
  
//   const {data:AttributesGroupsData , status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector(allAttributesGroupSelector);


  
//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState("");
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(0); 

//   // Function to handle the selection change
//   const handleGroupSelection = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     setGroupNameToShowAttributes(e.target.value);
//     setSelectedGroupIndex(selectedIndex ); 
//   };

//   useEffect(()=>{
//     if(groupNameToShowAttributes.length>0){
//         dispatch(getOneAttributesLink(groupNameToShowAttributes));
//     }
//   },[dispatch ,groupNameToShowAttributes]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector(oneAttributeGroupSelector);

//   const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;

//   const {groupAttributes, [additionalAttributesName]: additionalAttributes, imagesAttributes} = singleAttributeGroupData;

//   // console.log(singleAttributeGroupData);
//   // console.log(groupNameToShowAttributes);
//   // console.log(selectedGroupIndex);
//   // console.log(classNameToSave);


  
//   //[3] Handel Submit The data 
//   const [groupInputData, setGroupInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});
//   const [ additionalValidationErrors,  setAdditionalValidationErrors] = useState({});
//   const [ groupValidationErrors,  setGroupValidationErrors] = useState({});


  
//   const validateGroup = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;
  
//     if (value === '') {
//       // Clear error if the value is empty
//       setGroupValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }
  
//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }
  
//     setGroupValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
  
//     return value;
//   };
  

//   const changeGroupInputData = (e) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     // Determine if the current field is a child attribute
//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = groupAttributes[parentName]?.type;
//     const childDataType = groupAttributes[parentName]?.childDataType;

//     if (fieldType) {
//         newValue = validateGroup(fieldType, newValue, name);
//     }

//     if (childDataType && isChildAttribute) {
//         newValue = validateGroup(childDataType, newValue, name);
//     }

//     // Convert numeric values to numbers
//     if (fieldType === 'Number' || fieldType === 'Decimal' || fieldType === 'Percent' ||
//         childDataType === 'Number' || childDataType === 'Decimal' || childDataType === 'Percent') {
//         newValue = newValue === '' ? '' : parseFloat(newValue);
//     }

//     // Clear mandatory error if data is entered
//     setGroupMandatoryErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: newValue ? null : prevErrors[name],
//     }));

//     setGroupInputData((prevData) => ({
//         ...prevData,
//         [name]: newValue,
//     }));
//   };
  
  
//   const  validateAdditional = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;
  
//     if (value === '') {
//       // Clear error if the value is empty
//        setAdditionalValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }
  
//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }
  
//      setAdditionalValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
  
//     return value;
//   };

//   // const changeAdditionalInputData = (e) => {
//   //   const { name, value } = e.target;
//   //   let newValue = value;

//   //   // Determine if the current field is a child attribute
//   //   const isChildAttribute = name.endsWith("nEsTeD");
//   //   const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//   //   const fieldType = additionalAttributes[parentName]?.type;
//   //   const childDataType = additionalAttributes[parentName]?.childDataType;

//   //   if (fieldType) {
//   //       newValue = validateAdditional(fieldType, newValue, name);
//   //   }

//   //   if (childDataType && isChildAttribute) {
//   //       newValue = validateAdditional(childDataType, newValue, name);
//   //   }

//   //   // Convert numeric values to numbers
//   //   if (fieldType === 'Number' || fieldType === 'Decimal' || fieldType === 'Percent' ||
//   //       childDataType === 'Number' || childDataType === 'Decimal' || childDataType === 'Percent') {
//   //       newValue = newValue === '' ? '' : parseFloat(newValue);
//   //   }

//   //   // Clear mandatory error if data is entered
//   //   setAdditionalMandatoryErrors((prevErrors) => ({
//   //       ...prevErrors,
//   //       [name]: newValue ? null : prevErrors[name],
//   //   }));

//   //   setAdditionalInputData((prevData) => ({
//   //       ...prevData,
//   //       [name]: newValue,
//   //   }));
//   // };
 
//   const changeAdditionalInputData = (e) => {
//     const { name, value, type } = e.target;
//     let newValue = value;

//     // Check if the value is an array (e.g., for multi-select inputs)
//     if (type === 'select-multiple') {
//         // Convert the NodeList to an array and extract selected values
//         const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
//         newValue = selectedOptions;
//     }

//     // Determine if the current field is a child attribute
//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = additionalAttributes[parentName]?.type;
//     const childDataType = additionalAttributes[parentName]?.childDataType;

//     if (fieldType) {
//         newValue = validateAdditional(fieldType, newValue, name);
//     }

//     if (childDataType && isChildAttribute) {
//         newValue = validateAdditional(childDataType, newValue, name);
//     }

//     // Convert numeric values to numbers
//     if (fieldType === 'Number' || fieldType === 'Decimal' || fieldType === 'Percent' ||
//         childDataType === 'Number' || childDataType === 'Decimal' || childDataType === 'Percent') {
//         newValue = newValue === '' ? '' : parseFloat(newValue);
//     }

//     // Clear mandatory error if data is entered
//     setAdditionalMandatoryErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: newValue ? null : prevErrors[name],
//     }));

//     setAdditionalInputData((prevData) => ({
//         ...prevData,
//         [name]: newValue,
//     }));
// };


  
//   console.log(" groupValidationErrors ==" ,  groupValidationErrors);
//   console.log(" additionalValidationErrors ==" ,  additionalValidationErrors);


//   // Inside the component, define a new state for mandatory errors
//   const [groupMandatoryErrors, setGroupMandatoryErrors] = useState({});
//   const [additionalMandatoryErrors, setAdditionalMandatoryErrors] = useState({});

//   const handleSubmitData = (e) => {
//     e.preventDefault();

//     // Check if there are validation errors
//     if (Object.values(groupValidationErrors).some(error => error !== null) ||
//         Object.values(additionalValidationErrors).some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }

//    // Filter out empty and trim values from inputData
//     const filteredInputData = Object.fromEntries(
//       Object.entries(groupInputData).filter(([key, value]) => 
//         value !== undefined &&
//         value !== null &&
//         (Array.isArray(value) ? value.length > 0 : (typeof value === 'string' ? value.trim() !== '' : true))
//       )
//     );    
//     const trimmedInputData = Object.fromEntries(
//       Object.entries(filteredInputData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
//     );

//     // Filter out empty and trim values from additionalInputData
//     const filteredAdditionalInputData = Object.fromEntries(
//       Object.entries(additionalInputData).filter(([key, value]) =>
//         value !== undefined &&
//         value !== null &&
//         (Array.isArray(value) ? value.length > 0 : (typeof value === 'string' ? value.trim() !== '' : true))
//       )
//     );
//     const trimmedAdditionalInputData = Object.fromEntries(
//       Object.entries(filteredAdditionalInputData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
//     );

//     // Reset mandatory errors states
//     const newGroupMandatoryErrors = {};
//     const newAdditionalMandatoryErrors = {};

//     // Check for mandatory fields in groupAttributes
//     Object.entries(groupAttributes).forEach(([key, value]) => {
//         if (value.mandatory && !trimmedInputData[key]) {
//             newGroupMandatoryErrors[key] = 'This field is mandatory';
//         }
//     });

//     // Check for mandatory fields in additionalAttributes
//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//         if (value.mandatory && !trimmedAdditionalInputData[key]) {
//             newAdditionalMandatoryErrors[key] = 'This field is mandatory';
//         }
//     });

//     // Check for mandatory fields in nested dropdowns for groupAttributes
//     Object.entries(groupAttributes).forEach(([fieldName, fieldData]) => {
//         if (fieldData.type === 'NestedDropdown') {
//             const nestedAttributeName = fieldName;
//             const childAttributeName = `${fieldName}nEsTeD`;
//             const nestedAttributeValue = trimmedInputData[nestedAttributeName];
//             const childAttributeValue = trimmedInputData[childAttributeName];
//             if ((!nestedAttributeValue || !childAttributeValue) && fieldData.mandatory) {
//                 newGroupMandatoryErrors[childAttributeName] = 'This field is mandatory';
//             }
//         }
//     });

//     // Check for mandatory fields in nested dropdowns for additionalAttributes
//     Object.entries(additionalAttributes).forEach(([fieldName, fieldData]) => {
//         if (fieldData.type === 'NestedDropdown') {
//             const nestedAttributeName = fieldName;
//             const childAttributeName = `${fieldName}nEsTeD`;
//             const nestedAttributeValue = trimmedAdditionalInputData[nestedAttributeName];
//             const childAttributeValue = trimmedAdditionalInputData[childAttributeName];
//             if ((!nestedAttributeValue || !childAttributeValue) && fieldData.mandatory) {
//                 newAdditionalMandatoryErrors[childAttributeName] = 'This field is mandatory';
//             }
//         }
//     });

//     // Update mandatory errors states for groupAttributes and additionalAttributes
//     setGroupMandatoryErrors(newGroupMandatoryErrors);
//     setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);

//     // Check if there are any mandatory errors
//     if (Object.values(newGroupMandatoryErrors).some(error => error !== null) ||
//         Object.values(newAdditionalMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }

//     const data = {
//         groupAttributes: trimmedInputData,
//         [additionalAttributesName]: trimmedAdditionalInputData ,
//         imagesAttributes : inputValues,
//         groupName: groupNameToShowAttributes,
//         className: classNameToSave,
//     };

//     dispatch(createTablelink(data));
//     console.log("data===", data);
//   };

//   // console.log([additionalAttributesName])
  

//   console.log("groupMandatoryErrors" , groupMandatoryErrors)
//   console.log("additionalMandatoryErrors" , additionalMandatoryErrors)

//   const {  data, status , error } = useSelector(tableDataSelector);

//   useEffect(()=>{
//     if (data && status===STATUSES.SUCCESS) {
//       toast.success("Created Successfully");
//       dispatch(resetTableState({}));
//     }
//     if (error){
//       toast.error(error);
//       dispatch(resetTableState({}));
//     }
//   }, [data ,status ,error ,dispatch]);


//   useEffect(() => {
//     // Clear input data when group name changes
//     setGroupInputData({});
//     setAdditionalInputData({});
//     setAddNestedDropdownValues({});
//     setGroupValidationErrors({});
//     setAdditionalValidationErrors({});
//     setGroupMandatoryErrors({});
//     setAdditionalMandatoryErrors({});

//     setInputValues({});
//     setNestedDropdownValues({});
//     setImageValidationErrors({});
//   }, [groupNameToShowAttributes]);


//   const [groupNestedDropdownValues, setGroupNestedDropdownValues] = useState({});
//   const handleGroupNestedDropdownSelection = (name, value) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     setGroupNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: value,
//       [nestedAttributeName]: '', // Reset nested value when parent changes
//     }));
  
//     // Clear mandatory error for parent and child attribute
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null,
//       [nestedAttributeName]: null,
//     }));
//   };
  
//   const [addNestedDropdownValues, setAddNestedDropdownValues] = useState({});
//   const handleAdditionalNestedDropdownSelection = (name, value) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     setAddNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: value,
//       [nestedAttributeName]: '', // Reset nested value when parent changes
//     }));
  
//     // Clear mandatory error for parent and child attribute
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null,
//       [nestedAttributeName]: null,
//     }));
//   };
  
//   const saveGroupMultiSelectValues = (name, selectedValues) => {
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
  
//     // Clear mandatory error for Multiselect field
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };
  
//   const saveAdditionlMultiSelectValues = (name, selectedValues) => {
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
  
//     // Clear mandatory error for Multiselect field
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };
  

//   console.log("groupInputData ====", groupInputData);
//   console.log("additionalInputData ====", additionalInputData);

//   // [4] Choose reference 
//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;
//       dispatch(getAllDataForReferenceLink(classNameToSave));
//     }
//   }, [singleAttributeGroupStatus]);

  
  
  
//   const {data:AllDataForReference , status:statusForReference , error:errorForReference  } = useSelector(getAllDataForReference);

//   console.log(AllDataForReference);

//   const handleReferenceSelection = (e) => {
//     const selectedReferenceId = e.target.value;

//     if (!selectedReferenceId) {
//         console.log("Selected reference ID is empty.");
//         return;
//     }

//     const selectedReference = AllDataForReference.find(ref => {
//         const keys = Object.keys(ref.groupAttributes);
//         const firstKey = keys.length > 0 ? keys[0] : null;
//         return ref.groupAttributes[firstKey] === selectedReferenceId;
//     });

//     if (!selectedReference) {
//         console.log("Selected reference not found in AllDataForReference.");
//         return;
//     }

//     const { groupAttributes: selectedGroupAttributes, [additionalAttributesName]: selectedAdditionalAttributes } = selectedReference;

//     setGroupInputData(selectedGroupAttributes);
//     setAdditionalInputData(selectedAdditionalAttributes);

//     // Update addNestedDropdownValues based on selectedAdditionalAttributes
//     const newAddNestedDropdownValues = {};
//     Object.keys(selectedAdditionalAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newAddNestedDropdownValues[parentKey] = selectedAdditionalAttributes[parentKey];
//             newAddNestedDropdownValues[childKey] = selectedAdditionalAttributes[childKey];
//         }
//     });
//     setAddNestedDropdownValues(newAddNestedDropdownValues);

//     // Update groupNestedDropdownValues based on selectedGroupAttributes
//     const newGroupNestedDropdownValues = {};
//     Object.keys(selectedGroupAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newGroupNestedDropdownValues[parentKey] = selectedGroupAttributes[parentKey];
//             newGroupNestedDropdownValues[childKey] = selectedGroupAttributes[childKey];
//         }
//     });
//     setGroupNestedDropdownValues(newGroupNestedDropdownValues);
//   };

//   const validateImageAttribute = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;
  
//     if (value === '') {
//       // Clear error if the value is empty
//       setImageValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }
  
//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }
  
//     setImageValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
  
//     return value;
//   };
  
  
  
//   const [inputValues, setInputValues] = useState({});
//   const [nestedDropdownValues, setNestedDropdownValues] = useState({});

//   const [imageValidationErrors, setImageValidationErrors] = useState({});

//   const handleInputChange = (name, selectedValues, type) => {
//     const attributeName = name;

//     let newValue = selectedValues;
//     console.log(name)
//     console.log(type)
//     console.log(selectedValues)

//     if (type) {
//       newValue = validateImageAttribute(type, newValue, attributeName);
//     }

//     if (type === 'Number' || type === 'Decimal' || type === 'Percent') {
//       newValue = newValue === '' ? '' : parseFloat(newValue);
//     }
//     setInputValues((prevInputValues) => ({
//       ...prevInputValues,
//       [attributeName]: newValue,
//     }));
//   };

//   const saveSetMultiSelectValues = (name, selectedValues) => {
//     setInputValues((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//   };

//   const handleSetNestedDropdownSelection = (imageKey, groupName, i, value) => {
//     const parentAttributeName = `${groupName} ${i}`;
//     const nestedAttributeName = `${parentAttributeName}nEsTeD`;

//     setNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [parentAttributeName]: value,
//     }));

//     setInputValues((prevData) => ({
//       ...prevData,
//       [parentAttributeName]: value,
//       [nestedAttributeName]: '', // Reset nested value when parent changes
//     }));
//     // Trigger validation for parent attribute
//     const parentType = imagesAttributes[imageKey].attgroups[i]?.type;
//     if (parentType) {
//       validateImageAttribute(parentType, value, parentAttributeName);
//     }
//   };


//   console.log("inputValues", inputValues);
//   console.log("nestedDropdownValues", nestedDropdownValues);
//   console.log("imageValidationErrors", imageValidationErrors);

//   return (
//     <>
//     <div className='common_table_page add_table_page'>

//     <div className="select_att_group_button_Container">
//       <h6>Add {title}</h6>
//       <select className='select_step_data' type="text" required value={groupNameToShowAttributes} onChange={handleGroupSelection} >
//         <option value="" className='select_step_value'>Select Group</option>
//         {
//           AttributeGroupStatus === STATUSES.LOADING ? (
//             <option className='error_option'>Loading...</option>
//           ) : 
//           AttributeGroupStatus === STATUSES.ERROR ? (
//             <option className='error_option'>Something went wrong {AttributeGroupError}</option>
//           ) : 
//           Object.keys(AttributesGroupsData).length > 0 && AttributesGroupsData.map((item, index) =>
//             <option value={item.groupName} className='select_step_value' key={item.groupId}>{item.groupName}</option>
//           )
//         }
//       </select>
//     </div>


// <> 
//   <div className="add_table_data_container">
//     {
//       AttributeGroupStatus === STATUSES.LOADING ? (
//         <Loader/>
//       ) : AttributeGroupStatus === STATUSES.SUCCESS ?(
//         (
//           Object.keys(AttributesGroupsData).length===0 ?(
//             <RenderWarning message={`Please create a group of attributes to add ${title.toLowerCase()}`} showLink={linkForRedirectSetting} />
//           ):(              
//               groupNameToShowAttributes.length===0?(
//                 <RenderWarning message={`Please select the group of attributes to add ${title.toLowerCase()}`} />
//               ):(
//                 <>
//               {
  
//               singleAttributeGroupStatus === STATUSES.LOADING?(
//                 <Loader/>
//               ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                 <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//               ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                   (Object.keys(groupAttributes).length===0 && Object.keys(additionalAttributes).length === 0 && Object.keys(imagesAttributes).length === 0)?(
//                   <>
//                     <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                     <RenderWarning message={`No attributes found to add ${groupNameToShowAttributes}`} />
//                   </>
//                   ):(
                    
//                   <>
//                     <div className='att_header'><span>Attributes Group - <strong>{singleAttributeGroupData?.groupName}</strong></span>
//                     <select className='select_step_data choosse_reference' type="text" required onChange={handleReferenceSelection}>
//                       <option value="" className='select_step_value'>Choose reference</option>
//                       {
//                         statusForReference === STATUSES.LOADING ? (
//                           <option className='error_option'>Loading...</option>
//                         ) :
//                         statusForReference === STATUSES.ERROR ? (
//                           <option className='error_option'>Something went wrong {errorForReference}</option>
//                         ) :
//                         statusForReference === STATUSES.SUCCESS ? (
//                           AllDataForReference && typeof AllDataForReference === "string" ? (
//                             <option className='error_option'>{`No ${title} found to choose reference`}</option>
//                           ):(
//                             AllDataForReference?.map((item, index) => {
//                               const firstKey = Object.keys(item.groupAttributes)[0]; // Get the first key dynamically
//                               return (
//                                   <option value={item.groupAttributes[firstKey]} className='select_step_value' key={index}>
//                                     {item.groupAttributes[firstKey]}
//                                   </option>
//                               );
//                           })
                          
//                           ) 
//                         ) : null
                       
//                       }
//                     </select>
//                     </div>
  
  
//                     <div className="all_attributes_to_add_data">

                       
//                     {
//                       Object.keys(groupAttributes).map((i, index) => {
                                              
//                         if(groupAttributes[i].type==="Date") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} >
//                               <input className='table_input' type="date" required name={i} onChange={changeGroupInputData} placeholder='' value={groupInputData[i] || ""}/>
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
                        
//                         else if(groupAttributes[i].type==="DateTime") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                               <input className='table_input' type="datetime-local" required name={i} onChange={changeGroupInputData} placeholder="" value={groupInputData[i] || ""} />
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
  
//                         else if(groupAttributes[i].type==="Image") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                               <input className='table_input image_input' type="file" required name={i} onChange={changeGroupInputData} value={groupInputData[i] || ""}/>
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(groupAttributes[i].type==="Dropdown") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i} onChange={changeGroupInputData} required placeholder='' value={groupInputData[i] || ""}>
//                                   <option value="">{}</option>
//                                   {groupAttributes[i].options.map((option, optionIndex) => (
//                                       <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{groupAttributes[i].nametoDisplay}</span>
//                                 {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(groupAttributes[i].type==="MultiSelect") {
//                           return(
//                             <div style={{position:"relative"}} key={index}>
//                               <Multiselect  isObject={false} options={groupAttributes[i].options} placeholder={groupAttributes[i].nametoDisplay} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} onSelect={(selectedList) => saveGroupMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveGroupMultiSelectValues(i, selectedList)} selectedValues={groupInputData[i] || []} />
//                               {groupMandatoryErrors[i] && <div className="input_error multiSelectError">{groupMandatoryErrors[i]}</div>}
//                             </div>
                          
//                           )
//                         } 
//                         else if (groupAttributes[i].type === "NestedDropdown") {
//                           return (
//                             <div className='nested_input' key={index}>
//                               <h1>pk</h1>
//                               <div className='_input_box' title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i}  onChange={(e) => handleGroupNestedDropdownSelection(i, e.target.value)} required value={groupInputData[i] || ''}>
//                                   <option value="">{""}</option>
//                                   {groupAttributes[i].options.map((option, optionIndex) => (
//                                     <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{groupAttributes[i].nametoDisplay}</span>
//                                 {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
  
//                               </div>
                              
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "Dropdown" ? (
//                                 <div className='_input_box' title={`Data type- ${groupAttributes[i].childDataType}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                   <select className='table_input' name={`${i}nEsTeD`} onChange={changeGroupInputData} required value={groupInputData[`${i}nEsTeD`] || ''}>
//                                     <option value="">{""}</option>
//                                     {groupAttributes[i].nestedOptions[groupAttributes[i].options.indexOf(groupNestedDropdownValues[i])].map((nestedOption, nestedIndex) => (
//                                       <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                     ))}
//                                   </select>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "Date" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="date" required name={`${i}nEsTeD`} onChange={changeGroupInputData} placeholder=""  value={groupInputData[`${i}nEsTeD`] || ''}/>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "DateTime" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="datetime-local" required name={`${i}nEsTeD`} onChange={changeGroupInputData} placeholder=""  value={groupInputData[`${i}nEsTeD`] || ''}/>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {groupNestedDropdownValues[i] && ( groupAttributes[i].childDataType === "String" || groupAttributes[i].childDataType === "Number" || groupAttributes[i].childDataType === "Decimal" || groupAttributes[i].childDataType === "Percent" || groupAttributes[i].childDataType === "Email" || groupAttributes[i].childDataType === "URL" || groupAttributes[i].childDataType === "Phone") ? (
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="text" required name={`${i}nEsTeD`} onChange={changeGroupInputData}  value={groupInputData[`${i}nEsTeD`] || ''} />
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   { groupValidationErrors[`${i}nEsTeD`] && <div className="input_error">{ groupValidationErrors[`${i}nEsTeD`]}</div>}
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                             </div>
//                           );
//                         }
                        
//                         else return (
//                           <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} >
//                             <input className='table_input' type="text" required name={i} onChange={changeGroupInputData} placeholder='' value={groupInputData[i] || ""}/>
//                             <span>{groupAttributes[i].nametoDisplay}</span>
//                             { groupValidationErrors[i] && <div className="input_error">{ groupValidationErrors[i]}</div>}
//                             {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                           </div>
//                         );
                        
//                       })
//                     }
                  
  
//                     {
//                       Object.keys(additionalAttributes).map((i, index) => {
                                              
//                         if(additionalAttributes[i].type==="Date") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`} >
//                               <input className='table_input' type="date" required name={i} onChange={changeAdditionalInputData} placeholder='' value={additionalInputData[i] || ""}/>
//                               <span>{additionalAttributes[i].nametoDisplay}</span>
//                               {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(additionalAttributes[i].type==="DateTime") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                               <input className='table_input' type="datetime-local" required name={i} onChange={changeAdditionalInputData} placeholder="" value={additionalInputData[i] || ""}/>
//                               <span>{additionalAttributes[i].nametoDisplay}</span>
//                               {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
                  
//                         else if(additionalAttributes[i].type==="Image") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                               <input className='table_input image_input' type="file" required name={i} onChange={changeAdditionalInputData} value={additionalInputData[i] || ""}/>
//                               <span>{additionalAttributes[i].nametoDisplay}</span>
//                               {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(additionalAttributes[i].type==="Dropdown") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i} onChange={changeAdditionalInputData} required placeholder='' value={additionalInputData[i] || ""}>
//                                   <option value="">{}</option>
//                                   {additionalAttributes[i].options.map((option, optionIndex) => (
//                                       <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{additionalAttributes[i].nametoDisplay}</span>
//                                 {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(additionalAttributes[i].type==="MultiSelect") {
//                           return(
//                             <div style={{position:"relative"}} key={index}>
//                               <Multiselect isObject={false} options={additionalAttributes[i].options} placeholder={additionalAttributes[i].nametoDisplay} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`} onSelect={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)} selectedValues={additionalInputData[i] || []}/>
//                               {additionalMandatoryErrors[i] && <div className="input_error multiSelectError">{additionalMandatoryErrors[i]}</div>}
//                             </div>
                          
//                           )
//                         } 
//                         else if (additionalAttributes[i].type === "NestedDropdown") {
//                           return (
//                             <div className='nested_input' key={index}>
                            
//                               <div className='_input_box' title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i} onChange={(e) => handleAdditionalNestedDropdownSelection(i, e.target.value)} required value={additionalInputData[i] || ''}>
//                                   <option value="">{""}</option>
//                                   {additionalAttributes[i].options.map((option, optionIndex) => (
//                                     <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{additionalAttributes[i].nametoDisplay}</span>
//                                 {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
  
//                               </div>
                              
//                               {addNestedDropdownValues[i] && additionalAttributes[i].childDataType === "Dropdown" ? (
//                                 <div className='_input_box' title={`Data type- ${additionalAttributes[i].childDataType}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                                   <select className='table_input' name={`${i}nEsTeD`} onChange={changeAdditionalInputData} required value={additionalInputData[`${i}nEsTeD`] || ''}>
//                                     <option value="">{""}</option>
//                                     {additionalAttributes[i].nestedOptions[additionalAttributes[i].options.indexOf(addNestedDropdownValues[i])].map((nestedOption, nestedIndex) => (
//                                       <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                     ))}
//                                   </select>
//                                   <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                   {additionalMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{additionalMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {addNestedDropdownValues[i] && additionalAttributes[i].childDataType === "Date" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="date" required name={`${i}nEsTeD`} onChange={changeAdditionalInputData} placeholder="" value={additionalInputData[`${i}nEsTeD`] || ''} />
//                                   <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                   {additionalMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{additionalMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                               {addNestedDropdownValues[i] && additionalAttributes[i].childDataType === "DateTime" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="datetime-local" required name={`${i}nEsTeD`} onChange={changeAdditionalInputData} placeholder="" value={additionalInputData[`${i}nEsTeD`] || ''}/>
//                                   <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                   {additionalMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{additionalMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {addNestedDropdownValues[i] && ( additionalAttributes[i].childDataType === "String" || additionalAttributes[i].childDataType === "Number" || additionalAttributes[i].childDataType === "Decimal" || additionalAttributes[i].childDataType === "Percent" || additionalAttributes[i].childDataType === "Email" || additionalAttributes[i].childDataType === "URL" || additionalAttributes[i].childDataType === "Phone") ? (
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="text" required name={`${i}nEsTeD`} onChange={changeAdditionalInputData} value={additionalInputData[`${i}nEsTeD`] || ''} />
//                                   <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                   { additionalValidationErrors[`${i}nEsTeD`] && <div className="input_error">{ additionalValidationErrors[`${i}nEsTeD`]}</div>}
//                                   {additionalMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{additionalMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                             </div>
//                           );
//                         }
                        
//                         else return (
//                           <div className='_input_box' key={index} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}>
//                             <input className='table_input' type="text" required name={i} onChange={changeAdditionalInputData} placeholder='' value={additionalInputData[i] || ""} />
//                             <span>{additionalAttributes[i].nametoDisplay}</span>
//                             { additionalValidationErrors[i] && <div className="input_error">{ additionalValidationErrors[i]}</div>}
//                             {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                           </div>
//                         );
                        
//                       })
//                     }

//                     <div className="all_set_table_">
//                           {Object.keys(imagesAttributes).length !== 0 && (
//                             <div className="image_attributes_table_container">
//                               <div>
//                                 {Object.keys(imagesAttributes).map((imageKey) => {
//                                   const { groupsName, attgroups } = imagesAttributes[imageKey];
//                                   return (
//                                     <div key={imageKey} className='one_set'>
//                                       <table>
//                                         <thead>
//                                           <tr>
//                                             <th></th>
//                                             {Object.keys(groupsName).map((groupName) => (
//                                               <th key={`${imageKey}_${groupName}`}>{groupsName[groupName].nametoDisplay}</th>
//                                             ))}
//                                           </tr>
//                                         </thead>
//                                         <tbody>
//                                           {Object.keys(attgroups).map((i) => (
//                                             <tr key={`${imageKey}_${i}`}>
//                                               <td>{attgroups[i].nametoDisplay}</td>
//                                               {Object.keys(groupsName).map((groupName) => (
//                                                 <td key={`${imageKey}_${groupName}_${i}`}>
//                                                   {attgroups[i].type === "Dropdown" ? (
//                                                     <>
//                                                       <select
//                                                         className='table_input'
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                         required
//                                                         placeholder=''
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "MultiSelect" ? (
//                                                     <>
//                                                       <Multiselect
//                                                         isObject={false}
//                                                         options={attgroups[i].options}
//                                                         placeholder={attgroups[i].nametoDisplay}
//                                                         onSelect={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         onRemove={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         selectedValues={inputValues[`${groupName} ${i}`] || []}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "Date" ? (
//                                                     <>
//                                                       <input
//                                                         type="date"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "DateTime" ? (
//                                                     <>
//                                                       <input
//                                                         type="datetime-local"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "NestedDropdown" ? (
//                                                     <>
//                                                       <select
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleSetNestedDropdownSelection(imageKey, groupName, i, e.target.value)}
//                                                         required
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{""}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Dropdown" && (
//                                                         <>
//                                                           <select
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             required
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           >
//                                                             <option value="">{""}</option>
//                                                             {attgroups[i].nestedOptions[attgroups[i].options.indexOf(nestedDropdownValues[`${groupName} ${i}`][0])].map((nestedOption, nestedIndex) => (
//                                                               <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                             ))}
//                                                           </select>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Date" && (
//                                                         <>
//                                                           <input
//                                                             type="date"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "DateTime" && (
//                                                         <>
//                                                           <input
//                                                             type="datetime-local"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && ["String", "Number", "Decimal", "Percent", "Email", "URL", "Phone"].includes(attgroups[i].childDataType) && (
//                                                         <>
//                                                           <input
//                                                             type="text"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                     </>
//                                                   ) : (
//                                                     <>
//                                                       <input
//                                                         type="text"
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   )}
//                                                 </td>
//                                               ))}
//                                             </tr>
//                                           ))}
//                                         </tbody>
//                                       </table>
//                                     </div>
//                                   );
//                                 })}
//                               </div>
//                             </div>
//                           )}
//                     </div>
  
  
                                                
//                     </div>
//                     <div className="submit_table_data_btn">
//                       <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Edit and Save</button>
//                       <button onClick={handleSubmitData} className ={'common_btn2'} style={{width: 300}}>Clone</button>
//                     </div>
//                   </>                          
//                   )
//                 ):null                    
//               }
//                 </>
//               )
//           )
//         )
//       ) :null
//     }
//   </div>
// </>
// </div>

//     </>
//   )
// }


// export default TableAddCommon;














// ==============================================================================================================
// ==============================================================================================================
// ==============================================================================================================

// import React, { useEffect, useState } from 'react';
// import './TableAddCommon.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import Loader from '../../../Common/Loader/Loader';
// import RenderWarning from '../RenderWarning/RenderWarning';
// import STATUSES from '../../../../store/Statuses';
// import Multiselect from 'multiselect-react-dropdown';

// const TableAddCommon = ({ 
//   title,
//   getAllAttributesLink,
//   getOneAttributesLink,
//   createTablelink,
//   resetTableState,
//   tableDataSelector,
//   allAttributesGroupSelector,
//   oneAttributeGroupSelector,
//   classNamePrefix,
//   linkForRedirectSetting,
//   additionalAttributesName,
//   getAllDataForReferenceLink,
//   getAllDataForReference
// }) => {
  
//   const dispatch = useDispatch();
 
//   //[1 ]Get All Attributes Group 
//   useEffect(()=>{
//     dispatch(getAllAttributesLink({}));
//   },[dispatch]);
  
//   const {data:AttributesGroupsData , status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector(allAttributesGroupSelector);


  
//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState("");
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(0); 

//   // Function to handle the selection change
//   const handleGroupSelection = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     setGroupNameToShowAttributes(e.target.value);
//     setSelectedGroupIndex(selectedIndex ); 
//   };

//   useEffect(()=>{
//     if(groupNameToShowAttributes.length>0){
//         dispatch(getOneAttributesLink(groupNameToShowAttributes));
//     }
//   },[dispatch ,groupNameToShowAttributes]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector(oneAttributeGroupSelector);

//   const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;

//   const {groupAttributes, [additionalAttributesName]: additionalAttributes, imagesAttributes} = singleAttributeGroupData;

//   // console.log(singleAttributeGroupData);
//   // console.log(groupNameToShowAttributes);
//   // console.log(selectedGroupIndex);
//   // console.log(classNameToSave);

  


  
//   //[3] Handel Submit The data 
//   const [groupInputData, setGroupInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});
//   const [ additionalValidationErrors,  setAdditionalValidationErrors] = useState({});
//   const [ groupValidationErrors,  setGroupValidationErrors] = useState({});


// //   useEffect(() => {
// //     // Initialize inputValues with at least one input field for each key
// //     if (singleAttributeGroupStatus === STATUSES.SUCCESS){
// //       setAdditionalInputData(prevState => {
// //           const updatedState = { ...prevState };
// //           Object.keys(additionalAttributes).forEach(key => {
// //               if (!updatedState[key]) {
// //                   updatedState[key] = [""];
// //               }
// //           });
// //           return updatedState;
// //       });

// //     }
// // }, [additionalAttributes,singleAttributeGroupStatus]);


// //  ============================== 

//     // console.log("addNestedDropdownValues",addNestedDropdownValues)


//       useEffect(() => {
//         if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 
//         setAdditionalInputData(prevState => {
//           const updatedState = { ...prevState };
//           Object.keys(additionalAttributes).forEach(key => {
//             if (!updatedState[key]) {
//               updatedState[key] = [""];
//             }
    
//             // Initialize child attribute for NestedDropdown type
//             if (additionalAttributes[key].type === "NestedDropdown") {
//               const childAttributeName = `${key}nEsTeD`;
//               if (!updatedState[childAttributeName]) {
//                 updatedState[childAttributeName] = [""];
//               }
//             }
//           });
//           return updatedState;
//         }); }
//     }, [additionalAttributes,singleAttributeGroupStatus]);

//       useEffect(() => {
//         if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 

//         setAddNestedDropdownValues(prevState => {
//             const updatedState = { ...prevState };
//             Object.keys(additionalAttributes).forEach(key => {
//                 if (additionalAttributes[key].type === "NestedDropdown") {
//                     if (!updatedState[key]) {
//                         updatedState[key] = [""];
//                     }
//                 }
//             });
//             return updatedState;
//         }); }
//     }, [additionalAttributes,singleAttributeGroupStatus]);

//   const validateGroup = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;
  
//     if (value === '') {
//       // Clear error if the value is empty
//       setGroupValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }
  
//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }
  
//     setGroupValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
  
//     return value;
//   };
  

//   const changeGroupInputData = (e) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     // Determine if the current field is a child attribute
//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = groupAttributes[parentName]?.type;
//     const childDataType = groupAttributes[parentName]?.childDataType;

//     if (fieldType) {
//         newValue = validateGroup(fieldType, newValue, name);
//     }

//     if (childDataType && isChildAttribute) {
//         newValue = validateGroup(childDataType, newValue, name);
//     }

//     // Convert numeric values to numbers
//     if (fieldType === 'Number' || fieldType === 'Decimal' || fieldType === 'Percent' ||
//         childDataType === 'Number' || childDataType === 'Decimal' || childDataType === 'Percent') {
//         newValue = newValue === '' ? '' : parseFloat(newValue);
//     }

//     // Clear mandatory error if data is entered
//     setGroupMandatoryErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: newValue ? null : prevErrors[name],
//     }));

//     setGroupInputData((prevData) => ({
//         ...prevData,
//         [name]: newValue,
//     }));
//   };
  
  
// //   const validateAdditional = (type, value, name, index) => {
// //     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
// //     const hasSpecialChars = specialCharsRegex.test(value);
// //     const hasAlphabets = /[a-zA-Z]/.test(value);
// //     let errorMessage = null;

// //     if (value === '') {
// //       setAdditionalValidationErrors(prevErrors => {
// //         const updatedErrors = { ...prevErrors };
// //         if (updatedErrors[name]) {
// //           updatedErrors[name][index] = null;
// //         }
// //         return updatedErrors;
// //       });
// //       return value;
// //     }

// //     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
// //       if (hasSpecialChars || hasAlphabets) {
// //         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
// //       }
// //     } else if (type === 'Email') {
// //       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
// //       errorMessage = isValidEmail ? null : 'Invalid email format';
// //     } else if (type === 'URL') {
// //       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
// //       errorMessage = isValidURL ? null : 'Invalid URL format';
// //     } else if (type === 'Phone') {
// //       const isValidPhone = /^[0-9]{10}$/.test(value);
// //       errorMessage = isValidPhone ? null : 'Invalid phone number format';
// //     } else if (type === 'DateTime' || type === 'Date') {
// //       errorMessage = null;
// //     } else {
// //       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
// //     }

// //     setAdditionalValidationErrors(prevErrors => {
// //       const updatedErrors = { ...prevErrors };
// //       if (!updatedErrors[name]) {
// //         updatedErrors[name] = [];
// //       }
// //       updatedErrors[name][index] = errorMessage;
// //       return updatedErrors;
// //     });

// //     return value;
// //   };

// //   const changeAdditionalInputData = (e, i, index ) => {
// //     const { name, value } = e.target;
// //     let newValue = value;

// //     // Determine if the current field is a child attribute
// //     const isChildAttribute = name.endsWith("nEsTeD");
// //     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

// //     const fieldType = additionalAttributes[parentName]?.type;
// //     const childDataType = additionalAttributes[parentName]?.childDataType;

// //     if (fieldType) {
// //         newValue = validateAdditional(fieldType, newValue, name);
// //     }

// //     if (childDataType && isChildAttribute) {
// //         newValue = validateAdditional(childDataType, newValue, name);
// //     }

// //     // Convert numeric values to numbers
// //     // if (fieldType === 'Number' || fieldType === 'Decimal' || fieldType === 'Percent' ||
// //     //     childDataType === 'Number' || childDataType === 'Decimal' || childDataType === 'Percent') {
// //     //     newValue = newValue === '' ? '' : parseFloat(newValue);
// //     // }

// //     // Clear mandatory error if data is entered
// //     setAdditionalMandatoryErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [name]: newValue ? null : prevErrors[name],
// //     }));

// //     // setAdditionalInputData((prevData) => ({
// //     //     ...prevData,
// //     //     [name]: newValue,
// //     // }));
// //     setAdditionalInputData((prevData) => ({
// //       ...prevData,
// //       [name]: prevData[name].map((value, i) => i === index ? newValue : value)
// //     }));
// //   };

// //   const handleAddAnotherAdditional = (inputKey) => {
// //     setAdditionalInputData(prevState => ({
// //         ...prevState,
// //         [inputKey]: [...(prevState[inputKey] || []), ""]
// //     }));
// //   };
// //   const handleRemoveFieldAdditional = (inputKey, index) => {
// //     setAdditionalInputData(prevState => {
// //         const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
// //         if (updatedValues.length === 0) {
// //             const { [inputKey]: _, ...rest } = prevState;
// //             return rest;
// //         }
// //         return {
// //             ...prevState,
// //             [inputKey]: updatedValues
// //         };
// //     });
// // };

  
//   // console.log(" groupValidationErrors ==" ,  groupValidationErrors);
//   console.log(" additionalValidationErrors ==" ,  additionalValidationErrors);


//   // Inside the component, define a new state for mandatory errors
//   const [groupMandatoryErrors, setGroupMandatoryErrors] = useState({});
//   const [additionalMandatoryErrors, setAdditionalMandatoryErrors] = useState({});

//   // const handleSubmitData = (e) => {
//   //   e.preventDefault();

//   //   // Check if there are validation errors
//   //   if (Object.values(groupValidationErrors).some(error => error !== null) ||
//   //       Object.values(additionalValidationErrors).some(error => error !== null)) {
//   //       toast.warning('Please fix validation errors before saving.');
//   //       return;
//   //   }

//   //  // Filter out empty and trim values from inputData
//   //   const filteredInputData = Object.fromEntries(
//   //     Object.entries(groupInputData).filter(([key, value]) => 
//   //       value !== undefined &&
//   //       value !== null &&
//   //       (Array.isArray(value) ? value.length > 0 : (typeof value === 'string' ? value.trim() !== '' : true))
//   //     )
//   //   );    
//   //   const trimmedInputData = Object.fromEntries(
//   //     Object.entries(filteredInputData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
//   //   );

//   //   // Filter out empty and trim values from additionalInputData
//   //   const filteredAdditionalInputData = Object.fromEntries(
//   //     Object.entries(additionalInputData).filter(([key, value]) =>
//   //       value !== undefined &&
//   //       value !== null &&
//   //       (Array.isArray(value) ? value.length > 0 : (typeof value === 'string' ? value.trim() !== '' : true))
//   //     )
//   //   );
//   //   const trimmedAdditionalInputData = Object.fromEntries(
//   //     Object.entries(filteredAdditionalInputData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
//   //   );

//   //   // Reset mandatory errors states
//   //   const newGroupMandatoryErrors = {};
//   //   const newAdditionalMandatoryErrors = {};

//   //   // Check for mandatory fields in groupAttributes
//   //   Object.entries(groupAttributes).forEach(([key, value]) => {
//   //       if (value.mandatory && !trimmedInputData[key]) {
//   //           newGroupMandatoryErrors[key] = 'This field is mandatory';
//   //       }
//   //   });

//   //   // Check for mandatory fields in additionalAttributes
//   //   Object.entries(additionalAttributes).forEach(([key, value]) => {
//   //       if (value.mandatory && !trimmedAdditionalInputData[key]) {
//   //           newAdditionalMandatoryErrors[key] = 'This field is mandatory';
//   //       }
//   //   });

//   //   // Check for mandatory fields in nested dropdowns for groupAttributes
//   //   Object.entries(groupAttributes).forEach(([fieldName, fieldData]) => {
//   //       if (fieldData.type === 'NestedDropdown') {
//   //           const nestedAttributeName = fieldName;
//   //           const childAttributeName = `${fieldName}nEsTeD`;
//   //           const nestedAttributeValue = trimmedInputData[nestedAttributeName];
//   //           const childAttributeValue = trimmedInputData[childAttributeName];
//   //           if ((!nestedAttributeValue || !childAttributeValue) && fieldData.mandatory) {
//   //               newGroupMandatoryErrors[childAttributeName] = 'This field is mandatory';
//   //           }
//   //       }
//   //   });

//   //   // Check for mandatory fields in nested dropdowns for additionalAttributes
//   //   Object.entries(additionalAttributes).forEach(([fieldName, fieldData]) => {
//   //       if (fieldData.type === 'NestedDropdown') {
//   //           const nestedAttributeName = fieldName;
//   //           const childAttributeName = `${fieldName}nEsTeD`;
//   //           const nestedAttributeValue = trimmedAdditionalInputData[nestedAttributeName];
//   //           const childAttributeValue = trimmedAdditionalInputData[childAttributeName];
//   //           if ((!nestedAttributeValue || !childAttributeValue) && fieldData.mandatory) {
//   //               newAdditionalMandatoryErrors[childAttributeName] = 'This field is mandatory';
//   //           }
//   //       }
//   //   });

//   //   // Update mandatory errors states for groupAttributes and additionalAttributes
//   //   setGroupMandatoryErrors(newGroupMandatoryErrors);
//   //   setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);

//   //   // Check if there are any mandatory errors
//   //   if (Object.values(newGroupMandatoryErrors).some(error => error !== null) ||
//   //       Object.values(newAdditionalMandatoryErrors).some(error => error !== null)) {
//   //       toast.warning('Please fill in all mandatory fields before saving.');
//   //       return;
//   //   }

//   //   const data = {
//   //       groupAttributes: trimmedInputData,
//   //       [additionalAttributesName]: trimmedAdditionalInputData ,
//   //       imagesAttributes : inputValues,
//   //       groupName: groupNameToShowAttributes,
//   //       className: classNameToSave,
//   //   };

//   //   dispatch(createTablelink(data));
//   //   console.log("data===", data);
//   // };

//   // console.log([additionalAttributesName])
  

//   // console.log("groupMandatoryErrors" , groupMandatoryErrors)
//   console.log("additionalMandatoryErrors" , additionalMandatoryErrors)

//   const {  data, status , error } = useSelector(tableDataSelector);

//   useEffect(()=>{
//     if (data && status===STATUSES.SUCCESS) {
//       toast.success("Created Successfully");
//       dispatch(resetTableState({}));
//     }
//     if (error){
//       toast.error(error);
//       dispatch(resetTableState({}));
//     }
//   }, [data ,status ,error ,dispatch]);


//   useEffect(() => {
//     // Clear input data when group name changes
//     setGroupInputData({});
//     setAdditionalInputData({});
//     setAddNestedDropdownValues({});
//     setGroupValidationErrors({});
//     setAdditionalValidationErrors({});
//     setGroupMandatoryErrors({});
//     setAdditionalMandatoryErrors({});

//     setInputValues({});
//     setNestedDropdownValues({});
//     setImageValidationErrors({});
//   }, [groupNameToShowAttributes]);


//   const [groupNestedDropdownValues, setGroupNestedDropdownValues] = useState({});
//   const handleGroupNestedDropdownSelection = (name, value) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     setGroupNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: value,
//       [nestedAttributeName]: '', // Reset nested value when parent changes
//     }));
  
//     // Clear mandatory error for parent and child attribute
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null,
//       [nestedAttributeName]: null,
//     }));
//   };
  
//   const [addNestedDropdownValues, setAddNestedDropdownValues] = useState({});
//   // const handleAdditionalNestedDropdownSelection = (name, value) => {
//   //   const nestedAttributeName = `${name}nEsTeD`;
//   //   setAddNestedDropdownValues((prevValues) => ({
//   //     ...prevValues,
//   //     [name]: [value],
//   //   }));
//   //   setAdditionalInputData((prevData) => ({
//   //     ...prevData,
//   //     [name]: [value],
//   //     [nestedAttributeName]: '', // Reset nested value when parent changes
//   //   }));
  
//   //   // Clear mandatory error for parent and child attribute
//   //   setAdditionalMandatoryErrors((prevErrors) => ({
//   //     ...prevErrors,
//   //     [name]: null,
//   //     [nestedAttributeName]: null,
//   //   }));
//   // };
//   // const handleAdditionalNestedDropdownSelection = (name, value) => {
//   //   const nestedAttributeName = `${name}nEsTeD`;
    
//   //   setAddNestedDropdownValues((prevValues) => ({
//   //     ...prevValues,
//   //     [name]: value, // Update the parent value
//   //   }));
    
//   //   setAdditionalInputData((prevData) => ({
//   //     ...prevData,
//   //     [name]: [value], // Update the parent value
//   //     [nestedAttributeName]: [], // Reset nested value when parent changes
//   //   }));
    
//   //   setAdditionalMandatoryErrors((prevErrors) => ({
//   //     ...prevErrors,
//   //     [name]: null, // Clear parent error
//   //     [nestedAttributeName]: null, // Clear nested error
//   //   }));
//   // };
  
//   const saveGroupMultiSelectValues = (name, selectedValues) => {
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
  
//     // Clear mandatory error for Multiselect field
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };
  
//   // const saveAdditionlMultiSelectValues = (name, selectedValues) => {
//   //   setAdditionalInputData((prevData) => ({
//   //     ...prevData,
//   //     [name]: selectedValues,
//   //   }));
  
//   //   // Clear mandatory error for Multiselect field
//   //   setAdditionalMandatoryErrors((prevErrors) => ({
//   //     ...prevErrors,
//   //     [name]: selectedValues.length ? null : prevErrors[name],
//   //   }));
//   // };
  

//   // console.log("groupInputData ====", groupInputData);
//   console.log("additionalInputData ====", additionalInputData);

//   // [4] Choose reference 
//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;
//       dispatch(getAllDataForReferenceLink(classNameToSave));
//     }
//   }, [singleAttributeGroupStatus]);

  
  
  
//   const {data:AllDataForReference , status:statusForReference , error:errorForReference  } = useSelector(getAllDataForReference);

//   // console.log(AllDataForReference);

//   const handleReferenceSelection = (e) => {
//     const selectedReferenceId = e.target.value;

//     if (!selectedReferenceId) {
//         console.log("Selected reference ID is empty.");
//         return;
//     }

//     const selectedReference = AllDataForReference.find(ref => {
//         const keys = Object.keys(ref.groupAttributes);
//         const firstKey = keys.length > 0 ? keys[0] : null;
//         return ref.groupAttributes[firstKey] === selectedReferenceId;
//     });

//     if (!selectedReference) {
//         console.log("Selected reference not found in AllDataForReference.");
//         return;
//     }

//     const { groupAttributes: selectedGroupAttributes, [additionalAttributesName]: selectedAdditionalAttributes } = selectedReference;

//     setGroupInputData(selectedGroupAttributes);
//     setAdditionalInputData(selectedAdditionalAttributes);

//     // Update addNestedDropdownValues based on selectedAdditionalAttributes
//     const newAddNestedDropdownValues = {};
//     Object.keys(selectedAdditionalAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newAddNestedDropdownValues[parentKey] = selectedAdditionalAttributes[parentKey];
//             newAddNestedDropdownValues[childKey] = selectedAdditionalAttributes[childKey];
//         }
//     });
//     setAddNestedDropdownValues(newAddNestedDropdownValues);

//     // Update groupNestedDropdownValues based on selectedGroupAttributes
//     const newGroupNestedDropdownValues = {};
//     Object.keys(selectedGroupAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newGroupNestedDropdownValues[parentKey] = selectedGroupAttributes[parentKey];
//             newGroupNestedDropdownValues[childKey] = selectedGroupAttributes[childKey];
//         }
//     });
//     setGroupNestedDropdownValues(newGroupNestedDropdownValues);
//   };

//   const validateImageAttribute = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;
  
//     if (value === '') {
//       // Clear error if the value is empty
//       setImageValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }
  
//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }
  
//     setImageValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
  
//     return value;
//   };
  
  
  
//   const [inputValues, setInputValues] = useState({});
//   const [nestedDropdownValues, setNestedDropdownValues] = useState({});

//   const [imageValidationErrors, setImageValidationErrors] = useState({});

//   const handleInputChange = (name, selectedValues, type) => {
//     const attributeName = name;

//     let newValue = selectedValues;
//     console.log(name)
//     console.log(type)
//     console.log(selectedValues)

//     if (type) {
//       newValue = validateImageAttribute(type, newValue, attributeName);
//     }

//     if (type === 'Number' || type === 'Decimal' || type === 'Percent') {
//       newValue = newValue === '' ? '' : parseFloat(newValue);
//     }
//     setInputValues((prevInputValues) => ({
//       ...prevInputValues,
//       [attributeName]: newValue,
//     }));
//   };

//   const saveSetMultiSelectValues = (name, selectedValues) => {
//     setInputValues((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//   };

//   const handleSetNestedDropdownSelection = (imageKey, groupName, i, value) => {
//     const parentAttributeName = `${groupName} ${i}`;
//     const nestedAttributeName = `${parentAttributeName}nEsTeD`;

//     setNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [parentAttributeName]: value,
//     }));

//     setInputValues((prevData) => ({
//       ...prevData,
//       [parentAttributeName]: value,
//       [nestedAttributeName]: '', // Reset nested value when parent changes
//     }));
//     // Trigger validation for parent attribute
//     const parentType = imagesAttributes[imageKey].attgroups[i]?.type;
//     console.log("parentType-------------", parentType)
//     if (parentType) {
//       validateImageAttribute(parentType, value, parentAttributeName);
//     }
//   };


//   console.log("inputValues", inputValues);
//   console.log("nestedDropdownValues", nestedDropdownValues);
//   console.log("imageValidationErrors", imageValidationErrors);


    

//   const validateAdditional = (type, value, name, index) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       setAdditionalValidationErrors(prevErrors => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[name]) {
//           updatedErrors[name][index] = null;
//         }
//         return updatedErrors;
//       });
//       return value;
//     }

//     if (type === 'Number' || type === 'Percent' || type === 'Decimal') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setAdditionalValidationErrors(prevErrors => {
//       const updatedErrors = { ...prevErrors };
//       if (!updatedErrors[name]) {
//         updatedErrors[name] = [];
//       }
//       updatedErrors[name][index] = errorMessage;
//       return updatedErrors;
//     });

//     return value;
//   };

 
  
//   const changeAdditionalInputData = (e, i, index) => {
//     const { name, value } = e.target;
//     let newValue = value;
  
//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;
  
//     const fieldType = additionalAttributes[parentName]?.type;
//     const childDataType = additionalAttributes[parentName]?.childDataType;
  
//     if (fieldType && !isChildAttribute) {
//       newValue = validateAdditional(fieldType, newValue, name, index);
//     }
  
//     if (childDataType && isChildAttribute) {
//       newValue = validateAdditional(childDataType, newValue, name, index);
  
//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, additionalInputData[parentName][index], parentName, index);
//       }
//     }
  
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: newValue ? null : prevErrors[name],
//     }));
  
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? newValue : val),
//     }));
  
//     // For nested child inputs, update the corresponding parent value
//     if (isChildAttribute) {
//       const parentIndex = index;
//       const parentValue = additionalInputData[parentName][parentIndex];
//       const nestedAttributeName = `${parentName}nEsTeD`;
//       setAdditionalInputData((prevData) => ({
//         ...prevData,
//         [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === parentIndex ? newValue : val),
//       }));
  
//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, parentValue, parentName, parentIndex);
//       }
//     }
//   };
  

//   const handleAddAnotherAdditional = (inputKey) => {
//     const newField = "";
//     const updatedState = (prevState) => ({
//       ...prevState,
//       [inputKey]: [...prevState[inputKey], newField],
//     });
  
//     setAdditionalInputData(updatedState);
  
//     if (additionalAttributes[inputKey].type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
  
//       // Add a new field for the child attribute
//       setAdditionalInputData((prevState) => ({
//         ...prevState,
//         [childAttributeName]: [...prevState[childAttributeName], newField],
//       }));
  
//       setAddNestedDropdownValues((prevState) => ({
//         ...prevState,
//         [inputKey]: [...prevState[inputKey], newField],
//       }));
  
//       // Check if there are validation errors for the specific nestedDropdown being added
//       const hasValidationErrors = additionalValidationErrors[childAttributeName]?.some((error) => error !== null);
  
//       // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
//       setAdditionalValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         [childAttributeName]: hasValidationErrors ? prevErrors[childAttributeName] : null,
//       }));
//     }
//   };
  
  


//   const handleRemoveFieldAdditional = (inputKey, index) => {
//     setAdditionalInputData((prevState) => {
//       const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
//       return {
//         ...prevState,
//         [inputKey]: updatedValues,
//       };
//     });

//     // Clear validation error for the removed field
//     setAdditionalValidationErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[inputKey]) {
//         updatedErrors[inputKey] = updatedErrors[inputKey].filter((_, i) => i !== index);
//       }
//       return updatedErrors;
//     });

//     if (additionalAttributes[inputKey]?.type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
//       setAdditionalInputData((prevState) => {
//         const updatedChildValues = prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
//         return {
//           ...prevState,
//           [childAttributeName]: updatedChildValues,
//         };
//       });

//       // Clear validation error for the removed child field
//       setAdditionalValidationErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[childAttributeName]) {
//           updatedErrors[childAttributeName] = updatedErrors[childAttributeName].filter((_, i) => i !== index);
//         }
//         return updatedErrors;
//       });

//       setAddNestedDropdownValues((prevState) => {
//         const updatedValues = { ...prevState };
//         updatedValues[inputKey] = updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
//         return updatedValues;
//       });
//     }
//   };




  
//   const handleSubmitData = (e) => {
//     e.preventDefault();

//     // Check if there are any validation errors
//     if (Object.values(additionalValidationErrors).flat().some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }

//     // Trim values of all arrays in additionalInputData
//     const trimmedAdditionalInputData = Object.fromEntries(
//         Object.entries(additionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : value])
//     );
//     console.log("trimmedAdditionalInputData"  , trimmedAdditionalInputData)

//     // Remove empty strings from arrays
//     const filteredAdditionalInputData = Object.fromEntries(
//         Object.entries(trimmedAdditionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.filter(val => val !== '') : value])
//     );

//     console.log("filteredAdditionalInputData" , filteredAdditionalInputData)

//     // Check mandatory fields
//     const newAdditionalMandatoryErrors = {};

//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//         if (value.mandatory && (!filteredAdditionalInputData[key] || filteredAdditionalInputData[key].length === 0)) {
//             newAdditionalMandatoryErrors[key] = 'This field is mandatory';
//         }
//     });
//     // Check for mandatory fields in nested dropdowns for additionalAttributes
//     // Object.entries(additionalAttributes).forEach(([fieldName, fieldData]) => {
//     //   if (fieldData.type === 'NestedDropdown') {
//     //       const nestedAttributeName = fieldName;
//     //       const childAttributeName = `${fieldName}nEsTeD`;
//     //       const nestedAttributeValue = filteredAdditionalInputData[nestedAttributeName];
//     //       const childAttributeValue = filteredAdditionalInputData[childAttributeName];
//     //       if ((!nestedAttributeValue || !childAttributeValue) && fieldData.mandatory) {
//     //           newAdditionalMandatoryErrors[childAttributeName] = 'This field is mandatory';
//     //       }
//     //   }
//     // });

//     // Show mandatory errors
//     setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);

//     if (Object.values(newAdditionalMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }

//     // Proceed with saving data
//     console.log('Filtered Additional Input Data:', filteredAdditionalInputData);

//     toast.success('Data saved successfully.');
// };


  
//   const handleAdditionalNestedDropdownSelection = (name, value, index) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     let newValue = value;
//     const parentType = additionalAttributes[name].type;
   
//     // console.log("parentType",parentType )
//     setAddNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: prevValues[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//     }));
  
//     // Update both parent and child values in the state
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//       [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === index ? "" : val), // Reset child value
//     }));
//     if(parentType){
//       validateAdditional(parentType, newValue, name, index);
//     }
  
//     // Clear any errors related to parent and child
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null, // Clear parent error
//       [nestedAttributeName]: null, // Clear child error
//     }));
//   };
  
  
  

//   const saveAdditionlMultiSelectValues = (name, selectedValues) => {
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };

//   return (
//     <>
//     <div className='common_table_page add_table_page'>

//     <div className="select_att_group_button_Container">
//       <h6>Add {title}</h6>
//       <select className='select_step_data' type="text" required value={groupNameToShowAttributes} onChange={handleGroupSelection} >
//         <option value="" className='select_step_value'>Select Group</option>
//         {
//           AttributeGroupStatus === STATUSES.LOADING ? (
//             <option className='error_option'>Loading...</option>
//           ) : 
//           AttributeGroupStatus === STATUSES.ERROR ? (
//             <option className='error_option'>Something went wrong {AttributeGroupError}</option>
//           ) : 
//           Object.keys(AttributesGroupsData).length > 0 && AttributesGroupsData.map((item, index) =>
//             <option value={item.groupName} className='select_step_value' key={item.groupId}>{item.groupName}</option>
//           )
//         }
//       </select>
//     </div>


// <> 
//   <div className="add_table_data_container">
//     {
//       AttributeGroupStatus === STATUSES.LOADING ? (
//         <Loader/>
//       ) : AttributeGroupStatus === STATUSES.SUCCESS ?(
//         (
//           Object.keys(AttributesGroupsData).length===0 ?(
//             <RenderWarning message={`Please create a group of attributes to add ${title.toLowerCase()}`} showLink={linkForRedirectSetting} />
//           ):(              
//               groupNameToShowAttributes.length===0?(
//                 <RenderWarning message={`Please select the group of attributes to add ${title.toLowerCase()}`} />
//               ):(
//                 <>
//               {
  
//               singleAttributeGroupStatus === STATUSES.LOADING?(
//                 <Loader/>
//               ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                 <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//               ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                   (Object.keys(groupAttributes).length===0 && Object.keys(additionalAttributes).length === 0 && Object.keys(imagesAttributes).length === 0)?(
//                   <>
//                     <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                     <RenderWarning message={`No attributes found to add ${groupNameToShowAttributes}`} />
//                   </>
//                   ):(
                    
//                   <>
//                     <div className='att_header'><span>Attributes Group - <strong>{singleAttributeGroupData?.groupName}</strong></span>
//                     <select className='select_step_data choosse_reference' type="text" required onChange={handleReferenceSelection}>
//                       <option value="" className='select_step_value'>Choose reference</option>
//                       {
//                         statusForReference === STATUSES.LOADING ? (
//                           <option className='error_option'>Loading...</option>
//                         ) :
//                         statusForReference === STATUSES.ERROR ? (
//                           <option className='error_option'>Something went wrong {errorForReference}</option>
//                         ) :
//                         statusForReference === STATUSES.SUCCESS ? (
//                           AllDataForReference && typeof AllDataForReference === "string" ? (
//                             <option className='error_option'>{`No ${title} found to choose reference`}</option>
//                           ):(
//                             AllDataForReference?.map((item, index) => {
//                               const firstKey = Object.keys(item.groupAttributes)[0]; // Get the first key dynamically
//                               return (
//                                   <option value={item.groupAttributes[firstKey]} className='select_step_value' key={index}>
//                                     {item.groupAttributes[firstKey]}
//                                   </option>
//                               );
//                           })
                          
//                           ) 
//                         ) : null
                       
//                       }
//                     </select>
//                     </div>
  
  
//                     <div className="all_attributes_to_add_data">

                       
//                     {
//                       Object.keys(groupAttributes).map((i, index) => {
                                              
//                         if(groupAttributes[i].type==="Date") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} >
//                               <input className='table_input' type="date" required name={i} onChange={changeGroupInputData} placeholder='' value={groupInputData[i] || ""}/>
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
                        
//                         else if(groupAttributes[i].type==="DateTime") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                               <input className='table_input' type="datetime-local" required name={i} onChange={changeGroupInputData} placeholder="" value={groupInputData[i] || ""} />
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
  
//                         else if(groupAttributes[i].type==="Image") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                               <input className='table_input image_input' type="file" required name={i} onChange={changeGroupInputData} value={groupInputData[i] || ""}/>
//                               <span>{groupAttributes[i].nametoDisplay}</span>
//                               {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(groupAttributes[i].type==="Dropdown") {
//                           return(
//                             <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i} onChange={changeGroupInputData} required placeholder='' value={groupInputData[i] || ""}>
//                                   <option value="">{}</option>
//                                   {groupAttributes[i].options.map((option, optionIndex) => (
//                                       <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{groupAttributes[i].nametoDisplay}</span>
//                                 {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 
//                         else if(groupAttributes[i].type==="MultiSelect") {
//                           return(
//                             <div style={{position:"relative"}} key={index}>
//                               <Multiselect  isObject={false} options={groupAttributes[i].options} placeholder={groupAttributes[i].nametoDisplay} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} onSelect={(selectedList) => saveGroupMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveGroupMultiSelectValues(i, selectedList)} selectedValues={groupInputData[i] || []} />
//                               {groupMandatoryErrors[i] && <div className="input_error multiSelectError">{groupMandatoryErrors[i]}</div>}
//                             </div>
                          
//                           )
//                         } 
//                         else if (groupAttributes[i].type === "NestedDropdown") {
//                           return (
//                             <div className='nested_input' key={index}>
//                               <h1>pk</h1>
//                               <div className='_input_box' title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                 <select className='table_input' name={i}  onChange={(e) => handleGroupNestedDropdownSelection(i, e.target.value)} required value={groupInputData[i] || ''}>
//                                   <option value="">{""}</option>
//                                   {groupAttributes[i].options.map((option, optionIndex) => (
//                                     <option key={optionIndex} value={option}>{option}</option>
//                                   ))}
//                                 </select>
//                                 <span>{groupAttributes[i].nametoDisplay}</span>
//                                 {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
  
//                               </div>
                              
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "Dropdown" ? (
//                                 <div className='_input_box' title={`Data type- ${groupAttributes[i].childDataType}\nDescription- ${groupAttributes[i].varDescription}`}>
//                                   <select className='table_input' name={`${i}nEsTeD`} onChange={changeGroupInputData} required value={groupInputData[`${i}nEsTeD`] || ''}>
//                                     <option value="">{""}</option>
//                                     {groupAttributes[i].nestedOptions[groupAttributes[i].options.indexOf(groupNestedDropdownValues[i])].map((nestedOption, nestedIndex) => (
//                                       <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                     ))}
//                                   </select>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "Date" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="date" required name={`${i}nEsTeD`} onChange={changeGroupInputData} placeholder=""  value={groupInputData[`${i}nEsTeD`] || ''}/>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                               {groupNestedDropdownValues[i] && groupAttributes[i].childDataType === "DateTime" ?(
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="datetime-local" required name={`${i}nEsTeD`} onChange={changeGroupInputData} placeholder=""  value={groupInputData[`${i}nEsTeD`] || ''}/>
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
  
//                               {groupNestedDropdownValues[i] && ( groupAttributes[i].childDataType === "String" || groupAttributes[i].childDataType === "Number" || groupAttributes[i].childDataType === "Decimal" || groupAttributes[i].childDataType === "Percent" || groupAttributes[i].childDataType === "Email" || groupAttributes[i].childDataType === "URL" || groupAttributes[i].childDataType === "Phone") ? (
//                                 <div className='_input_box'>
//                                   <input className='table_input' type="text" required name={`${i}nEsTeD`} onChange={changeGroupInputData}  value={groupInputData[`${i}nEsTeD`] || ''} />
//                                   <span>{groupAttributes[i].nametoDisplay2}</span>
//                                   { groupValidationErrors[`${i}nEsTeD`] && <div className="input_error">{ groupValidationErrors[`${i}nEsTeD`]}</div>}
//                                   {groupMandatoryErrors[`${i}nEsTeD`] && <div className="input_error">{groupMandatoryErrors[`${i}nEsTeD`]}</div>}
//                                 </div>
//                               ) : null}
//                             </div>
//                           );
//                         }
                        
//                         else return (
//                           <div className='_input_box' key={index} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} >
//                             <input className='table_input' type="text" required name={i} onChange={changeGroupInputData} placeholder='' value={groupInputData[i] || ""}/>
//                             <span>{groupAttributes[i].nametoDisplay}</span>
//                             { groupValidationErrors[i] && <div className="input_error">{ groupValidationErrors[i]}</div>}
//                             {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                           </div>
//                         );
                        
//                       })
//                     }
                  
  
//                     {
//                          <>
//                          {Object.keys(additionalAttributes).map((i, index) => {
//                            if (additionalAttributes[i].type === "Date") {
//                              return (
//                                <div key={index} className='inp_cont'>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                      <input className='table_input' type="text" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} />
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            } else if (additionalAttributes[i].type === "DateTime") {
//                              return (
//                                <div className='inp_cont' key={index}>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                      <input className='table_input' type="datetime-local" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} />
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            } else if (additionalAttributes[i].type === "Dropdown") {
//                              return (
//                                <div key={index} className='inp_cont'>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                      <select className='table_input' value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} >
//                                        <option value="">{}</option>
//                                        {additionalAttributes[i].options.map((option, optionIndex) => (
//                                          <option key={optionIndex} value={option}>{option}</option>
//                                        ))}
//                                      </select>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              )
//                            } else if (additionalAttributes[i].type === "NestedDropdown") {
//                              const parentAttributeName = i;
//                              const childAttributeName = `${i}nEsTeD`;
                       
//                              // Check if any parent values are present
//                              const isParentValueAdded = additionalInputData[parentAttributeName] && additionalInputData[parentAttributeName].some(value => value.trim() !== "");
                       
//                              // Check if any child values are present
//                              const isChildValueAdded = additionalInputData[childAttributeName] && additionalInputData[childAttributeName].some(value => value.trim() !== "");
                       
//                              // Check if all sets have both parent and child values
//                              const allSetsHaveValues = (additionalInputData[parentAttributeName] || []).every((_, valueIndex) => {
//                                const isParentValuePresent = additionalInputData[parentAttributeName][valueIndex] && additionalInputData[parentAttributeName][valueIndex].trim() !== "";
//                                const isChildValuePresent = additionalInputData[childAttributeName] && additionalInputData[childAttributeName][valueIndex] && additionalInputData[childAttributeName][valueIndex].trim() !== "";
//                                return isParentValuePresent && isChildValuePresent;
//                              });
                       
//                              return (
//                                <div className='inp_cont' key={index}>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                      <select className='table_input' value={value} onChange={(e) => handleAdditionalNestedDropdownSelection(i, e.target.value, valueIndex)} name={i}>
//                                        <option value="">Select an option</option>
//                                        {additionalAttributes[i].options.map((option, optionIndex) => (
//                                          <option key={optionIndex} value={option}>{option}</option>
//                                        ))}
//                                      </select>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
                       
//                                      {/* Render child inputs based on the index of addNestedDropdownValues */}
//                                      {addNestedDropdownValues[i] && addNestedDropdownValues[i][valueIndex] && (
//                                        <div>
//                                          {/* Render child inputs based on childDataType */}
//                                          {additionalAttributes[i].childDataType === "Dropdown" && (
//                                            <div>
//                                              <select value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} name={childAttributeName}>
//                                                <option value="">Select an option</option>
//                                                {additionalAttributes[i].nestedOptions[additionalAttributes[i].options.indexOf(value)].map((nestedOption, nestedIndex) => (
//                                                  <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                ))}
//                                              </select>
//                                              <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                            </div>
//                                          )}
//                                          {additionalAttributes[i].childDataType === "Date" && (
//                                           <div className='_input_box'>
//                                            <input type="date" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                            </div>
//                                          )}
//                                          {additionalAttributes[i].childDataType === "DateTime" && (
//                                           <div className='_input_box'>
//                                            <input className='table_input' type="datetime-local" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                            </div>
//                                          )}
//                                          {(additionalAttributes[i].childDataType === "String" || additionalAttributes[i].childDataType === "Number" || additionalAttributes[i].childDataType === "Decimal" || additionalAttributes[i].childDataType === "Percent" || additionalAttributes[i].childDataType === "Email" || additionalAttributes[i].childDataType === "URL" || additionalAttributes[i].childDataType === "Phone") && (
//                                           <div className='_input_box'>
//                                             <input className='table_input' type="text" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} />
//                                           </div>

//                                          )}
//                                          {/* Display mandatory error if present */}
//                                          {additionalMandatoryErrors[childAttributeName] && <div className="input_error">{additionalMandatoryErrors[childAttributeName]}</div>}
//                                          {additionalValidationErrors[childAttributeName]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[childAttributeName][valueIndex]}</div>}
//                                        </div>
//                                      )}
                       
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                    </div>
//                                  ))}
                       
//                                  {/* Conditionally render the "Add" button only once */}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={!isParentValueAdded || !isChildValueAdded || !allSetsHaveValues ||  Object.values(additionalValidationErrors[i] || {}).some(error => error !== null) ||  Object.values(additionalValidationErrors[childAttributeName] || {}).some(error => error !== null)} >+</button>
//                                  )}
//                                </div>
//                              );
//                            } 
//                            else {
//                              return (
//                                <div key={index} className='inp_cont'>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                      <input className='table_input' type="text" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} />
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            }
//                          })}
//                          {/* <button onClick={handleSubmitData}>Submit</button>
//                          <pre>{JSON.stringify(additionalInputData, null, 2)}</pre> */}
//                        </>
//                     }

//                     <div className="all_set_table_">
//                           {Object.keys(imagesAttributes).length !== 0 && (
//                             <div className="image_attributes_table_container">
//                               <div>
//                                 {Object.keys(imagesAttributes).map((imageKey) => {
//                                   const { groupsName, attgroups } = imagesAttributes[imageKey];
//                                   return (
//                                     <div key={imageKey} className='one_set'>
//                                       <table>
//                                         <thead>
//                                           <tr>
//                                             <th></th>
//                                             {Object.keys(groupsName).map((groupName) => (
//                                               <th key={`${imageKey}_${groupName}`}>{groupsName[groupName].nametoDisplay}</th>
//                                             ))}
//                                           </tr>
//                                         </thead>
//                                         <tbody>
//                                           {Object.keys(attgroups).map((i) => (
//                                             <tr key={`${imageKey}_${i}`}>
//                                               <td>{attgroups[i].nametoDisplay}</td>
//                                               {Object.keys(groupsName).map((groupName) => (
//                                                 <td key={`${imageKey}_${groupName}_${i}`}>
//                                                   {attgroups[i].type === "Dropdown" ? (
//                                                     <>
//                                                       <select
//                                                         className='table_input'
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                         required
//                                                         placeholder=''
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "MultiSelect" ? (
//                                                     <>
//                                                       <Multiselect
//                                                         isObject={false}
//                                                         options={attgroups[i].options}
//                                                         placeholder={attgroups[i].nametoDisplay}
//                                                         onSelect={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         onRemove={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         selectedValues={inputValues[`${groupName} ${i}`] || []}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "Date" ? (
//                                                     <>
//                                                       <input
//                                                         type="date"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "DateTime" ? (
//                                                     <>
//                                                       <input
//                                                         type="datetime-local"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "NestedDropdown" ? (
//                                                     <>
//                                                       <select
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleSetNestedDropdownSelection(imageKey, groupName, i, e.target.value)}
//                                                         required
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{""}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Dropdown" && (
//                                                         <>
//                                                           <select
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             required
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           >
//                                                             <option value="">{""}</option>
//                                                             {attgroups[i].nestedOptions[attgroups[i].options.indexOf(nestedDropdownValues[`${groupName} ${i}`][0])].map((nestedOption, nestedIndex) => (
//                                                               <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                             ))}
//                                                           </select>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Date" && (
//                                                         <>
//                                                           <input
//                                                             type="date"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "DateTime" && (
//                                                         <>
//                                                           <input
//                                                             type="datetime-local"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && ["String", "Number", "Decimal", "Percent", "Email", "URL", "Phone"].includes(attgroups[i].childDataType) && (
//                                                         <>
//                                                           <input
//                                                             type="text"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                     </>
//                                                   ) : (
//                                                     <>
//                                                       <input
//                                                         type="text"
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   )}
//                                                 </td>
//                                               ))}
//                                             </tr>
//                                           ))}
//                                         </tbody>
//                                       </table>
//                                     </div>
//                                   );
//                                 })}
//                               </div>
//                             </div>
//                           )}
//                     </div>
  
  
                                                
//                     </div>
//                     <div className="submit_table_data_btn">
//                       <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Save</button>
//                       {/* <button onClick={handleSubmitData} className ={'common_btn2'} style={{width: 300}}>Clone</button> */}
//                     </div>
//                   </>                          
//                   )
//                 ):null                    
//               }
//                 </>
//               )
//           )
//         )
//       ) :null
//     }
//   </div>
// </>
// </div>

//     </>
//   )
// }


// export default TableAddCommon;






// import React ,{ useEffect, useState} from 'react';
// import './AllStep.css';
// import { getAllProduct } from '../../../../store/Slices/marketMapTableSlices.js';
// import { useDispatch, useSelector} from 'react-redux';
// import STATUSES from '../../../../store/Statuses.js';
// import { toast } from 'react-toastify';
// import { getAllProductAttributes } from '../../../../store/Slices/marketMapAttributeSlice.js';
// import { addProductInProgram, resetaddProductInProgramState } from '../../../../store/Slices/marketMapProgram.js';


// const ProductStep = ({programId, programClassName ,productGroupName}) => {
//   const dispatch = useDispatch();

//   useEffect(()=>{
//       dispatch(getAllProductAttributes({}));
//   },[dispatch]);

//   const { data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => state.AllProductAttributeGroup);

//   const [groupNameToShowAttributes, setGroupNameToShowAttributes] = useState(productGroupName);
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
//   const handleGroupSelection = (e, groupName, selectedIndex) => {
//     setGroupNameToShowAttributes(groupName);
//     setSelectedGroupIndex(selectedIndex);
//   };

  
//   useEffect(()=>{
//     if(groupNameToShowAttributes.length>0 ){
//       const classNameToFetch = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
//       dispatch(getAllProduct(classNameToFetch));
//     }
//   },[selectedGroupIndex, getAllProduct,groupNameToShowAttributes ,productGroupName]);
  
//   const {data , status} = useSelector((state) => state.AllProducts);
//   // console.log(data);

//   const [selectedItemId, setSelectedItemId] = useState("");

//   useEffect(()=>{
//     if(groupNameToShowAttributes.length===0){
//       setSelectedItemId("");
//     }
//   },[groupNameToShowAttributes]);


//   const saveData = () => {
//     if (!programId) {
//       toast.error("Program not found. Please create a program first.");
//       return;
//     }
//     if (!selectedItemId) {
//       toast.error("Please select a product.");
//       return;
//     }

//     const productClassName = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
//     // Dispatch action to save product in the program
//     dispatch(addProductInProgram({ programId, productId : selectedItemId ,productClassName, programClassName }));
//     setSelectedItemId('');
//   }

      

//   const {data: AddedData  ,status : AddedStatus, error :AddedError } = useSelector((state)=> state.ProductInProgram);

//   useEffect(() => {
//     if (AddedData && AddedStatus === STATUSES.SUCCESS) {
//       toast.success(AddedData);
//       dispatch(resetaddProductInProgramState({}));
//     }
//     if (AddedStatus === STATUSES.ERROR) {
//       toast.error(AddedError ? AddedError :"Something went Wrong");
//       dispatch(resetaddProductInProgramState({}));
//     }
//   }, [AddedData, AddedStatus, AddedError, dispatch]);


//    console.log(selectedItemId);
//    console.log(groupNameToShowAttributes);
   
//   console.log( "in ths product ====", programId )
 
// return (
 
//       <> 
//         {
//           productGroupName.length===0 ? (
//             <div className="step_input_container">
//               <h2>Please select program first</h2>
//             </div>
//           ) : (
//             <>
//               <div className="step_input_container">
//           <h5 className='steps_heading'>Select from existing products</h5>

          
//           <select
//             className='select_step_data'
//             onChange={(e) => handleGroupSelection(e, e.target.value, e.target.selectedIndex)}
//             value={groupNameToShowAttributes}
//             required
//             disabled={true}
//           >
//             <option value="">Select Product</option>
//             {AttributeGroupStatus === STATUSES.LOADING ? (
//               <option className='select_step_value' value="">Loading...</option>
//             ) : AttributeGroupStatus === STATUSES.ERROR ? (
//               <option className='select_step_value' value="">Something went wrong {AttributeGroupError}</option>
//             ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
//               AttributesGroupsData.length === 0 ? (
//                 <option className='select_step_value' value="">No Groups found</option>
//               ) : (
//                 AttributesGroupsData.map((item, index) => (
//                   <option className='select_step_value' value={item.groupName} key={item.groupId}>
//                     {item.groupName}
//                   </option>
//                 ))
//               )
//             ) : null}
//           </select>

//           {
//             groupNameToShowAttributes.length>0 && 
//             <select className='select_step_data' type="text" required value={selectedItemId} onChange={(e)=>setSelectedItemId(e.target.value)}>
//             <option value="" className='select_step_value'>Select Product</option>
//             {
//               status=== STATUSES.LOADING ? (
//                 <option value="" className='select_step_value'>Loading...</option>
//               ): 
//               status === STATUSES.ERROR ?(
//                 <option value="" className='select_step_value'>Something went wrong</option>
//               ) :
//               status === STATUSES.SUCCESS ? (
//                 data && typeof data === "string" ?(
//                   <option  value="" className='select_step_value'>No products found</option>
//                 ):(
//                   data?.map((item, index) => {
//                     const firstKey = Object.keys(item.groupAttributes)[0]; // Get the first key dynamically
//                     return (
//                       <option key={item.productId} value={item.productId} className='select_step_value'>
//                         {item.groupAttributes["Product name"]}
//                       </option>
//                     );
//                   })
//                 )
                
//               )
//                :null
              
              
//             }
//           </select>
//           }
//               </div>

              
//               <div className="step_btn_contianer">
//                 <button  className = {`common_btn`} style={{width:150}} onClick={saveData}>Save</button>
//               </div>
//             </>
//           )
//         }
//       </>
// )
// }

// export default ProductStep;

// import React, { useEffect, useState } from "react";
// import "./Login.css";
// import login_img from "../../../assets/Images/login_img.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// import { toast } from 'react-toastify';
// import STATUSES from "../../../store/Statuses";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { resetUserLogin, resetUserToken, resetUserValidate, userLogin, userToken, userValidate } from "../../../store/Slices/userAuthSlice";
// import Cookies from 'js-cookie'; 

// import React, { useEffect, useState } from "react";
// import "./Login.css";
// import login_img from "../../../assets/Images/login_img.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from 'react-toastify';
// import STATUSES from "../../../store/Statuses";
// import { useSelector, useDispatch } from "react-redux";
// import { resetUserLogin, resetUserToken, resetUserValidate, userLogin, userToken, userValidate } from "../../../store/Slices/userAuthSlice";
// import { setAuthToken, setUserDetails, clearAuth } from "../../../store/Slices/userAuthSlice";
// import Cookies from 'js-cookie';

// const Login = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [userName, setUserName] = useState("");
//   const [userPassword, setUserPassword] = useState("");

//   const [nameFilled, setNameFilled] = useState(false);
//   const [passwordFilled, setPasswordFilled] = useState(false);

//   const { token, userDetails } = useSelector((state) => state.auth);

//   console.log(token, userDetails)
//   useEffect(() => {
//     if (token && userDetails) {
//       navigate('/market-map');
//     }
//   }, [token, userDetails ,navigate]);

//   const handleSubmit = () => {
//     if (!userName.trim()) {
//       setNameFilled(true);
//       return;
//     }
//     if (!userPassword.trim()) {
//       setPasswordFilled(true);
//       return;
//     }
//     if (userPassword.trim() && userName.trim()) {
//       const data = { username: userName.trim(), password: userPassword.trim() };
//       dispatch(userLogin(data));
//     }
//   };

  
//   const { data, status, error } = useSelector((state) => state.UserLogin);

//   useEffect(() => {
//     if (status === STATUSES.SUCCESS) {
//       const data = { username: userName.trim(), password: userPassword.trim() };
//       dispatch(userToken(data));
//     } else if (status === STATUSES.ERROR) {
//       toast.error(error ? error : "Something Went Wrong");
//       dispatch(resetUserLogin({}));
//     }
//   }, [data, status, error, dispatch, resetUserLogin]);

//   const { data: tokenData, status: tokenStatus, error: tokenError } = useSelector((state) => state.UserToken);

//   useEffect(() => {
//     if (tokenStatus === STATUSES.SUCCESS) {
//       const data = { token: tokenData };
//       dispatch(userValidate(data));
//     } else if (tokenStatus === STATUSES.ERROR) {
//       toast.error(tokenError ? tokenError : "Something Went Wrong");
//       dispatch(resetUserToken({}));
//     }
//   }, [tokenData, tokenStatus, tokenError, dispatch]);

//   const { data: validateData, status: validateStatus, error: validateError } = useSelector((state) => state.UserValidate);

//   useEffect(() => {
//     if (validateStatus === STATUSES.SUCCESS) {
//       const token = tokenData;
//       Cookies.set('onifiedPKtoken', token);
//       localStorage.setItem('onifiedPKData', JSON.stringify(data));

//       dispatch(setAuthToken(token));
//       dispatch(setUserDetails(data));

//       toast.success("Login successfully");
//       dispatch(resetUserLogin({}));
//       dispatch(resetUserToken({}));
//       dispatch(resetUserValidate({}));
//       setUserName("");
//       setUserDetails("");
//       setNameFilled(false);
//       setPasswordFilled(false);

//     } else if (validateStatus === STATUSES.ERROR) {
//       toast.error(validateError || "Something Went Wrong");
//       dispatch(resetUserValidate({}));
//     }
//   }, [validateStatus, validateData, validateError, dispatch]);

  
//   return (
//     <>
//       <div className="login_con">
//         <div className="login_box">
//           <div className="col1">
//             <img src={login_img} alt={"hero"} id="login_img" />
//           </div>
//           <div className="col1">
//             <div className="common_bg login">
//               <h3 className="main_heading">SIGN IN</h3>
//               <div className="input_tag">User ID</div>
//               <div className={'inp_box'}>
//                 <i>
//                   <FontAwesomeIcon icon={faLock} className="input_icon" />
//                 </i>
//                 <input
//                   className={`input_ ${nameFilled ? 'error' : ''}`}
//                   type="text"
//                   name="userName"
//                   onChange={(e) => { setUserName(e.target.value); setNameFilled(false); }}
//                 />
//                 {nameFilled && <div className="error_message">* User id is required</div>}
//               </div>
//               <div className="input_tag">Password</div>
//               <div className={'inp_box'}>
//                 <i>
//                   <FontAwesomeIcon icon={faLock} className="input_icon" />
//                 </i>
//                 <input
//                   className={`input_ ${passwordFilled ? 'error' : ''}`}
//                   type="password"
//                   name="userPassword"
//                   onChange={(e) => { setUserPassword(e.target.value); setPasswordFilled(false); }}
//                 />
//                 {passwordFilled && <div className="error_message">* Password is required</div>}
//               </div>
              
//                 <Link onClick={handleSubmit} style={{ textDecoration: "none" }}>
//                   <button className="common_btn">SIGN IN</button>
//                 </Link>
              
//               <div className="reset_btn">
//                 <span>Reset Password ?</span> <span>Forget Password ?</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;

// (
//   Object.keys(RegionAdditionalAttributes).length>0 ? Object.keys(RegionAdditionalAttributes).map((attributeKey, index) => (
//   <React.Fragment key={`${attributeKey}_${index}`}>
//       <tr>
//           <td>{RegionAdditionalAttributes[attributeKey].nametoDisplay}</td>
//           {data.map((item, dataIndex) => (
//               <td key={dataIndex}>
//                   {RegionAdditionalAttributes[attributeKey].type === "Image" ? (
//                      "Not Found"
//                   ) : (
//                        item?.regionInProgram?.regionAdditionalAttributes[attributeKey] && item?.regionInProgram?.regionAdditionalAttributes[attributeKey].length > 0 ? item?.regionInProgram?.regionAdditionalAttributes[attributeKey].join(', ') : "Not found"
//                   )}
//               </td>
//           ))}
//       </tr>
//       {RegionAdditionalAttributes[attributeKey].type === "NestedDropdown" && (
//           <tr key={`${attributeKey}_${index}_additional`}>
//               <td>{RegionAdditionalAttributes[attributeKey].nametoDisplay2}</td>
//               {data.map((item, dataIndex) => (
//                   <td key={dataIndex}>
//                       { item?.regionInProgram?.regionAdditionalAttributes[`${attributeKey}nEsTeD`] && item?.regionInProgram?.regionAdditionalAttributes[`${attributeKey}nEsTeD`].length > 0 ? item?.regionInProgram?.regionAdditionalAttributes[`${attributeKey}nEsTeD`].join(', ') : "Not found"}
//                   </td>
//               ))}
//           </tr>
//       )}
//   </React.Fragment>
// )) :null
// )



// import React ,{ useEffect, useState } from 'react';
// import './AllStep.css';
// import { useDispatch, useSelector} from 'react-redux';
// import { toast } from "react-toastify";
// import img from '../../../../assets/Images/MarketMaps/AddData.png'
// import { Link } from 'react-router-dom';
// import { getAllProgramAttributes, getOneProgramAttributes } from '../../../../store/Slices/marketMapAttributeSlice.js';
// import { createProgram, resetProgramState} from '../../../../store/Slices/marketMapProgram.js'
// import STATUSES from '../../../../store/Statuses.js';
// import Loader from '../../../Common/Loader/Loader.jsx';
// import Multiselect from 'multiselect-react-dropdown';



// const ProgramStep = ({setProgramId ,setProgramClassName ,setProductGroupName ,
//   title,
//   getAllAttributesLink,
//   getOneAttributesLink,
//   createTablelink,
//   resetTableState,
//   tableDataSelector,
//   allAttributesGroupSelector,
//   oneAttributeGroupSelector,
//   classNamePrefix,
//   linkForRedirectSetting,
//   additionalAttributesName,
//   getAllDataForReferenceLink,
//   getAllDataForReference,
//   ImageUploadLink,
//   getID,
//   productGroupName,

//   programId, 
//   programClassName,
//   selectedProductType,
//   selectedGroupNameToShowAttributes
// }) => {
 

//   // =====================================================================


//   const dispatch = useDispatch();
  
//   //[1 ]Get All Attributes Group 
//   useEffect(()=>{
//     dispatch(getAllAttributesLink({}));
//   },[dispatch]);

//   const {data:AttributesGroupsData , status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector(allAttributesGroupSelector);



//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState(selectedGroupNameToShowAttributes);
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(0); 
//   console.log("groupNameToShowAttributes --", groupNameToShowAttributes)

//   // Function to handle the selection change
//   const handleGroupSelection = (e) => {
//     setGroupNameToShowAttributes(e.target.value);
//   };

//   const handleClassNameSelection = (e) =>{
//     const selectedIndex = e.target.selectedIndex;
//     setSelectedGroupIndex(selectedIndex ); 
//   }

//   useEffect(()=>{
//     if(groupNameToShowAttributes.length>0){
//         dispatch(getOneAttributesLink(groupNameToShowAttributes));
//     }
//   },[dispatch ,groupNameToShowAttributes ,setProgramClassName,selectedGroupIndex]);

//   useEffect(()=>{
//     if(productGroupName.length>0){
//         setProgramClassName(selectedGroupIndex === 1 ? "Program" : `Program${selectedGroupIndex}`)
//     }
//   },[dispatch ,setProgramClassName,selectedGroupIndex]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector(oneAttributeGroupSelector);

//   const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;

//   const {groupAttributes, [additionalAttributesName]: additionalAttributes, imagesAttributes} = singleAttributeGroupData;

//   // console.log(singleAttributeGroupData);
//   // console.log(groupNameToShowAttributes);
//   // console.log(selectedGroupIndex);
//   // // console.log(classNameToSave);
//   // console.log(classNameToSave)
//   // console.log(selectedGroupIndex)




//   //[3] Handel Submit The data 
//   const [groupInputData, setGroupInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});
//   const [ additionalValidationErrors,  setAdditionalValidationErrors] = useState({});
//   const [ groupValidationErrors,  setGroupValidationErrors] = useState({});


//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       setAdditionalInputData(prevState => {
//         const updatedState = { ...prevState };

//         Object.keys(additionalAttributes).forEach(key => {
//           // Do not initialize for type "Multiselect"
//           if (additionalAttributes[key].type === "MultiSelect" || additionalAttributes[key].type === "Image") {
//             return;
//           }

//           // Initialize the value if not already present
//           if (!updatedState[key]) {
//             updatedState[key] = [""];
//           }

//           // Initialize child attribute for NestedDropdown type
//           if (additionalAttributes[key].type === "NestedDropdown") {
//             const childAttributeName = `${key}nEsTeD`;
//             if (!updatedState[childAttributeName]) {
//               updatedState[childAttributeName] = [""];
//             }
//           }
//         });

//         return updatedState;
//       });
//     }
//   }, [additionalAttributes, singleAttributeGroupStatus]);


//   useEffect(() => {
//       if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 

//       setAddNestedDropdownValues(prevState => {
//           const updatedState = { ...prevState };
//           Object.keys(additionalAttributes).forEach(key => {
//               if (additionalAttributes[key].type === "NestedDropdown") {
//                   if (!updatedState[key]) {
//                       updatedState[key] = [""];
//                   }
//               }
//           });
//           return updatedState;
//       }); }
//   }, [additionalAttributes,singleAttributeGroupStatus]);

//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 
//     setGroupInputData(prevState => {
//       const updatedState = { ...prevState };
//       Object.keys(groupAttributes).forEach(key => {
//         if (groupAttributes[key].type === "MultiSelect"|| groupAttributes[key].type === "Image") {
//           return;
//         }

//         if (!updatedState[key]) {
//           updatedState[key] = [""];
//         }

//         // Initialize child attribute for NestedDropdown type
//         if (groupAttributes[key].type === "NestedDropdown") {
//           const childAttributeName = `${key}nEsTeD`;
//           if (!updatedState[childAttributeName]) {
//             updatedState[childAttributeName] = [""];
//           }
//         }
//       });
//       return updatedState;
//     }); }
//   }, [groupAttributes,singleAttributeGroupStatus]);

//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 

//     setGroupNestedDropdownValues(prevState => {
//         const updatedState = { ...prevState };
//         Object.keys(groupAttributes).forEach(key => {
//             if (groupAttributes[key].type === "NestedDropdown") {
//                 if (!updatedState[key]) {
//                     updatedState[key] = [""];
//                 }
//             }
//         });
//         return updatedState;
//     }); }
//   }, [groupAttributes,singleAttributeGroupStatus]);






//   // Inside the component, define a new state for mandatory errors
//   const [groupMandatoryErrors, setGroupMandatoryErrors] = useState({});
//   const [additionalMandatoryErrors, setAdditionalMandatoryErrors] = useState({});

//   const [groupNestedDropdownValues, setGroupNestedDropdownValues] = useState({});
//   const [addNestedDropdownValues, setAddNestedDropdownValues] = useState({});



//   // console.log("groupInputData ====", groupInputData);
//   // console.log("additionalInputData ====", additionalInputData);
//   // console.log("groupValidationErrors ===" ,  groupValidationErrors);
//   // console.log("groupMandatoryErrors ===" , groupMandatoryErrors)




//   useEffect(() => {
//     // Clear input data when group name changes
//     setGroupInputData({});
//     setAdditionalInputData({});
//     setAddNestedDropdownValues({});
//     setGroupNestedDropdownValues({});
//     setGroupValidationErrors({});
//     setAdditionalValidationErrors({});
//     setGroupMandatoryErrors({});
//     setAdditionalMandatoryErrors({});

//     setInputValues({});
//     setNestedDropdownValues({});
//     setImageValidationErrors({});

//     setImageFiles({});
//     setImageMandatoryErrors({});
//     setProductGroupName("");
//     setProgramId("");
//     setProgramClassName("");
//   }, [groupNameToShowAttributes]);

 



//   // [4] Choose reference 
//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;
//       dispatch(getAllDataForReferenceLink(classNameToSave));
//     }
//   }, [singleAttributeGroupStatus]);




//   const {data:AllDataForReference , status:statusForReference , error:errorForReference  } = useSelector(getAllDataForReference);

//   // console.log(AllDataForReference);


//   const ensureNonEmptyArraysInAdditional = (attributes, additionalInputData) => {
//     const updatedAttributes = {};
  
//     // Iterate over additionalInputData to construct updatedAttributes
//     Object.keys(additionalInputData).forEach(key => {
//       if (attributes.hasOwnProperty(key)) {
//         updatedAttributes[key] = attributes[key];
//       } else {
//         updatedAttributes[key] = additionalInputData[key];
//       }
  
//       // Ensure non-empty arrays
//       if ((Array.isArray(updatedAttributes[key]) && updatedAttributes[key].length === 0) || updatedAttributes[key] === null) {
//         updatedAttributes[key] = [""];
//       }
//     });
  
//     return updatedAttributes;
//   };
  
//   const ensureNonEmptyArraysInGroup = (attributes, additionalInputData) => {
//     const updatedAttributes = {};
  
//     // Iterate over groupInputData to construct updatedAttributes
//     Object.keys(groupInputData).forEach(key => {
//       if (attributes.hasOwnProperty(key)) {
//         updatedAttributes[key] = attributes[key];
//       } else {
//         updatedAttributes[key] = additionalInputData[key];
//       }
  
//       // Ensure non-empty arrays
//       if ((Array.isArray(updatedAttributes[key]) && updatedAttributes[key].length === 0) || updatedAttributes[key] === null) {
//         updatedAttributes[key] = [""];
//       }
//     });
  
//     return updatedAttributes;
//   };

//   const handleReferenceSelection = (e) => {
//     const selectedId = e.target.value;

//     if (!selectedId) {
//         console.log("Selected ID is empty.");
//         return;
//     }


//     // Log the AllDataForReference for debugging
//     console.log("AllDataForReference:", JSON.stringify(AllDataForReference, null, 2));

//     const selectedReference = AllDataForReference.find(ref => ref[getID] === selectedId);

//     if (!selectedReference) {
//         console.log("Selected reference not found in AllDataForReference.");
//         return;
//     }

//     // Log the selected reference for debugging
//     console.log("Selected Reference:", JSON.stringify(selectedReference, null, 2));

//     let { groupAttributes: selectedGroupAttributes, [additionalAttributesName]: selectedAdditionalAttributes , imagesAttributes :selectedImagesAttributes } = selectedReference;
//     selectedGroupAttributes = ensureNonEmptyArraysInGroup(selectedGroupAttributes, groupInputData);
//     selectedAdditionalAttributes = ensureNonEmptyArraysInAdditional(selectedAdditionalAttributes, additionalInputData);
//     // Log the selected attributes for debugging
//     console.log("Selected Group Attributes:", selectedGroupAttributes);
//     console.log("Selected Additional Attributes:", selectedAdditionalAttributes);

//     setGroupInputData(selectedGroupAttributes);
//     setAdditionalInputData(selectedAdditionalAttributes);

//     // Update addNestedDropdownValues based on selectedAdditionalAttributes
//     const newAddNestedDropdownValues = {};
//     Object.keys(selectedAdditionalAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newAddNestedDropdownValues[parentKey] = selectedAdditionalAttributes[parentKey];
//             newAddNestedDropdownValues[childKey] = selectedAdditionalAttributes[childKey];
//         }
//     });
//     setAddNestedDropdownValues(newAddNestedDropdownValues);

//     // Log newAddNestedDropdownValues for debugging
//     console.log("New Add Nested Dropdown Values:", newAddNestedDropdownValues);

//     // Update groupNestedDropdownValues based on selectedGroupAttributes
//     const newGroupNestedDropdownValues = {};
//     Object.keys(selectedGroupAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             const childKey = key;
//             newGroupNestedDropdownValues[parentKey] = selectedGroupAttributes[parentKey];
//             newGroupNestedDropdownValues[childKey] = selectedGroupAttributes[childKey];
//         }
//     });
//     setGroupNestedDropdownValues(newGroupNestedDropdownValues);

//     const newNestedDropdownValues = {};
//     Object.keys(selectedImagesAttributes).forEach((key) => {
//         if (key.endsWith("nEsTeD")) {
//             const parentKey = key.slice(0, -"nEsTeD".length);
//             newNestedDropdownValues[parentKey] = selectedImagesAttributes[parentKey] ? selectedImagesAttributes[parentKey][0] : "";
//             newNestedDropdownValues[key] = selectedImagesAttributes[key] ? selectedImagesAttributes[key][0] : "";
//         }
//     });

//     setNestedDropdownValues(newNestedDropdownValues);

//     setInputValues(selectedImagesAttributes);

//     // Log newGroupNestedDropdownValues for debugging
//     console.log("New Group Nested Dropdown Values:", newGroupNestedDropdownValues);
//   };



//   const validateImageAttribute = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       // Clear error if the value is empty
//       setImageValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } 
//     else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     }else if (type === 'nestedDropdown' ) {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setImageValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));

//     return value;
//   };



//   const [inputValues, setInputValues] = useState({});
//   const [nestedDropdownValues, setNestedDropdownValues] = useState({});
//   const [imageValidationErrors, setImageValidationErrors] = useState({});

//   // console.log(inputValues)
//   // console.log(nestedDropdownValues)
//   // console.log(groupNestedDropdownValues)
//   const handleInputChange = (name, selectedValues, type) => {
//     const attributeName = name;
//     let newValue = selectedValues;

//     if (type) {
//       newValue = validateImageAttribute(type, newValue, attributeName);
//     }

      
//     setInputValues((prevInputValues) => ({
//       ...prevInputValues,
//       [attributeName]: [newValue],
//     }));
    
//   };

//   const saveSetMultiSelectValues = (name, selectedValues) => {
//     setInputValues((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//   };

//   const handleSetNestedDropdownSelection = (imageKey, groupName, i, value) => {
//     const parentAttributeName = `${groupName} ${i}`;
//     const nestedAttributeName = `${parentAttributeName}nEsTeD`;

//     setNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [parentAttributeName]: value, // Store the parent value in an array
//     }));

//     setInputValues((prevData) => ({
//       ...prevData,
//       [parentAttributeName]: [value], // Store the parent value in an array
//       [nestedAttributeName]: [prevData[nestedAttributeName] || ''], // Preserve the nested value in an array
//     }));
    
//     // Trigger validation for parent attribute
//     const parentType = imagesAttributes[imageKey].attgroups[i]?.type;
//     console.log("parentType-------------", parentType);
//     if (parentType) {
//         validateImageAttribute(parentType, value, parentAttributeName);
//       }
//   };


//   // console.log("inputValues", inputValues);
//   // console.log("nestedDropdownValues", nestedDropdownValues);
//   // console.log("imageValidationErrors", imageValidationErrors);



//   //  Group ================================================================================= 
//   const validateGroup = (type, value, name, index) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       setGroupValidationErrors(prevErrors => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[name]) {
//           updatedErrors[name][index] = null;
//         }
//         return updatedErrors;
//       });
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } 
//     else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setGroupValidationErrors(prevErrors => {
//       const updatedErrors = { ...prevErrors };
//       if (!updatedErrors[name]) {
//         updatedErrors[name] = [];
//       }
//       updatedErrors[name][index] = errorMessage;
//       return updatedErrors;
//     });

//     return value;
//   };

//   const changeGroupInputData = (e, i, index) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = groupAttributes[parentName]?.type;
//     const childDataType = groupAttributes[parentName]?.childDataType;

//     if (fieldType && !isChildAttribute) {
//       newValue = validateGroup(fieldType, newValue, name, index);
//     }

//     if (childDataType && isChildAttribute) {
//       newValue = validateGroup(childDataType, newValue, name, index);

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = groupAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateGroup(parentFieldType, groupInputData[parentName][index], parentName, index);
//       }
//     }

//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: newValue ? null : prevErrors[name],
//     }));

//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? newValue : val),
//     }));

//     // For nested child inputs, update the corresponding parent value
//     if (isChildAttribute) {
//       const parentIndex = index;
//       const parentValue = groupInputData[parentName][parentIndex];
//       const nestedAttributeName = `${parentName}nEsTeD`;
//       setGroupInputData((prevData) => ({
//         ...prevData,
//         [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === parentIndex ? newValue : val),
//       }));

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = groupAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateGroup(parentFieldType, parentValue, parentName, parentIndex);
//       }
//     }
//   };

//   const handleAddAnotherGroup = (inputKey) => {
//     const newField = "";
//     const updatedState = (prevState) => ({
//       ...prevState,
//       [inputKey]: [...prevState[inputKey], newField],
//     });

//     setGroupInputData(updatedState);

//     if (groupAttributes[inputKey].type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;

//       // Add a new field for the child attribute
//       setGroupInputData((prevState) => ({
//         ...prevState,
//         [childAttributeName]: [...prevState[childAttributeName], newField],
//       }));

//       setGroupNestedDropdownValues((prevState) => ({
//         ...prevState,
//         [inputKey]: [...prevState[inputKey], newField],
//       }));

//       // Check if there are validation errors for the specific nestedDropdown being added
//       const hasValidationErrors = groupValidationErrors[childAttributeName]?.some((error) => error !== null);

//       // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
//       setGroupValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         [childAttributeName]: hasValidationErrors ? prevErrors[childAttributeName] : null,
//       }));
//     }
//   };

//   const handleRemoveFieldGroup = (inputKey, index) => {
//     setGroupInputData((prevState) => {
//       const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
//       return {
//         ...prevState,
//         [inputKey]: updatedValues,
//       };
//     });

//     // Clear validation error for the removed field
//     setGroupValidationErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[inputKey]) {
//         updatedErrors[inputKey] = updatedErrors[inputKey].filter((_, i) => i !== index);
//       }
//       return updatedErrors;
//     });

//     if (groupAttributes[inputKey]?.type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
//       setGroupInputData((prevState) => {
//         const updatedChildValues = prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
//         return {
//           ...prevState,
//           [childAttributeName]: updatedChildValues,
//         };
//       });

//       // Clear validation error for the removed child field
//       setGroupValidationErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[childAttributeName]) {
//           updatedErrors[childAttributeName] = updatedErrors[childAttributeName].filter((_, i) => i !== index);
//         }
//         return updatedErrors;
//       });

//       setGroupNestedDropdownValues((prevState) => {
//         const updatedValues = { ...prevState };
//         updatedValues[inputKey] = updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
//         return updatedValues;
//       });
//     }
//   };

//   const handleGroupNestedDropdownSelection = (name, value, index) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     let newValue = value;
//     const parentType = groupAttributes[name].type;

//     // console.log("parentType",parentType )
//     setGroupNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: prevValues[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//     }));

//     // Update both parent and child values in the state
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//       [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === index ? "" : val), // Reset child value
//     }));
//     if(parentType){
//       validateGroup(parentType, newValue, name, index);
//     }

//     // Clear any errors related to parent and child
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null, // Clear parent error
//       [nestedAttributeName]: null, // Clear child error
//     }));
//   };

//   const saveGroupMultiSelectValues = (name, selectedValues) => {
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };


    
//   //  Addittional=================================================================================
//   const validateAdditional = (type, value, name, index) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       setAdditionalValidationErrors(prevErrors => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[name]) {
//           updatedErrors[name][index] = null;
//         }
//         return updatedErrors;
//       });
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setAdditionalValidationErrors(prevErrors => {
//       const updatedErrors = { ...prevErrors };
//       if (!updatedErrors[name]) {
//         updatedErrors[name] = [];
//       }
//       updatedErrors[name][index] = errorMessage;
//       return updatedErrors;
//     });

//     return value;
//   };

//   const changeAdditionalInputData = (e, i, index) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = additionalAttributes[parentName]?.type;
//     const childDataType = additionalAttributes[parentName]?.childDataType;

//     if (fieldType && !isChildAttribute) {
//       newValue = validateAdditional(fieldType, newValue, name, index);
//     }

//     if (childDataType && isChildAttribute) {
//       newValue = validateAdditional(childDataType, newValue, name, index);

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, additionalInputData[parentName][index], parentName, index);
//       }
//     }

//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: newValue ? null : prevErrors[name],
//     }));

//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? newValue : val),
//     }));

//     // For nested child inputs, update the corresponding parent value
//     if (isChildAttribute) {
//       const parentIndex = index;
//       const parentValue = additionalInputData[parentName][parentIndex];
//       const nestedAttributeName = `${parentName}nEsTeD`;
//       setAdditionalInputData((prevData) => ({
//         ...prevData,
//         [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === parentIndex ? newValue : val),
//       }));

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, parentValue, parentName, parentIndex);
//       }
//     }
//   };

//   const handleAddAnotherAdditional = (inputKey) => {
//     const newField = "";
//     const updatedState = (prevState) => ({
//       ...prevState,
//       [inputKey]: [...prevState[inputKey], newField],
//     });

//     setAdditionalInputData(updatedState);

//     if (additionalAttributes[inputKey].type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;

//       // Add a new field for the child attribute
//       setAdditionalInputData((prevState) => ({
//         ...prevState,
//         [childAttributeName]: [...prevState[childAttributeName], newField],
//       }));

//       setAddNestedDropdownValues((prevState) => ({
//         ...prevState,
//         [inputKey]: [...prevState[inputKey], newField],
//       }));

//       // Check if there are validation errors for the specific nestedDropdown being added
//       const hasValidationErrors = additionalValidationErrors[childAttributeName]?.some((error) => error !== null);

//       // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
//       setAdditionalValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         [childAttributeName]: hasValidationErrors ? prevErrors[childAttributeName] : null,
//       }));
//     }
//   };

//   const handleRemoveFieldAdditional = (inputKey, index) => {
//     setAdditionalInputData((prevState) => {
//       const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
//       return {
//         ...prevState,
//         [inputKey]: updatedValues,
//       };
//     });

//     // Clear validation error for the removed field
//     setAdditionalValidationErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[inputKey]) {
//         updatedErrors[inputKey] = updatedErrors[inputKey].filter((_, i) => i !== index);
//       }
//       return updatedErrors;
//     });

//     if (additionalAttributes[inputKey]?.type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
//       setAdditionalInputData((prevState) => {
//         const updatedChildValues = prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
//         return {
//           ...prevState,
//           [childAttributeName]: updatedChildValues,
//         };
//       });

//       // Clear validation error for the removed child field
//       setAdditionalValidationErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[childAttributeName]) {
//           updatedErrors[childAttributeName] = updatedErrors[childAttributeName].filter((_, i) => i !== index);
//         }
//         return updatedErrors;
//       });

//       setAddNestedDropdownValues((prevState) => {
//         const updatedValues = { ...prevState };
//         updatedValues[inputKey] = updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
//         return updatedValues;
//       });
//     }
//   };
    
//   const handleAdditionalNestedDropdownSelection = (name, value, index) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     let newValue = value;
//     const parentType = additionalAttributes[name].type;
  
//     // console.log("parentType",parentType )
//     setAddNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: prevValues[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//     }));

//     // Update both parent and child values in the state
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//       [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === index ? "" : val), // Reset child value
//     }));
//     if(parentType){
//       validateAdditional(parentType, newValue, name, index);
//     }

//     // Clear any errors related to parent and child
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null, // Clear parent error
//       [nestedAttributeName]: null, // Clear child error
//     }));
//   };

//   const saveAdditionlMultiSelectValues = (name, selectedValues) => {
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };


//   // =============================== images =========================
//   const [imageFiles, setImageFiles] = useState({});
//   const [imageMandatoryErrors, setImageMandatoryErrors] = useState({});
//   // console.log("imageFiles -----" , imageFiles)
//   // console.log("imageMandatoryErrors -----" , imageMandatoryErrors)

//   const handleImageChange = (event, attributeName) => {
//     const file = event.target.files[0];

//     if (file) {
//         setImageFiles((prevFiles) => ({
//             ...prevFiles,
//             [attributeName]: file,
//         }));

//         // Clear mandatory error for the image attribute
//         setImageMandatoryErrors((prevErrors) => {
//             const updatedErrors = { ...prevErrors };
//             if (updatedErrors[attributeName]) {
//                 updatedErrors[attributeName] = null;
//             }
//             return updatedErrors;
//         });
//     }
//   };


//   const handleSubmitData = (e) => {
//     e.preventDefault();

//     // Check if there are any validation errors
//     if (Object.values(additionalValidationErrors).flat().some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }
//     if (Object.values(groupValidationErrors).flat().some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }

//     // Trim values of all arrays in additionalInputData
//     const trimmedAdditionalInputData = Object.fromEntries(
//         Object.entries(additionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : value])
//     );
//     // Trim values of all arrays in groupInputData
//     const trimmedGroupInputData = Object.fromEntries(
//         Object.entries(groupInputData).map(([key, value]) => [key, Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : value])
//     );


//     // Remove empty strings from arrays
//     const filteredAdditionalInputData = Object.fromEntries(
//         Object.entries(trimmedAdditionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.filter(val => val !== '') : value])
//     );
//     const filteredGroupInputData = Object.fromEntries(
//         Object.entries(trimmedGroupInputData).map(([key, value]) => [key, Array.isArray(value) ? value.filter(val => val !== '') : value])
//     );

    

//     // Check mandatory fields
//     const newAdditionalMandatoryErrors = {};
//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//         return;
//       }
//         if (value.mandatory && (!filteredAdditionalInputData[key] || filteredAdditionalInputData[key].length === 0)) {
//             newAdditionalMandatoryErrors[key] = 'This field is mandatory';
//         }
//         // Check mandatory fields for nested dropdowns
//       if (value.type === "NestedDropdown") {
//         const childAttributeName = `${key}nEsTeD`;

//         (filteredAdditionalInputData[key] || []).forEach((parentValue, index) => {
//             const childValue = filteredAdditionalInputData[childAttributeName] ? filteredAdditionalInputData[childAttributeName][index] : "";

//             if (value.mandatory && (!parentValue || parentValue.trim() === "" || !childValue || childValue.trim() === "")) {
//                 newAdditionalMandatoryErrors[childAttributeName] = newAdditionalMandatoryErrors[childAttributeName] || [];
//                 newAdditionalMandatoryErrors[childAttributeName][index] = 'Both fields are mandatory';
//             }
//         });
//       }
//     });
//     setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);


//     const newGroupMandatoryErrors = {};
//     Object.entries(groupAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//         return;
//       }
//         if (value.mandatory && (!filteredGroupInputData[key] || filteredGroupInputData[key].length === 0)) {
//           newGroupMandatoryErrors[key] = 'This field is mandatory';
//         }
//           // Check mandatory fields for nested dropdowns
//       if (value.type === "NestedDropdown") {
//         const childAttributeName = `${key}nEsTeD`;

//         (filteredGroupInputData[key] || []).forEach((parentValue, index) => {
//             const childValue = filteredGroupInputData[childAttributeName] ? filteredGroupInputData[childAttributeName][index] : "";

//             if (value.mandatory && (!parentValue || parentValue.trim() === "" || !childValue || childValue.trim() === "")) {
//               newGroupMandatoryErrors[childAttributeName] = newGroupMandatoryErrors[childAttributeName] || [];
//                 newGroupMandatoryErrors[childAttributeName][index] = 'Both fields are mandatory';
//             }
//         });
//       }
//     });
//     setGroupMandatoryErrors(newGroupMandatoryErrors);

    
//     const newImageMandatoryErrors = {};
//     Object.entries(groupAttributes).forEach(([key, value]) => {
//         if (value.type === "Image") {
//             if (value.mandatory && ( !imageFiles[key] ) ){
//                 newImageMandatoryErrors[key] = 'This field is mandatory';
//             }
//         }
//     });
//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//           if (value.mandatory && ( !imageFiles[key] ) ){
//               newImageMandatoryErrors[key] = 'This field is mandatory';
//           }
//       }
//     });
//     setImageMandatoryErrors(newImageMandatoryErrors);


//     // Show mandatory errors

//     if (Object.values(newAdditionalMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }
//     if (Object.values(newGroupMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }
//     if (Object.values(newImageMandatoryErrors).some(error => error !== null)) {
//       toast.warning('Please fill in all mandatory fields before saving.');
//       return;
//     }


//     const data = {
//         groupAttributes: filteredGroupInputData,
//         [additionalAttributesName]: filteredAdditionalInputData ,
//         imagesAttributes : inputValues,
//         groupName: groupNameToShowAttributes,
//         className: classNameToSave,
//         productType : productGroupName,
//         regionInProgram : {},
//         packagingInProgram : {},
//         promotionInProgram : {},
//         groupInProgram : {}
//     };

//     dispatch(createTablelink(data));
//     console.log("data===", data);

//     // toast.success('Data saved successfully.');
//   };

//   const {  data, status , error } = useSelector(tableDataSelector);

//   // useEffect(()=>{
//   //   if (data && status===STATUSES.SUCCESS) {
//   //     toast.success("Created Successfully");
//   //     dispatch(resetTableState({}));
//   //   }
//   //   if (error){
//   //     toast.error(error);
//   //     dispatch(resetTableState({}));
//   //   }
//   // }, [data ,status ,error ,dispatch]);

//   useEffect(() => {
//     if (data && status === STATUSES.SUCCESS) {
        
//         const createdID = data; // Assuming the ID is in `data.id`
        
//         if (createdID && imageFiles) {
//             Object.entries(imageFiles).forEach(async ([key, file]) => {
//                 const imageFormData = new FormData();
//                 imageFormData.append("images", file);
//                 const paramData = { [getID]: data, imageName: key };
//                 await dispatch(ImageUploadLink({formData : imageFormData , ...paramData}));
//             });
//         }
        
//         toast.success("Created Successfully");
//         //       const programId = data.split(': ')[1];
//         // setProgramId(programId); // Pass programId to parent component
//         setProgramId(data)
//         dispatch(resetTableState({}));
//     }
//     if (error) {
//         toast.error(error);
//         dispatch(resetTableState({}));
//     }
//   }, [data, status, error]);

//   console.log(productGroupName);




//   const RenderWarning = ({msg, showLink}) => {
//   return (
//     <div className='select_warning'>
//       <img src={img} alt="imgs" />
//       <h6>{msg}</h6>
//       {showLink?<Link to={"/market-map/market-Map-Settings/Add-Program-Attributes"}>Create Attributes group</Link>:null}
//     </div>
//   )};

//   const HoverTitle = (type, varDescription, mandatory) => {
//     const displayType = type === 'String' ? 'Text - Single line' : type;
//     return `Data type - ${displayType}\nDescription - ${varDescription}\nMandatory - ${mandatory ? 'Yes' : 'No'}`;
//   };

//   const renderStar = (mandatory) => {
//     return mandatory ? <i className='mandatory_star'>*</i> : null;
//   };


 
//   return (
//     <>
    
//     <div className='add_table_page program_step_input_container' style={productGroupName.length === 0 ? { position: "relative" } : { position: "absolute" }}>

//       <div className="program_step_select_container" style={productGroupName.length === 0 ? { position: "relative" } : { position: "absolute" }}>
//         <div className="selecter_container">
//           <select  type="text" required value={selectedGroupNameToShowAttributes} onChange={handleGroupSelection} >
//                 <option value="" className='select_step_value'>Select Group of program</option>
//                 {
//                   AttributeGroupStatus === STATUSES.LOADING ? (
//                     <option className='error_option' >Loading...</option>
//                   ) : 
//                   AttributeGroupStatus === STATUSES.ERROR ? (
//                     <option className='error_option' >Something went wrong {AttributeGroupError}</option>
//                   ) :(
//                     Object.keys(AttributesGroupsData).length>0 && AttributesGroupsData.map((item ,index) =>
//                         <option value={item.groupName} className='select_step_value' key={item.groupId}>{item.groupName}</option>
//                       )
//                   )
//                 }
//           </select>
//         </div>
//         {
//           groupNameToShowAttributes.length > 0 && (
//             <div className="selecter_container">
//               <select type="text" required onChange={(e) => { setProductGroupName(e.target.value); handleClassNameSelection(e); }}>
//                 <option value="" className='select_step_value'>Select Group of product</option>
//                 {
//                   AttributeGroupStatus === STATUSES.LOADING ? (
//                     <option className='error_option' >Loading...</option>
//                   ) : 
//                   AttributeGroupStatus === STATUSES.ERROR ? (
//                     <option className='error_option' >Something went wrong.</option>
//                   ) :(
//                     Object.keys(AttributesGroupsData).length>0 && 
//                       AttributesGroupsData.map((item, index) => {
//                         if (item.groupName === groupNameToShowAttributes && item.productType && item.productType.productGroup) {
//                           return item.productType.productGroup.map((product, index) => (
//                             <option value={product} className='select_step_value' key={index}>{product}</option>
//                           ));
//                         }
//                         return null;
//                       })
                    
//                   )
//                 }
//               </select>
//             </div>

//           )
//         }
//       </div>
      


//   <> 
//       {
//         productGroupName.length=== 0 ?(
//           <></>
//         ): (
//           <div className="program_step_inputs">
//     {
//       AttributeGroupStatus === STATUSES.LOADING ? (
//         <Loader/>
//       ) : AttributeGroupStatus === STATUSES.SUCCESS ?(
//         (
//           Object.keys(AttributesGroupsData).length===0 ?(
//             <RenderWarning message={`Please create a group of attributes to add ${title.toLowerCase()}`} showLink={linkForRedirectSetting} />
//           ):(              
//               groupNameToShowAttributes.length===0?(
//                 <RenderWarning message={`Please select the group of attributes to add ${title.toLowerCase()}`} />
//               ):(
//                 <>
//               {
  
//               singleAttributeGroupStatus === STATUSES.LOADING?(
//                 <Loader/>
//               ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                 <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//               ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                   (Object.keys(groupAttributes).length===0 && Object.keys(additionalAttributes).length === 0 && Object.keys(imagesAttributes).length === 0)?(
//                   <>
//                     <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                     <RenderWarning message={`No attributes found to add ${groupNameToShowAttributes}`} />
//                   </>
//                   ):(
                    
//                   <>
//                     <div className='att_header'><span>Attributes Group - <strong>{singleAttributeGroupData?.groupName}</strong></span>
//                     <select className='select_step_data choosse_reference' type="text" required onChange={handleReferenceSelection}>
//                       <option value="" className='select_step_value'>Choose reference</option>
//                       {
//                         statusForReference === STATUSES.LOADING ? (
//                           <option className='error_option'>Loading...</option>
//                         ) :
//                         statusForReference === STATUSES.ERROR ? (
//                           <option className='error_option'>Something went wrong {errorForReference}</option>
//                         ) :
//                         statusForReference === STATUSES.SUCCESS ? (
//                           AllDataForReference && typeof AllDataForReference === "string" ? (
//                             <option className='error_option'>{`No ${title} found to choose reference`}</option>
//                           ):(
//                             AllDataForReference?.map((item, index) => {
//                               const firstKey = Object.keys(groupAttributes)[0]; // Get the first key dynamically
//                               return (
//                                 <option value={item[getID]} className='select_step_value' key={index}>
//                                   {item.groupAttributes[firstKey]}
//                                 </option>
//                               );
//                             })
//                           ) 
//                         ) : null
//                       }
//                     </select>
                    
//                     </div>
  
  
//                     <div className="all_attributes_to_add_data">

//                     {
//                       <>
//                       {Object.keys(groupAttributes).map((i, index) => {
//                         if (groupAttributes[i].type === "Date") {
//                           return (
//                             <div key={index} className='inp_cont' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                               {(groupInputData[i] || []).map((value, valueIndex) => (
//                                 <div className='_input_box' key={valueIndex}>
//                                            {renderStar(groupAttributes[i]?.mandatory)}

//                                   <input className='table_input' type="date" value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required />
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
//                                   {valueIndex !== 0 && (
//                                     <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                   )}
//                                   {/* Display mandatory error if present */}
//                                   {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                   {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                 </div>
//                               ))}
//                               {groupAttributes[i].repeat && (
//                                 <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                               )}
//                             </div>
//                           );
//                         }
//                         else if (groupAttributes[i].type === "Image"){
//                           return(
//                             <div key={index} className='_input_box'   title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                                                          {renderStar(groupAttributes[i]?.mandatory)}

//                               <input className='table_input image_input' type="file" name={i} onChange={(e) => handleImageChange(e, i)} accept="image/*" required/>
//                               <span>{groupAttributes[i].nametoDisplay}</span>
          
//                               {imageMandatoryErrors[i] && <div className="input_error">{imageMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         }
                        
//                         else if (groupAttributes[i].type === "DateTime") {
//                           return (
//                             <div className='inp_cont' key={index} title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                               {(groupInputData[i] || []).map((value, valueIndex) => (
//                                 <div className='_input_box' key={valueIndex}>
//                                                                              {renderStar(groupAttributes[i]?.mandatory)}

//                                   <input className='table_input' type="datetime-local" value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required placeholder='' />
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
//                                   {valueIndex !== 0 && (
//                                     <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                   )}
//                                   {/* Display mandatory error if present */}
//                                   {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                   {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                 </div>
//                               ))}
//                               {groupAttributes[i].repeat && (
//                                 <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                               )}
//                             </div>
//                           );
//                         } 
//                         else if(groupAttributes[i].type==="MultiSelect") {
//                           return(
//                             <div style={{position:"relative"}} key={index} title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                                                          {renderStar(groupAttributes[i]?.mandatory)}

//                               <Multiselect  isObject={false} options={groupAttributes[i].options} placeholder={groupAttributes[i].nametoDisplay} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} onSelect={(selectedList) => saveGroupMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveGroupMultiSelectValues(i, selectedList)} selectedValues={groupInputData[i] || []} required/>
//                               {groupMandatoryErrors[i] && <div className="input_error multiSelectError">{groupMandatoryErrors[i]}</div>}
//                             </div>
//                           )
//                         } 

//                         else if (groupAttributes[i].type === "Dropdown") {
//                           return (
//                             <div key={index} className='inp_cont' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                               {(groupInputData[i] || []).map((value, valueIndex) => (
//                                 <div className='_input_box' key={valueIndex}>
//                                                                              {renderStar(groupAttributes[i]?.mandatory)}

//                                   <select className='table_input' value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required >
//                                     <option value="">{}</option>
//                                     {groupAttributes[i].options.map((option, optionIndex) => (
//                                       <option key={optionIndex} value={option}>{option}</option>
//                                     ))}
//                                   </select>
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
//                                   {valueIndex !== 0 && (
//                                     <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                   )}
//                                   {/* Display mandatory error if present */}
//                                   {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                   {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                 </div>
//                               ))}
//                               {groupAttributes[i].repeat && (
//                                 <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                               )}
//                             </div>
//                           )
//                         } else if (groupAttributes[i].type === "NestedDropdown") {
//                           const parentAttributeName = i;
//                           const childAttributeName = `${i}nEsTeD`;

//                           // Check if any parent values are present
//                           const isParentValueAdded = groupInputData[parentAttributeName] && groupInputData[parentAttributeName].some(value => value.trim() !== "");

//                           // Check if any child values are present
//                           const isChildValueAdded = groupInputData[childAttributeName] && groupInputData[childAttributeName].some(value => value.trim() !== "");

//                           // Check if all sets have both parent and child values
//                           const allSetsHaveValues = (groupInputData[parentAttributeName] || []).every((_, valueIndex) => {
//                             const isParentValuePresent = groupInputData[parentAttributeName][valueIndex] && groupInputData[parentAttributeName][valueIndex].trim() !== "";
//                             const isChildValuePresent = groupInputData[childAttributeName] && groupInputData[childAttributeName][valueIndex] && groupInputData[childAttributeName][valueIndex].trim() !== "";
//                             return isParentValuePresent && isChildValuePresent;
//                           });

//                           return (
//                             <div className='inp_cont' key={index}>
//                               <>
//                               {(groupInputData[i] || []).map((value, valueIndex) => (
//                                 <div className='nested_input' key={valueIndex}>
//                                                                              {renderStar(groupAttributes[i]?.mandatory)}

//                                   <div className='_input_box' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                   <select className='table_input' value={value} onChange={(e) => handleGroupNestedDropdownSelection(i, e.target.value, valueIndex)} name={i} required>
//                                     <option value=""></option>
//                                     {groupAttributes[i].options.map((option, optionIndex) => (
//                                       <option key={optionIndex} value={option}>{option}</option>
//                                     ))}
//                                   </select>
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
//                                   {/* Display mandatory error if present */}
//                                   {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                   {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                   </div>
//                                   {/* Render child inputs based on the index of groupNestedDropdownValues */}
//                                   {groupNestedDropdownValues[i] && groupNestedDropdownValues[i][valueIndex] && (
//                                     <>
//                                       {/* Render child inputs based on childDataType */}
//                                       {groupAttributes[i].childDataType === "Dropdown" && (
//                                         <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                           <select className='table_input' value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} name={childAttributeName} required>
//                                             <option value=""></option>
//                                             {groupAttributes[i].nestedOptions[groupAttributes[i].options.indexOf(value)].map((nestedOption, nestedIndex) => (
//                                               <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                             ))}
//                                           </select>
//                                           <span>{groupAttributes[i].nametoDisplay2}</span>
//                                         </div>
//                                       )}
//                                       {groupAttributes[i].childDataType === "Date" && (
//                                       <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                         <input className='table_input' type="date" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                         <span>{groupAttributes[i].nametoDisplay2}</span>
//                                       </div>
//                                       )}
//                                       {groupAttributes[i].childDataType === "DateTime" && (
//                                       <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                         <input className='table_input' type="datetime-local" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                         <span>{groupAttributes[i].nametoDisplay2}</span>
//                                       </div>
//                                       )}
//                                       {(groupAttributes[i].childDataType === "String" || groupAttributes[i].childDataType === "Number" || groupAttributes[i].childDataType === "Decimal" || groupAttributes[i].childDataType === "Percent" || groupAttributes[i].childDataType === "Email" || groupAttributes[i].childDataType === "URL" || groupAttributes[i].childDataType === "Phone") && (
//                                       <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                         <input className='table_input' type="text" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} />
//                                         <span>{groupAttributes[i].nametoDisplay2}</span>
//                                       </div>

//                                       )}
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[childAttributeName] && <div className="input_error">{groupMandatoryErrors[childAttributeName]}</div>}
//                                       {groupValidationErrors[childAttributeName]?.[valueIndex] && <div className="input_error">{groupValidationErrors[childAttributeName][valueIndex]}</div>}
//                                     </>
//                                   )}

//                                   {valueIndex !== 0 && (
//                                     <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                   )}
//                                 </div>
//                               ))}

//                               {/* Conditionally render the "Add" button only once */}
//                               {groupAttributes[i].repeat && (
//                                 <button onClick={() => handleAddAnotherGroup(i)} disabled={!isParentValueAdded || !isChildValueAdded || !allSetsHaveValues ||  Object.values(groupValidationErrors[i] || {}).some(error => error !== null) ||  Object.values(groupValidationErrors[childAttributeName] || {}).some(error => error !== null)} >+</button>
//                               )}
//                               </>
//                             </div>
//                           );
//                         } 
//                         else {
//                           return (
//                             <div key={index} className='inp_cont' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                               {(groupInputData[i] || []).map((value, valueIndex) => (
//                                 <div className='_input_box' key={valueIndex}>
//                                                                              {renderStar(groupAttributes[i]?.mandatory)}

//                                   <input className='table_input' type="text" value={groupInputData[i][valueIndex]} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required/>
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
//                                   {valueIndex !== 0 && (
//                                     <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                   )}
//                                   {/* Display mandatory error if present */}
//                                   {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                   {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                 </div>
//                               ))}
//                               {groupAttributes[i].repeat && (
//                                 <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                               )}
//                             </div>
//                           );
//                         }
//                       })}
//                       </>
//                     }
                  
  
//                     {
//                       <>
//                          {Object.keys(additionalAttributes).map((i, index) => {
//                            if (additionalAttributes[i].type === "Date") {
//                              return (
//                                <div key={index} className='inp_cont' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                                          {renderStar(additionalAttributes[i]?.mandatory)}

//                                      <input className='table_input' type="date" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} placeholder='' required/>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            } else if (additionalAttributes[i].type === "Image"){
//                             return(
//                               <div key={index} className='_input_box' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}  >
//                                                                                          {renderStar(additionalAttributes[i]?.mandatory)}

//                                 <input className='table_input image_input' type="file" name={i} onChange={(e) => handleImageChange(e, i)} accept="image/*" required />
//                                 <span>{additionalAttributes[i].nametoDisplay}</span>
            
//                                 {imageMandatoryErrors[i] && <div className="input_error">{imageMandatoryErrors[i]}</div>}
//                               </div>
//                             )
//                           }                         
//                            else if (additionalAttributes[i].type === "DateTime") {
//                              return (
//                                <div className='inp_cont' key={index} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                                                                              {renderStar(additionalAttributes[i]?.mandatory)}

//                                      <input className='table_input' type="datetime-local" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} placeholder='' required/>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            } 
//                            else if(additionalAttributes[i].type==="MultiSelect") {
//                             return(
//                               <div style={{position:"relative"}} key={index} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                                                          {renderStar(additionalAttributes[i]?.mandatory)}

//                                 <Multiselect isObject={false} options={additionalAttributes[i].options} placeholder={additionalAttributes[i].nametoDisplay} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`} onSelect={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)} selectedValues={additionalInputData[i] || []} required/>
//                                 {additionalMandatoryErrors[i] && <div className="input_error multiSelectError">{additionalMandatoryErrors[i]}</div>}
//                               </div>
                            
//                             )
//                           } 
                           
//                            else if (additionalAttributes[i].type === "Dropdown") {
//                              return (
//                                <div key={index} className='inp_cont' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex}>
//                                                                                              {renderStar(additionalAttributes[i]?.mandatory)}

//                                      <select className='table_input' value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} required>
//                                        <option value="">{}</option>
//                                        {additionalAttributes[i].options.map((option, optionIndex) => (
//                                          <option key={optionIndex} value={option}>{option}</option>
//                                        ))}
//                                      </select>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              )
//                            } else if (additionalAttributes[i].type === "NestedDropdown") {
//                              const parentAttributeName = i;
//                              const childAttributeName = `${i}nEsTeD`;
                       
//                              // Check if any parent values are present
//                              const isParentValueAdded = additionalInputData[parentAttributeName] && additionalInputData[parentAttributeName].some(value => value.trim() !== "");
                       
//                              // Check if any child values are present
//                              const isChildValueAdded = additionalInputData[childAttributeName] && additionalInputData[childAttributeName].some(value => value.trim() !== "");
                       
//                              // Check if all sets have both parent and child values
//                              const allSetsHaveValues = (additionalInputData[parentAttributeName] || []).every((_, valueIndex) => {
//                                const isParentValuePresent = additionalInputData[parentAttributeName][valueIndex] && additionalInputData[parentAttributeName][valueIndex].trim() !== "";
//                                const isChildValuePresent = additionalInputData[childAttributeName] && additionalInputData[childAttributeName][valueIndex] && additionalInputData[childAttributeName][valueIndex].trim() !== "";
//                                return isParentValuePresent && isChildValuePresent;
//                              });
                       
//                              return (
//                                <div className='inp_cont' key={index}>
//                                <>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div  className='nested_input' key={valueIndex}>
//                                                                                              {renderStar(additionalAttributes[i]?.mandatory)}

//                                     <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                      <select className='table_input' value={value} onChange={(e) => handleAdditionalNestedDropdownSelection(i, e.target.value, valueIndex)} name={i} required>
//                                        <option value=""></option>
//                                        {additionalAttributes[i].options.map((option, optionIndex) => (
//                                          <option key={optionIndex} value={option}>{option}</option>
//                                        ))}
//                                      </select>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                     </div>
                       
//                                      {/* Render child inputs based on the index of addNestedDropdownValues */}
//                                      {addNestedDropdownValues[i] && addNestedDropdownValues[i][valueIndex] && (
//                                        <>
//                                          {/* Render child inputs based on childDataType */}
//                                          {additionalAttributes[i].childDataType === "Dropdown" && (
//                                            <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                              <select className='table_input' value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} name={childAttributeName} required>
//                                                <option value=""></option>
//                                                {additionalAttributes[i].nestedOptions[additionalAttributes[i].options.indexOf(value)].map((nestedOption, nestedIndex) => (
//                                                  <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                ))}
//                                              </select>
//                                              <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                            </div>
//                                          )}
//                                          {additionalAttributes[i].childDataType === "Date" && (
//                                           <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                            <input className='table_input' type="date" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                              <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                            </div>
//                                          )}
//                                          {additionalAttributes[i].childDataType === "DateTime" && (
//                                           <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                            <input className='table_input' type="datetime-local" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                              <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                            </div>
//                                          )}
//                                          {(additionalAttributes[i].childDataType === "String" || additionalAttributes[i].childDataType === "Number" || additionalAttributes[i].childDataType === "Decimal" || additionalAttributes[i].childDataType === "Percent" || additionalAttributes[i].childDataType === "Email" || additionalAttributes[i].childDataType === "URL" || additionalAttributes[i].childDataType === "Phone") && (
//                                           <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                             <input className='table_input' type="text" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} />
//                                               <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                           </div>

//                                          )}
//                                          {/* Display mandatory error if present */}
//                                          {additionalMandatoryErrors[childAttributeName] && <div className="input_error">{additionalMandatoryErrors[childAttributeName]}</div>}
//                                          {additionalValidationErrors[childAttributeName]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[childAttributeName][valueIndex]}</div>}
//                                        </>
//                                      )}
                       
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                    </div>
//                                  ))}
                       
//                                  {/* Conditionally render the "Add" button only once */}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={!isParentValueAdded || !isChildValueAdded || !allSetsHaveValues ||  Object.values(additionalValidationErrors[i] || {}).some(error => error !== null) ||  Object.values(additionalValidationErrors[childAttributeName] || {}).some(error => error !== null)} >+</button>
//                                  )}
//                                </>
//                                </div>
//                              );
//                            } 
//                            else {
//                              return (
//                                <div key={index} className='inp_cont'>
//                                  {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                    <div className='_input_box' key={valueIndex} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                                                              {renderStar(additionalAttributes[i]?.mandatory)}

//                                      <input className='table_input' type="text" value={additionalInputData[i][valueIndex]} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} required/>
//                                      <span>{additionalAttributes[i].nametoDisplay}</span>
//                                      {valueIndex !== 0 && (
//                                        <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                      )}
//                                      {/* Display mandatory error if present */}
//                                      {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                      {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                    </div>
//                                  ))}
//                                  {additionalAttributes[i].repeat && (
//                                    <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                  )}
//                                </div>
//                              );
//                            }
//                          })}
//                       </>
//                     }

//                     <div className="all_set_table_">
//                           {Object.keys(imagesAttributes).length !== 0 && (
//                             <div className="image_attributes_table_container">
//                               <>
//                                 {Object.keys(imagesAttributes).map((imageKey) => {
//                                   const { groupsName, attgroups } = imagesAttributes[imageKey];
//                                   return (
//                                     <div key={imageKey} className='one_set'>
//                                       <table>
//                                         <thead>
//                                           <tr>
//                                             <th></th>
//                                             {Object.keys(groupsName).map((groupName) => (
//                                               <th key={`${imageKey}_${groupName}`}>{groupsName[groupName].nametoDisplay}</th>
//                                             ))}
//                                           </tr>
//                                         </thead>
//                                         <tbody>
//                                           {Object.keys(attgroups).map((i) => (
//                                             <tr key={`${imageKey}_${i}`}>
//                                               <td>{attgroups[i].nametoDisplay}</td>
//                                               {Object.keys(groupsName).map((groupName) => (
//                                                 <td key={`${imageKey}_${groupName}_${i}`}>
//                                                   {attgroups[i].type === "Dropdown" ? (
//                                                     <>
//                                                       <select
//                                                         className='table_input'
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                         required
//                                                         placeholder=''
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "MultiSelect" ? (
//                                                     <>
//                                                       <Multiselect
//                                                         isObject={false}
//                                                         options={attgroups[i].options}
//                                                         placeholder={attgroups[i].nametoDisplay}
//                                                         onSelect={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         onRemove={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                         selectedValues={inputValues[`${groupName} ${i}`] || []}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "Date" ? (
//                                                     <>
//                                                       <input
//                                                         type="date"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "DateTime" ? (
//                                                     <>
//                                                       <input
//                                                         type="datetime-local"
//                                                         name={`${groupName} ${i}`}
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   ) : attgroups[i].type === "NestedDropdown" ? (
//                                                     <>
//                                                       <select
//                                                         name={`${groupName} ${i}`}
//                                                         onChange={(e) => handleSetNestedDropdownSelection(imageKey, groupName, i, e.target.value)}
//                                                         required
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                       >
//                                                         <option value="">{""}</option>
//                                                         {attgroups[i].options.map((option, optionIndex) => (
//                                                           <option key={optionIndex} value={option}>{option}</option>
//                                                         ))}
//                                                       </select>
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Dropdown" && (
//                                                         <>
//                                                           <select
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             required
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           >
//                                                             <option value="">{""}</option>
//                                                             {attgroups[i].nestedOptions[attgroups[i].options.indexOf(nestedDropdownValues[`${groupName} ${i}`][0])].map((nestedOption, nestedIndex) => (
//                                                               <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                             ))}
//                                                           </select>
//                                                           <span>{attgroups[i].nametoDisplay2}</span>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Date" && (
//                                                         <>
//                                                           <input
//                                                             type="date"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           <span>{attgroups[i].nametoDisplay2}</span>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "DateTime" && (
//                                                         <>
//                                                           <input
//                                                             type="datetime-local"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             placeholder=""
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           <span>{attgroups[i].nametoDisplay2}</span>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                       {nestedDropdownValues[`${groupName} ${i}`] && ["String", "Number", "Decimal", "Percent", "Email", "URL", "Phone"].includes(attgroups[i].childDataType) && (
//                                                         <>
//                                                           <input
//                                                             type="text"
//                                                             required
//                                                             name={`${groupName} ${i}nEsTeD`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                             value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                           />
//                                                           <span>{attgroups[i].nametoDisplay2}</span>
//                                                           {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                     </>
//                                                   ) : (
//                                                     <>
//                                                       <input
//                                                         type="text"
//                                                         value={inputValues[`${groupName} ${i}`] || ''}
//                                                         onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                       />
//                                                       {imageValidationErrors[`${groupName} ${i}`] && (
//                                                         <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                       )}
//                                                     </>
//                                                   )}
//                                                 </td>
//                                               ))}
//                                             </tr>
//                                           ))}
//                                         </tbody>
//                                       </table>
//                                     </div>
//                                   );
//                                 })}
//                               </>
//                             </div>
//                           )}
//                     </div>
  
  
                                                
//                     </div>
//                     <div className="submit_table_data_btn">
//                       <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Save</button>
//                     </div>
//                   </>                          
//                   )
//                 ):null                    
//               }
//                 </>
//               )
//           )
//         )
//       ) :null
//     }
//           </div>
//         )
//       }
//   </>

   
      
//     </div>

    
//     </>
//   )
// }

// export default ProgramStep;









// import React, { useEffect, useState } from 'react';
// import './UpdateTable.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClose } from '@fortawesome/free-solid-svg-icons';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import Loader from '../../../Common/Loader/Loader';
// import RenderWarning from '../RenderWarning/RenderWarning';
// import STATUSES from '../../../../store/Statuses';
// import Multiselect from 'multiselect-react-dropdown';


// const UpdateTable = ({
//   title,
//   updateTableToggle,
//   handelUpdateTableToggle,
//   getAllAttributesLink,
//   getOneAttributesLink,
//   createTablelink,
//   resetTableState,
//   tableDataSelector,
//   allAttributesGroupSelector,
//   oneAttributeGroupSelector,
//   classNamePrefix,
//   linkForRedirectSetting,
//   additionalAttributesName,
//   getAllDataForReferenceLink,
//   getAllDataForReference,
//   ImageUploadLink,
//   getID,
//   sendID,
//   recivedGroupNameToShowAttributes,
//   recivedSelectedGroupIndex,
//   getOneTableItemLink,
//   getOnetableItemSelector,
//   updateTableItemLink,
//   updateTableItemSelector,
//   resetUpdateItemState
// }) => {

//   const handelClose = () => {
//     handelUpdateTableToggle("")
//     document.body.style.overflow = 'unset';
//   }


//   const dispatch = useDispatch();
//   // console.log(tableDataSelector)
//   // console.log(title)
  
//   //[1 ]Get All Attributes Group 
//   // useEffect(()=>{
//   //   dispatch(getAllAttributesLink({}));
//   // },[dispatch]);
//   // console.log(allAttributesGroupSelector)

//   const {data:AttributesGroupsData , status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector(allAttributesGroupSelector);


//   console.log("AttributesGroupsData -" , AttributesGroupsData)
//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState(recivedGroupNameToShowAttributes);
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(recivedSelectedGroupIndex); 

//   // console.log("groupNameToShowAttributes 111111111111111111111" ,groupNameToShowAttributes)
//   // console.log("recivedGroupNameToShowAttributes 0000000000000000000000000" ,recivedGroupNameToShowAttributes)
//   // console.log("selectedGroupIndex" ,selectedGroupIndex)
//   // console.log("recivedSelectedGroupIndex" ,recivedSelectedGroupIndex)

//   // Function to handle the selection change
//   const handleGroupSelection = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     setGroupNameToShowAttributes(e.target.value);
//     setSelectedGroupIndex(selectedIndex ); 
//   };

//   // useEffect(()=>{
//   //   if(AttributeGroupStatus=== STATUSES.SUCCESS){
//   //     if(groupNameToShowAttributes.length>0){
//   //         dispatch(getOneAttributesLink(groupNameToShowAttributes));
//   //     }
//   //   }
//   // },[dispatch ,groupNameToShowAttributes, AttributeGroupStatus ]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector(oneAttributeGroupSelector);

//   const classNameToSave = selectedGroupIndex === 1 ? classNamePrefix : `${classNamePrefix}${selectedGroupIndex}`;

//   const {groupAttributes, [additionalAttributesName]: additionalAttributes, imagesAttributes} = singleAttributeGroupData;

//   console.log("singleAttributeGroupData " , singleAttributeGroupData)
//   console.log("groupAttributes " , groupAttributes)


//   // useEffect(()=>{
//   //   dispatch(getOneTableItem({}));
//   // },[]);

//   // useEffect(()=>{
//   //   if(singleAttributeGroupStatus=== STATUSES.SUCCESS){
//   //     if(sendID.length>0){
//   //       if(classNameToSave.length>0){
//   //         dispatch(getOneTableItemLink({ className :classNameToSave ,[getID] : sendID}))
//   //       }
//   //     }
//   //   }
//   // },[classNameToSave, sendID,singleAttributeGroupStatus]);

//   useEffect(() => {
//     if (AttributeGroupStatus === STATUSES.SUCCESS && singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       if (sendID.length > 0 && classNameToSave.length > 0) {
//         dispatch(getOneTableItemLink({ className: classNameToSave, [getID]: sendID }));
//         console.log("ppppppppppp")
//       }
//     }
//   }, [ AttributeGroupStatus, singleAttributeGroupStatus, sendID, classNameToSave, getID]);
  


//   const {data: OneTableItemData , status:OneTableItemStatus ,error:OneTableItemError} = useSelector(getOnetableItemSelector);



//   console.log("OneTableItemData ", OneTableItemData)




//   //[3] Handel Submit The data 
//   const [groupInputData, setGroupInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});
//   const [ additionalValidationErrors,  setAdditionalValidationErrors] = useState({});
//   const [ groupValidationErrors, setGroupValidationErrors] = useState({});
//   // console.log(groupAttributes)
//   // console.log(additionalAttributes)
  


//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
//       setAdditionalInputData(prevState => {
//         const updatedState = { ...prevState };
  
//         Object.keys(additionalAttributes).forEach(key => {
//           // Do not initialize for type "Multiselect"
//           if ( additionalAttributes[key].type === "Image") {
//             return;
//           }
//           if ( additionalAttributes[key].type === "MultiSelect") {
//             if (!updatedState[key]) {
//               updatedState[key] = [];
//             }
//             return;
//           }
  
//           // Initialize the value if not already present
//           if (!updatedState[key]) {
//             updatedState[key] = [""];
//           }
  
//           // Initialize child attribute for NestedDropdown type
//           if (additionalAttributes[key].type === "NestedDropdown") {
//             const childAttributeName = `${key}nEsTeD`;
//             if (!updatedState[childAttributeName]) {
//               updatedState[childAttributeName] = [""];
//             }
//           }
//         });
  
//         return updatedState;
//       });
//     }
//   }, [additionalAttributes, singleAttributeGroupStatus]);
  

//   useEffect(() => {
//       if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 

//       setAddNestedDropdownValues(prevState => {
//           const updatedState = { ...prevState };
//           Object.keys(additionalAttributes).forEach(key => {
//               if (additionalAttributes[key].type === "NestedDropdown") {
//                   if (!updatedState[key]) {
//                       updatedState[key] = [""];
//                   }
//               }
//           });
//           return updatedState;
//       }); }
//   }, [additionalAttributes,singleAttributeGroupStatus]);

//   useEffect(() => {
//     if (AttributeGroupStatus === STATUSES.SUCCESS && singleAttributeGroupStatus === STATUSES.SUCCESS){ 
//       if (sendID.length > 0 && classNameToSave.length > 0) { 

//         setGroupInputData(prevState => {
//           const updatedState = { ...prevState };
//           Object.keys(groupAttributes).forEach(key => {
//             if ( groupAttributes[key].type === "Image") {
//               return;
//             }
//             if ( groupAttributes[key].type === "MultiSelect") {
//               if (!updatedState[key]) {
//                 updatedState[key] = [];
//               }
//               return;
//             }
      
//             if (!updatedState[key]) {
//               updatedState[key] = [""];
//             }
      
//             // Initialize child attribute for NestedDropdown type
//             if (groupAttributes[key].type === "NestedDropdown") {
//               const childAttributeName = `${key}nEsTeD`;
//               if (!updatedState[childAttributeName]) {
//                 updatedState[childAttributeName] = [""];
//               }
//             }
//           });
//           return updatedState;
//         }); }
//       }
//   }, [AttributeGroupStatus, singleAttributeGroupStatus, sendID, classNameToSave, getID , groupAttributes , groupInputData]);
  
//   useEffect(() => {
//     if (singleAttributeGroupStatus === STATUSES.SUCCESS){ 
  
//     setGroupNestedDropdownValues(prevState => {
//         const updatedState = { ...prevState };
//         Object.keys(groupAttributes).forEach(key => {
//             if (groupAttributes[key].type === "NestedDropdown") {
//                 if (!updatedState[key]) {
//                     updatedState[key] = [""];
//                 }
//             }
//         });
//         return updatedState;
//     }); }
//   }, [groupAttributes,singleAttributeGroupStatus]);
  






//   // Inside the component, define a new state for mandatory errors
//   const [groupMandatoryErrors, setGroupMandatoryErrors] = useState({});
//   const [additionalMandatoryErrors, setAdditionalMandatoryErrors] = useState({});

//   const [groupNestedDropdownValues, setGroupNestedDropdownValues] = useState({});
//   const [addNestedDropdownValues, setAddNestedDropdownValues] = useState({});



//   console.log("groupInputData ====", groupInputData);
//   console.log("additionalInputData ====", additionalInputData);
//   // console.log(" groupValidationErrors ===" ,  groupValidationErrors);
//   // console.log("groupMandatoryErrors ===" , groupMandatoryErrors)




//   useEffect(() => {
//     // Clear input data when group name changes
//     setGroupInputData({});
//     setAdditionalInputData({});
//     setAddNestedDropdownValues({});
//     setGroupNestedDropdownValues({});
//     setGroupValidationErrors({});
//     setAdditionalValidationErrors({});
//     setGroupMandatoryErrors({});
//     setAdditionalMandatoryErrors({});

//     setInputValues({});
//     setNestedDropdownValues({});
//     setImageValidationErrors({});

//     setImageFiles({});
//     setImageMandatoryErrors({});
//   }, [groupNameToShowAttributes]);






//   // [4] Choose reference 
  



//   const {data:AllDataForReference , status:statusForReference , error:errorForReference  } = useSelector(getAllDataForReference);

//   // console.log(AllDataForReference);

//   const ensureNonEmptyArraysInAdditional = (attributes, additionalInputData) => {
//     const updatedAttributes = {};

//     // Iterate over additionalInputData to construct updatedAttributes
//     Object.keys(additionalInputData).forEach(key => {
//       if (attributes.hasOwnProperty(key)) {
//         updatedAttributes[key] = attributes[key];
//       } else {
//         updatedAttributes[key] = additionalInputData[key];
//       }

//       // Ensure non-empty arrays
//       if ((Array.isArray(updatedAttributes[key]) && updatedAttributes[key].length === 0) || updatedAttributes[key] === null) {
//         updatedAttributes[key] = [""];
//       }
//     });

//     return updatedAttributes;
//   };

//   const ensureNonEmptyArraysInGroup = (attributes, additionalInputData) => {
//     const updatedAttributes = {};

//     // Iterate over groupInputData to construct updatedAttributes
//     Object.keys(groupInputData).forEach(key => {
//       if (attributes.hasOwnProperty(key)) {
//         updatedAttributes[key] = attributes[key];
//       } else {
//         updatedAttributes[key] = additionalInputData[key];
//       }

//       // Ensure non-empty arrays
//       if ((Array.isArray(updatedAttributes[key]) && updatedAttributes[key].length === 0) || updatedAttributes[key] === null) {
//         updatedAttributes[key] = [""];
//       }
//     });

//     return updatedAttributes;
//   };


//   // useEffect(()=>{
//   //   if ( OneTableItemStatus === STATUSES.SUCCESS){
//   //     if (OneTableItemData.length>0){
//   //       let { groupAttributes: OneItemGroupAttributes, [additionalAttributesName]: OneItemAdditionalAttributes , imagesAttributes :OneItemImagesAttributes } = OneTableItemData[0];
      
//   //     OneItemGroupAttributes = ensureNonEmptyArraysInGroup(OneItemGroupAttributes, groupInputData);
//   //     OneItemAdditionalAttributes = ensureNonEmptyArraysInAdditional(OneItemAdditionalAttributes, additionalInputData);
//   //     // Log the selected attributes for debugging
//   //     console.log("Selected Group Attributes:", OneItemGroupAttributes);
//   //     console.log("Selected Additional Attributes:", OneItemAdditionalAttributes);

//   //     setGroupInputData(OneItemGroupAttributes);
//   //     setAdditionalInputData(OneItemAdditionalAttributes);

//   //     // Update addNestedDropdownValues based on OneItemAdditionalAttributes
//   //     const newAddNestedDropdownValues = {};
//   //     Object.keys(OneItemAdditionalAttributes).forEach((key) => {
//   //         if (key.endsWith("nEsTeD")) {
//   //             const parentKey = key.slice(0, -"nEsTeD".length);
//   //             const childKey = key;
//   //             newAddNestedDropdownValues[parentKey] = OneItemAdditionalAttributes[parentKey];
//   //             newAddNestedDropdownValues[childKey] = OneItemAdditionalAttributes[childKey];
//   //         }
//   //     });
//   //     setAddNestedDropdownValues(newAddNestedDropdownValues);

//   //     // Log newAddNestedDropdownValues for debugging
//   //     console.log("New Add Nested Dropdown Values:", newAddNestedDropdownValues);

//   //     // Update groupNestedDropdownValues based on OneItemGroupAttributes
//   //     const newGroupNestedDropdownValues = {};
//   //     Object.keys(OneItemGroupAttributes).forEach((key) => {
//   //         if (key.endsWith("nEsTeD")) {
//   //             const parentKey = key.slice(0, -"nEsTeD".length);
//   //             const childKey = key;
//   //             newGroupNestedDropdownValues[parentKey] = OneItemGroupAttributes[parentKey];
//   //             newGroupNestedDropdownValues[childKey] = OneItemGroupAttributes[childKey];
//   //         }
//   //     });
//   //     setGroupNestedDropdownValues(newGroupNestedDropdownValues);

//   //     const newNestedDropdownValues = {};
//   //     Object.keys(OneItemImagesAttributes).forEach((key) => {
//   //         if (key.endsWith("nEsTeD")) {
//   //             const parentKey = key.slice(0, -"nEsTeD".length);
//   //             newNestedDropdownValues[parentKey] = OneItemImagesAttributes[parentKey] ? OneItemImagesAttributes[parentKey][0] : "";
//   //             newNestedDropdownValues[key] = OneItemImagesAttributes[key] ? OneItemImagesAttributes[key][0] : "";
//   //         }
//   //     });

//   //     setNestedDropdownValues(newNestedDropdownValues);

//   //     setInputValues(OneItemImagesAttributes);
//   //     }
//   //   }
    
//   // },[OneTableItemData, OneTableItemStatus])



//   const validateImageAttribute = (type, value, name) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       // Clear error if the value is empty
//       setImageValidationErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: null,
//       }));
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } 
//     else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     }else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     }else if (type === 'nestedDropdown' ) {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setImageValidationErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));

//     return value;
//   };



//   const [inputValues, setInputValues] = useState({});
//   const [nestedDropdownValues, setNestedDropdownValues] = useState({});
//   const [imageValidationErrors, setImageValidationErrors] = useState({});

//   // console.log(inputValues)
//   // console.log(nestedDropdownValues)
//   // console.log(groupNestedDropdownValues)
//   const handleInputChange = (name, selectedValues, type) => {
//     const attributeName = name;
//     let newValue = selectedValues;

//     if (type) {
//       newValue = validateImageAttribute(type, newValue, attributeName);
//     }

      
//     setInputValues((prevInputValues) => ({
//       ...prevInputValues,
//       [attributeName]: [newValue],
//     }));
    
//   };

//   const saveSetMultiSelectValues = (name, selectedValues) => {
//     setInputValues((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//   };

//   const handleSetNestedDropdownSelection = (imageKey, groupName, i, value) => {
//     const parentAttributeName = `${groupName} ${i}`;
//     const nestedAttributeName = `${parentAttributeName}nEsTeD`;

//     setNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [parentAttributeName]: value, // Store the parent value in an array
//     }));

//     setInputValues((prevData) => ({
//       ...prevData,
//       [parentAttributeName]: [value], // Store the parent value in an array
//       [nestedAttributeName]: [prevData[nestedAttributeName] || ''], // Preserve the nested value in an array
//     }));
    
//     // Trigger validation for parent attribute
//     const parentType = imagesAttributes[imageKey].attgroups[i]?.type;
//     console.log("parentType-------------", parentType);
//     if (parentType) {
//         validateImageAttribute(parentType, value, parentAttributeName);
//       }
//   };


//   // console.log("inputValues", inputValues);
//   // console.log("nestedDropdownValues", nestedDropdownValues);
//   // console.log("imageValidationErrors", imageValidationErrors);



//   //  Group ================================================================================= 
//   const validateGroup = (type, value, name, index) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       setGroupValidationErrors(prevErrors => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[name]) {
//           updatedErrors[name][index] = null;
//         }
//         return updatedErrors;
//       });
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } 
//     else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setGroupValidationErrors(prevErrors => {
//       const updatedErrors = { ...prevErrors };
//       if (!updatedErrors[name]) {
//         updatedErrors[name] = [];
//       }
//       updatedErrors[name][index] = errorMessage;
//       return updatedErrors;
//     });

//     return value;
//   };

//   const changeGroupInputData = (e, i, index) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = groupAttributes[parentName]?.type;
//     const childDataType = groupAttributes[parentName]?.childDataType;

//     if (fieldType && !isChildAttribute) {
//       newValue = validateGroup(fieldType, newValue, name, index);
//     }

//     if (childDataType && isChildAttribute) {
//       newValue = validateGroup(childDataType, newValue, name, index);

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = groupAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateGroup(parentFieldType, groupInputData[parentName][index], parentName, index);
//       }
//     }

//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: newValue ? null : prevErrors[name],
//     }));

//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? newValue : val),
//     }));

//     // For nested child inputs, update the corresponding parent value
//     if (isChildAttribute) {
//       const parentIndex = index;
//       const parentValue = groupInputData[parentName][parentIndex];
//       const nestedAttributeName = `${parentName}nEsTeD`;
//       setGroupInputData((prevData) => ({
//         ...prevData,
//         [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === parentIndex ? newValue : val),
//       }));

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = groupAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateGroup(parentFieldType, parentValue, parentName, parentIndex);
//       }
//     }
//   };

//   const handleAddAnotherGroup = (inputKey) => {
//     const newField = "";
//     const updatedState = (prevState) => ({
//       ...prevState,
//       [inputKey]: [...prevState[inputKey], newField],
//     });

//     setGroupInputData(updatedState);

//     if (groupAttributes[inputKey].type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;

//       // Add a new field for the child attribute
//       setGroupInputData((prevState) => ({
//         ...prevState,
//         [childAttributeName]: [...prevState[childAttributeName], newField],
//       }));

//       setGroupNestedDropdownValues((prevState) => ({
//         ...prevState,
//         [inputKey]: [...prevState[inputKey], newField],
//       }));

//       // Check if there are validation errors for the specific nestedDropdown being added
//       const hasValidationErrors = groupValidationErrors[childAttributeName]?.some((error) => error !== null);

//       // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
//       setGroupValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         [childAttributeName]: hasValidationErrors ? prevErrors[childAttributeName] : null,
//       }));
//     }
//   };

//   const handleRemoveFieldGroup = (inputKey, index) => {
//     setGroupInputData((prevState) => {
//       const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
//       return {
//         ...prevState,
//         [inputKey]: updatedValues,
//       };
//     });

//     // Clear validation error for the removed field
//     setGroupValidationErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[inputKey]) {
//         updatedErrors[inputKey] = updatedErrors[inputKey].filter((_, i) => i !== index);
//       }
//       return updatedErrors;
//     });

//     if (groupAttributes[inputKey]?.type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
//       setGroupInputData((prevState) => {
//         const updatedChildValues = prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
//         return {
//           ...prevState,
//           [childAttributeName]: updatedChildValues,
//         };
//       });

//       // Clear validation error for the removed child field
//       setGroupValidationErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[childAttributeName]) {
//           updatedErrors[childAttributeName] = updatedErrors[childAttributeName].filter((_, i) => i !== index);
//         }
//         return updatedErrors;
//       });

//       setGroupNestedDropdownValues((prevState) => {
//         const updatedValues = { ...prevState };
//         updatedValues[inputKey] = updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
//         return updatedValues;
//       });
//     }
//   };

//   const handleGroupNestedDropdownSelection = (name, value, index) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     let newValue = value;
//     const parentType = groupAttributes[name].type;

//     // console.log("parentType",parentType )
//     setGroupNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: prevValues[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//     }));

//     // Update both parent and child values in the state
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//       [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === index ? "" : val), // Reset child value
//     }));
//     if(parentType){
//       validateGroup(parentType, newValue, name, index);
//     }

//     // Clear any errors related to parent and child
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null, // Clear parent error
//       [nestedAttributeName]: null, // Clear child error
//     }));
//   };

//   const saveGroupMultiSelectValues = (name, selectedValues) => {
//     setGroupInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//     setGroupMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };


    
//   //  Addittional=================================================================================
//   const validateAdditional = (type, value, name, index) => {
//     const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
//     const hasSpecialChars = specialCharsRegex.test(value);
//     const hasAlphabets = /[a-zA-Z]/.test(value);
//     let errorMessage = null;

//     if (value === '') {
//       setAdditionalValidationErrors(prevErrors => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[name]) {
//           updatedErrors[name][index] = null;
//         }
//         return updatedErrors;
//       });
//       return value;
//     }

//     if (type === 'Number') {
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Percent' || type === 'Decimal') {
//       const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
//       const hasSpecialChars = specialCharsRegex.test(value);
//       if (hasSpecialChars || hasAlphabets) {
//         errorMessage = hasSpecialChars ? 'Special characters are not allowed' : 'Alphabets are not allowed';
//       }
//     } else if (type === 'Email') {
//       const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errorMessage = isValidEmail ? null : 'Invalid email format';
//     } else if (type === 'URL') {
//       const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
//       errorMessage = isValidURL ? null : 'Invalid URL format';
//     } else if (type === 'Phone') {
//       const isValidPhone = /^[0-9]{10}$/.test(value);
//       errorMessage = isValidPhone ? null : 'Invalid phone number format';
//     } else if (type === 'DateTime' || type === 'Date') {
//       errorMessage = null;
//     } else {
//       errorMessage = hasSpecialChars ? 'Special characters are not allowed' : null;
//     }

//     setAdditionalValidationErrors(prevErrors => {
//       const updatedErrors = { ...prevErrors };
//       if (!updatedErrors[name]) {
//         updatedErrors[name] = [];
//       }
//       updatedErrors[name][index] = errorMessage;
//       return updatedErrors;
//     });

//     return value;
//   };

//   const changeAdditionalInputData = (e, i, index) => {
//     const { name, value } = e.target;
//     let newValue = value;

//     const isChildAttribute = name.endsWith("nEsTeD");
//     const parentName = isChildAttribute ? name.slice(0, -"nEsTeD".length) : name;

//     const fieldType = additionalAttributes[parentName]?.type;
//     const childDataType = additionalAttributes[parentName]?.childDataType;

//     if (fieldType && !isChildAttribute) {
//       newValue = validateAdditional(fieldType, newValue, name, index);
//     }

//     if (childDataType && isChildAttribute) {
//       newValue = validateAdditional(childDataType, newValue, name, index);

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, additionalInputData[parentName][index], parentName, index);
//       }
//     }

//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: newValue ? null : prevErrors[name],
//     }));

//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? newValue : val),
//     }));

//     // For nested child inputs, update the corresponding parent value
//     if (isChildAttribute) {
//       const parentIndex = index;
//       const parentValue = additionalInputData[parentName][parentIndex];
//       const nestedAttributeName = `${parentName}nEsTeD`;
//       setAdditionalInputData((prevData) => ({
//         ...prevData,
//         [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === parentIndex ? newValue : val),
//       }));

//       // Also validate the parent attribute to update its mandatory error state
//       const parentFieldType = additionalAttributes[parentName]?.type;
//       if (parentFieldType) {
//         validateAdditional(parentFieldType, parentValue, parentName, parentIndex);
//       }
//     }
//   };

//   const handleAddAnotherAdditional = (inputKey) => {
//     const newField = "";
//     const updatedState = (prevState) => ({
//       ...prevState,
//       [inputKey]: [...prevState[inputKey], newField],
//     });

//     setAdditionalInputData(updatedState);

//     if (additionalAttributes[inputKey].type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;

//       // Add a new field for the child attribute
//       setAdditionalInputData((prevState) => ({
//         ...prevState,
//         [childAttributeName]: [...prevState[childAttributeName], newField],
//       }));

//       setAddNestedDropdownValues((prevState) => ({
//         ...prevState,
//         [inputKey]: [...prevState[inputKey], newField],
//       }));

//       // Check if there are validation errors for the specific nestedDropdown being added
//       const hasValidationErrors = additionalValidationErrors[childAttributeName]?.some((error) => error !== null);

//       // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
//       setAdditionalValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         [childAttributeName]: hasValidationErrors ? prevErrors[childAttributeName] : null,
//       }));
//     }
//   };

//   const handleRemoveFieldAdditional = (inputKey, index) => {
//     setAdditionalInputData((prevState) => {
//       const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
//       return {
//         ...prevState,
//         [inputKey]: updatedValues,
//       };
//     });

//     // Clear validation error for the removed field
//     setAdditionalValidationErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[inputKey]) {
//         updatedErrors[inputKey] = updatedErrors[inputKey].filter((_, i) => i !== index);
//       }
//       return updatedErrors;
//     });

//     if (additionalAttributes[inputKey]?.type === "NestedDropdown") {
//       const childAttributeName = `${inputKey}nEsTeD`;
//       setAdditionalInputData((prevState) => {
//         const updatedChildValues = prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
//         return {
//           ...prevState,
//           [childAttributeName]: updatedChildValues,
//         };
//       });

//       // Clear validation error for the removed child field
//       setAdditionalValidationErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         if (updatedErrors[childAttributeName]) {
//           updatedErrors[childAttributeName] = updatedErrors[childAttributeName].filter((_, i) => i !== index);
//         }
//         return updatedErrors;
//       });

//       setAddNestedDropdownValues((prevState) => {
//         const updatedValues = { ...prevState };
//         updatedValues[inputKey] = updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
//         return updatedValues;
//       });
//     }
//   };
    
//   const handleAdditionalNestedDropdownSelection = (name, value, index) => {
//     const nestedAttributeName = `${name}nEsTeD`;
//     let newValue = value;
//     const parentType = additionalAttributes[name].type;
  
//     // console.log("parentType",parentType )
//     setAddNestedDropdownValues((prevValues) => ({
//       ...prevValues,
//       [name]: prevValues[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//     }));

//     // Update both parent and child values in the state
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].map((val, idx) => idx === index ? value : val), // Update the parent value
//       [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) => idx === index ? "" : val), // Reset child value
//     }));
//     if(parentType){
//       validateAdditional(parentType, newValue, name, index);
//     }

//     // Clear any errors related to parent and child
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: null, // Clear parent error
//       [nestedAttributeName]: null, // Clear child error
//     }));
//   };

//   const saveAdditionlMultiSelectValues = (name, selectedValues) => {
//     setAdditionalInputData((prevData) => ({
//       ...prevData,
//       [name]: selectedValues,
//     }));
//     setAdditionalMandatoryErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: selectedValues.length ? null : prevErrors[name],
//     }));
//   };


//   // =============================== images =========================
//   const [imageFiles, setImageFiles] = useState({});
//   const [imageMandatoryErrors, setImageMandatoryErrors] = useState({});
//   // console.log("imageFiles -----" , imageFiles)
//   // console.log("imageMandatoryErrors -----" , imageMandatoryErrors)

//   const handleImageChange = (event, attributeName) => {
//     const file = event.target.files[0];

//     if (file) {
//         setImageFiles((prevFiles) => ({
//             ...prevFiles,
//             [attributeName]: file,
//         }));

//         // Clear mandatory error for the image attribute
//         setImageMandatoryErrors((prevErrors) => {
//             const updatedErrors = { ...prevErrors };
//             if (updatedErrors[attributeName]) {
//                 updatedErrors[attributeName] = null;
//             }
//             return updatedErrors;
//         });
//     }
//   };


//   const handleSubmitData = (e) => {
//     e.preventDefault();

//     // Check if there are any validation errors
//     if (Object.values(additionalValidationErrors).flat().some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }
//     if (Object.values(groupValidationErrors).flat().some(error => error !== null)) {
//         toast.warning('Please fix validation errors before saving.');
//         return;
//     }

//     // Trim values of all arrays in additionalInputData
//     const trimmedAdditionalInputData = Object.fromEntries(
//         Object.entries(additionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : value])
//     );
//     // Trim values of all arrays in groupInputData
//     const trimmedGroupInputData = Object.fromEntries(
//         Object.entries(groupInputData).map(([key, value]) => [key, Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : value])
//     );


//     // Remove empty strings from arrays
//     const filteredAdditionalInputData = Object.fromEntries(
//         Object.entries(trimmedAdditionalInputData).map(([key, value]) => [key, Array.isArray(value) ? value.filter(val => val !== '') : value])
//     );
//     const filteredGroupInputData = Object.fromEntries(
//         Object.entries(trimmedGroupInputData).map(([key, value]) => [key, Array.isArray(value) ? value.filter(val => val !== '') : value])
//     );

    

//     // Check mandatory fields
//     const newAdditionalMandatoryErrors = {};
//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//         return;
//       }
//         if (value.mandatory && (!filteredAdditionalInputData[key] || filteredAdditionalInputData[key].length === 0)) {
//             newAdditionalMandatoryErrors[key] = 'This field is mandatory';
//         }
//         // Check mandatory fields for nested dropdowns
//       if (value.type === "NestedDropdown") {
//         const childAttributeName = `${key}nEsTeD`;

//         (filteredAdditionalInputData[key] || []).forEach((parentValue, index) => {
//             const childValue = filteredAdditionalInputData[childAttributeName] ? filteredAdditionalInputData[childAttributeName][index] : "";

//             if (value.mandatory && (!parentValue || parentValue.trim() === "" || !childValue || childValue.trim() === "")) {
//                 newAdditionalMandatoryErrors[childAttributeName] = newAdditionalMandatoryErrors[childAttributeName] || [];
//                 newAdditionalMandatoryErrors[childAttributeName][index] = 'Both fields are mandatory';
//             }
//         });
//       }
//     });
//     setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);


//     const newGroupMandatoryErrors = {};
//     Object.entries(groupAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//         return;
//       }
//         if (value.mandatory && (!filteredGroupInputData[key] || filteredGroupInputData[key].length === 0)) {
//           newGroupMandatoryErrors[key] = 'This field is mandatory';
//         }
//           // Check mandatory fields for nested dropdowns
//       if (value.type === "NestedDropdown") {
//         const childAttributeName = `${key}nEsTeD`;

//         (filteredGroupInputData[key] || []).forEach((parentValue, index) => {
//             const childValue = filteredGroupInputData[childAttributeName] ? filteredGroupInputData[childAttributeName][index] : "";

//             if (value.mandatory && (!parentValue || parentValue.trim() === "" || !childValue || childValue.trim() === "")) {
//               newGroupMandatoryErrors[childAttributeName] = newGroupMandatoryErrors[childAttributeName] || [];
//                 newGroupMandatoryErrors[childAttributeName][index] = 'Both fields are mandatory';
//             }
//         });
//       }
//     });
//     setGroupMandatoryErrors(newGroupMandatoryErrors);

    
//     const newImageMandatoryErrors = {};
//     Object.entries(groupAttributes).forEach(([key, value]) => {
//         if (value.type === "Image") {
//             if (value.mandatory && ( !imageFiles[key] ) ){
//                 newImageMandatoryErrors[key] = 'This field is mandatory';
//             }
//         }
//     });
//     Object.entries(additionalAttributes).forEach(([key, value]) => {
//       if (value.type === "Image") {
//           if (value.mandatory && ( !imageFiles[key] ) ){
//               newImageMandatoryErrors[key] = 'This field is mandatory';
//           }
//       }
//     });
//     setImageMandatoryErrors(newImageMandatoryErrors);


//     // Show mandatory errors

//     if (Object.values(newAdditionalMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }
//     if (Object.values(newGroupMandatoryErrors).some(error => error !== null)) {
//         toast.warning('Please fill in all mandatory fields before saving.');
//         return;
//     }
//     if (Object.values(newImageMandatoryErrors).some(error => error !== null)) {
//       toast.warning('Please fill in all mandatory fields before saving.');
//       return;
//     }


//     const data = {
//         groupAttributes: filteredGroupInputData,
//         [additionalAttributesName]: filteredAdditionalInputData ,
//         imagesAttributes : inputValues,
//         groupName: groupNameToShowAttributes,
//         className: classNameToSave,
//         [getID] : sendID
//     };

//     dispatch(updateTableItemLink(data));
//     console.log("data===", data);

//     // toast.success('Data saved successfully.');
//   };

//   const {  data, status , error } = useSelector(updateTableItemSelector);



//   useEffect(() => {
//     if (data && status === STATUSES.SUCCESS) {
        
//         const createdID = data; // Assuming the ID is in `data.id`
        
//         if (createdID && imageFiles) {
//             Object.entries(imageFiles).forEach(async ([key, file]) => {
//                 const imageFormData = new FormData();
//                 imageFormData.append("images", file);
//                 const paramData = { [getID]: data, imageName: key };
//                 await dispatch(ImageUploadLink({formData : imageFormData , ...paramData}));
//             });
//         }
        
//         toast.success("Updated Successfully");
//         dispatch(resetUpdateItemState({}));
//     }
//     if (error) {
//         toast.error(error);
//         dispatch(resetUpdateItemState({}));
//     }
//   }, [data, status, error]);


//   const HoverTitle = (type, varDescription, mandatory) => {
//     const displayType = type === 'String' ? 'Text - Single line' : type;
//     return `Data type - ${displayType}\nDescription - ${varDescription}\nMandatory - ${mandatory ? 'Yes' : 'No'}`;
//   };

//   const renderStar = (mandatory) => {
//     return mandatory ? <i className='mandatory_star'>*</i> : null;
//   };


//   return (
//     <div className='common_update_table'>
//       <div className={updateTableToggle ? 'common_update_table_container' : 'common_update_table_container close'}>
//       <i className='close'><FontAwesomeIcon icon={faClose} onClick={handelClose} /></i>

//       <div className='add_table_page'> 
//       <div className="add_table_data_container">
//         {
//           AttributeGroupStatus === STATUSES.LOADING ? (
//             <Loader/>
//           ) : AttributeGroupStatus === STATUSES.SUCCESS ?(
//             (
//               Object.keys(AttributesGroupsData).length===0 ?(
//                 <RenderWarning message={`Please create a group of attributes to add ${title}`} showLink={linkForRedirectSetting} />
//               ):(              
//                   groupNameToShowAttributes.length===0?(
//                     <RenderWarning message={`Please select the group of attributes to add ${title}`} />
//                   ):(
//                     <>
//                   {
      
//                   singleAttributeGroupStatus === STATUSES.LOADING?(
//                     <Loader/>
//                   ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                     <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//                   ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                       (Object.keys(groupAttributes).length===0 && Object.keys(additionalAttributes).length === 0 && Object.keys(imagesAttributes).length === 0)?(
//                       <>
//                         <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                         <RenderWarning message={`No attributes found to add ${groupNameToShowAttributes}`} />
//                       </>
//                       ):(
                        
//                       <>
//                         <div className='att_header'><span>Attributes Group - <strong>{singleAttributeGroupData?.groupName}</strong></span>
//                         {/* <select className='select_step_data choosse_reference' type="text" required onChange={handleReferenceSelection}>
//                           <option value="" className='select_step_value'>Choose reference</option>
//                           {
//                             statusForReference === STATUSES.LOADING ? (
//                               <option className='error_option'>Loading...</option>
//                             ) :
//                             statusForReference === STATUSES.ERROR ? (
//                               <option className='error_option'>Something went wrong {errorForReference}</option>
//                             ) :
//                             statusForReference === STATUSES.SUCCESS ? (
//                               AllDataForReference && typeof AllDataForReference === "string" ? (
//                                 <option className='error_option'>{`No ${title} found to choose reference`}</option>
//                               ):(
//                                 AllDataForReference?.map((item, index) => {
//                                   const firstKey = Object.keys(groupAttributes)[0]; // Get the first key dynamically
//                                   return (
//                                     <option value={item[getID]} className='select_step_value' key={index}>
//                                       {item.groupAttributes[firstKey]}
//                                     </option>
//                                   );
//                                 })
//                               ) 
//                             ) : null
//                           }
//                         </select> */}
                        
//                         </div>
      
      
//                         <div className="all_attributes_to_add_data">

                          
//                         {
//                           <>
//                           {Object.keys(groupAttributes).map((i, index) => {
//                             if (groupAttributes[i].type === "Date") {
//                               return (
//                                 <div key={index} className='inp_cont' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                   {(groupInputData[i] || []).map((value, valueIndex) => (
//                                     <div className='_input_box' key={valueIndex}>
//                                                                         {renderStar(groupAttributes[i]?.mandatory)}

//                                       <input className='table_input' type="date" value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required />
//                                       <span>{groupAttributes[i].nametoDisplay}</span>
//                                       {valueIndex !== 0 && (
//                                         <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                       )}
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                       {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                     </div>
//                                   ))}
//                                   {groupAttributes[i].repeat && (
//                                     <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                   )}
//                                 </div>
//                               );
//                             }
//                             else if (groupAttributes[i].type === "Image"){
//                               return(
//                                 <div key={index} className='_input_box' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)} >
//                                                                     {renderStar(groupAttributes[i]?.mandatory)}

//                                   <input className='table_input image_input' type="file" name={i} onChange={(e) => handleImageChange(e, i)} accept="image/*" required/>
//                                   <span>{groupAttributes[i].nametoDisplay}</span>
              
//                                   {imageMandatoryErrors[i] && <div className="input_error">{imageMandatoryErrors[i]}</div>}
//                                 </div>
//                               )
//                             }
                            
//                             else if (groupAttributes[i].type === "DateTime") {
//                               return (
//                                 <div className='inp_cont' key={index} title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                   {(groupInputData[i] || []).map((value, valueIndex) => (
//                                     <div className='_input_box' key={valueIndex}>
//                                                                         {renderStar(groupAttributes[i]?.mandatory)}

//                                       <input className='table_input' type="datetime-local" value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required placeholder='' />
//                                       <span>{groupAttributes[i].nametoDisplay}</span>
//                                       {valueIndex !== 0 && (
//                                         <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                       )}
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                       {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                     </div>
//                                   ))}
//                                   {groupAttributes[i].repeat && (
//                                     <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                   )}
//                                 </div>
//                               );
//                             } 
//                             else if(groupAttributes[i].type==="MultiSelect") {
//                               return(
//                                 <div style={{position:"relative"}} key={index} title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                                                     {renderStar(groupAttributes[i]?.mandatory)}

//                                   <Multiselect  isObject={false} options={groupAttributes[i].options} placeholder={groupAttributes[i].nametoDisplay} title={`Data type- ${groupAttributes[i].type}\nDescription- ${groupAttributes[i].varDescription}`} onSelect={(selectedList) => saveGroupMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveGroupMultiSelectValues(i, selectedList)} selectedValues={groupInputData[i] || []} required/>
//                                   {groupMandatoryErrors[i] && <div className="input_error multiSelectError">{groupMandatoryErrors[i]}</div>}
//                                 </div>
//                               )
//                             } 

//                             else if (groupAttributes[i].type === "Dropdown") {
//                               return (
//                                 <div key={index} className='inp_cont' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                   {(groupInputData[i] || []).map((value, valueIndex) => (
//                                     <div className='_input_box' key={valueIndex}>
//                                                                         {renderStar(groupAttributes[i]?.mandatory)}

//                                       <select className='table_input' value={value} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required >
//                                         <option value="">{}</option>
//                                         {groupAttributes[i].options.map((option, optionIndex) => (
//                                           <option key={optionIndex} value={option}>{option}</option>
//                                         ))}
//                                       </select>
//                                       <span>{groupAttributes[i].nametoDisplay}</span>
//                                       {valueIndex !== 0 && (
//                                         <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                       )}
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                       {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                     </div>
//                                   ))}
//                                   {groupAttributes[i].repeat && (
//                                     <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                   )}
//                                 </div>
//                               )
//                             } else if (groupAttributes[i].type === "NestedDropdown") {
//                               const parentAttributeName = i;
//                               const childAttributeName = `${i}nEsTeD`;

//                               // Check if any parent values are present
//                               const isParentValueAdded = groupInputData[parentAttributeName] && groupInputData[parentAttributeName].some(value => value.trim() !== "");

//                               // Check if any child values are present
//                               const isChildValueAdded = groupInputData[childAttributeName] && groupInputData[childAttributeName].some(value => value.trim() !== "");

//                               // Check if all sets have both parent and child values
//                               const allSetsHaveValues = (groupInputData[parentAttributeName] || []).every((_, valueIndex) => {
//                                 const isParentValuePresent = groupInputData[parentAttributeName][valueIndex] && groupInputData[parentAttributeName][valueIndex].trim() !== "";
//                                 const isChildValuePresent = groupInputData[childAttributeName] && groupInputData[childAttributeName][valueIndex] && groupInputData[childAttributeName][valueIndex].trim() !== "";
//                                 return isParentValuePresent && isChildValuePresent;
//                               });

//                               return (
//                                 <div className='inp_cont' key={index} >
//                                   <>
//                                   {(groupInputData[i] || []).map((value, valueIndex) => (
//                                     <div className='nested_input' key={valueIndex}>
//                                       {renderStar(groupAttributes[i]?.mandatory)}

//                                       <div className='_input_box' title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                       <select className='table_input' value={value} onChange={(e) => handleGroupNestedDropdownSelection(i, e.target.value, valueIndex)} name={i} required>
//                                         <option value=""></option>
//                                         {groupAttributes[i].options.map((option, optionIndex) => (
//                                           <option key={optionIndex} value={option}>{option}</option>
//                                         ))}
//                                       </select>
//                                       <span>{groupAttributes[i].nametoDisplay}</span>
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                       {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                       </div>
//                                       {/* Render child inputs based on the index of groupNestedDropdownValues */}
//                                       {groupNestedDropdownValues[i] && groupNestedDropdownValues[i][valueIndex] && (
//                                         <>
//                                           {/* Render child inputs based on childDataType */}
//                                           {groupAttributes[i].childDataType === "Dropdown" && (
//                                             <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                               <select className='table_input' value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} name={childAttributeName} required>
//                                                 <option value=""></option>
//                                                 {groupAttributes[i].nestedOptions[groupAttributes[i].options.indexOf(value)].map((nestedOption, nestedIndex) => (
//                                                   <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                 ))}
//                                               </select>
//                                               <span>{groupAttributes[i].nametoDisplay2}</span>
//                                             </div>
//                                           )}
//                                           {groupAttributes[i].childDataType === "Date" && (
//                                           <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                             <input className='table_input' type="date" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                             <span>{groupAttributes[i].nametoDisplay2}</span>
//                                           </div>
//                                           )}
//                                           {groupAttributes[i].childDataType === "DateTime" && (
//                                           <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                             <input className='table_input' type="datetime-local" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                             <span>{groupAttributes[i].nametoDisplay2}</span>
//                                           </div>
//                                           )}
//                                           {(groupAttributes[i].childDataType === "String" || groupAttributes[i].childDataType === "Number" || groupAttributes[i].childDataType === "Decimal" || groupAttributes[i].childDataType === "Percent" || groupAttributes[i].childDataType === "Email" || groupAttributes[i].childDataType === "URL" || groupAttributes[i].childDataType === "Phone") && (
//                                           <div className='_input_box' title={HoverTitle(groupAttributes[i]?.childDataType, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                             <input className='table_input' type="text" required name={childAttributeName} value={groupInputData[childAttributeName][valueIndex]} onChange={(e) => changeGroupInputData(e, childAttributeName, valueIndex)} />
//                                             <span>{groupAttributes[i].nametoDisplay2}</span>
//                                           </div>

//                                           )}
//                                           {/* Display mandatory error if present */}
//                                           {groupMandatoryErrors[childAttributeName] && <div className="input_error">{groupMandatoryErrors[childAttributeName]}</div>}
//                                           {groupValidationErrors[childAttributeName]?.[valueIndex] && <div className="input_error">{groupValidationErrors[childAttributeName][valueIndex]}</div>}
//                                         </>
//                                       )}

//                                       {valueIndex !== 0 && (
//                                         <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                       )}
//                                     </div>
//                                   ))}

//                                   {/* Conditionally render the "Add" button only once */}
//                                   {groupAttributes[i].repeat && (
//                                     <button onClick={() => handleAddAnotherGroup(i)} disabled={!isParentValueAdded || !isChildValueAdded || !allSetsHaveValues ||  Object.values(groupValidationErrors[i] || {}).some(error => error !== null) ||  Object.values(groupValidationErrors[childAttributeName] || {}).some(error => error !== null)} >+</button>
//                                   )}
//                                   </>
//                                 </div>
//                               );
//                             } 
//                             else {
//                               return (
//                                 <div key={index} className='inp_cont'>
//                                   {(groupInputData[i] || []).map((value, valueIndex) => (
//                                     <div className='_input_box' key={valueIndex} title={HoverTitle(groupAttributes[i]?.type, groupAttributes[i]?.varDescription, groupAttributes[i]?.mandatory)}>
//                                       {renderStar(groupAttributes[i]?.mandatory)}

//                                       <input className='table_input' type="text" value={groupInputData[i][valueIndex]} onChange={(e) => changeGroupInputData(e, i, valueIndex)} name={i} required/>
//                                       <span>{groupAttributes[i].nametoDisplay}</span>
//                                       {valueIndex !== 0 && (
//                                         <em onClick={() => handleRemoveFieldGroup(i, valueIndex)}>&times;</em>
//                                       )}
//                                       {/* Display mandatory error if present */}
//                                       {groupMandatoryErrors[i] && <div className="input_error">{groupMandatoryErrors[i]}</div>}
//                                       {groupValidationErrors[i]?.[valueIndex] && <div className="input_error">{groupValidationErrors[i][valueIndex]}</div>}
//                                     </div>
//                                   ))}
//                                   {groupAttributes[i].repeat && (
//                                     <button onClick={() => handleAddAnotherGroup(i)} disabled={groupInputData[i]?.some(value => value.trim() === "") || Object.values(groupValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                   )}
//                                 </div>
//                               );
//                             }
//                           })}
//                           </>
//                         }
                      
      
//                         {
//                           <>
//                             {Object.keys(additionalAttributes).map((i, index) => {
//                               if (additionalAttributes[i].type === "Date") {
//                                 return (
//                                   <div key={index} className='inp_cont' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                     {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                       <div className='_input_box' key={valueIndex}>
//                                                                                                             {renderStar(additionalAttributes[i]?.mandatory)}

//                                         <input className='table_input' type="date" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} placeholder='' required/>
//                                         <span>{additionalAttributes[i].nametoDisplay}</span>
//                                         {valueIndex !== 0 && (
//                                           <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                         )}
//                                         {/* Display mandatory error if present */}
//                                         {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                         {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                       </div>
//                                     ))}
//                                     {additionalAttributes[i].repeat && (
//                                       <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                     )}
//                                   </div>
//                                 );
//                               } else if (additionalAttributes[i].type === "Image"){
//                                 return(
//                                   <div key={index} className='_input_box' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)} >
//                                       {renderStar(additionalAttributes[i]?.mandatory)}
//                                     <input className='table_input image_input' type="file" name={i} onChange={(e) => handleImageChange(e, i)} accept="image/*" required />
//                                     <span>{additionalAttributes[i].nametoDisplay}</span>
                
//                                     {imageMandatoryErrors[i] && <div className="input_error">{imageMandatoryErrors[i]}</div>}
//                                   </div>
//                                 )
//                               }                         
//                               else if (additionalAttributes[i].type === "DateTime") {
//                                 return (
//                                   <div className='inp_cont' key={index} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                     {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                       <div className='_input_box' key={valueIndex}>
//                                                                           {renderStar(additionalAttributes[i]?.mandatory)}

//                                         <input className='table_input' type="datetime-local" value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} placeholder='' required/>
//                                         <span>{additionalAttributes[i].nametoDisplay}</span>
//                                         {valueIndex !== 0 && (
//                                           <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                         )}
//                                         {/* Display mandatory error if present */}
//                                         {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                         {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                       </div>
//                                     ))}
//                                     {additionalAttributes[i].repeat && (
//                                       <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                     )}
//                                   </div>
//                                 );
//                               } 
//                               else if(additionalAttributes[i].type==="MultiSelect") {
//                                 return(
//                                   <div style={{position:"relative"}} key={index} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                                       {renderStar(additionalAttributes[i]?.mandatory)}

//                                     <Multiselect isObject={false} options={additionalAttributes[i].options} placeholder={additionalAttributes[i].nametoDisplay} title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`} onSelect={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)}  onRemove={(selectedList) => saveAdditionlMultiSelectValues(i, selectedList)} selectedValues={additionalInputData[i] || []} required/>
//                                     {additionalMandatoryErrors[i] && <div className="input_error multiSelectError">{additionalMandatoryErrors[i]}</div>}
//                                   </div>
                                
//                                 )
//                               } 
                              
//                               else if (additionalAttributes[i].type === "Dropdown") {
//                                 return (
//                                   <div key={index} className='inp_cont' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                     {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                       <div className='_input_box' key={valueIndex}>
//                                                                           {renderStar(additionalAttributes[i]?.mandatory)}

//                                         <select className='table_input' value={value} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} required>
//                                           <option value="">{}</option>
//                                           {additionalAttributes[i].options.map((option, optionIndex) => (
//                                             <option key={optionIndex} value={option}>{option}</option>
//                                           ))}
//                                         </select>
//                                         <span>{additionalAttributes[i].nametoDisplay}</span>
//                                         {valueIndex !== 0 && (
//                                           <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                         )}
//                                         {/* Display mandatory error if present */}
//                                         {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                         {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                       </div>
//                                     ))}
//                                     {additionalAttributes[i].repeat && (
//                                       <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                     )}
//                                   </div>
//                                 )
//                               } else if (additionalAttributes[i].type === "NestedDropdown") {
//                                 const parentAttributeName = i;
//                                 const childAttributeName = `${i}nEsTeD`;
                          
//                                 // Check if any parent values are present
//                                 const isParentValueAdded = additionalInputData[parentAttributeName] && additionalInputData[parentAttributeName].some(value => value.trim() !== "");
                          
//                                 // Check if any child values are present
//                                 const isChildValueAdded = additionalInputData[childAttributeName] && additionalInputData[childAttributeName].some(value => value.trim() !== "");
                          
//                                 // Check if all sets have both parent and child values
//                                 const allSetsHaveValues = (additionalInputData[parentAttributeName] || []).every((_, valueIndex) => {
//                                   const isParentValuePresent = additionalInputData[parentAttributeName][valueIndex] && additionalInputData[parentAttributeName][valueIndex].trim() !== "";
//                                   const isChildValuePresent = additionalInputData[childAttributeName] && additionalInputData[childAttributeName][valueIndex] && additionalInputData[childAttributeName][valueIndex].trim() !== "";
//                                   return isParentValuePresent && isChildValuePresent;
//                                 });
                          
//                                 return (
//                                   <div className='inp_cont' key={index}>
//                                   <>
//                                     {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                       <div  className='nested_input' key={valueIndex}>
//                                         <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                         {renderStar(additionalAttributes[i]?.mandatory)}

//                                         <select className='table_input' value={value} onChange={(e) => handleAdditionalNestedDropdownSelection(i, e.target.value, valueIndex)} name={i} required>
//                                           <option value=""></option>
//                                           {additionalAttributes[i].options.map((option, optionIndex) => (
//                                             <option key={optionIndex} value={option}>{option}</option>
//                                           ))}
//                                         </select>
//                                         <span>{additionalAttributes[i].nametoDisplay}</span>
//                                         {/* Display mandatory error if present */}
//                                         {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                         {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                         </div>
                          
//                                         {/* Render child inputs based on the index of addNestedDropdownValues */}
//                                         {addNestedDropdownValues[i] && addNestedDropdownValues[i][valueIndex] && (
//                                           <>
//                                             {/* Render child inputs based on childDataType */}
//                                             {additionalAttributes[i].childDataType === "Dropdown" && (
//                                               <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                 <select className='table_input' value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} name={childAttributeName} required>
//                                                   <option value=""></option>
//                                                   {additionalAttributes[i].nestedOptions[additionalAttributes[i].options.indexOf(value)].map((nestedOption, nestedIndex) => (
//                                                     <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                   ))}
//                                                 </select>
//                                                 <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                               </div>
//                                             )}
//                                             {additionalAttributes[i].childDataType === "Date" && (
//                                               <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                               <input className='table_input' type="date" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                                 <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                               </div>
//                                             )}
//                                             {additionalAttributes[i].childDataType === "DateTime" && (
//                                               <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                               <input className='table_input' type="datetime-local" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} placeholder="" />
//                                                 <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                               </div>
//                                             )}
//                                             {(additionalAttributes[i].childDataType === "String" || additionalAttributes[i].childDataType === "Number" || additionalAttributes[i].childDataType === "Decimal" || additionalAttributes[i].childDataType === "Percent" || additionalAttributes[i].childDataType === "Email" || additionalAttributes[i].childDataType === "URL" || additionalAttributes[i].childDataType === "Phone") && (
//                                               <div className='_input_box' title={HoverTitle(additionalAttributes[i]?.childDataType, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                 <input className='table_input' type="text" required name={childAttributeName} value={additionalInputData[childAttributeName][valueIndex]} onChange={(e) => changeAdditionalInputData(e, childAttributeName, valueIndex)} />
//                                                   <span>{additionalAttributes[i].nametoDisplay2}</span>
//                                               </div>

//                                             )}
//                                             {/* Display mandatory error if present */}
//                                             {additionalMandatoryErrors[childAttributeName] && <div className="input_error">{additionalMandatoryErrors[childAttributeName]}</div>}
//                                             {additionalValidationErrors[childAttributeName]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[childAttributeName][valueIndex]}</div>}
//                                           </>
//                                         )}
                          
//                                         {valueIndex !== 0 && (
//                                           <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                         )}
//                                       </div>
//                                     ))}
                          
//                                     {/* Conditionally render the "Add" button only once */}
//                                     {additionalAttributes[i].repeat && (
//                                       <button onClick={() => handleAddAnotherAdditional(i)} disabled={!isParentValueAdded || !isChildValueAdded || !allSetsHaveValues ||  Object.values(additionalValidationErrors[i] || {}).some(error => error !== null) ||  Object.values(additionalValidationErrors[childAttributeName] || {}).some(error => error !== null)} >+</button>
//                                     )}
//                                   </>
//                                   </div>
//                                 );
//                               } 
//                               else {
//                                 return (
//                                   <div key={index} className='inp_cont'>
//                                     {(additionalInputData[i] || []).map((value, valueIndex) => (
//                                       <div className='_input_box' key={valueIndex} title={HoverTitle(additionalAttributes[i]?.type, additionalAttributes[i]?.varDescription, additionalAttributes[i]?.mandatory)}>
//                                                                           {renderStar(additionalAttributes[i]?.mandatory)}

//                                         <input className='table_input' type="text" value={additionalInputData[i][valueIndex]} onChange={(e) => changeAdditionalInputData(e, i, valueIndex)} name={i} required/>
//                                         <span>{additionalAttributes[i].nametoDisplay}</span>
//                                         {valueIndex !== 0 && (
//                                           <em onClick={() => handleRemoveFieldAdditional(i, valueIndex)}>&times;</em>
//                                         )}
//                                         {/* Display mandatory error if present */}
//                                         {additionalMandatoryErrors[i] && <div className="input_error">{additionalMandatoryErrors[i]}</div>}
//                                         {additionalValidationErrors[i]?.[valueIndex] && <div className="input_error">{additionalValidationErrors[i][valueIndex]}</div>}
//                                       </div>
//                                     ))}
//                                     {additionalAttributes[i].repeat && (
//                                       <button onClick={() => handleAddAnotherAdditional(i)} disabled={additionalInputData[i]?.some(value => value.trim() === "") || Object.values(additionalValidationErrors[i] || {}).some(error => error !== null)}>+</button>
//                                     )}
//                                   </div>
//                                 );
//                               }
//                             })}
//                           </>
//                         }

//                         <div className="all_set_table_">
//                               {Object.keys(imagesAttributes).length !== 0 && (
//                                 <div className="image_attributes_table_container">
//                                   <>
//                                     {Object.keys(imagesAttributes).map((imageKey) => {
//                                       const { groupsName, attgroups } = imagesAttributes[imageKey];
//                                       return (
//                                         <div key={imageKey} className='one_set'>
//                                           <table>
//                                             <thead>
//                                               <tr>
//                                                 <th></th>
//                                                 {Object.keys(groupsName).map((groupName) => (
//                                                   <th key={`${imageKey}_${groupName}`}>{groupsName[groupName].nametoDisplay}</th>
//                                                 ))}
//                                               </tr>
//                                             </thead>
//                                             <tbody>
//                                               {Object.keys(attgroups).map((i) => (
//                                                 <tr key={`${imageKey}_${i}`}>
//                                                   <td>{attgroups[i].nametoDisplay}</td>
//                                                   {Object.keys(groupsName).map((groupName) => (
//                                                     <td key={`${imageKey}_${groupName}_${i}`}>
//                                                       {attgroups[i].type === "Dropdown" ? (
//                                                         <>
//                                                           <select
//                                                             className='table_input'
//                                                             name={`${groupName} ${i}`}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                             required
//                                                             placeholder=''
//                                                             value={inputValues[`${groupName} ${i}`] || ''}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           >
//                                                             <option value="">{}</option>
//                                                             {attgroups[i].options.map((option, optionIndex) => (
//                                                               <option key={optionIndex} value={option}>{option}</option>
//                                                             ))}
//                                                           </select>
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                         </>
//                                                       ) : attgroups[i].type === "MultiSelect" ? (
//                                                         <>
//                                                           <Multiselect
//                                                             isObject={false}
//                                                             options={attgroups[i].options}
//                                                             placeholder={attgroups[i].nametoDisplay}
//                                                             onSelect={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                             onRemove={(selectedList) => saveSetMultiSelectValues(`${groupName} ${i}`, selectedList)}
//                                                             selectedValues={inputValues[`${groupName} ${i}`] || []}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                         </>
//                                                       ) : attgroups[i].type === "Date" ? (
//                                                         <>
//                                                           <input
//                                                             type="date"
//                                                             name={`${groupName} ${i}`}
//                                                             value={inputValues[`${groupName} ${i}`] || ''}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                         </>
//                                                       ) : attgroups[i].type === "DateTime" ? (
//                                                         <>
//                                                           <input
//                                                             type="datetime-local"
//                                                             name={`${groupName} ${i}`}
//                                                             value={inputValues[`${groupName} ${i}`] || ''}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                         </>
//                                                       ) : attgroups[i].type === "NestedDropdown" ? (
//                                                         <>
//                                                           <select
//                                                             name={`${groupName} ${i}`}
//                                                             onChange={(e) => handleSetNestedDropdownSelection(imageKey, groupName, i, e.target.value)}
//                                                             required
//                                                             value={inputValues[`${groupName} ${i}`] || ''}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           >
//                                                             <option value="">{""}</option>
//                                                             {attgroups[i].options.map((option, optionIndex) => (
//                                                               <option key={optionIndex} value={option}>{option}</option>
//                                                             ))}
//                                                           </select>
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                           {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Dropdown" && (
//                                                             <>
//                                                               <select
//                                                                 name={`${groupName} ${i}nEsTeD`}
//                                                                 onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                                 required
//                                                                 value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                                 title={HoverTitle(attgroups[i]?.childDataType, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                               >
//                                                                 <option value="">{""}</option>
//                                                                 {attgroups[i].nestedOptions[attgroups[i].options.indexOf(nestedDropdownValues[`${groupName} ${i}`][0])].map((nestedOption, nestedIndex) => (
//                                                                   <option key={nestedIndex} value={nestedOption}>{nestedOption}</option>
//                                                                 ))}
//                                                               </select>
//                                                               <span>{attgroups[i].nametoDisplay2}</span>
//                                                               {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                                 <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                               )}
//                                                             </>
//                                                           )}
//                                                           {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "Date" && (
//                                                             <>
//                                                               <input
//                                                                 type="date"
//                                                                 required
//                                                                 name={`${groupName} ${i}nEsTeD`}
//                                                                 onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                                 placeholder=""
//                                                                 value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                                 title={HoverTitle(attgroups[i]?.childDataType, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                               />
//                                                               <span>{attgroups[i].nametoDisplay2}</span>
//                                                               {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                                 <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                               )}
//                                                             </>
//                                                           )}
//                                                           {nestedDropdownValues[`${groupName} ${i}`] && attgroups[i].childDataType === "DateTime" && (
//                                                             <>
//                                                               <input
//                                                                 type="datetime-local"
//                                                                 required
//                                                                 name={`${groupName} ${i}nEsTeD`}
//                                                                 onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                                 placeholder=""
//                                                                 value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                                 title={HoverTitle(attgroups[i]?.childDataType, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                               />
//                                                               <span>{attgroups[i].nametoDisplay2}</span>
//                                                               {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                                 <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                               )}
//                                                             </>
//                                                           )}
//                                                           {nestedDropdownValues[`${groupName} ${i}`] && ["String", "Number", "Decimal", "Percent", "Email", "URL", "Phone"].includes(attgroups[i].childDataType) && (
//                                                             <>
//                                                               <input
//                                                                 type="text"
//                                                                 required
//                                                                 name={`${groupName} ${i}nEsTeD`}
//                                                                 onChange={(e) => handleInputChange(`${groupName} ${i}nEsTeD`, e.target.value,attgroups[i].childDataType)}
//                                                                 value={inputValues[`${groupName} ${i}nEsTeD`] || ''}
//                                                                 title={HoverTitle(attgroups[i]?.childDataType, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                               />
//                                                               <span>{attgroups[i].nametoDisplay2}</span>
//                                                               {imageValidationErrors[`${groupName} ${i}nEsTeD`] && (
//                                                                 <span className="error-message">{imageValidationErrors[`${groupName} ${i}nEsTeD`]}</span>
//                                                               )}
//                                                             </>
//                                                           )}
//                                                         </>
//                                                       ) : (
//                                                         <>
//                                                           <input
//                                                             type="text"
//                                                             value={inputValues[`${groupName} ${i}`] || ''}
//                                                             onChange={(e) => handleInputChange(`${groupName} ${i}`, e.target.value,attgroups[i].type)}
//                                                             title={HoverTitle(attgroups[i]?.type, attgroups[i]?.varDescription, attgroups[i]?.mandatory)}
//                                                           />
//                                                           {imageValidationErrors[`${groupName} ${i}`] && (
//                                                             <span className="error-message">{imageValidationErrors[`${groupName} ${i}`]}</span>
//                                                           )}
//                                                         </>
//                                                       )}
//                                                     </td>
//                                                   ))}
//                                                 </tr>
//                                               ))}
//                                             </tbody>
//                                           </table>
//                                         </div>
//                                       );
//                                     })}
//                                   </>
//                                 </div>
//                               )}
//                         </div>
      
      
                                                    
//                         </div>
//                         <div className="submit_table_data_btn">
//                           <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Update</button>
//                         </div>
//                       </>                          
//                       )
//                     ):null                    
//                   }
//                     </>
//                   )
//               )
//             )
//           ) :null
//         }
//       </div>
//        </div>

//       </div>
//     </div>
//   )
// }


// export default UpdateTable;



 // useEffect(() => {
  //   const uploadImages = async () => {
  //     if (data && status === STATUSES.SUCCESS) {
  //       if (programId.length > 0 && imageFiles) {
  //         const uploadPromises = Object.entries(imageFiles).map(async ([key, file]) => {
  //           const imageFormData = new FormData();
  //           imageFormData.append("images", file);
  //           const paramData = { programId, imageName: key };
  //           try {
  //             await dispatch(ImageUpdateLink({ formData: imageFormData, ...paramData }));
  //             console.log('Uploaded:', paramData, imageFormData.get("images"));
  //           } catch (err) {
  //             console.error('Upload error:', err);
  //           }
  //         });

  //         await Promise.all(uploadPromises);
  //         toast.success("Packaging details added in program successfully");
  //         dispatch(resetTableState({}));
  //       }
  //     }

  //     if (error) {
  //       toast.error(error);
  //       dispatch(resetTableState({}));
  //     }
  //   };

  //   uploadImages();
  // }, [data, status, error, programId, imageFiles, dispatch]);






//   import React, { useCallback, useEffect, useState } from 'react';
// import './SingleAttributeGroup.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClose, faCircleInfo, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { useDispatch, useSelector } from 'react-redux';
// import STATUSES from '../../../../store/Statuses';
// import Loader, { LoaderCircle } from '../../../Common/Loader/Loader';
// import NoDataInAttributes from '../../MarketMapsComponents/NoDataInAttributes/NoDataInAttributes';
// import ErrorBox from '../../../Common/ErrorBox/ErrorBox';
// import { toast } from 'react-toastify';
// import { NonRepetableIcon, NonUniqueIcon, RepetableIcon, UniqueIcon } from '../CreateAttributeGroup/CreateAttributeGroup';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


// const SingleAttributeGroup = ({ sendGroupName, singleAttributeGroupToggle, handelsingleAttributeGroupToggle, GetSingleGroupAPI, GetSingleGroupDataAPI, groupHeadingName, receiveAdditionalAttributeAs, SaveGroupAPIName, ResetGroupAPIName, getGroupAPIName }) => {


//     const dispatch = useDispatch();

//     const userDetails = useSelector(state => state.auth.userDetails);

//     const [editMode, setEditMode] = useState(false);
//     const [showUpdateBox, setShowUpdateBox] = useState(false);
//     const [showSetUpdateBox, setShowSetUpdateBox] = useState(false);
//     const [updateData, setUpdateData] = useState({
//         nametoDisplay: '',
//         type: '',
//         varDescription: '',
//         mandatory: false, 
//         repeat : false,
//         unique :false,
//         attributeType: '', 
//         attributeKey: '',
//         options: [], // Added options array
//         nametoDisplay2 : '',
//         nestedOptions : [],
//         childDataType : ''

//     });
//     console.log(updateData)
//     const [newOption, setNewOption] = useState('');

//     const [newNestedOptions, setNewNestedOptions] = useState(
//         updateData.nestedOptions ? Array.from({ length: updateData.nestedOptions.length }, () => '') : []
//     );
//     const [newParentOption, setNewParentOption] = useState('');

//     const handleEditMode = () => {
//         setEditMode(!editMode);
//     }
//     const handlePencilClick = () => {
//         setShowUpdateBox(true); // Show the update box when pencil button is clicked
//     };

//     const handleUpdate = () => {
//         const trimmedUpdateData = {
//             ...updateData,
//             nametoDisplay: updateData.nametoDisplay.trim(),
//             type: updateData.type.trim(),
//             varDescription: updateData.varDescription.trim(),
//         };
    
//         if ( trimmedUpdateData.nametoDisplay === '' || trimmedUpdateData.type === '' ||  trimmedUpdateData.varDescription === '' ) {
//             toast.warn('Please fill in all required fields.');
//             return;
//         }
    
//         if (trimmedUpdateData.attributeType === "group") {
//             if (trimmedUpdateData.type === 'Dropdown' || trimmedUpdateData.type === 'MultiSelect') {
//                 setDisplayGroupAttributes(prevState => ({
//                     ...prevState,
//                     [trimmedUpdateData.attributeKey]: {
//                         ...prevState[trimmedUpdateData.attributeKey],
//                         nametoDisplay: trimmedUpdateData.nametoDisplay,
//                         mandatory: trimmedUpdateData.mandatory,
//                         repeat: trimmedUpdateData.repeat,
//                         unique: trimmedUpdateData.unique,
//                         type: trimmedUpdateData.type,
//                         varDescription: trimmedUpdateData.varDescription,
//                         options: updateData.options
//                     }
//                 }));
//             }
//             else if (trimmedUpdateData.type === "NestedDropdown"){
//                 if (updateData.options.length === 0) {
//                     toast.warning('Please add at least one parent option');
//                     return;
//                 }
//                 if (!updateData.nametoDisplay2) {
//                     toast.warning('Please fill in the child attribute name');
//                     return;
//                 }
//                 if (updateData.nametoDisplay2 && updateData.nametoDisplay2.trim().length ===0) {
//                     toast.warning('Please fill in the child attribute name');
//                     return;
//                 }
                
//                 if (updateData.childDataType === "Dropdown"){
//                     if (updateData.nestedOptions.some(option => option.length === 0)) {
//                         toast.warning('Each parent option must have at least one nested option');
//                         return;
//                     }
//                     setDisplayGroupAttributes(prevState => ({
//                         ...prevState,
//                         [trimmedUpdateData.attributeKey]: {
//                             ...prevState[trimmedUpdateData.attributeKey],
//                             nametoDisplay: trimmedUpdateData.nametoDisplay,
//                             mandatory: trimmedUpdateData.mandatory,
//                             repeat: trimmedUpdateData.repeat,
//                             unique: trimmedUpdateData.unique,
//                             type: trimmedUpdateData.type,
//                             varDescription: trimmedUpdateData.varDescription,
//                             options:  updateData.options,
//                             nametoDisplay2 : updateData.nametoDisplay2 ,
//                             nestedOptions : updateData.nestedOptions ,
//                             childDataType : updateData.childDataType 

//                         }
//                     }));
//                 }else if((updateData.childDataType !== "Dropdown")) {
//                     setDisplayGroupAttributes(prevState => ({
//                         ...prevState,
//                         [trimmedUpdateData.attributeKey]: {
//                             ...prevState[trimmedUpdateData.attributeKey],
//                             nametoDisplay: trimmedUpdateData.nametoDisplay,
//                             mandatory: trimmedUpdateData.mandatory,
//                             repeat: trimmedUpdateData.repeat,
//                             unique: trimmedUpdateData.unique,
//                             type: trimmedUpdateData.type,
//                             varDescription: trimmedUpdateData.varDescription,
//                             options:  updateData.options,
//                             nametoDisplay2 : updateData.nametoDisplay2 ,
//                             childDataType : updateData.childDataType 
//                         }
//                     }));
//                 }
//             }
//             else {
//                 setDisplayGroupAttributes(prevState => ({
//                     ...prevState,
//                     [trimmedUpdateData.attributeKey]: {
//                         ...prevState[trimmedUpdateData.attributeKey],
//                         nametoDisplay: trimmedUpdateData.nametoDisplay,
//                         mandatory: trimmedUpdateData.mandatory,
//                         repeat: trimmedUpdateData.repeat,
//                         unique: trimmedUpdateData.unique,
//                         type: trimmedUpdateData.type,
//                         varDescription: trimmedUpdateData.varDescription
//                     }
//                 }));
//             }
//         } 
//         else if (trimmedUpdateData.attributeType === "additional") {
//             if (trimmedUpdateData.type === 'Dropdown' || trimmedUpdateData.type === 'MultiSelect') {
//                 setDisplayAdditionalAttributes(prevState => ({
//                     ...prevState,
//                     [trimmedUpdateData.attributeKey]: {
//                         ...prevState[trimmedUpdateData.attributeKey],
//                         nametoDisplay: trimmedUpdateData.nametoDisplay,
//                         mandatory: trimmedUpdateData.mandatory,
//                         repeat: trimmedUpdateData.repeat,
//                         unique: trimmedUpdateData.unique,
//                         type: trimmedUpdateData.type,
//                         varDescription: trimmedUpdateData.varDescription,
//                         options: updateData.options || []
//                     }
//                 }));
//             } 
//             else  if (trimmedUpdateData.type === "NestedDropdown"){
//                 if (updateData.options.length === 0) {
//                     toast.warning('Please add at least one parent option');
//                     return;
//                 }
//                 if (!updateData.nametoDisplay2) {
//                     toast.warning('Please fill in the child attribute name');
//                     return;
//                 }
//                 if (updateData.nametoDisplay2 && trimmedUpdateData.nametoDisplay2.trim().length ===0) {
//                     toast.warning('Please fill in the child attribute name');
//                     return;
//                 }
                
//                 if (updateData.childDataType === "Dropdown"){
//                     if (updateData.nestedOptions.some(option => option.length === 0)) {
//                         toast.warning('Each parent option must have at least one nested option');
//                         return;
//                     }
//                     setDisplayAdditionalAttributes(prevState => ({
//                         ...prevState,
//                         [trimmedUpdateData.attributeKey]: {
//                             ...prevState[trimmedUpdateData.attributeKey],
//                             nametoDisplay: trimmedUpdateData.nametoDisplay,
//                             mandatory: trimmedUpdateData.mandatory,
//                             repeat: trimmedUpdateData.repeat,
//                             unique: trimmedUpdateData.unique,
//                             type: trimmedUpdateData.type,
//                             varDescription: trimmedUpdateData.varDescription,
//                             options:  updateData.options,
//                             nametoDisplay2 : updateData.nametoDisplay2 ,
//                             nestedOptions : updateData.nestedOptions ,
//                             childDataType : updateData.childDataType 

//                         }
//                     }));
//                 }else if((updateData.childDataType !== "Dropdown")) {
//                     setDisplayAdditionalAttributes(prevState => ({
//                         ...prevState,
//                         [trimmedUpdateData.attributeKey]: {
//                             ...prevState[trimmedUpdateData.attributeKey],
//                             nametoDisplay: trimmedUpdateData.nametoDisplay,
//                             mandatory: trimmedUpdateData.mandatory,
//                             repeat: trimmedUpdateData.repeat,
//                             unique: trimmedUpdateData.unique,
//                             type: trimmedUpdateData.type,
//                             varDescription: trimmedUpdateData.varDescription,
//                             options:  updateData.options,
//                             nametoDisplay2 : updateData.nametoDisplay2 ,
//                             childDataType : updateData.childDataType 
//                         }
//                     }));
//                 }
//             }
//             else {
//                 // If type is not Dropdown or MultiSelect, update without options
//                 setDisplayAdditionalAttributes(prevState => ({
//                     ...prevState,
//                     [trimmedUpdateData.attributeKey]: {
//                         ...prevState[trimmedUpdateData.attributeKey],
//                         nametoDisplay: trimmedUpdateData.nametoDisplay,
//                         mandatory: trimmedUpdateData.mandatory,
//                         repeat: trimmedUpdateData.repeat,
//                         unique: trimmedUpdateData.unique,
//                         type: trimmedUpdateData.type,
//                         varDescription: trimmedUpdateData.varDescription
//                     }
//                 }));
//             }
//         }
//         // Clear updateData state after update
//         setUpdateData({
//             nametoDisplay: '',
//             type: '',
//             varDescription: '',
//             mandatory: false,
//             repeat : false,
//             unique : false,
//             attributeType: '',
//             attributeKey: '',
//             options: [],
//             nametoDisplay2: '',
//             nestedOptions: [],
//             childDataType: ''
//         });
//         setShowUpdateBox(false);
//     }
    

//     const handleUpdateInputChange = (e) => {
//         const { name, value, type } = e.target;
//         const newValue = type === 'checkbox' ? e.target.checked : value;
//         setUpdateData({ ...updateData, [name]: newValue });

//         if (name === 'type') {
//             if (value === 'Dropdown' || value === 'NestedDropdown' || value === 'MultiSelect') {
//                 setUpdateData(prevState => ({
//                     ...prevState,
//                     options: prevState.options || [], // Ensure options is an array
//                     nestedOptions: prevState.nestedOptions || [], // Ensure nestedOptions is an array if needed
//                 }));
//             }
//         }
//     }

//     const handleRemoveOption = (index) => {
//         const updatedOptions = [...updateData.options];
//         updatedOptions.splice(index, 1);
//         setUpdateData({ ...updateData, options: updatedOptions });
//     }

//     const handleAddOption = () => {
//         if (newOption.trim() === '') {
//             toast.warn('Please enter an option.');
//             return;
//         }
//         const updatedOptions = [...updateData.options, newOption.trim()];
//         setUpdateData({ ...updateData, options: updatedOptions });
//         setNewOption(''); // Clear the input field after adding the option
//     }

//     const handleAddNestedOption = (parentIndex) => {
//         const updatedNestedOptions = [...updateData.nestedOptions];
//         if (!updatedNestedOptions[parentIndex]) {
//             updatedNestedOptions[parentIndex] = []; // Ensure the array exists
//         }
//         updatedNestedOptions[parentIndex] = [...updatedNestedOptions[parentIndex], newNestedOptions[parentIndex].trim()]; // Trim the input
//         setUpdateData({ ...updateData, nestedOptions: updatedNestedOptions });
//         setNewNestedOptions(prevOptions => {
//             const newOptions = [...prevOptions];
//             newOptions[parentIndex] = ''; // Clear input field after adding the option
//             return newOptions;
//         });
//     };
    
//     const handleRemoveNestedOption = (parentIndex, nestedIndex) => {
//         // Use Array.map to iterate over the nestedOptions array
//         const updatedNestedOptions = updateData.nestedOptions.map((parentOptions, index) => {
//             // If the current index matches the parentIndex, filter out the nested option at nestedIndex
//             if (index === parentIndex) {
//                 return parentOptions.filter((option, nestedIdx) => nestedIdx !== nestedIndex);
//             }
//             return parentOptions; // Otherwise, return the original parentOptions array
//         });
    
//         // Update the state with the updatedNestedOptions
//         setUpdateData({ ...updateData, nestedOptions: updatedNestedOptions });
//     };
    
//     const handleAddParentOption = () => {
//         if (updateData.childDataType === 'Dropdown') {
//             // If childDataType is Dropdown, add parent option and an empty array for nestedOptions
//             const updatedOptions = [...updateData.options, newParentOption.trim()]; // Trim the input
//             const updatedNestedOptions = [...updateData.nestedOptions, []]; // Add an empty array for nested options
//             setUpdateData({ ...updateData, options: updatedOptions, nestedOptions: updatedNestedOptions });
//             setNewParentOption('');
//         } else {
//             // If childDataType is not Dropdown, simply add the parent option to options array
//             const updatedOptions = [...updateData.options, newParentOption.trim()]; // Trim the input
//             setUpdateData({ ...updateData, options: updatedOptions });
//         }
//     };

    
//     const handleRemoveParentOption = (index) => {
//         const updatedOptions = [...updateData.options];
//         updatedOptions.splice(index, 1);
        
//         if (updateData.childDataType === 'Dropdown') {
//             // Only update nestedOptions if childDataType is Dropdown
//             const updatedNestedOptions = [...updateData.nestedOptions];
//             updatedNestedOptions.splice(index, 1);
//             setUpdateData({ ...updateData, options: updatedOptions, nestedOptions: updatedNestedOptions });
//         } else {
//             // If childDataType is not Dropdown, update options only
//             setUpdateData({ ...updateData, options: updatedOptions });
//         }
//     };
//     const handleNewNestedOptionChange = (e, index) => {
//         const { value } = e.target;
//         const updatedNewNestedOptions = [...newNestedOptions];
//         updatedNewNestedOptions[index] = value;
//         setNewNestedOptions(updatedNewNestedOptions);
//     };


//     const [editedAttributes, setEditedAttributes] = useState({});
//     const [activeItem, setActiveItem] = useState(null);

//     const handleEditClick = (itemName, value) => {
//         setActiveItem(itemName);
//         setEditedAttributes(value.groupsName);
//         setEditMode(true);
//     };

//     const handleInputChange = (e, name) => {
//         const { value } = e.target;
//         setEditedAttributes(prev => ({
//             ...prev,
//             [name]: {
//                 ...prev[name],
//                 nametoDisplay: value
//             }
//         }));
//     };

//     const handleSave = () => {
//         let shouldShowToast = false; // Flag to track if toast should be shown
//         const updatedAttSets = { ...displayImagesAttributes }; // Copy the state
    
//         if (activeItem) {
//             const updatedGroupsName = { ...editedAttributes };
            
//             // Trim and check for empty nametoDisplay in each group
//             Object.keys(updatedGroupsName).forEach(groupKey => {
//                 const trimmedValue = updatedGroupsName[groupKey].nametoDisplay.trim();
//                 if (trimmedValue === '') {
//                     // Set flag to true if any nametoDisplay is empty
//                     shouldShowToast = true;
//                     return; // Skip saving this group
//                 }
//                 updatedGroupsName[groupKey] = {
//                     ...updatedGroupsName[groupKey], // Ensure immutability
//                     nametoDisplay: trimmedValue
//                 };
//             });
    
//             updatedAttSets[activeItem] = {
//                 ...updatedAttSets[activeItem],
//                 groupsName: updatedGroupsName
//             };
//         }
    
//         // Show toast warning if any nametoDisplay was empty
//         if (shouldShowToast) {
//             // Code to display toast warning goes here
//             toast.warning('The set name can not be empty');
//             return; // Exit handleSave to prevent further execution
//         }
    
//         // Update state with the modified attributes
//         setDisplayImagesAttributes(updatedAttSets);
//         setShowSetUpdateBox(!setShowSetUpdateBox);
//         setActiveItem(null);
//     };
    
    

//     // console.log(activeItem);
//     // console.log(editedAttributes);


//     const [refreshEffect, setRefreshEffect] = useState(false);
//     const handelClose = () => {
//         handelsingleAttributeGroupToggle("")
//         document.body.style.overflow = 'unset';
//         setRefreshEffect(prevState => !prevState);
//     }
        
//     const handleCancle = useCallback(() => {
//         setEditMode(false);
//         setShowUpdateBox(false);
//     }, []);

//     useEffect(() => {
//         if (sendGroupName.length > 0 || refreshEffect) {
//             dispatch(GetSingleGroupAPI(sendGroupName));
//             // Reset refreshEffect to false after dispatching the action
//             setRefreshEffect(false);
//         }
//     }, [dispatch, sendGroupName, GetSingleGroupAPI, refreshEffect]);

//     const { data, status, error } = useSelector(GetSingleGroupDataAPI);
//     const { groupAttributes, [receiveAdditionalAttributeAs]: AdditionalAttributes, imagesAttributes } = data;
//     // console.log(data);

//     const [displayGroupAttributes, setDisplayGroupAttributes] = useState({});
//     const [displayAdditionalAttributes, setDisplayAdditionalAttributes] = useState({});
//     const [displayImagesAttributes, setDisplayImagesAttributes] = useState({});

//     useEffect(() => {
//         if (status === STATUSES.SUCCESS) {
//             if (groupAttributes && Object.keys(groupAttributes).length > 0) {
//                 setDisplayGroupAttributes({ ...groupAttributes });
//             } else {
//                 setDisplayGroupAttributes({});
//             }
//             if (AdditionalAttributes && Object.keys(AdditionalAttributes).length > 0) {
//                 setDisplayAdditionalAttributes({ ...AdditionalAttributes });
//             } else {
//                 setDisplayAdditionalAttributes({});
//             }
//             if (imagesAttributes && Object.keys(imagesAttributes).length > 0) {
//                 setDisplayImagesAttributes({ ...imagesAttributes });
//             } else {
//                 setDisplayImagesAttributes({});
//             }
//         } else {
//             // If status is not SUCCESS, reset display attributes to empty objects
//             setDisplayGroupAttributes({});
//             setDisplayAdditionalAttributes({});
//             setDisplayImagesAttributes({});
//         }
//     }, [groupAttributes, AdditionalAttributes, imagesAttributes, status, sendGroupName]);

//     console.log("displayGroupAttributes == ", displayGroupAttributes)
//     // console.log("displayAdditionalAttributes == ", displayAdditionalAttributes)
//     // console.log("displayImagesAttributes == ", displayImagesAttributes)


    



//     const submitUpdateData = () => {

//         const dataToSave = { groupName: sendGroupName, groupAttributes: displayGroupAttributes, [receiveAdditionalAttributeAs]: displayAdditionalAttributes, imagesAttributes: displayImagesAttributes }
//         dispatch(SaveGroupAPIName(dataToSave));
//         console.log(dataToSave);
//     }


//     const {data :updateAttributeData, status : updateAttributeStatus, error :updateAttributeError } = useSelector(getGroupAPIName);

//     useEffect(() => {
//         if (updateAttributeStatus === STATUSES.SUCCESS) {
//             toast.success(updateAttributeStatus ? updateAttributeStatus :"Updated Successfully");
//             dispatch(ResetGroupAPIName({}));
//         }
//         if (updateAttributeStatus === STATUSES.ERROR) {
//             toast.error(updateAttributeError ? updateAttributeError : "Something Went Wrong");
//             dispatch(ResetGroupAPIName({}));
//         }
//     }, [updateAttributeStatus, updateAttributeError, updateAttributeData, dispatch, ResetGroupAPIName]);


//     const handleDragEnd = (result) => {
//         if (!result.destination) return;

//         const items = Array.from(Object.entries(displayGroupAttributes));
//         const [reorderedItem] = items.splice(result.source.index, 1);
//         items.splice(result.destination.index, 0, reorderedItem);

//         const newOrder = Object.fromEntries(items);
//         setDisplayGroupAttributes(newOrder);
//     };


//     return (
//         <div className={'single_attribute_group'}>
//             <div className={singleAttributeGroupToggle ? 'single_attribute_group_container' : 'single_attribute_group_container close'}>
//                 <i><FontAwesomeIcon icon={faClose} onClick={handelClose} /></i>
//                 {
//                     status === STATUSES.LOADING ? (
//                         <Loader />
//                     ) : status === STATUSES.SUCCESS ? (
//                         (
//                             <>
//                                 <h6>{groupHeadingName} Attributes - <span>{data && data.groupName}</span></h6>
//                                 <div className={editMode ? "single_attribute_group_content" : "single_attribute_group_content close"}>
//                                     <h5>Group Description - {data && data.groupDescription}
//                                     {
//                                         (Object.keys(displayGroupAttributes).length > 0 || Object.keys(displayAdditionalAttributes).length > 0 || Object.keys(displayImagesAttributes).length > 0 ) ? 
//                                         (
//                                             userDetails?.authorities[0]?.authority === "ROLE_ADMIN" || userDetails?.authorities[0]?.authority === "ROLE_MANAGER" ? (
//                                                 <button className='common_btn' onClick={handleEditMode}>{editMode ? "Hide edit" : "Edit"}</button> 
//                                             ):
//                                             (
//                                                 <button className='common_btn'  data-tooltip-id="tooltip" data-tooltip-content="Only admin can access this feature." disabled >{"Edit"}</button>
//                                             )
//                                         )                                       
//                                         : null
//                                     }
                                    
//                                     </h5>

//                                     <div className="boxes">
//                                         {/* Main Attributes */}
//                                         <div className="col1">
//                                             <h6>Main Attributes</h6>
//                                             {Object.keys(displayGroupAttributes).length === 0 ? (
//                                                 <NoDataInAttributes NodataHeading="No data Found" />
//                                             ) : (
//                                                 Object.keys(displayGroupAttributes).map((item, index) => (
//                                                     <div key={index} className='single_attribute'>
//                                                         <div className='att_name'>{displayGroupAttributes[item].nametoDisplay}</div>
                                                        
//                                                         <div className='display_radio'>
//                                                             {displayGroupAttributes[item].repeat ? <RepetableIcon/> : <NonRepetableIcon/>}
//                                                             {displayGroupAttributes[item].unique ? <UniqueIcon/> : <NonUniqueIcon/>}
//                                                             <div className="checkbox-wrapper-3">
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     checked={displayGroupAttributes[item].mandatory}
//                                                                     disabled={true} // Set disabled attribute to true
//                                                                 />
//                                                                 <label className="toggle"><span></span></label>
//                                                             </div>
//                                                             <i title={`Data type- ${displayGroupAttributes[item].type}\nDescription- ${displayGroupAttributes[item].varDescription}`}>
//                                                                 <FontAwesomeIcon icon={faCircleInfo} />
//                                                             </i>
//                                                             {editMode && (
//                                                                 <i>
//                                                                 <FontAwesomeIcon icon={faPencil} onClick={() => { setUpdateData({ ...displayGroupAttributes[item], attributeType: 'group', attributeKey: item }); handlePencilClick(); }} />
//                                                                 </i>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 ))
//                                                 <DragDropContext onDragEnd={handleDragEnd}>
//                                                 <Droppable droppableId="groupAttributes">
//                                                     {(provided) => (
//                                                         <div {...provided.droppableProps} ref={provided.innerRef}>
//                                                             {Object.keys(displayGroupAttributes).map((item, index) => (
//                                                                 <Draggable key={item} draggableId={item} index={index} isDragDisabled={!editMode}>
//                                                                     {(provided) => (
//                                                                         <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='single_attribute'>
//                                                                             <div className='att_name'>{displayGroupAttributes[item].nametoDisplay}</div>
//                                                                             <div className='display_radio'>
//                                                                                 {displayGroupAttributes[item].repeat ? <RepetableIcon/> : <NonRepetableIcon/>}
//                                                                                 {displayGroupAttributes[item].unique ? <UniqueIcon/> : <NonUniqueIcon/>}
//                                                                                 <div className="checkbox-wrapper-3">
//                                                                                     <input
//                                                                                         type="checkbox"
//                                                                                         checked={displayGroupAttributes[item].mandatory}
//                                                                                         disabled={true} 
//                                                                                     />
//                                                                                     <label className="toggle"><span></span></label>
//                                                                                 </div>
//                                                                                 <i title={`Data type- ${displayGroupAttributes[item].type}\nDescription- ${displayGroupAttributes[item].varDescription}`}>
//                                                                                     <FontAwesomeIcon icon={faCircleInfo} />
//                                                                                 </i>
//                                                                                 {editMode && (
//                                                                                     <i>
//                                                                                         <FontAwesomeIcon icon={faPencil} onClick={() => { setUpdateData({ ...displayGroupAttributes[item], attributeType: 'group', attributeKey: item }); handlePencilClick(); }} />
//                                                                                     </i>
//                                                                                 )}
//                                                                             </div>
//                                                                         </div>
//                                                                     )}
//                                                                 </Draggable>
//                                                             ))}
//                                                             {provided.placeholder}
//                                                         </div>
//                                                     )}
//                                                 </Droppable>
//                                             </DragDropContext>
//                                             )}
//                                         </div>
//                                         {/* New added Attributes */}
//                                         <div className="col1">
//                                             <h6>New added Attributes</h6>
//                                             {Object.keys(displayAdditionalAttributes).length === 0 ? (
//                                                 <NoDataInAttributes NodataHeading="No data Found" />
//                                             ) : (
//                                                 Object.keys(displayAdditionalAttributes).map((item, index) => (
//                                                     <div key={index} className='single_attribute'>
//                                                         <div className='att_name'>{displayAdditionalAttributes[item].nametoDisplay}</div>
                                                        

//                                                         <div className='display_radio'>
//                                                             {displayAdditionalAttributes[item].repeat ? <RepetableIcon/> : <NonRepetableIcon/>}
//                                                             {displayAdditionalAttributes[item].unique ? <UniqueIcon/> : <NonUniqueIcon/>}
//                                                             <div className="checkbox-wrapper-3">
//                                                                 <input
//                                                                     type="checkbox"
//                                                                     checked={displayAdditionalAttributes[item].mandatory}
//                                                                     disabled={true} // Set disabled attribute to true
//                                                                 />
//                                                                 <label className="toggle"><span></span></label>
//                                                             </div>

//                                                             <i title={`Data type- ${displayAdditionalAttributes[item].type}\nDescription- ${displayAdditionalAttributes[item].varDescription}`}>
//                                                                 <FontAwesomeIcon icon={faCircleInfo} />
//                                                             </i>
//                                                             {editMode && (
//                                                                 <i>
//                                                                     <FontAwesomeIcon icon={faPencil} onClick={() => {setUpdateData({ ...displayAdditionalAttributes[item], attributeType: 'additional', attributeKey: item }); handlePencilClick();}} />
//                                                                 </i>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 ))
//                                             )}
//                                         </div>
//                                         {/* Sets of Attributes */}
//                                         <div className="col1">
//                                             <h6>Sets of Attributes</h6>
//                                             {Object.keys(displayImagesAttributes).length === 0 ? (
//                                                 <NoDataInAttributes NodataHeading="No data Found" />
//                                             ) : (
//                                                 Object.entries(displayImagesAttributes).map(([itemName, value]) => (
//                                                     <div key={itemName} className="att_set_container">
//                                                         {editMode && 
//                                                             <i className='edit_set_name' onClick={() => {handleEditClick(itemName, value); setShowSetUpdateBox(true)}}>
//                                                                 <FontAwesomeIcon icon={faPencil} style={{ float: "right" }} />
//                                                             </i>                                                        
//                                                         } 
//                                                         {Object.keys(value?.groupsName).map((name, index) => (
//                                                             <div className='att_set_item' key={index}>
//                                                                 {value?.groupsName[name].nametoDisplay}
//                                                             </div>
//                                                         ))}
//                                                         {Object.keys(value.attgroups).map((key , index) => (
//                                                             <div className='att_sets_attribute' key={index}>
//                                                                 {value.attgroups[key].nametoDisplay}
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 ))
//                                             )}

//                                         </div>
//                                     </div>

//                                     {/* Update Attributes Box */}
//                                     {showUpdateBox && editMode && (
//                                     <div className="attributes_update_box">
//                                         <h6>Update Attributes</h6>
//                                         <div className="col">
//                                             <div className='_input_box'>
//                                                 <input className='table_input' type="text" name="nametoDisplay" value={updateData.nametoDisplay || ''} onChange={handleUpdateInputChange} />
//                                                 <span>Attribute name</span>
//                                             </div>
//                                             <select className='data_type_select' type="text" name="type" value={updateData.type || ''} onChange={handleUpdateInputChange} >
//                                             <option value="" className='select_step_value'>Select the attribute data type</option>
//                                             <option value="String" className='select_step_value'>Text - single line</option>
//                                             <option value="Number" className='select_step_value'>Number</option>
//                                             <option value="Decimal" className='select_step_value'>Decimal</option>
//                                             <option value="Dropdown" className='select_step_value'>Dropdown</option>
//                                             <option value="NestedDropdown" className='select_step_value'>Nested Dropdown</option>
//                                             <option value="MultiSelect" className='select_step_value'>Multi-select</option>
//                                             <option value="Image" className='select_step_value'>Image</option>
//                                             <option value="Percent" className='select_step_value'>Percent</option>
//                                             <option value="Date" className='select_step_value'>Date</option>
//                                             <option value="DateTime" className='select_step_value'>Date and Time</option>
//                                             <option value="Email" className='select_step_value'>Email</option>
//                                             <option value="URL" className='select_step_value'>URL</option>
//                                             <option value="Phone" className='select_step_value'>Phone</option>
//                                             </select>
//                                         </div>
//                                         <div className="col">
//                                             <div className='_input_box'>
//                                                 <input className='table_input' type="text" name="varDescription" value={updateData.varDescription || ''} onChange={handleUpdateInputChange} />
//                                                 <span>Attribute Description</span>
//                                             </div>
//                                             <div>
//                                                 <div className="checkbox-wrapper-3">
//                                                     <input
//                                                         type="checkbox"
//                                                         id="cbx-new-mandatory"
//                                                         name="mandatory"
//                                                         checked={updateData.mandatory}
//                                                         onChange={handleUpdateInputChange}
//                                                     />
//                                                     <label htmlFor="cbx-new-mandatory" className="toggle"><span></span></label>
//                                                     <span className="custom-radio-button">
//                                                         {updateData.mandatory ? "Mandatory" : "Non Mandatory"}
//                                                     </span>
//                                                 </div>
//                                                 <div className="checkbox-wrapper-3">
//                                                     <input
//                                                         type="checkbox"
//                                                         id="cbx-new-repeat"
//                                                         name="repeat"
//                                                         checked={updateData.repeat}
//                                                         onChange={handleUpdateInputChange}
//                                                     />
//                                                     <label htmlFor="cbx-new-repeat" className="toggle"><span></span></label>
//                                                     <span className="custom-radio-button">
//                                                         {updateData.repeat ? <span><RepetableIcon/> <div>Repeatable</div></span> : <span><NonRepetableIcon/><div>Non repeatable</div> </span>}
//                                                     </span>
//                                                 </div>
//                                                 <div className="checkbox-wrapper-3">
//                                                     <input
//                                                         type="checkbox"
//                                                         id="cbx-new-unique"
//                                                         name="unique"
//                                                         checked={updateData.unique}
//                                                         onChange={handleUpdateInputChange}
//                                                     />
//                                                     <label htmlFor="cbx-new-unique" className="toggle"><span></span></label>
//                                                     <span className="custom-radio-button">
//                                                         {updateData.unique ? <span><UniqueIcon/> <div>Unique</div></span> : <span><NonUniqueIcon/><div>Non unique</div> </span>}
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         {/* Options */}
//                                         {updateData.type === 'Dropdown' || updateData.type === 'MultiSelect' ? (
//                                             <div className="options-container">
//                                                 <h6>Update the options</h6>
//                                                 {updateData.options.map((option, index) => (
//                                                     <div key={index} className="option-item">
//                                                         <span>{option}</span>
//                                                         <i onClick={() => handleRemoveOption(index)}><FontAwesomeIcon icon={faTrash} /></i>
//                                                     </div>
//                                                 ))}
//                                                 <div className='search_box'>
//                                                     <input className='table_input' type="text" placeholder="Option" value={newOption || ''} onChange={(e) => setNewOption(e.target.value)} />
//                                                     <span onClick={handleAddOption}>Add option</span>
//                                                 </div>
//                                             </div>
//                                         ) : null}

//                                         {updateData.type === 'NestedDropdown' && (
//                                         <>
//                                             <div className="options-container">
//                                                 <div className="child_attribute_box">
//                                                     <div className='_input_box'>
//                                                         <input className='table_input' type="text" required name="nametoDisplay2" value={updateData.nametoDisplay2} onChange={handleUpdateInputChange}/>
//                                                         <span>Add child attribute name</span>
//                                                     </div>
//                                                     <select
//                                                         className="data_type_select"
//                                                         value={updateData.childDataType}
//                                                         onChange={(e) => handleUpdateInputChange({ target: { name: "childDataType", value: e.target.value } })}
//                                                     >
//                                                         <option value="" className='select_step_value'>Select the attribute data type</option>
//                                                         <option value="String" className='select_step_value'>Text - single line</option>
//                                                         <option value="Number" className='select_step_value'>Number</option>
//                                                         <option value="Decimal" className='select_step_value'>Decimal</option>
//                                                         <option value="Dropdown" className='select_step_value'>Dropdown</option>
//                                                         <option value="Percent" className='select_step_value'>Percent</option>
//                                                         <option value="Date" className='select_step_value'>Date</option>
//                                                         <option value="DateTime" className='select_step_value'>Date and Time</option>
//                                                         <option value="Email" className='select_step_value'>Email</option>
//                                                         <option value="URL" className='select_step_value'>URL</option>
//                                                         <option value="Phone" className='select_step_value'>Phone</option>
//                                                     </select>
//                                                 </div>

//                                                 {updateData.childDataType === 'Dropdown' && (
//                                                     <>
//                                                         <h6>Add the options</h6>
//                                                         <div className="nested_option_container">
//                                                             {updateData.options.map((parentOption, index) => (
//                                                                 <div key={index} className="nested-option-item">
//                                                                     <div className="nested-option-header">
//                                                                         <span style={{fontWeight:"bold", paddingRight: 10}}>{parentOption}</span>
//                                                                         <i onClick={() => handleRemoveParentOption(index)}><FontAwesomeIcon icon ={faTrash} /></i>
//                                                                     </div>
//                                                                     <div className="nested-options">
//                                                                         {updateData.nestedOptions[index].map((nestedOption, nestedIndex) => (
//                                                                             <div key={nestedIndex} className="nested-option">
//                                                                                 <span style={{fontSize:13, paddingRight: 10}}>{nestedOption}</span>
//                                                                                 <i onClick={() => handleRemoveNestedOption(index, nestedIndex)}><FontAwesomeIcon icon ={faTrash} /></i>
//                                                                             </div>
//                                                                         ))}
//                                                                         <div className="search_box">
//                                                                             <input
//                                                                                 type="text"
//                                                                                 placeholder="Add child option"
//                                                                                 value={newNestedOptions[index] || ''}
//                                                                                 onChange={(e) => handleNewNestedOptionChange(e, index)}
//                                                                             />
//                                                                             <span onClick={() => handleAddNestedOption(index)}>Add</span>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                         <div className="search_box">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder=" Add parent option"
//                                                                 value={newParentOption}
//                                                                 onChange={(e) => setNewParentOption(e.target.value)}
//                                                             />
//                                                             <span onClick={handleAddParentOption}>Parent Option</span>
//                                                         </div>
//                                                     </>
//                                                 )}
//                                                 {updateData.childDataType !== 'Dropdown' && (
//                                                     <>
//                                                         {/* Render parent options with functionality for non-Dropdown */}
//                                                         <h6>Add the options</h6>
//                                                         <div className="nested_option_container">
//                                                             {updateData.options.map((parentOption, index) => (
//                                                                 <div key={index} className="nested-option-item">
//                                                                     <div className="nested-option-header">
//                                                                         <span style={{fontWeight:"bold", paddingRight: 10}}>{parentOption}</span>
//                                                                         {/* Provide functionality to remove parent options */}
//                                                                         <i onClick={() => handleRemoveParentOption(index)}><FontAwesomeIcon icon ={faTrash} /></i>
//                                                                     </div>
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                         {/* Provide functionality to add parent options */}
//                                                         <div className="search_box">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder=" Add parent option"
//                                                                 value={newParentOption}
//                                                                 onChange={(e) => setNewParentOption(e.target.value)}
//                                                             />
//                                                             <span onClick={handleAddParentOption}>Parent Option</span>
//                                                         </div>
//                                                     </>
//                                                 )}
//                                             </div>
//                                         </>
//                                         )}

//                                         <div className="attributes_update_box_btn">
//                                             <button onClick={handleUpdate}>Update</button>
//                                         </div>
//                                     </div>
//                                     )}

//                                     {showSetUpdateBox && editMode && (
//                                         <div className="attributes_update_box">
//                                             <h6>Update set names</h6>
//                                             <div className='col' style={{flexWrap:"wrap", marginBottom:25}} >
//                                             {Object.keys(editedAttributes).map((name, index) => (
//                                                     <div className="_input_box" key={index}>
//                                                         <input type="text" className='table_input' value={editedAttributes[name].nametoDisplay} onChange={(e) => handleInputChange(e, name)}
//                                                         />
//                                                         <span>Set name {index + 1}</span>
//                                                     </div>
//                                             ))}
//                                             </div>
//                                             <div className="attributes_update_box_btn">
//                                             <button onClick={handleSave}>Save</button>
//                                             </div>
//                                         </div>
//                                     )}


//                                 </div>

//                                 {editMode &&
//                                     <div className="attributes_action_container">
//                                         <button className={updateAttributeStatus === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} onClick={submitUpdateData} disabled={updateAttributeStatus === STATUSES.LOADING}>
//                                             {updateAttributeStatus === STATUSES.LOADING ? <LoaderCircle /> : "Save Changes"}
//                                         </button>
//                                         <button onClick={handleCancle} className="common_btn2" >Cancel</button>
//                                     </div>
//                                 }
//                             </>
//                         )
//                     ) : status === STATUSES.ERROR ? (
//                         <ErrorBox message={error?.message ? error?.message : "Something Went Wrong"} />
//                     ) : null
//                 }
//             </div>
//         </div>
//     )
// }

// export default SingleAttributeGroup;



// import { BrowserRouter , Routes, Route, Navigate  } from "react-router-dom";
// import React from 'react'
// import Homepage from "../pages/Homepage";
// import Ekyc from "../components/Ekyc";
// import ErrorPage from "../pages/ErrorPage";
// import HomeComponents from "../components/HomeComponents";
// import Login from "../components/HomeComponents/Login/Login";
// import SighUp from "../components/HomeComponents/Login/SignUp";
// import RequestPage from "../components/HomeComponents/RequestPage/RequestPage";

// import MarketMapsPage from "../pages/MarketMapsPage/MarketMapsPage";

// import AddList from "../components/MarketMaps/MarketAllPages/AddList/AddList";
// import PMSpage from "../pages/PMSpage/PMSpage";
// import UserProfile from "../components/Common/User/UserProfile/UserProfile";
// import PMShome from "../components/PMS/PMSallPages/PMShome/PMShome";
// import UserProfileEdit from "../components/Common/User/UserProfileEdit/UserProfileEdit";
// import AllEmployee from "../components/Common/User/ALLEmployee/AllEmployee";
// import AddEmployeeProfile from "../components/Common/User/AddEmployeeProfile/AddEmployeeProfile";
// import ImportEmployees from "../components/Common/User/ImportEmployees/ImportEmployees";
// import UserPersonalSetting from "../components/Common/User/UserPersonalSetting/UserPersonalSetting";
// import Features from "../components/HomeComponents/Features/Features";
// import MarketMapFeatures from "../components/HomeComponents/AllFeatures/MarketMapFeatures";
// import FirstPage from "../components/Common/FirstPage/FirstPage";
// import ProductTableHome from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/ProductTableHome";
// import AddProductTable from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/AddProductTable";
// import UserPage from "../pages/UserPage/UserPage";
// import RetailerTableHome from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/RetailerTableHome";
// import AddRetailerTable from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/AddRetailerTable";
// import AddManufacturerTable from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/AddManufacturerTable";
// import ManufacturerTableHome from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/ManufacturerTableHome";
// import SupplierTableHome from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/SupplierTableHome";
// import AddSupplierTable from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/AddSupplierTable";
// import PackagingTableHome from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/PackagingTableHome";
// import AddPackagingTable from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/AddPackagingTable";
// import AddPromotionTable from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/AddPromotionTable";
// import PromotionTablehome from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/PromotionTablehome";

// import RegionTableHome from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/RegionTableHome";
// import AddRegionTable from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/AddRegionTable";
// import GroupTableHome from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/GroupTableHome";
// import AddGroupTable from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/AddGroupTable";

// import SettingHome from "../components/MarketMaps/Settings/SettingHome/SettingHome";
// import ProgramHome from "../components/MarketMaps/MarketAllPages/Program/ProgramHome/ProgramHome";

// // All Charts from market map 
// import Barchart from "../components/MarketMaps/MarketAllPages/AllCharts/Barchart/Barchart";
// import Areachart from "../components/MarketMaps/MarketAllPages/AllCharts/Areachart/Areachart";
// import Linechart from "../components/MarketMaps/MarketAllPages/AllCharts/Linechart/Linechart";
// import Radarchart from "../components/MarketMaps/MarketAllPages/AllCharts/Radarchart/Radarchart";
// import Vbarchart from "../components/MarketMaps/MarketAllPages/AllCharts/Vbarchart/Vbarchart";
// import Piechart from "../components/MarketMaps/MarketAllPages/AllCharts/Piechart/Piechart";
// import BubbleChart from "../components/MarketMaps/MarketAllPages/AllCharts/BubbleChart/BubbleChart";
// import StackedBarChart from "../components/MarketMaps/MarketAllPages/AllCharts/StackedBarChart/StackedBarChart";

// import AddNewProgram from "../components/MarketMaps/MarketAllPages/Program/AddNewProgram/AddNewProgram";
// import MarketMapHome from "../components/MarketMaps/MarketAllPages/MarketMapHome/MarketMapHome";
// // import EditProduct from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/EditProduct";
// // import EditRetailer from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/EditRetailer";
// // import EditManufacturer from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/EditManufacturer";
// // import EditSupplier from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/EditSupplier";
// // import EditPackaging from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/EditPackaging";
// // import EditPromotion from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/EditPromotion";
// // import EditRegion from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/EditRegion";
// // import EditGroup from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/EditGroup";
// import ViewAllPrograms from "../components/MarketMaps/MarketAllPages/Program/ViewAllPrograms/ViewAllPrograms";
// import ProductAtt from "../components/MarketMaps/Settings/AllAttributes/ProductAtt";
// import RetailerAtt from "../components/MarketMaps/Settings/AllAttributes/RetailerAtt";
// import ManufacturerAtt from "../components/MarketMaps/Settings/AllAttributes/ManufacturerAtt";
// import SupplierAtt from "../components/MarketMaps/Settings/AllAttributes/SupplierAtt";
// import PackagingAtt from "../components/MarketMaps/Settings/AllAttributes/PackagingAtt";
// import MarketingCallOutAtt from "../components/MarketMaps/Settings/AllAttributes/MarketingCallOutAtt";
// import ProgramAtt from "../components/MarketMaps/Settings/AllAttributes/ProgramAtt";
// import RegionAtt from "../components/MarketMaps/Settings/AllAttributes/RegionAtt";
// import GroupAtt from "../components/MarketMaps/Settings/AllAttributes/GroupAtt";
// import EditProgram from "../components/MarketMaps/MarketAllPages/Program/EditProgram/EditProgram";
// import { useSelector } from "react-redux";
// import EditOneProgram from "../components/MarketMaps/MarketAllPages/Program/EditOneProgram/EditOneProgram";
// import config from '../config';



// function MyRoutes() {
  
//   const companyName = config.SERVICE_COMPANY_NAME;
  
//   console.log("companyName" ,companyName);
//   console.log("Server name=", process.env.REACT_APP_ENV_NAME);

//   const { token, userDetails } = useSelector((state) => state.auth);


//   return (
//     <BrowserRouter>
//       <Routes>

//         {/* Home  */}
//         <Route path="/" element={<Homepage />}>
//             <Route index  path="/"  element={<HomeComponents />}/>
//             <Route path="/ekyc"  element={<Ekyc />} />
//             <Route path="/login"  element={<Login />} />
//             <Route path="/signup"  element={<SighUp />} />
//             <Route path="/request"  element={<RequestPage />} />
//             <Route path="/features"  element={<Features />} />
//             <Route path="/features/market-map-features" element={<MarketMapFeatures />} />
//         </Route>
         

//         {/* Market Map */}
//         {
//           token && userDetails ? (
//           <Route path="market-map"  element={<MarketMapsPage />}>
//             <Route index  path="" element={<MarketMapHome />}/>

//             {/* All Charts  */}
//             <Route  path="Bar-Chart"  element={<Barchart/>}/>
//             <Route  path="Pie-Chart"  element={<Piechart/>}/>
//             <Route  path="Area-Chart"  element={<Areachart/>}/>
//             <Route  path="Bubble-Chart"  element={<BubbleChart/>}/>
//             <Route  path="Line-Chart"  element={<Linechart/>}/>
//             <Route  path="Radar-Chart"  element={<Radarchart/>}/>
//             <Route  path='Vertical-Bar-Chart' element={<Vbarchart/>}/>
//             <Route  path='Stacked-Bar-Chart' element={<StackedBarChart/>}/>
//             {/* <Route  path='Add-List' element={<AddList/>}/> */} 


 
//             <Route path="Tables" element={<AddList/>}/>

//             {/* All table pages */}
//             <Route path="Tables/Product-Table" element={<ProductTableHome/>}/>
//             <Route path="Tables/Product-Table/Add-Product" element={<AddProductTable/>}/>
//             {/* <Route path="Tables/Product-Table/Edit-Product" element={<EditProduct/>}/> */}
        
//             <Route path="Tables/Retailer-Table" element={<RetailerTableHome/>}/>
//             <Route path="Tables/Retailer-Table/Add-Retailer" element={<AddRetailerTable/>}/>
//             {/* <Route path="Tables/Retailer-Table/Edit-Retailer" element={<EditRetailer/>}/> */}

//             <Route path="Tables/Manufacturer-Table" element={<ManufacturerTableHome/>}/>
//             <Route path="Tables/Manufacturer-Table/Add-Manufacturer" element={<AddManufacturerTable/>}/>
//             {/* <Route path="Tables/Manufacturer-Table/Edit-Manufacturer" element={<EditManufacturer/>}/> */}

//             <Route path="Tables/Supplier-Table" element={<SupplierTableHome/>}/>
//             <Route path="Tables/Supplier-Table/Add-Supplier" element={<AddSupplierTable/>}/>
//             {/* <Route path="Tables/Supplier-Table/Edit-Supplier" element={<EditSupplier/>}/> */}
 
//             <Route path="Tables/Packaging-Table" element={<PackagingTableHome/>}/>
//             <Route path="Tables/Packaging-Table/Add-Packaging" element={<AddPackagingTable/>}/>
//             {/* <Route path="Tables/Packaging-Table/Edit-Packaging" element={<EditPackaging/>}/> */}

//             <Route path="Tables/Promotion-Table" element={<PromotionTablehome/>}/>
//             <Route path="Tables/Promotion-Table/Add-Promotion" element={<AddPromotionTable/>}/>
//             {/* <Route path="Tables/Promotion-Table/Edit-Promotion" element={<EditPromotion/>}/> */}


//             <Route path="Tables/Region-Table" element={<RegionTableHome/>}/>
//             <Route path="Tables/Region-Table/Add-Region" element={<AddRegionTable/>}/>
//             {/* <Route path="Tables/Region-Table/Edit-Region" element={<EditRegion/>}/> */}

//             <Route path="Tables/Group-Table" element={<GroupTableHome/>}/>
//             <Route path="Tables/Group-Table/Add-Group" element={<AddGroupTable/>}/>
//             {/* <Route path="Tables/Group-Table/Edit-Group" element={<EditGroup/>}/> */}

            
//             {/* programs  */}
//             <Route path="programs" element={<ProgramHome/>}/>
//             <Route path="programs/add-New-Program" element={<AddNewProgram/>}/>
//             <Route path="programs/view-All-Programs" element={<ViewAllPrograms/>}/>
//             <Route path="programs/edit-All-Programs" element={<EditProgram/>}/>
//             <Route path="programs/edit-All-Programs/edit-One-Program" element={<EditOneProgram/>}/>



            

//             {/* Settings  */}
//             <Route path="market-Map-Settings" element={<SettingHome/>}/>

//             {/* {Attributes Setting} */}
//             <Route path="market-Map-Settings/Add-Product-Attributes" element={<ProductAtt/>}/>
//             <Route path="market-Map-Settings/Add-Retailer-Attributes" element={<RetailerAtt/>}/>
//             <Route path="market-Map-Settings/Add-Manufacturer-Attributes" element={<ManufacturerAtt/>}/>
//             <Route path="market-Map-Settings/Add-Supplier-Attributes" element={<SupplierAtt/>}/>
//             <Route path="market-Map-Settings/Add-Packaging-Details-Attributes" element={<PackagingAtt/>}/>
//             <Route path="market-Map-Settings/Add-Marketing-Callout-Attributes" element={<MarketingCallOutAtt/>}/>
//             <Route path="market-Map-Settings/Add-Program-Attributes" element={<ProgramAtt/>}/>
//             <Route path="market-Map-Settings/Add-Region-Attributes" element={<RegionAtt/>}/>
//             <Route path="market-Map-Settings/Add-Groups-Attributes" element={<GroupAtt/>}/>


            

//           </Route>
//           ) : (
//             <Route path="market-map" element={<Navigate to="/login" />} />
//             // null
//           )
//         }
        

//         {/* PMS  */}
//         <Route path="pms" element={<PMSpage/>}>
//             <Route index path="" element={<PMShome/>}/>   
//         </Route>

        

//         {/* User Profile  */}
//         <Route path="profile" element={<UserPage/>}>
//             {/* <Route index path="" element={<PMShome/>}/>   */}
//             <Route index path='' element={<UserProfile/>}/>  
//             <Route path='editProfile' element={<UserProfileEdit/>}/>  
//             <Route path='userPersonalSetting' element={<UserPersonalSetting/>}/>  
//             <Route path='allEmployees' element={<AllEmployee/>}/>  
//             <Route path='allEmployees/addNewProfile' element={<AddEmployeeProfile/>}/>  
//             <Route path='allEmployees/importEmployees' element={<ImportEmployees/>}/>  
//         </Route>


//         {/* After login the user  */} 
//         <Route path="home" element={<FirstPage/>}/>
        
//         <Route path="*" element={<ErrorPage />} />

//       </Routes>
      
      
//     </BrowserRouter> 
//   )
// }


// export default MyRoutes;

import { createSlice } from '@reduxjs/toolkit';

const SideBarToggleSlice = createSlice({
  name: 'toggle',
  initialState: false,
  reducers: {
    sideBartoggle: (state) => !state,
  },
});



export const { sideBartoggle } = SideBarToggleSlice.actions;
export {SideBarToggleSlice};



const DataSelectionToggleSlice = createSlice({
  name: 'toggleDataSelection',
  initialState: false,
  reducers: {
    dataSelectionToggleFun: (state) => !state,
  },
});



export const { dataSelectionToggleFun } = DataSelectionToggleSlice.actions;
export {DataSelectionToggleSlice};



const FilterBoxToggleToggleSlice = createSlice({
  name: 'toggleFilterBoxToggle',
  initialState: false,
  reducers: {
    filterBoxToggleToggleFun: (state) => !state,
  },
});



export const { filterBoxToggleToggleFun } = FilterBoxToggleToggleSlice.actions;
export {FilterBoxToggleToggleSlice};


// const DataSelectionToggleSlice = createSlice({
//   name: 'toggleDataSelection',
//   initialState: false,
//   reducers: {
//     dataSelectionToggleFun: (state) => !state,
//   },
// });



// export const { dataSelectionToggleFun } = DataSelectionToggleSlice.actions;
// export {DataSelectionToggleSlice};
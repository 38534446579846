import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;
const serviceURL5 = config.SERVICE_5_URL;
const serviceURL6 = config.SERVICE_6_URL;
const serviceURL7 = config.SERVICE_7_URL;
const serviceURL8 = config.SERVICE_8_URL;

const createProductSlice = createSlice({
    name : "createProduct",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


const getAllProductSlice = createSlice({
    name : "getAllProducts",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Create Product == Product post API
export const createProduct = createAsyncThunk( "product/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL1}/product/saveProduct`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Products == Product get API
export const getAllProduct = createAsyncThunk( "product/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL1}/product/getAllProducts/${className}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



const getOneProductSlice = createSlice({
    name : "getOneProduct",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Product
export const getOneProduct = createAsyncThunk( "Product/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,productId: data.productId}
        };

        const responce = await axios.get(`${serviceURL2}/product/getProduct`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// ============================ Retailer ============================

const createRetailerSlice = createSlice({
    name : "createRetailer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllRetailerSlice = createSlice({
    name : "getAllretailers",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Retailer == Retailer post API
export const createRetailer = createAsyncThunk( "retailer/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL2}/retailers/saveRetailer`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Retailers == Retailer get API
export const getAllRetailer = createAsyncThunk( "retailer/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL2}/retailers/getAllRetailers/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


const getOneRetailerSlice = createSlice({
    name : "getOneRetailer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Retailer
export const getOneRetailer = createAsyncThunk( "Retailer/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,retailerId: data.retailerId}
        };

        const responce = await axios.get(`${serviceURL2}/retailers/getRetailer`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// ========================== Manufacturer ================================ 

const createManufacturerSlice = createSlice({
    name : "createManufacturer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllManufacturerSlice = createSlice({
    name : "getAllManufacturer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Manufacturer == Manufacturer post API
export const createManufacturer = createAsyncThunk( "manufacturer/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL3}/manufacturers/saveManufacturer`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Manufacturers == Manufacturer get API
export const getAllManufacturer = createAsyncThunk( "manufacturers/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL3}/manufacturers/getAllManufacturers/${className}`,config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOneManufacturerSlice = createSlice({
    name : "getOneManufacturer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Manufacturer
export const getOneManufacturer = createAsyncThunk( "Manufacturer/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,manufacturerId: data.manufacturerId}
        };

        const responce = await axios.get(`${serviceURL3}/manufacturers/getManufacturer`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})











// ========================== Supplier ================================ 

const createSupplierSlice = createSlice({
    name : "createSupplier",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createSupplier.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createSupplier.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createSupplier.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllSupplierSlice = createSlice({
    name : "getAllSupplier",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllSupplier.pending, (state, action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllSupplier.fulfilled, (state, action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllSupplier.rejected, (state, action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Supplier == Supplier post API
export const createSupplier = createAsyncThunk( "supplier/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL4}/suppliers/saveSupplier`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Suppliers == Supplier get API
export const getAllSupplier = createAsyncThunk( "supplier/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL4}/suppliers/getAllSuppliers/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOneSupplierSlice = createSlice({
    name : "getOneSupplier",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneSupplier.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneSupplier.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneSupplier.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Supplier
export const getOneSupplier = createAsyncThunk( "Supplier/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,supplierId: data.supplierId}
        };

        const responce = await axios.get(`${serviceURL4}/suppliers/getSupplier`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})












// ========================== Packaging ================================ 

const createPackagingSlice = createSlice({
    name : "createPackaging",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetPackagingState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createPackaging.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createPackaging.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createPackaging.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllPackagingSlice = createSlice({
    name : "getAllPackaging",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllPackagingState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllPackaging.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllPackaging.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllPackaging.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Packaging == Packaging post API
export const createPackaging = createAsyncThunk( "packaging/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL5}/packagings/savePackaging`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Packagings == Packaging get API
export const getAllPackaging = createAsyncThunk( "packaging/getAll", async ( className, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL5}/packagings/getAllPackagings/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOnePackagingSlice = createSlice({
    name : "getOnePackaging",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOnePackaging.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOnePackaging.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOnePackaging.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Packaging
export const getOnePackaging = createAsyncThunk( "Packaging/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,packagingId: data.packagingId}
        };

        const responce = await axios.get(`${serviceURL5}/packagings/getPackaging`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// ========================== Promotion ================================ 

const createPromotionSlice = createSlice({
    name : "createPromotion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetPromotionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createPromotion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createPromotion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createPromotion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllPromotionSlice = createSlice({
    name : "getAllPromotion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllPromotionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllPromotion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllPromotion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllPromotion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Promotion == Promotion post API
export const createPromotion = createAsyncThunk( "promotion/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL6}/promotions/savePromotion`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Promotions == Promotion get API
export const getAllPromotion = createAsyncThunk( "promotion/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL6}/promotions/getAllPromotions/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOnePromotionSlice = createSlice({
    name : "getOnePromotion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOnePromotion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOnePromotion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOnePromotion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Promotion
export const getOnePromotion = createAsyncThunk( "Promotion/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,promotionId: data.promotionId}
        };

        const responce = await axios.get(`${serviceURL6}/promotions/getPromotion`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// ========================== Region ================================ 

const createRegionSlice = createSlice({
    name : "createRegion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetRegionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createRegion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createRegion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createRegion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllRegionSlice = createSlice({
    name : "getAllRegion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllRegionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllRegion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllRegion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllRegion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Create Region == Region post API
export const createRegion = createAsyncThunk( "region/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL7}/regions/saveRegion`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Regions == Region get API
export const getAllRegion = createAsyncThunk( "region/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL7}/regions/getAllRegions/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOneRegionSlice = createSlice({
    name : "getOneRegion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneRegion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneRegion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneRegion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Region
export const getOneRegion = createAsyncThunk( "Region/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,regionId: data.regionId}
        };

        const responce = await axios.get(`${serviceURL7}/regions/getRegion`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// ========================== Group ================================ 

const createGroupSlice = createSlice({
    name : "createGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

const getAllGroupSlice = createSlice({
    name : "getAllGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(getAllGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Group == Group post API
export const createGroup = createAsyncThunk( "group/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL8}/group/saveGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Get all Groups == Group get API
export const getAllGroup = createAsyncThunk( "group/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL8}/group/getAllGroups/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getOneGroupSlice = createSlice({
    name : "getOneGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Group
export const getOneGroup = createAsyncThunk( "Group/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,groupId: data.groupId}
        };

        const responce = await axios.get(`${serviceURL8}/group/getGroup`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})











export {
    createProductSlice,
    getAllProductSlice,
    getOneProductSlice,

    createRetailerSlice,
    getAllRetailerSlice,
    getOneRetailerSlice,

    createManufacturerSlice,
    getAllManufacturerSlice,
    getOneManufacturerSlice,


    createSupplierSlice,
    getAllSupplierSlice,
    getOneSupplierSlice,


    createPackagingSlice,
    getAllPackagingSlice,
    getOnePackagingSlice,


    createPromotionSlice,
    getAllPromotionSlice,
    getOnePromotionSlice,


    createRegionSlice,
    getAllRegionSlice,
    getOneRegionSlice,


    createGroupSlice,
    getAllGroupSlice,
    getOneGroupSlice,


}

export const {resetProductState} = createProductSlice.actions;
export const {resetRetailerState} = createRetailerSlice.actions;
export const {resetManufacturerState} = createManufacturerSlice.actions;
export const {resetSupplierState} = createSupplierSlice.actions;
export const {resetPackagingState} = createPackagingSlice.actions;
export const {resetPromotionState} = createPackagingSlice.actions;
export const {resetRegionState} = createRegionSlice.actions;
export const {resetGroupState} = createGroupSlice.actions;


export const {resetGetAllProductState} = getAllProductSlice.actions;
export const {resetGetAllRetailerState} = getAllRetailerSlice.actions;
export const {resetGetAllManufacturerState} = getAllManufacturerSlice.actions;
export const {resetGetAllSupplierState} = getAllSupplierSlice.actions;
export const {resetGetAllPackagingState} = getAllPackagingSlice.actions;
export const {resetGetAllPromotionState} = getAllPromotionSlice.actions;
export const {resetGetAllRegionState} = getAllRegionSlice.actions;
export const {resetGetAllGroupState} = getAllGroupSlice.actions;
import React, { useEffect, useState } from "react";
import "./TableHomeCommon.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader/Loader";
import STATUSES from "../../../../store/Statuses";
import TableHomeButtonHeader from "../../MarketMapsComponents/TableHomeButtonHeader/TableHomeButtonHeader";
import ErrorBox from "../../../Common/ErrorBox/ErrorBox";
import RenderWarning from "../../MarketMapsComponents/RenderWarning/RenderWarning";
import UpdateTable from "../UpdateTable/UpdateTable";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Star from "../NoDataInTable/ShowMandatoryStarInTable";
import Edit from "../../../../assets/Images/Edit.svg";
import Delete from "../../../../assets/Images/delete.svg";

const TableHomeCommon = ({
  title,
  getAllTableDataLink,
  getAllAttributesLink,
  getOneAttributesLink,
  getAllTableDataSelector,
  allAttributesGroupSelector,
  oneAttributeGroupSelector,
  classNamePrefix,
  additionalAttributesName,
  getID,
  getImageLink,
  getImagesSelector,
  getImagesStatusSelector,
  add_button_url,
  edit_button_url,
  deleteLink,
  deleteSelector,
  resetDeleteState,

  createTablelink,
  resetTableState,
  tableDataSelector,
  linkForRedirectSetting,
  getAllDataForReferenceLink,
  getAllDataForReference,
  ImageUploadLink,
  getOneTableItemLink,
  getOnetableItemSelector,
  updateTableItemLink,
  updateTableItemSelector,
  resetUpdateItemState,
  ImageUpdateLink,
}) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [imageStatus, setImageStatus] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const userDetails = useSelector((state) => state.auth.userDetails);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // [1] Get all attributes list
  useEffect(() => {
    dispatch(getAllAttributesLink({}));
  }, [dispatch]);

  const {
    data: AttributesGroupsData,
    status: AttributeGroupStatus,
    error: AttributeGroupError,
  } = useSelector(allAttributesGroupSelector);

  // [2] Get single attributes list by selected groupName
  const [groupNameToShowAttributes, setGroupNameToShowAttributes] =
    useState("");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(1);

  const handleGroupSelection = (e, groupName, selectedIndex) => {
    setGroupNameToShowAttributes(groupName);
    setSelectedGroupIndex(selectedIndex + 1);
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (AttributesGroupsData.length > 0) {
      setGroupNameToShowAttributes(AttributesGroupsData[0].groupName);
      setSelectedGroupIndex(1);
    }
  }, [AttributesGroupsData]);

  useEffect(() => {
    if (groupNameToShowAttributes.length > 0) {
      dispatch(getOneAttributesLink(groupNameToShowAttributes));
    }
  }, [dispatch, groupNameToShowAttributes]);

  const {
    data: singleAttributeGroupData,
    status: singleAttributeGroupStatus,
    error: singleAttributeGroupError,
  } = useSelector(oneAttributeGroupSelector);

  const {
    groupAttributes,
    [additionalAttributesName]: additionalAttributes,
    imagesAttributes,
  } = singleAttributeGroupData;

  // [3] Get all data according to selected group name
  const classNameToFetch =
    selectedGroupIndex === 1
      ? classNamePrefix
      : `${classNamePrefix}${selectedGroupIndex}`;

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      dispatch(getAllTableDataLink(classNameToFetch));
    }
  }, [dispatch, singleAttributeGroupStatus, selectedGroupIndex]);

  const { data, status, error } = useSelector(getAllTableDataSelector);
  const imageUrls = useSelector(getImagesSelector);
  // console.log(additionalAttributes)

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      if (status === STATUSES.SUCCESS) {
        if (typeof data !== "string") {
          data.forEach((item) => {
            const id = item[getID];
            const attributes = {
              ...groupAttributes,
              ...additionalAttributes,
            };
            Object.keys(attributes || {}).forEach((attributeKey) => {
              if (attributes[attributeKey].type === "Image") {
                setImageStatus((prevState) => ({
                  ...prevState,
                  [`${id}_${attributeKey}`]: STATUSES.LOADING,
                }));
                dispatch(getImageLink({ [getID]: id, imageName: attributeKey }))
                  .then(() => {
                    setImageStatus((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: STATUSES.SUCCESS,
                    }));
                  })
                  .catch((err) => {
                    setImageStatus((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: STATUSES.ERROR,
                    }));
                    setImageErrors((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: err.message,
                    }));
                  });
              }
            });
          });
        }
      }
    }
  }, [
    data,
    singleAttributeGroupData,
    singleAttributeGroupStatus,
    status,
    dispatch,
  ]);

  const [updateTableToggle, setUpdateTableToggle] = useState(false);
  const [sendID, setSendID] = useState("");

  // console.log(sendID)

  const handelUpdateTableToggle = (id) => {
    setUpdateTableToggle(!updateTableToggle);
    if (id) {
      setSendID(id);
    }
    console.log(id);

    // hide scrollBar
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const handleDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this, it will be deleted from the program also"
      )
    ) {
      dispatch(deleteLink({ [getID]: id, className: classNameToFetch }));
    }
  };
  const {
    data: deleteData,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deleteSelector);

  useEffect(() => {
    if (deleteData && deleteStatus === STATUSES.SUCCESS) {
      toast.success(deleteData);
      dispatch(resetDeleteState({}));
      // Fetch the updated list of all after deletion
      dispatch(getAllTableDataLink(classNameToFetch));
    }
    if (deleteStatus === STATUSES.ERROR) {
      toast.error(deleteError ? deleteError : "Something went Wrong");
      dispatch(resetDeleteState({}));
    }
  }, [deleteData, deleteStatus, deleteError, dispatch, classNameToFetch]);

  const [showOperationsButton, setShowOperationsButton] = useState(false);
  const handleShowOperationsButton = () => {
    setShowOperationsButton(!showOperationsButton);
  };
  // console.log("groupNameToShowAttributes ------------- ", groupNameToShowAttributes)

  return (
    <>
      <div className="common_table_page">
        <TableHomeButtonHeader
          add_button_url={add_button_url}
          table_heading={title}
          data={data}
          status={status}
          showOperationsButton={showOperationsButton}
          handleShowOperationsButton={handleShowOperationsButton}
          handleGroupSelection={handleGroupSelection}
          AttributesGroupsData={AttributesGroupsData}
          groupNameToShowAttributes={groupNameToShowAttributes}
        />

        <div className="market_table_container 4">
          <div className="market_table">
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <Loader />
            ) : AttributeGroupStatus === STATUSES.ERROR ? (
              <ErrorBox message={AttributeGroupError} />
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              AttributesGroupsData?.length === 0 ? (
                <RenderWarning message={`No data found, please add data`} />
              ) : (
                <>
                  {/* <div
                    className={`dropdown-icon ${dropdownOpen ? "open" : ""}`}
                    onClick={toggleDropdown}
                  >
                    {" "}
                    &#8645;
                  </div>

                  {dropdownOpen && (
                    <div className={`dropdown ${dropdownOpen ? "" : "closed"}`}>
                      <div className="dropdown-options">
                        {AttributesGroupsData.map((item, index) => (
                          <div
                            className={`option ${
                              groupNameToShowAttributes === item.groupName
                                ? "selected"
                                : ""
                            }`}
                            key={item.groupId}
                            onClick={(e) =>
                              handleGroupSelection(e, item.groupName, index)
                            }
                          >
                            {item.groupName}
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}

                  {singleAttributeGroupStatus === STATUSES.LOADING ? (
                    <Loader />
                  ) : singleAttributeGroupStatus === STATUSES.ERROR ? (
                    <ErrorBox message={singleAttributeGroupError?.message} />
                  ) : singleAttributeGroupStatus === STATUSES.SUCCESS ? (
                    status === STATUSES.LOADING ? (
                      <Loader />
                    ) : status === STATUSES.ERROR ? (
                      <ErrorBox message={error?.message} />
                    ) : status === STATUSES.SUCCESS ? (
                      data && typeof data === "string" ? (
                        <RenderWarning
                          message={`No data found in ${groupNameToShowAttributes}`}
                        />
                      ) : (
                        <>
                          <table>
                            <tbody>
                              {showOperationsButton ? (
                                <tr>
                                  <td>Operations</td>
                                  {data.map((item, i) => (
                                    <td key={i}>
                                      <div className="edit_btns_container">
                                        <i
                                          onClick={() =>
                                            handelUpdateTableToggle(item[getID])
                                          }
                                        >
                                          {/* <FontAwesomeIcon icon={faEdit} /> */}
                                          <img src={Edit} className="w22" />
                                        </i>
                                        {/* <i onClick={() => handleDelete(item[getID])}><FontAwesomeIcon icon={faTrash}/></i> */}
                                        {userDetails?.authorities[0]
                                          ?.authority === "ROLE_ADMIN" ||
                                        userDetails?.authorities[0]
                                          ?.authority === "ROLE_MANAGER" ? (
                                          <i
                                            onClick={() =>
                                              handleDelete(item[getID])
                                            }
                                          >
                                            {/* <FontAwesomeIcon icon={faTrash} /> */}
                                            <img src={Delete} className="w22" />
                                          </i>
                                        ) : (
                                          <i
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content="Only admin can access this feature."
                                            disabled
                                          >
                                            {/* <FontAwesomeIcon icon={faTrash} /> */}
                                            <img src={Delete} className="w22" />
                                          </i>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                                </tr>
                              ) : null}

                              {Object.keys(groupAttributes).map(
                                (attributeKey, index) => (
                                  <React.Fragment
                                    key={`${attributeKey}_${index}`}
                                  >
                                    <tr>
                                      <td>
                                        {
                                          groupAttributes[attributeKey]
                                            .nametoDisplay
                                        }
                                        <Star
                                          mandatory={
                                            groupAttributes[attributeKey]
                                              .mandatory
                                          }
                                        />
                                      </td>
                                      {data.map((item, dataIndex) => (
                                        <td key={dataIndex}>
                                          {groupAttributes[attributeKey]
                                            .type === "Image" ? (
                                            imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.LOADING ? (
                                              "Loading..."
                                            ) : imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.ERROR ? (
                                              "Something went wrong"
                                            ) : imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.SUCCESS ? (
                                              imageUrls[
                                                `${item[getID]}_${attributeKey}`
                                              ] ? (
                                                <img
                                                  src={
                                                    imageUrls[
                                                      `${item[getID]}_${attributeKey}`
                                                    ]
                                                  }
                                                  alt="images of data"
                                                />
                                              ) : (
                                                "No image found"
                                              )
                                            ) : null
                                          ) : item.groupAttributes[
                                              attributeKey
                                            ] &&
                                            item.groupAttributes[attributeKey]
                                              .length > 0 ? (
                                            item.groupAttributes[
                                              attributeKey
                                            ].join(", ")
                                          ) : (
                                            "Not found"
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                    {groupAttributes[attributeKey].type ===
                                      "NestedDropdown" && (
                                      <tr
                                        key={`${attributeKey}_${index}_additional`}
                                      >
                                        <td>
                                          {
                                            groupAttributes[attributeKey]
                                              .nametoDisplay2
                                          }
                                          <Star
                                            mandatory={
                                              groupAttributes[attributeKey]
                                                .mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {item.groupAttributes[
                                              `${attributeKey}nEsTeD`
                                            ] &&
                                            item.groupAttributes[
                                              `${attributeKey}nEsTeD`
                                            ].length > 0
                                              ? item.groupAttributes[
                                                  `${attributeKey}nEsTeD`
                                                ].join(", ")
                                              : "Not found"}
                                          </td>
                                        ))}
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )
                              )}

                              {Object.keys(additionalAttributes).map(
                                (attributeKey, index) => (
                                  <React.Fragment
                                    key={`${attributeKey}_${index}`}
                                  >
                                    <tr>
                                      <td>
                                        {
                                          additionalAttributes[attributeKey]
                                            .nametoDisplay
                                        }{" "}
                                        <Star
                                          mandatory={
                                            additionalAttributes[attributeKey]
                                              .mandatory
                                          }
                                        />
                                      </td>
                                      {data.map((item, dataIndex) => (
                                        <td key={dataIndex}>
                                          {additionalAttributes[attributeKey]
                                            .type === "Image" ? (
                                            imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.LOADING ? (
                                              "Loading..."
                                            ) : imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.ERROR ? (
                                              <ErrorBox
                                                message={
                                                  imageErrors[
                                                    `${item[getID]}_${attributeKey}`
                                                  ] || "Something went wrong"
                                                }
                                              />
                                            ) : imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.SUCCESS ? (
                                              imageUrls[
                                                `${item[getID]}_${attributeKey}`
                                              ] ? (
                                                <img
                                                  src={
                                                    imageUrls[
                                                      `${item[getID]}_${attributeKey}`
                                                    ]
                                                  }
                                                  alt="images of data"
                                                />
                                              ) : (
                                                "No image found"
                                              )
                                            ) : null
                                          ) : item[additionalAttributesName][
                                              attributeKey
                                            ] &&
                                            item[additionalAttributesName][
                                              attributeKey
                                            ].length > 0 ? (
                                            item[additionalAttributesName][
                                              attributeKey
                                            ].join(", ")
                                          ) : (
                                            "Not found"
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                    {additionalAttributes[attributeKey].type ===
                                      "NestedDropdown" && (
                                      <tr
                                        key={`${attributeKey}_${index}_additional`}
                                      >
                                        <td>
                                          {
                                            additionalAttributes[attributeKey]
                                              .nametoDisplay2
                                          }{" "}
                                          <Star
                                            mandatory={
                                              additionalAttributes[attributeKey]
                                                .mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {item[additionalAttributesName][
                                              `${attributeKey}nEsTeD`
                                            ] &&
                                            item[additionalAttributesName][
                                              `${attributeKey}nEsTeD`
                                            ].length > 0
                                              ? item[additionalAttributesName][
                                                  `${attributeKey}nEsTeD`
                                                ].join(", ")
                                              : "Not found"}
                                          </td>
                                        ))}
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )
                              )}

                              {Object.keys(imagesAttributes).map((imageKey) => {
                                const { groupsName, attgroups } =
                                  imagesAttributes[imageKey];
                                return (
                                  <React.Fragment key={imageKey}>
                                    {Object.keys(groupsName).map(
                                      (groupName, index) => {
                                        return (
                                          <React.Fragment
                                            key={`${imageKey}-${groupName}`}
                                          >
                                            {Object.keys(attgroups).map(
                                              (attribute, index) => (
                                                <React.Fragment
                                                  key={`${groupName}-${attribute}`}
                                                >
                                                  <tr>
                                                    <td>{`${groupsName[groupName].nametoDisplay} ${attgroups[attribute].nametoDisplay}`}</td>
                                                    {data.map(
                                                      (item, dataIndex) => {
                                                        return (
                                                          <td key={dataIndex}>
                                                            {item
                                                              .imagesAttributes[
                                                              `${groupName} ${attribute}`
                                                            ] !== undefined &&
                                                            item
                                                              .imagesAttributes[
                                                              `${groupName} ${attribute}`
                                                            ].length > 0
                                                              ? item.imagesAttributes[
                                                                  `${groupName} ${attribute}`
                                                                ].join(", ")
                                                              : "Not found"}
                                                          </td>
                                                        );
                                                      }
                                                    )}
                                                  </tr>
                                                  {attgroups[attribute].type ===
                                                    "NestedDropdown" && (
                                                    <tr>
                                                      <td>{`${groupsName[groupName].nametoDisplay} ${attgroups[attribute].nametoDisplay2}`}</td>
                                                      {data.map(
                                                        (item, dataIndex) => {
                                                          return (
                                                            <td key={dataIndex}>
                                                              {item
                                                                .imagesAttributes[
                                                                `${groupName} ${attribute}nEsTeD`
                                                              ] !== undefined &&
                                                              item
                                                                .imagesAttributes[
                                                                `${groupName} ${attribute}nEsTeD`
                                                              ].length > 0
                                                                ? item.imagesAttributes[
                                                                    `${groupName} ${attribute}nEsTeD`
                                                                  ].join(", ")
                                                                : "Not found"}
                                                            </td>
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </>
                      )
                    ) : null
                  ) : null}
                </>
              )
            ) : null}
          </div>
        </div>
      </div>

      {updateTableToggle ? (
        <UpdateTable
          title={title}
          updateTableToggle={updateTableToggle}
          handelUpdateTableToggle={handelUpdateTableToggle}
          getAllAttributesLink={getAllAttributesLink}
          getOneAttributesLink={getOneAttributesLink}
          createTablelink={createTablelink}
          resetTableState={resetTableState}
          tableDataSelector={tableDataSelector}
          allAttributesGroupSelector={allAttributesGroupSelector}
          oneAttributeGroupSelector={oneAttributeGroupSelector}
          classNamePrefix={classNamePrefix}
          linkForRedirectSetting={linkForRedirectSetting}
          additionalAttributesName={additionalAttributesName}
          getAllDataForReferenceLink={getAllDataForReferenceLink}
          getAllDataForReference={getAllDataForReference}
          getID={getID}
          sendID={sendID}
          recivedGroupNameToShowAttributes={groupNameToShowAttributes}
          recivedSelectedGroupIndex={selectedGroupIndex}
          getOneTableItemLink={getOneTableItemLink}
          getOnetableItemSelector={getOnetableItemSelector}
          updateTableItemLink={updateTableItemLink}
          updateTableItemSelector={updateTableItemSelector}
          resetUpdateItemState={resetUpdateItemState}
          ImageUploadLink={ImageUploadLink}
          ImageUpdateLink={ImageUpdateLink}
          getImageLink={getImageLink}
          getImagesSelector={getImagesSelector}
          getImagesStatusSelector={getImagesStatusSelector}
        />
      ) : null}
    </>
  );
};

export default TableHomeCommon;

import React from "react";
import "./MarketMapHome.css";
import AdminHomeData from "../../MarketMapsComponents/MarketMapHomeData/AdminHomeData/AdminHomeData";
import { useSelector } from "react-redux";
import SingleUserHomeData from "../../MarketMapsComponents/MarketMapHomeData/SingleUserHomeData/SingleUserHomeData";

const MarketMapHome = () => {

  const userDetails = useSelector((state) => state.auth.userDetails);

  
  return <div className="marketmap_home">

    {
      // userDetails?.authorities[0]?.authority === "ROLE_ADMIN" ? <AdminHomeData/> : <SingleUserHomeData/> 
      userDetails?.authorities[0]?.authority === "ROLE_ADMIN" ? <AdminHomeData/> : null
    }
    
 


  </div>;
};

export default MarketMapHome;

import React, { useEffect, useState } from 'react';
import './ChangeGroupName.css';
import { LoaderCircle } from '../../../Common/Loader/Loader';
import STATUSES from '../../../../store/Statuses';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const ChangeGroupName = ({ GroupNameBoxToggle, handleGroupNameBoxToggle, sendGroupName, sendGroupDescription, ChangeGroupNameAPI, getChangedGroupName, ResetChangedGroupAPIName }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    handleGroupNameBoxToggle();
    document.body.style.overflow = 'unset';
  };

  const [groupName, setGroupName] = useState(sendGroupName);
  const [groupDescription, setGroupDescription] = useState(sendGroupDescription);

  useEffect(() => {
    setGroupName(sendGroupName);
    setGroupDescription(sendGroupDescription);
  }, [sendGroupName, sendGroupDescription]);

  const handleSubmit = () => {
    if (groupName.trim().length === 0 || groupDescription.trim().length === 0) {
      toast.warning("Please enter the group name and description");
    } else if (groupName.trim() === sendGroupName && groupDescription.trim() === sendGroupDescription) {
      toast.warning("No changes detected");
    } else {
      const dataToSave = { OldGroupName: sendGroupName, groupName: groupName.trim(), groupDescription: groupDescription.trim() };
      dispatch(ChangeGroupNameAPI(dataToSave));
      console.log(dataToSave);
    }
  };

  const { status, error } = useSelector(getChangedGroupName);

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      toast.success("Updated Successfully");
      dispatch(ResetChangedGroupAPIName({}));
      handleClose();
      setGroupName("");
      setGroupDescription("");
    }
    if (status === STATUSES.ERROR) {
      toast.error(error ? error : "Something went wrong");
      dispatch(ResetChangedGroupAPIName({}));
    }
  }, [status, error, dispatch]);

  return (
    <div className='change_group_name'>
      <div className={GroupNameBoxToggle ? 'change_group_name_container' : 'change_group_name_container close'}>
        <h6>Update Group Name <strong> - {sendGroupName}</strong></h6>
        <div className="dropdown-options">
          <div className='_input_box'>
            <input className='table_input' type="text" required name='groupName' value={groupName} onChange={(e) => setGroupName(e.target.value)} />
            <span>Enter group name</span>
          </div>

          <div className='_input_box'>
            <textarea className='table_input' type="text" required name='groupDescription' value={groupDescription} onChange={(e) => setGroupDescription(e.target.value)} />
            <span>Enter group description</span>
          </div>

          <div className="empty_btn_container">
            <button className={status === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} onClick={handleSubmit} disabled={status === STATUSES.LOADING}>
              {status === STATUSES.LOADING ? <LoaderCircle /> : "Save"}
            </button>
            <button className='common_btn2' onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeGroupName;

import React from 'react';
import './ShowFilterQuerys.css';
import { filterBoxToggleToggleFun } from '../../../../../store/Slices/sideBarSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faMultiply } from '@fortawesome/free-solid-svg-icons';
import { addSelectedQuery, removeSelectedQuery } from '../../../../../store/Slices/marketMapShortedDataSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';


const ShowFilterQuerys = () => {
    
  const dispatch = useDispatch();

    const selectedQuerys = useSelector((state) => state.selectedQuerys);
    const selectedAttributesHeading = useSelector((state) => state.AllselectedAttributesHeading);
    const {tableName, groupName, productGroupNameForProgram} = useSelector((state) => state.DataSelectionNames);

  console.log(tableName)

  const removeValue = (key, valueToRemove) => {
    const currentSelections = selectedQuerys[key]?.value || [];
    const updatedSelections = currentSelections.filter((value) => value !== valueToRemove);

    if (updatedSelections.length === 0) {
      dispatch(removeSelectedQuery({ key }));
    } else {
      dispatch(addSelectedQuery({
        key,
        nametoDisplay: selectedAttributesHeading.find(attr => attr.key === key)?.nametoDisplay || '',
        value: updatedSelections,
      }));
    }
  };
  const totalSelectedValues = Object.values(selectedQuerys)
    .flatMap(({ value }) => value)
    .length;

  return (
    <>

   
    <div className="selected-values">

     <h6>{tableName === "Program" ? productGroupNameForProgram : tableName === "Product" ?groupName :null}</h6>


     <i className='filter_icon_for_data' onClick={() => dispatch(filterBoxToggleToggleFun())}><FontAwesomeIcon icon={faFilter}/> 
     <span>{totalSelectedValues}</span></i>

        {Object.entries(selectedQuerys).map(([key, { value }]) => (
        <div key={key} className='selected_on_query'>
        {value.map((val) => (
            <div key={val} className="selected-value">
            <span>{val}</span>
            <button
            className='reomve_btn_querry'
                onClick={() => removeValue(key, val)}
            >
               <FontAwesomeIcon icon={faMultiply} />
            </button>
            </div>
        ))}
        </div>
        ))}
    </div>
    </>
  )
}

export default ShowFilterQuerys;

import React, { useEffect, useState } from 'react';
import './AllAttributes.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd,faPencil } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import { createPromotionAttributeGroup, getAllPromotionAttributes, getOnePromotionAttributes, resetPromotionGroupState, resetUpdatePromotionGroupNameState, resetUpdatePromotionGroupState, updatePromotionAttributeGroup, updatePromotionGroupName } from '../../../../store/Slices/marketMapAttributeSlice';
import { useSelector } from 'react-redux';
import SingleAttributeGroup from '../SingleAttributeGroup/SingleAttributeGroup';
import STATUSES from '../../../../store/Statuses';
import CreateAttributeGroup from '../CreateAttributeGroup/CreateAttributeGroup';
import {PromotionTableMainAttributes} from '../../Data';
import ErrorBox from '../../../Common/ErrorBox/ErrorBox';
import RenderWarning from '../../MarketMapsComponents/RenderWarning/RenderWarning';
import Loader, { LoaderCircle } from '../../../Common/Loader/Loader';
import { toast } from 'react-toastify';
import ChangeGroupName from '../ChangeGroupName/ChangeGroupName';




const MarketingCallOutAtt = () => {

  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.auth.userDetails);
    
  const {data:AllAttributesGroups  , status:AllAttributesGroupsStatus ,error:AllAttributesGroupsError} = useSelector((state) => state.AllPromotionAttributeGroup);
  console.log(AllAttributesGroups);

  
  // SingleAttributeGroup toggle 
  const [singleAttributeGroupToggle, setsingleAttributeGroupToggle] = useState(false);
  const [sendGroupName ,setSendGroupName] = useState("");
  const handelsingleAttributeGroupToggle = (e) => {
    setsingleAttributeGroupToggle(!singleAttributeGroupToggle);
    setSendGroupName(e)
    // hide scrollBar 
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };
  
  // CreateAttributeGroup toggle 
  const [createAttributeGroupToggle, setCreateAttributeGroupToggle] = useState(false);
  const handelCreateAttributeGroupToggle = (e) => {
    setCreateAttributeGroupToggle(!createAttributeGroupToggle);
    setSendGroupName(e)
    // hide scrollBar 
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  // GroupNameBoxToggle toggle 
  const [GroupNameBoxToggle, setGroupNameBoxToggle] = useState(false);
  const [sendGroupDescription ,setSendGroupDescription] = useState("");
   const handleGroupNameBoxToggle = (e, description) => {
    setGroupNameBoxToggle(!GroupNameBoxToggle);
    setSendGroupName(e);
    setSendGroupDescription(description);
       // hide scrollBar 
    if (typeof window !== 'undefined' && window.document) {
    document.body.style.overflow = 'hidden';
    }
  };
  

  const ChangeGroupNameAPI = updatePromotionGroupName;
  const getChangedGroupName = (state) => state.UpdatePromotionGroupName;
  const ResetChangedGroupAPIName = resetUpdatePromotionGroupNameState;

  useEffect(()=>{
    dispatch(getAllPromotionAttributes({}));
  },[dispatch,createAttributeGroupToggle ,GroupNameBoxToggle]);

  // create group 
  const SaveGroupAPIName = updatePromotionAttributeGroup;
  const ResetGroupAPIName = resetUpdatePromotionGroupState;
  const getGroupAPIName = (state) => state.UpdatePromotionAttributeGroup;
  const TableMainAttributes = PromotionTableMainAttributes;

  // get single group 
  const GetSingleGroupAPI = getOnePromotionAttributes;
  const GetSingleGroupDataAPI = (state) => state.OnePromotionAttributesGroup;


  // Creaet a Empty group ======
  const [groupName, setGroupName] = useState("")
  const [groupDescription, setGroupDescription] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
  };

  const handleSubmitEmptyGroup = ()=>{
    if(groupName.trim().length===0 || groupDescription.trim().length===0){
      toast.warning("Please enter the group name and description");
    }
    else{
      const dataToSave = { groupName:groupName.trim(),groupDescription :groupDescription.trim(), groupAttributes:{},promotionAdditionalAttributes:{} ,imagesAttributes: {}}
      dispatch(createPromotionAttributeGroup(dataToSave));
      console.log(dataToSave);
    }
  }
  
  const handleCloseDropDown = ()=>{
    setGroupName("");
    setGroupDescription("");
    toggleDropdown();
  }

  const { status:EmptyGroupStatus, error:EmptyGroupError} = useSelector((state) => state.PromotionAttributeGroup);

  useEffect(()=>{
    if (EmptyGroupStatus===STATUSES.SUCCESS) {
      toast.success("Created Successfully");
      dispatch(resetPromotionGroupState({}));
      toggleDropdown();
      dispatch(getAllPromotionAttributes({}));
      setGroupName("");
      setGroupDescription("");
    }
    if(EmptyGroupStatus === STATUSES.ERROR){
      toast.error(EmptyGroupError ? EmptyGroupError : "Something went wrong");
      dispatch(resetPromotionGroupState({}));
    }
  }, [EmptyGroupStatus ,EmptyGroupError,dispatch, resetPromotionGroupState]);

   
 
  return (
    <div className='attributes_page'>

      <div className='att_group_list'>
          <h6 className='header'>All Marketing Callouts
  
           {
            userDetails?.authorities[0]?.authority === "ROLE_ADMIN"  ? (
              <div className={`dropdown-icon ${dropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}><FontAwesomeIcon icon={faAdd}/> Create </div>
            ) : (
              <div className={`dropdown-icon`} data-tooltip-id="tooltip" data-tooltip-content="Only admin can access this feature." disabled ><FontAwesomeIcon icon={faAdd}/> Create </div>
            )
          }
          
          {dropdownOpen && (
              <div className={`dropdown ${dropdownOpen ? '' : 'closed'}`}>
              <div className="dropdown-options">
                  <div className='_input_box'>
                    <input className='table_input' type="text" required name='groupName' onChange={(e) => setGroupName(e.target.value)}/>
                    <span>Enter group name</span>
                  </div>
  
                  <div className='_input_box'>
                    <textarea  className='table_input' type="text" required name='groupDescription' onChange={(e) => setGroupDescription(e.target.value)}/>
                    <span>Enter group description</span>
                  </div>
                  <div className="empty_btn_container">
                    <button className={EmptyGroupStatus === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} onClick={handleSubmitEmptyGroup} disabled={EmptyGroupStatus === STATUSES.LOADING}> 
                      {EmptyGroupStatus === STATUSES.LOADING ? <LoaderCircle/> : "Save"} 
                    </button>  
                    <button className='common_btn2' onClick={handleCloseDropDown}>Close</button> 
                  </div>
              </div>
              </div>
          )}
          </h6>
  
  
  
          <div className="groups_of_attributes">
  
            {
              AllAttributesGroupsStatus === STATUSES.LOADING ? (
                <span><Loader/></span>
              ): AllAttributesGroupsStatus === STATUSES.ERROR? (
                // <h6></h6>
                <ErrorBox message={AllAttributesGroupsError?.message ? AllAttributesGroupsError?.message : "Something Went Wrong" }/>

              ): AllAttributesGroupsStatus === STATUSES.SUCCESS ? (
                  AllAttributesGroups.length === 0 ?(
                    <RenderWarning message={"No group found, please add group"}/>
                  ) : AllAttributesGroups.map((item ,index)=>
                  (
                    <div key={index} className='attributes_heading_box' >
                        <p>{item.groupName}</p>
                        <div className="attributes_btns_group">
                          {
                          userDetails?.authorities[0]?.authority === "ROLE_ADMIN"  ? (
                            <button className='add_attributes_btn' onClick={() => handelCreateAttributeGroupToggle(item.groupName)} >Add attributes</button>
                          ) : (
                            <button className='add_attributes_btn disabled' data-tooltip-id="tooltip" data-tooltip-content="Only admin can access this feature." disabled>
                              Add attributes
                            </button>
                          )
                        }
                          <button className='add_attributes_btn' onClick={()=> handelsingleAttributeGroupToggle(item.groupName)}  >All attributes</button>
                        </div>
                        {
                          userDetails?.authorities[0]?.authority === "ROLE_ADMIN"  ? (
                           
                            <button className="groupNameEdit" onClick={() => handleGroupNameBoxToggle(item.groupName, item.groupDescription)} ><FontAwesomeIcon icon={faPencil} /></button>
                          ) : (
                            <button className="groupNameEdit"  data-tooltip-id="tooltip" data-tooltip-content="Only admin can access this feature." disabled ><FontAwesomeIcon icon={faPencil} /></button>
                          )
                        }
                    </div>
                  )
                  )
                ) : null
            }
           
          </div>
          
        </div>
      
      

        {createAttributeGroupToggle ? (
        
        userDetails?.authorities[0]?.authority === "ROLE_ADMIN" ? (
        <CreateAttributeGroup
           handelCreateAttributeGroupToggle={handelCreateAttributeGroupToggle} 
           createAttributeGroupToggle={createAttributeGroupToggle} 
           groupHeadingName ={"Marketing Callout"}
           SaveGroupAPIName={SaveGroupAPIName}
           ResetGroupAPIName={ResetGroupAPIName}
           getGroupAPIName={getGroupAPIName}
           TableMainAttributes={TableMainAttributes}
           sendAdditionalAttributesAs={"promotionAdditionalAttributes"}
           sendGroupName={sendGroupName}
           GetSingleGroupAPI={GetSingleGroupAPI}
           GetSingleGroupDataAPI={GetSingleGroupDataAPI}
        /> 
      ): null
        
        
    ): null}
    
        
        {singleAttributeGroupToggle ? (
        <SingleAttributeGroup 
          handelsingleAttributeGroupToggle={handelsingleAttributeGroupToggle} 
          singleAttributeGroupToggle={singleAttributeGroupToggle} 
          sendGroupName={sendGroupName}
          GetSingleGroupAPI={GetSingleGroupAPI}
          GetSingleGroupDataAPI={GetSingleGroupDataAPI}
          groupHeadingName={"Marketing Callout"}
          receiveAdditionalAttributeAs = {"promotionAdditionalAttributes"}
          SaveGroupAPIName={SaveGroupAPIName}
          ResetGroupAPIName={ResetGroupAPIName}
          getGroupAPIName={getGroupAPIName}
        />
        ): null}

{GroupNameBoxToggle ? (
        userDetails?.authorities[0]?.authority === "ROLE_ADMIN" ? (
        <ChangeGroupName 
          sendGroupName={sendGroupName}
          sendGroupDescription={sendGroupDescription} 
          GroupNameBoxToggle={GroupNameBoxToggle}
          handleGroupNameBoxToggle={handleGroupNameBoxToggle}
          ChangeGroupNameAPI={ChangeGroupNameAPI}
          getChangedGroupName={getChangedGroupName}
          ResetChangedGroupAPIName={ResetChangedGroupAPIName}
        />
      ): null
        
        
    ): null}
    


    </div>
  )
}


export default MarketingCallOutAtt;
import React ,{ useEffect, useRef, useState } from "react";
import "./FilterBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
// import {t_data ,table_data} from '../../Data.js';
import { useSelector,useDispatch } from "react-redux";
import { filterBoxToggleToggleFun } from "../../../../store/Slices/sideBarSlice.js";

import { getAllGroupAttributes, getAllManufacturerAttributes, getAllPackagingAttributes, getAllProductAttributes, getAllProgramAttributes, getAllPromotionAttributes, getAllRegionAttributes, getAllRetailerAttributes, getAllSupplierAttributes, getOneGroupAttributes, getOneManufacturerAttributes, getOnePackagingAttributes, getOneProductAttributes, getOneProgramAttributes, getOnePromotionAttributes, getOneRegionAttributes, getOneRetailerAttributes, getOneSupplierAttributes, resetOneGroupAttributesGroupState, resetOneManufacturerAttributesGroupState, resetOnePackagingAttributesGroupState, resetOneProductAttributesGroupState, resetOneProgramAttributesGroupState, resetOnePromotionAttributesGroupState, resetOneRegionAttributesGroupState, resetOneRetailerAttributesGroupState, resetOneSupplierAttributesGroupState } from '../../../../store/Slices/marketMapAttributeSlice.js';
import STATUSES from '../../../../store/Statuses.js';
import { getAllGroup, getAllManufacturer, getAllPackaging, getAllProduct, getAllPromotion, getAllRegion, getAllRetailer, getAllSupplier, resetGetAllGroupState, resetGetAllManufacturerState, resetGetAllPackagingState, resetGetAllProductState, resetGetAllPromotionState, resetGetAllRegionState, resetGetAllRetailerState, resetGetAllSupplierState } from '../../../../store/Slices/marketMapTableSlices.js';
import Loader from '../../../Common/Loader/Loader.jsx';
import RenderWarning from '../RenderWarning/RenderWarning.jsx';
import { getAllProgram, resetGetAllProgramState } from '../../../../store/Slices/marketMapProgram.js';
import Select from 'react-select';
import { addSelectedQuery, clearAllSelectedQuerys, removeSelectedQuery ,setSelectedAttributesHeading } from '../../../../store/Slices/marketMapShortedDataSlice.js'; 


const FilterBox = () => {
  const dispatch = useDispatch();

 


  

    const DataMainHeading = ["Product","Program", "Retailer", "Manufacturer", "Supplier"];

    const [selectTable, setSelectTable] = useState('');

    const HandleSelectedTable = (event) => {
      setSelectTable(event.target.value);
      // clear states 
      setGroupNameToShowAttributes("");
      setSelectedGroupIndex(0);
      setProductGroupNameForProgram("");

      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);

      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);

    };
    // console.log("selectTable -- " , selectTable);

    //======================== [1] Get all Attribute Groups for the slected Table name  ===================================
    useEffect(() => {
      if(selectTable !== ''){
        getAllAttributesData(selectTable);
      }
    }, [dispatch,selectTable]);

    const getAllAttributesData = (selectTable) => {
      switch (selectTable) {
        case "Product":
          dispatch(getAllProductAttributes({}));
          break;
        case "Retailer":
          dispatch(getAllRetailerAttributes({}));
          break;
        case "Manufacturer":
          dispatch(getAllManufacturerAttributes({}));
          break;
        case "Supplier":
          dispatch(getAllSupplierAttributes({}));
          break;
        case "Program":
          dispatch(getAllProgramAttributes({}));
          break;
        case "Packaging Detail":
          dispatch(getAllPackagingAttributes({}));
          break;
        case "Marketing Callout":
          dispatch(getAllPromotionAttributes({}));
          break;
        case "Region":
          dispatch(getAllRegionAttributes({}));
          break;
        case "Customized Group":
          dispatch(getAllGroupAttributes({}));
          break;
        default:
          break;
      }
    };

    // Fetch data status and error for selected table
    const { data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.AllProductAttributeGroup;
        case "Retailer":
          return state.AllRetailerAttributeGroup;
        case "Manufacturer":
          return state.AllManufacturerAttributeGroup;
        case "Supplier":
          return state.AllSupplierAttributeGroup;
        case "Program":
          return state.AllProgramAttributeGroup;
        case "Packaging Detail":
          return state.AllPackagingAttributeGroup;
        case "Marketing Callout":
          return state.AllPromotionAttributeGroup;
        case "Region":
          return state.AllRegionAttributeGroup;
        case "Customized Group":
          return state.AllGroupAttributeGroup;
        default:
          return {};
      }
    });


    //======================== [2] Get One Attribute Groups for the slected group name  ===================================

    const [groupNameToShowAttributes, setGroupNameToShowAttributes] = useState("");
    const [selectedGroupIndex, setSelectedGroupIndex] = useState( 0);

    const handleGroupSelection = (e) => {
      const groupName = e.target.value;
      setGroupNameToShowAttributes(groupName);
      setSelectedGroupIndex(e.target.selectedIndex);
  
      setProductGroupNameForProgram("");

      // Reset selected product if selectTable is "Program"
      if (selectTable === "Program") {
        setSelectedGroupIndex(0);
      }

      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);

      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);

    };

    const [productGroupNameForProgram , setProductGroupNameForProgram] = useState("");
    const handleProductSelection = (e) => {
      setSelectedGroupIndex(e.target.selectedIndex);
      setProductGroupNameForProgram(e.target.value);
    };

    // console.log("groupNameToShowAttributes -- " ,groupNameToShowAttributes );
    // console.log("selectedGroupIndex -- " ,selectedGroupIndex );
    // console.log("productGroupNameForProgram -- " ,productGroupNameForProgram );


    // Clear All var and table , single Attribute State while changing the selectTable name 
    useEffect(()=>{
      // setGroupNameToShowAttributes("");
      // setProductGroupNameForProgram("");
      // setSelectedGroupIndex(0);
      switch (selectTable) {
          case "Product":
            dispatch(resetOneProductAttributesGroupState({}));
            dispatch(resetGetAllProductState({}));
            break;
          case "Retailer":
            dispatch(resetOneRetailerAttributesGroupState({}));
            dispatch(resetGetAllRetailerState({}));
            break;
          case "Manufacturer":
            dispatch(resetOneManufacturerAttributesGroupState({}));
            dispatch(resetGetAllManufacturerState({}));
            break;
          case "Supplier":
            dispatch(resetOneSupplierAttributesGroupState({}));
            dispatch(resetGetAllSupplierState({}));
            break;
          case "Program":
              dispatch(resetOneProgramAttributesGroupState({}));
              dispatch(resetGetAllProgramState({}));
            break;
          case "Packaging Detail":
            dispatch(resetOnePackagingAttributesGroupState({}));
            dispatch(resetGetAllPackagingState({}));
            break;
          case "Marketing Callout":
            dispatch(resetOnePromotionAttributesGroupState({}));
            dispatch(resetGetAllPromotionState({}));
            break;
          case "Region":
            dispatch(resetOneRegionAttributesGroupState({}));
            dispatch(resetGetAllRegionState({}));
            break;
          case "Customized Group":
            dispatch(resetOneGroupAttributesGroupState({}));
            dispatch(resetGetAllGroupState({}));
            break;
          default:
            break;
      }
      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);
      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);
    },[selectTable])

    useEffect(() => {
      if(groupNameToShowAttributes !== '' && selectTable !== ''){
          getOneAttributesData(selectTable ,groupNameToShowAttributes);
      }
    }, [dispatch,groupNameToShowAttributes ]);
  
    const getOneAttributesData = (selectTable ,groupNameToShowAttributes) => {
      switch (selectTable) {
        case "Product":
          dispatch(getOneProductAttributes(groupNameToShowAttributes));
          break;
        case "Retailer":
          dispatch(getOneRetailerAttributes(groupNameToShowAttributes));
          break;
        case "Manufacturer":
          dispatch(getOneManufacturerAttributes(groupNameToShowAttributes));
          break;
        case "Supplier":
          dispatch(getOneSupplierAttributes(groupNameToShowAttributes));
          break;
        case "Program":
          dispatch(getOneProgramAttributes(groupNameToShowAttributes));
          break;
        case "Packaging Detail":
          dispatch(getOnePackagingAttributes(groupNameToShowAttributes));
          break;
        case "Marketing Callout":
          dispatch(getOnePromotionAttributes(groupNameToShowAttributes));
          break;
        case "Region":
          dispatch(getOneRegionAttributes(groupNameToShowAttributes));
          break;
        case "Customized Group":
          dispatch(getOneGroupAttributes(groupNameToShowAttributes));
          break;
        default:
          break;
      }
    };
  
    // Fetch data status and error for group name
    const { data: OneAttributesGroupsData ,  status: OneAttributeGroupStatus , error: OneAttributeGroupError  } = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.OneProductAttributesGroup;
        case "Retailer":
          return state.OneRetailerAttributesGroup;
        case "Manufacturer":
          return state.OneManufacturerAttributesGroup;
        case "Supplier":
          return state.OneSupplierAttributesGroup;
        case "Program":
          return state.OneProgramAttributesGroup;
        case "Packaging Detail":
          return state.OnePackagingAttributesGroup;
        case "Marketing Callout":
          return state.OnePromotionAttributesGroup;
        case "Region":
          return state.OneRegionAttributesGroup;
        case "Customized Group":
          return state.OneGroupAttributesGroup;
        default:
          return {};
      }
    });

    // console.log(OneAttributesGroupsData)


    //======================== [3] Get One table data according to selected groupName and index  ===================================
    useEffect(() => {
      if(selectTable !== '' && groupNameToShowAttributes!=='' && selectedGroupIndex !== 0){
        getData(selectTable);
      }
    }, [dispatch,selectTable,groupNameToShowAttributes ,selectedGroupIndex]);

    const getData = (selectTable) => {
      let classNameToFetch;
      switch (selectTable) {
        case "Product":
          classNameToFetch = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
          dispatch(getAllProduct(classNameToFetch));
          break;
        case "Retailer":
          classNameToFetch = selectedGroupIndex === 1 ? `Retailer` : `Retailer${selectedGroupIndex}`;
          dispatch(getAllRetailer(classNameToFetch));
          break;
        case "Manufacturer":
          classNameToFetch = selectedGroupIndex === 1 ? `Manufacturer` : `Manufacturer${selectedGroupIndex}`;
          dispatch(getAllManufacturer(classNameToFetch));
          break;
        case "Supplier":
          classNameToFetch = selectedGroupIndex === 1 ? `Supplier` : `Supplier${selectedGroupIndex}`;
          dispatch(getAllSupplier(classNameToFetch));
          break;
        case "Program":
          classNameToFetch = selectedGroupIndex === 1 ? `Program` : `Program${selectedGroupIndex}`;
          dispatch(getAllProgram(classNameToFetch));
          break;
        case "Packaging Detail":
          classNameToFetch = selectedGroupIndex === 1 ? `Packaging` : `Packaging${selectedGroupIndex}`;
          dispatch(getAllPackaging(classNameToFetch));
          break;
        case "Marketing Callout":
          classNameToFetch = selectedGroupIndex === 1 ? `Promotion` : `Promotion${selectedGroupIndex}`;
          dispatch(getAllPromotion(classNameToFetch));
          break;
        case "Region":
          classNameToFetch = selectedGroupIndex === 1 ? `Region` : `Region${selectedGroupIndex}`;
          dispatch(getAllRegion(classNameToFetch));
          break;
        case "Customized Group":
          classNameToFetch = selectedGroupIndex === 1 ? `Group` : `Group${selectedGroupIndex}`;
          dispatch(getAllGroup(classNameToFetch));
          break;
        default:
          break;
      }
    };

    // Fetch data status and error for selected table
    const { data :tableData, status :tableStatus, error:tableError} = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.AllProducts;
        case "Retailer":
          return state.AllRetailers;
        case "Manufacturer":
          return state.AllManufacturers;
        case "Supplier":
          return state.AllSuppliers;
        case "Program":
          return state.AllPrograms;
        case "Packaging Detail":
          return state.AllPackagings;
        case "Marketing Callout":
          return state.AllPromotions;
        case "Region":
          return state.AllRegions;
        case "Customized Group":
          return state.AllGroups;
        default:
          return {};
      }
    });


    

    // console.log(AttributesGroupsData);
    // console.log(tableData);

    const [groupAttributesHeading, setGroupAttributesHeading] = useState([]);
    const [additionalAttributesHeading, setAdditionalAttributesHeading] = useState([]);
    const [setsAttributesHeading, setSetsAttributesHeading] = useState([]);

    const [programProductGroupAttributesHeading, setProgramProductGroupAttributesHeading] = useState([]);
    const [programProductAdditionalAttributesHeading, setProgramProductAdditionalAttributesHeading] = useState([]);
    const [programProductSetsAttributesHeading, setProgramProductSetsAttributesHeading] = useState([]);
    // console.log("programProductGroupAttributesHeading" , programProductGroupAttributesHeading)
    // console.log("programProductSetsAttributesHeading" , programProductSetsAttributesHeading)

    const [programRetailerGroupAttributesHeading, setProgramRetailerGroupAttributesHeading] = useState([]);
    const [programRetailerAdditionalAttributesHeading, setProgramRetailerAdditionalAttributesHeading] = useState([]);
    const [programRetailerSetsAttributesHeading, setProgramRetailerSetsAttributesHeading] = useState([]);

    const [programManufacturerGroupAttributesHeading, setProgramManufacturerGroupAttributesHeading] = useState([]);
    const [programManufacturerAdditionalAttributesHeading, setProgramManufacturerAdditionalAttributesHeading] = useState([]);
    const [programManufacturerSetsAttributesHeading, setProgramManufacturerSetsAttributesHeading] = useState([]);

    const [programSupplierGroupAttributesHeading, setProgramSupplierGroupAttributesHeading] = useState([]);
    const [programSupplierAdditionalAttributesHeading, setProgramSupplierAdditionalAttributesHeading] = useState([]);
    const [programSupplierSetsAttributesHeading, setProgramSupplierSetsAttributesHeading] = useState([]);

    const [programPackagingGroupAttributesHeading, setProgramPackagingGroupAttributesHeading] = useState([]);
    const [programPackagingAdditionalAttributesHeading, setProgramPackagingAdditionalAttributesHeading] = useState([]);
    const [programPackagingSetsAttributesHeading, setProgramPackagingSetsAttributesHeading] = useState([]);

    const [programPromotionGroupAttributesHeading, setProgramPromotionGroupAttributesHeading] = useState([]);
    const [programPromotionAdditionalAttributesHeading, setProgramPromotionAdditionalAttributesHeading] = useState([]);
    const [programPromotionSetsAttributesHeading, setProgramPromotionSetsAttributesHeading] = useState([]);

    const [programRegionGroupAttributesHeading, setProgramRegionGroupAttributesHeading] = useState([]);
    const [programRegionAdditionalAttributesHeading, setProgramRegionAdditionalAttributesHeading] = useState([]);
    const [programRegionSetsAttributesHeading, setProgramRegionSetsAttributesHeading] = useState([]);

    const [programGroupGroupAttributesHeading, setProgramGroupGroupAttributesHeading] = useState([]);
    const [programGroupAdditionalAttributesHeading, setProgramGroupAdditionalAttributesHeading] = useState([]);
    const [programGroupSetsAttributesHeading, setProgramGroupSetsAttributesHeading] = useState([]);

    // clear state onchanging the groupNameToShowAttributes 
    // useEffect(()=>{
     
    // },[groupNameToShowAttributes , selectedGroupIndex]);

    useEffect(()=>{
      if(selectTable=== "Program" && productGroupNameForProgram.length > 0){
        dispatch(getOneProductAttributes(productGroupNameForProgram));
        dispatch(getOneRetailerAttributes("Retailer"));
        dispatch(getOneManufacturerAttributes("Manufacturer"));
        dispatch(getOneSupplierAttributes("Supplier"));
        dispatch(getOnePackagingAttributes("Packaging Details"));
        dispatch(getOnePromotionAttributes("Marketing Callout"));
        dispatch(getOneRegionAttributes("Region"));
      }
    },[selectTable , productGroupNameForProgram]);

    const { data: ProductInProgramAttributesData, status: ProductInProgramAttributesStatus, error: ProductInProgramAttributesError } = useSelector((state) => state.OneProductAttributesGroup);
    const { data:RetailerInProgramAttributesData, status: RetailerInProgramAttributesStatus, error: RetailerInProgramAttributesError } = useSelector((state) => state.OneRetailerAttributesGroup);
    const { data:ManufacturerInProgramAttributesData, status: ManufacturerInProgramAttributesStatus, error: ManufacturerInProgramAttributesError } = useSelector((state) => state.OneManufacturerAttributesGroup);
    const { data:SupplierInProgramAttributesData, status: SupplierInProgramAttributesStatus, error: SupplierInProgramAttributesError } = useSelector((state) => state.OneSupplierAttributesGroup);
    const { data:PackagingInProgramAttributesData, status: PackagingInProgramAttributesStatus, error: PackagingInProgramAttributesError } = useSelector((state) => state.OnePackagingAttributesGroup);
    const { data:PromotionInProgramAttributesData, status: PromotionInProgramAttributesStatus, error: PromotionInProgramAttributesError } = useSelector((state) => state.OnePromotionAttributesGroup);
    const { data:RegionInProgramAttributesData, status: RegionInProgramAttributesStatus, error: RegionInProgramAttributesError } = useSelector((state) => state.OneRegionAttributesGroup);




    useEffect(() => {
      if (OneAttributesGroupsData && Object.keys(OneAttributesGroupsData).length > 0) {
        if (typeof tableData !== "string") {
          if (tableData?.length > 0) {
            const groupAttrs = [];
            const additionalAttrs = [];
            const setsAttrs = [];
    
            const programProductGroupAttrs = [];
            const programProductAdditionalAttrs = [];
            const programProductSetsAttrs = [];
    
            const programRetailerGroupAttrs = [];
            const programRetailerAdditionalAttrs = [];
            const programRetailerSetsAttrs = [];
    
            const programManufacturerGroupAttrs = [];
            const programManufacturerAdditionalAttrs = [];
            const programManufacturerSetsAttrs = [];
    
            const programSupplierGroupAttrs = [];
            const programSupplierAdditionalAttrs = [];
            const programSupplierSetsAttrs = [];
    
            const programPackagingGroupAttrs = [];
            const programPackagingAdditionalAttrs = [];
            const programPackagingSetsAttrs = [];
    
            const programPromotionGroupAttrs = [];
            const programPromotionAdditionalAttrs = [];
            const programPromotionSetsAttrs = [];
    
            const programRegionGroupAttrs = [];
            const programRegionAdditionalAttrs = [];
            const programRegionSetsAttrs = [];
    
            const programGroupGroupAttrs = [];
            const programGroupAdditionalAttrs = [];
            const programGroupSetsAttrs = [];

            const handleAttributes = (attributes, targetArray ,fromType , fromInSideProgram) => {
              Object.entries(attributes).forEach(([key, value]) => {
                if (value.type === "Image" || value.type === "Email") { return; }
                if (value.type === "NestedDropdown") {
                  targetArray.push({ key, nametoDisplay: value.nametoDisplay , fromType  ,fromInSideProgram });
                  targetArray.push({ key: `${key}nEsTeD`, nametoDisplay: value.nametoDisplay2 , fromType  ,fromInSideProgram });
                } else {
                  targetArray.push({ key, nametoDisplay: value.nametoDisplay , fromType ,fromInSideProgram });
                }
              });
            };

            const handleSetsAttributes = (imagesAttributes, targetArray ,fromType ,fromInSideProgram) => {
              Object.keys(imagesAttributes).forEach(imageKey => {
                const { groupsName, attgroups } = imagesAttributes[imageKey];
                Object.keys(groupsName).forEach(groupName => {
                  Object.entries(attgroups).forEach(([attributeKey, attributeValue]) => {
                    if (attributeValue.type === "NestedDropdown") {
                      targetArray.push({ key: `${groupName} ${attributeKey}`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay}` , fromType ,fromInSideProgram});
                      targetArray.push({ key: `${groupName} ${attributeKey}nEsTeD`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay2}` , fromType ,fromInSideProgram});
                    } else {
                      targetArray.push({ key: `${groupName} ${attributeKey}`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay}` , fromType ,fromInSideProgram});
                    }
                  });
                });
              });
            };
    
    
            switch (selectTable) {
              case "Product":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.productAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");
                break;
              case "Retailer":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.retailerAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");
                break;
              case "Manufacturer":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.manufacturerAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");
                break;
              case "Supplier":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.supplierAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");
                break;
              case "Program":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.programAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");


                    if (Object.keys(ProductInProgramAttributesData).length > 0) {
                      handleAttributes(ProductInProgramAttributesData.groupAttributes, programProductGroupAttrs, "group"  , "Product");
                      handleAttributes(ProductInProgramAttributesData.productAdditionalAttributes, programProductAdditionalAttrs, "additional" , "Product");
                      handleSetsAttributes(ProductInProgramAttributesData.imagesAttributes, programProductSetsAttrs, "set" , "Product");
                    }
                    if (Object.keys(RetailerInProgramAttributesData).length > 0) {
                      handleAttributes(RetailerInProgramAttributesData.groupAttributes, programRetailerGroupAttrs , "group" , "Retailer");
                      handleAttributes(RetailerInProgramAttributesData.retailerAdditionalAttributes, programRetailerAdditionalAttrs , "additional" , "Retailer");
                      handleSetsAttributes(RetailerInProgramAttributesData.imagesAttributes, programRetailerSetsAttrs , "set" , "Retailer");
                    }
                    if (Object.keys(ManufacturerInProgramAttributesData).length > 0) {
                      handleAttributes(ManufacturerInProgramAttributesData.groupAttributes, programManufacturerGroupAttrs , "group" , "Manufacturer");
                      handleAttributes(ManufacturerInProgramAttributesData.manufacturerAdditionalAttributes, programManufacturerAdditionalAttrs , "additional" , "Manufacturer");
                      handleSetsAttributes(ManufacturerInProgramAttributesData.imagesAttributes, programManufacturerSetsAttrs , "set" , "Manufacturer");
                    }
                    if (Object.keys(SupplierInProgramAttributesData).length > 0) {
                      handleAttributes(SupplierInProgramAttributesData.groupAttributes, programSupplierGroupAttrs , "group" , "Supplier");
                      handleAttributes(SupplierInProgramAttributesData.supplierAdditionalAttributes, programSupplierAdditionalAttrs , "additional" , "Supplier");
                      handleSetsAttributes(SupplierInProgramAttributesData.imagesAttributes, programSupplierSetsAttrs , "set" , "Supplier");
                    }
                  

                break;
              case "Packaging Detail":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.packagingAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");

                break;
              case "Marketing Callout":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs, "group");
                handleAttributes(OneAttributesGroupsData.promotionAdditionalAttributes, additionalAttrs, "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs, "set");

                break;
              case "Region":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs, "group");
                handleAttributes(OneAttributesGroupsData.regionAdditionalAttributes, additionalAttrs, "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs, "set");

                break;
              case "Customized Group":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs , "group");
                handleAttributes(OneAttributesGroupsData.groupAdditionalAttributes, additionalAttrs , "additional");
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs , "set");

                break;
              default:
                break;
            }
    
            setGroupAttributesHeading([...new Set(groupAttrs)]);
            setAdditionalAttributesHeading([...new Set(additionalAttrs)]);
            setSetsAttributesHeading([...new Set(setsAttrs)]);
    
            setProgramProductGroupAttributesHeading([...new Set(programProductGroupAttrs)]);
            setProgramProductAdditionalAttributesHeading([...new Set(programProductAdditionalAttrs)]);
            setProgramProductSetsAttributesHeading([...new Set(programProductSetsAttrs)]);
    
            setProgramRetailerGroupAttributesHeading([...new Set(programRetailerGroupAttrs)]);
            setProgramRetailerAdditionalAttributesHeading([...new Set(programRetailerAdditionalAttrs)]);
            setProgramRetailerSetsAttributesHeading([...new Set(programRetailerSetsAttrs)]);
    
            setProgramManufacturerGroupAttributesHeading([...new Set(programManufacturerGroupAttrs)]);
            setProgramManufacturerAdditionalAttributesHeading([...new Set(programManufacturerAdditionalAttrs)]);
            setProgramManufacturerSetsAttributesHeading([...new Set(programManufacturerSetsAttrs)]);
    
            setProgramSupplierGroupAttributesHeading([...new Set(programSupplierGroupAttrs)]);
            setProgramSupplierAdditionalAttributesHeading([...new Set(programSupplierAdditionalAttrs)]);
            setProgramSupplierSetsAttributesHeading([...new Set(programSupplierSetsAttrs)]);
    
            setProgramPackagingGroupAttributesHeading([...new Set(programPackagingGroupAttrs)]);
            setProgramPackagingAdditionalAttributesHeading([...new Set(programPackagingAdditionalAttrs)]);
            setProgramPackagingSetsAttributesHeading([...new Set(programPackagingSetsAttrs)]);
    
            setProgramPromotionGroupAttributesHeading([...new Set(programPromotionGroupAttrs)]);
            setProgramPromotionAdditionalAttributesHeading([...new Set(programPromotionAdditionalAttrs)]);
            setProgramPromotionSetsAttributesHeading([...new Set(programPromotionSetsAttrs)]);
    
            setProgramRegionGroupAttributesHeading([...new Set(programRegionGroupAttrs)]);
            setProgramRegionAdditionalAttributesHeading([...new Set(programRegionAdditionalAttrs)]);
            setProgramRegionSetsAttributesHeading([...new Set(programRegionSetsAttrs)]);
    
            setProgramGroupGroupAttributesHeading([...new Set(programGroupGroupAttrs)]);
            setProgramGroupAdditionalAttributesHeading([...new Set(programGroupAdditionalAttrs)]);
            setProgramGroupSetsAttributesHeading([...new Set(programGroupSetsAttrs)]);
          }
        }
      }
    }, [selectTable, OneAttributesGroupsData, tableData , ProductInProgramAttributesData, RetailerInProgramAttributesData, ManufacturerInProgramAttributesData,  SupplierInProgramAttributesData]);
    
  
  
    
  
    const selectedAttributesHeading = useSelector((state) => state.AllselectedAttributesHeading);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    
    // console.log(groupAttributesHeading);
    // console.log(selectedAttributesHeading);

    const handleSelectChange = (e) => {
      const { value, dataset } = e.target.options[e.target.selectedIndex];
      const { type } = dataset;
    
      if (value === "") return;
    
      // console.log('Selected Value:', value);
      // console.log('Data Type:', type);
    
      let selectedAttribute;
      switch (type) {
        case "group":
          selectedAttribute = groupAttributesHeading.find(item => item.key === value);
          if (selectedAttribute) {
            dispatch(setSelectedAttributesHeading([...selectedAttributesHeading, selectedAttribute]));
            setGroupAttributesHeading(groupAttributesHeading.filter(item => item.key !== value));
          }
          break;
        case "additional":
          selectedAttribute = additionalAttributesHeading.find(item => item.key === value);
          if (selectedAttribute) {
            dispatch(setSelectedAttributesHeading([...selectedAttributesHeading, selectedAttribute]));
            setAdditionalAttributesHeading(additionalAttributesHeading.filter(item => item.key !== value));
          }
          break;
        case "set":
          selectedAttribute = setsAttributesHeading.find(item => item.key === value);
          if (selectedAttribute) {
            dispatch(setSelectedAttributesHeading([...selectedAttributesHeading, selectedAttribute]));
            setSetsAttributesHeading(setsAttributesHeading.filter(item => item.key !== value));
          }
          break;
        default:
          break;
      }
      // Reset the dropdown to show the placeholder
    setSelectedAttribute('');
    };
  
    const handleRemove = (item) => {
    dispatch(setSelectedAttributesHeading(selectedAttributesHeading.filter(attr => attr.key !== item.key)));
  
      switch (item.fromType) {
        case "group":
          setGroupAttributesHeading([...groupAttributesHeading, item]);
          break;
        case "additional":
          setAdditionalAttributesHeading([...additionalAttributesHeading, item]);
          break;
        case "set":
          setSetsAttributesHeading([...setsAttributesHeading, item]);
          break;
        default:
          break;
      }
    };
    

    const resetFilter = () => {
      selectedAttributesHeading.forEach(item => {
        switch (item.fromType) {
          case "group":
            setGroupAttributesHeading(prev => [...prev, item]);
            break;
          case "additional":
            setAdditionalAttributesHeading(prev => [...prev, item]);
            break;
          case "set":
            setSetsAttributesHeading(prev => [...prev, item]);
            break;
          default:
            break;
        }
      });
    
    dispatch(setSelectedAttributesHeading([]));
      setSelectedAttribute('');
      dispatch(clearAllSelectedQuerys());
    };

    // console.log(tableData)
  return (
    <div className="filter_box">

        <div className="box1">

          <h6>Select to apply filter</h6>
          <select className="filter_select" onChange={HandleSelectedTable} value={selectTable}>
            <option className="select_step_value" value="">Select to Analyze</option>
            {DataMainHeading.map((item, index) => (
              <option className="select_step_value" value={item} key={index}>{item}</option>
            ))}
          </select>

          {selectTable && (
            <select
              className="filter_select"
              onChange={handleGroupSelection}
              value={groupNameToShowAttributes}
              required
            >
              <option value="" className="select_step_value" >Select Group</option>
              {AttributeGroupStatus === STATUSES.LOADING ? (
                <option className="select_step_value"  value="" >Loading...</option>
              ) : AttributeGroupStatus === STATUSES.ERROR ? (
                <option className="select_step_value"  value=""  >Something went wrong</option>
              ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
                AttributesGroupsData.length === 0 ? (
                  <option className="select_step_value" value="" >No Groups found</option>
                ) : (
                  AttributesGroupsData.map((item, index) => (
                    <option value={item.groupName} key={item.groupId} className="select_step_value" >
                      {item.groupName}
                    </option>
                  ))
                )
              ) : null}
            </select>
          )}

          {
            selectTable === "Program" && groupNameToShowAttributes && (
              <select
              className="filter_select"
              onChange={handleProductSelection}
              value={productGroupNameForProgram}
              required
            >
              <option value="" className="select_step_value" >Select Product Group</option>
              {AttributeGroupStatus === STATUSES.LOADING ? (
                <option className="select_step_value"  value="" >Loading...</option>
              ) : AttributeGroupStatus === STATUSES.ERROR ? (
                <option className="select_step_value"  value=""  >Something went wrong</option>
              ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
                AttributesGroupsData.length === 0 ? (
                  <option className="select_step_value" value="" >No Groups found</option>
                ) : (
                  AttributesGroupsData.map((item, index) => {
                    if (item.groupName === groupNameToShowAttributes && item.productType && item.productType.productGroup) {
                      return item.productType.productGroup.map((product, index) => (
                        <option value={product} className='select_step_value' key={index}>{product}</option>
                      ));
                    }
                    return null;
                  })
                )
              ) : null}
            </select>
            )
          }
        </div>

        <div className={`${groupNameToShowAttributes && selectedGroupIndex>0 ? "box2 box2_open" : "box2 "}`}>

          <div className="col1">
            <span>{selectTable}</span>
            {groupAttributesHeading.length > 0 || additionalAttributesHeading.length > 0 || setsAttributesHeading.length > 0 ? (
            <>
              <select className="filter_select" onChange={handleSelectChange} value={selectedAttribute}>
                <option value="">Select Attribute</option>
                {groupAttributesHeading.length > 0 && (
                  <>
                    {groupAttributesHeading.map((item, index) => (
                      <option key={`group-${index}`} value={item.key} data-type={item?.fromType}>
                        {item.nametoDisplay}
                      </option>
                    ))}
                  </>
                )}
                {additionalAttributesHeading.length > 0 && (
                  <>
                    {additionalAttributesHeading.map((item, index) => (
                      <option key={`additional-${index}`} value={item.key} data-type={item?.fromType}>
                        {item.nametoDisplay}
                      </option>
                    ))}
                  </>
                )}
                {setsAttributesHeading.length > 0 && (
                  <>
                    {setsAttributesHeading.map((item, index) => (
                      <option key={`set-${index}`} value={item.key} data-type={item?.fromType}>
                        {item.nametoDisplay}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </>
          ) : null}
          </div>

          <RenderOptions selectedAttributesHeading ={selectedAttributesHeading} tableData={tableData} selectTable ={selectTable} handleRemove={handleRemove}/>

        </div>

     

      <div className="btn_group">
        <button className="common_btn" onClick={() => dispatch(filterBoxToggleToggleFun())}>Apply</button>
        <button className="common_btn2"  onClick={resetFilter}>Reset</button>
        <button className="common_btn3"  onClick={() => dispatch(filterBoxToggleToggleFun())}>Close</button>
      </div>
    </div>
  );
};

export default FilterBox;



const RenderOptions = ({ tableData, selectedAttributesHeading, selectTable ,handleRemove}) => {
  // Helper function to get values based on the key and type
  const getValuesForKey = (item, key, selectTable) => {
    if (key in item.groupAttributes) return item.groupAttributes[key];
    switch (selectTable) {
      case "Product":
        if (key in item.productAdditionalAttributes) return item.productAdditionalAttributes[key];
        break;
      case "Program":
        if (key in item.programAdditionalAttributes) return item.programAdditionalAttributes[key];
        break;
      case "Retailer":
        if (key in item.retailerAdditionalAttributes) return item.retailerAdditionalAttributes[key];
        break;
      case "Manufacturer":
        if (key in item.manufacturerAdditionalAttributes) return item.manufacturerAdditionalAttributes[key];
        break;
      case "Supplier":
        if (key in item.supplierAdditionalAttributes) return item.supplierAdditionalAttributes[key];
        break;
      case "Packaging Detail":
        if (key in item.packagingAdditionalAttributes) return item.packagingAdditionalAttributes[key];
        break;
      case "Region":
        if (key in item.regionAdditionalAttributes) return item.regionAdditionalAttributes[key];
        break;
      case "Customized Group":
        if (key in item.groupAdditionalAttributes) return item.groupAdditionalAttributes[key];
        break;
      default:
        return [];
    }
    return [];
  };

  // Extract unique values for dropdown options
  const uniqueOptions = selectedAttributesHeading?.reduce((acc, { key, nametoDisplay }) => {
    if (tableData && typeof tableData !== "string" && tableData.length > 0) {
      // Extract values for the key from tableData
      const values = tableData.flatMap(item => getValuesForKey(item, key, selectTable)) || [];
      // Remove duplicates and assign to the acc object
      acc[nametoDisplay] = Array.from(new Set(values));
    }
    return acc;
  }, {});

  const dispatch = useDispatch();
  const selectedQuerys = useSelector((state) => state.selectedQuerys);


  // const [selectedQuerys, setSelectedQuerys] = useState({});

  // console.log("selectedQuerys ==== " , selectedQuerys);

  const handleOptionSelect = (item, selectedOptions) => {
    dispatch(addSelectedQuery({
      key: item.key,
      nametoDisplay: item.nametoDisplay,
      value: selectedOptions.map(option => option.value),
    }));
  };

  
  const handleOptionDeselect = (item, value) => {
    const currentSelections = selectedQuerys[item.key]?.value || [];
    const updatedSelections = currentSelections.filter((v) => v !== value);

    if (updatedSelections.length === 0) {
      dispatch(removeSelectedQuery({ key: item.key }));
    } else {
      dispatch(addSelectedQuery({
        key: item.key,
        nametoDisplay: item.nametoDisplay,
        value: updatedSelections,
      }));
    }
  };

 

  const handleTrashClick = (item) => {
    dispatch(removeSelectedQuery({ key: item.key }));
    handleRemove(item);
  };


  return (
    <>
      {selectedAttributesHeading?.map((item, index) => (
        <div key={index} className="col2">
          <span>{item?.nametoDisplay} </span>
          <Select
            isMulti
            options={uniqueOptions[item?.nametoDisplay]?.map((value) => ({ value, label: value }))}
            value={selectedQuerys[item.key]?.value.map((value) => ({ value, label: value })) || []}
            onChange={(selectedOptions) => handleOptionSelect(item, selectedOptions)}
            onDeselect={(value) => handleOptionDeselect(item, value)}
            className="filter_select_multi"
          />
          <i onClick={() => handleTrashClick(item)}>
            <FontAwesomeIcon icon={faTrash} />
          </i>
        </div>
      ))}

    </>
  );
};

export { RenderOptions };
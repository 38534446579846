import React, { useCallback, useEffect, useState } from "react";
import "./SingleAttributeGroup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCircleInfo,
  faPencil,
  faTrash,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import STATUSES from "../../../../store/Statuses";
import Loader, { LoaderCircle } from "../../../Common/Loader/Loader";
import NoDataInAttributes from "../../MarketMapsComponents/NoDataInAttributes/NoDataInAttributes";
import ErrorBox from "../../../Common/ErrorBox/ErrorBox";
import { toast } from "react-toastify";
import {
  NonRepetableIcon,
  NonUniqueIcon,
  RepetableIcon,
  UniqueIcon,
} from "../CreateAttributeGroup/CreateAttributeGroup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import importicon from "../../../../assets/Images/importicon.svg";

const SingleAttributeGroup = ({
  sendGroupName,
  singleAttributeGroupToggle,
  handelsingleAttributeGroupToggle,
  GetSingleGroupAPI,
  GetSingleGroupDataAPI,
  groupHeadingName,
  receiveAdditionalAttributeAs,
  SaveGroupAPIName,
  ResetGroupAPIName,
  getGroupAPIName,
}) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth.userDetails);

  const [editMode, setEditMode] = useState(false);
  const [showUpdateBox, setShowUpdateBox] = useState(false);
  const [showSetUpdateBox, setShowSetUpdateBox] = useState(false);
  const [updateData, setUpdateData] = useState({
    nametoDisplay: "",
    type: "",
    varDescription: "",
    mandatory: false,
    repeat: false,
    unique: false,
    attributeType: "",
    attributeKey: "",
    options: [], // Added options array
    nametoDisplay2: "",
    nestedOptions: [],
    childDataType: "",
  });
  useEffect(() => {
    if (updateData.type === "NestedDropdown") {
      setUpdateData((prevData) => ({
        ...prevData,
        nestedOptions: prevData.options.map(() => []), // Initialize nestedOptions as empty arrays
      }));
    }
  }, [updateData.type]);

  console.log(updateData);
  const [newOption, setNewOption] = useState("");

  const [newNestedOptions, setNewNestedOptions] = useState(
    updateData.nestedOptions
      ? Array.from({ length: updateData.nestedOptions.length }, () => "")
      : []
  );
  const [newParentOption, setNewParentOption] = useState("");

  const handleEditMode = () => {
    setEditMode(!editMode);
  };
  const handlePencilClick = () => {
    setShowUpdateBox(true); // Show the update box when pencil button is clicked
  };

  const handleUpdate = () => {
    const trimmedUpdateData = {
      ...updateData,
      nametoDisplay: updateData.nametoDisplay.trim(),
      type: updateData.type.trim(),
      varDescription: updateData.varDescription.trim(),
    };

    if (
      trimmedUpdateData.nametoDisplay === "" ||
      trimmedUpdateData.type === "" ||
      trimmedUpdateData.varDescription === ""
    ) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    if (trimmedUpdateData.attributeType === "group") {
      if (
        trimmedUpdateData.type === "Dropdown" ||
        trimmedUpdateData.type === "MultiSelect"
      ) {
        setDisplayGroupAttributes((prevState) => ({
          ...prevState,
          [trimmedUpdateData.attributeKey]: {
            ...prevState[trimmedUpdateData.attributeKey],
            nametoDisplay: trimmedUpdateData.nametoDisplay,
            mandatory: trimmedUpdateData.mandatory,
            repeat: trimmedUpdateData.repeat,
            unique: trimmedUpdateData.unique,
            type: trimmedUpdateData.type,
            varDescription: trimmedUpdateData.varDescription,
            options: updateData.options,
          },
        }));
      } else if (trimmedUpdateData.type === "NestedDropdown") {
        if (updateData.options.length === 0) {
          toast.warning("Please add at least one parent option");
          return;
        }
        if (!updateData.nametoDisplay2) {
          toast.warning("Please fill in the child attribute name");
          return;
        }
        if (
          updateData.nametoDisplay2 &&
          updateData.nametoDisplay2.trim().length === 0
        ) {
          toast.warning("Please fill in the child attribute name");
          return;
        }

        if (updateData.childDataType === "Dropdown") {
          if (updateData.nestedOptions.some((option) => option.length === 0)) {
            toast.warning(
              "Each parent option must have at least one nested option"
            );
            return;
          }
          setDisplayGroupAttributes((prevState) => ({
            ...prevState,
            [trimmedUpdateData.attributeKey]: {
              ...prevState[trimmedUpdateData.attributeKey],
              nametoDisplay: trimmedUpdateData.nametoDisplay,
              mandatory: trimmedUpdateData.mandatory,
              repeat: trimmedUpdateData.repeat,
              unique: trimmedUpdateData.unique,
              type: trimmedUpdateData.type,
              varDescription: trimmedUpdateData.varDescription,
              options: updateData.options,
              nametoDisplay2: updateData.nametoDisplay2,
              nestedOptions: updateData.nestedOptions,
              childDataType: updateData.childDataType,
            },
          }));
        } else if (updateData.childDataType !== "Dropdown") {
          setDisplayGroupAttributes((prevState) => ({
            ...prevState,
            [trimmedUpdateData.attributeKey]: {
              ...prevState[trimmedUpdateData.attributeKey],
              nametoDisplay: trimmedUpdateData.nametoDisplay,
              mandatory: trimmedUpdateData.mandatory,
              repeat: trimmedUpdateData.repeat,
              unique: trimmedUpdateData.unique,
              type: trimmedUpdateData.type,
              varDescription: trimmedUpdateData.varDescription,
              options: updateData.options,
              nametoDisplay2: updateData.nametoDisplay2,
              childDataType: updateData.childDataType,
            },
          }));
        }
      } else {
        setDisplayGroupAttributes((prevState) => ({
          ...prevState,
          [trimmedUpdateData.attributeKey]: {
            ...prevState[trimmedUpdateData.attributeKey],
            nametoDisplay: trimmedUpdateData.nametoDisplay,
            mandatory: trimmedUpdateData.mandatory,
            repeat: trimmedUpdateData.repeat,
            unique: trimmedUpdateData.unique,
            type: trimmedUpdateData.type,
            varDescription: trimmedUpdateData.varDescription,
          },
        }));
      }
    } else if (trimmedUpdateData.attributeType === "additional") {
      if (
        trimmedUpdateData.type === "Dropdown" ||
        trimmedUpdateData.type === "MultiSelect"
      ) {
        setDisplayAdditionalAttributes((prevState) => ({
          ...prevState,
          [trimmedUpdateData.attributeKey]: {
            ...prevState[trimmedUpdateData.attributeKey],
            nametoDisplay: trimmedUpdateData.nametoDisplay,
            mandatory: trimmedUpdateData.mandatory,
            repeat: trimmedUpdateData.repeat,
            unique: trimmedUpdateData.unique,
            type: trimmedUpdateData.type,
            varDescription: trimmedUpdateData.varDescription,
            options: updateData.options || [],
          },
        }));
      } else if (trimmedUpdateData.type === "NestedDropdown") {
        if (updateData.options.length === 0) {
          toast.warning("Please add at least one parent option");
          return;
        }
        if (!updateData.nametoDisplay2) {
          toast.warning("Please fill in the child attribute name");
          return;
        }
        if (
          updateData.nametoDisplay2 &&
          trimmedUpdateData.nametoDisplay2.trim().length === 0
        ) {
          toast.warning("Please fill in the child attribute name");
          return;
        }

        if (updateData.childDataType === "Dropdown") {
          if (updateData.nestedOptions.some((option) => option.length === 0)) {
            toast.warning(
              "Each parent option must have at least one nested option"
            );
            return;
          }
          setDisplayAdditionalAttributes((prevState) => ({
            ...prevState,
            [trimmedUpdateData.attributeKey]: {
              ...prevState[trimmedUpdateData.attributeKey],
              nametoDisplay: trimmedUpdateData.nametoDisplay,
              mandatory: trimmedUpdateData.mandatory,
              repeat: trimmedUpdateData.repeat,
              unique: trimmedUpdateData.unique,
              type: trimmedUpdateData.type,
              varDescription: trimmedUpdateData.varDescription,
              options: updateData.options,
              nametoDisplay2: updateData.nametoDisplay2,
              nestedOptions: updateData.nestedOptions,
              childDataType: updateData.childDataType,
            },
          }));
        } else if (updateData.childDataType !== "Dropdown") {
          setDisplayAdditionalAttributes((prevState) => ({
            ...prevState,
            [trimmedUpdateData.attributeKey]: {
              ...prevState[trimmedUpdateData.attributeKey],
              nametoDisplay: trimmedUpdateData.nametoDisplay,
              mandatory: trimmedUpdateData.mandatory,
              repeat: trimmedUpdateData.repeat,
              unique: trimmedUpdateData.unique,
              type: trimmedUpdateData.type,
              varDescription: trimmedUpdateData.varDescription,
              options: updateData.options,
              nametoDisplay2: updateData.nametoDisplay2,
              childDataType: updateData.childDataType,
            },
          }));
        }
      } else {
        // If type is not Dropdown or MultiSelect, update without options
        setDisplayAdditionalAttributes((prevState) => ({
          ...prevState,
          [trimmedUpdateData.attributeKey]: {
            ...prevState[trimmedUpdateData.attributeKey],
            nametoDisplay: trimmedUpdateData.nametoDisplay,
            mandatory: trimmedUpdateData.mandatory,
            repeat: trimmedUpdateData.repeat,
            unique: trimmedUpdateData.unique,
            type: trimmedUpdateData.type,
            varDescription: trimmedUpdateData.varDescription,
          },
        }));
      }
    }
    // Clear updateData state after update
    setUpdateData({
      nametoDisplay: "",
      type: "",
      varDescription: "",
      mandatory: false,
      repeat: false,
      unique: false,
      attributeType: "",
      attributeKey: "",
      options: [],
      nametoDisplay2: "",
      nestedOptions: [],
      childDataType: "",
    });
    setShowUpdateBox(false);
  };

  const handleUpdateInputChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === "checkbox" ? e.target.checked : value;
    setUpdateData({ ...updateData, [name]: newValue });

    if (name === "type") {
      if (
        value === "Dropdown" ||
        value === "NestedDropdown" ||
        value === "MultiSelect"
      ) {
        setUpdateData((prevState) => ({
          ...prevState,
          options: prevState.options || [], // Ensure options is an array
          nestedOptions: prevState.nestedOptions || [], // Ensure nestedOptions is an array if needed
        }));
      }
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...updateData.options];
    updatedOptions.splice(index, 1);
    setUpdateData({ ...updateData, options: updatedOptions });
  };

  const handleAddOption = () => {
    if (newOption.trim() === "") {
      toast.warn("Please enter an option.");
      return;
    }
    const updatedOptions = [...updateData.options, newOption.trim()];
    setUpdateData({ ...updateData, options: updatedOptions });
    setNewOption(""); // Clear the input field after adding the option
  };

  const handleAddNestedOption = (parentIndex) => {
    const updatedNestedOptions = [...updateData.nestedOptions];
    if (!updatedNestedOptions[parentIndex]) {
      updatedNestedOptions[parentIndex] = []; // Ensure the array exists
    }
    updatedNestedOptions[parentIndex] = [
      ...updatedNestedOptions[parentIndex],
      newNestedOptions[parentIndex].trim(),
    ]; // Trim the input
    setUpdateData({ ...updateData, nestedOptions: updatedNestedOptions });
    setNewNestedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[parentIndex] = ""; // Clear input field after adding the option
      return newOptions;
    });
  };

  const handleRemoveNestedOption = (parentIndex, nestedIndex) => {
    // Use Array.map to iterate over the nestedOptions array
    const updatedNestedOptions = updateData.nestedOptions.map(
      (parentOptions, index) => {
        // If the current index matches the parentIndex, filter out the nested option at nestedIndex
        if (index === parentIndex) {
          return parentOptions.filter(
            (option, nestedIdx) => nestedIdx !== nestedIndex
          );
        }
        return parentOptions; // Otherwise, return the original parentOptions array
      }
    );

    // Update the state with the updatedNestedOptions
    setUpdateData({ ...updateData, nestedOptions: updatedNestedOptions });
  };

  const handleAddParentOption = () => {
    if (updateData.childDataType === "Dropdown") {
      // If childDataType is Dropdown, add parent option and an empty array for nestedOptions
      const updatedOptions = [...updateData.options, newParentOption.trim()]; // Trim the input
      const updatedNestedOptions = [...updateData.nestedOptions, []]; // Add an empty array for nested options
      setUpdateData({
        ...updateData,
        options: updatedOptions,
        nestedOptions: updatedNestedOptions,
      });
      setNewParentOption("");
    } else {
      // If childDataType is not Dropdown, simply add the parent option to options array
      const updatedOptions = [...updateData.options, newParentOption.trim()]; // Trim the input
      setUpdateData({ ...updateData, options: updatedOptions });
    }
  };

  const handleRemoveParentOption = (index) => {
    const updatedOptions = [...updateData.options];
    updatedOptions.splice(index, 1);

    if (updateData.childDataType === "Dropdown") {
      // Only update nestedOptions if childDataType is Dropdown
      const updatedNestedOptions = [...updateData.nestedOptions];
      updatedNestedOptions.splice(index, 1);
      setUpdateData({
        ...updateData,
        options: updatedOptions,
        nestedOptions: updatedNestedOptions,
      });
    } else {
      // If childDataType is not Dropdown, update options only
      setUpdateData({ ...updateData, options: updatedOptions });
    }
  };
  const handleNewNestedOptionChange = (e, index) => {
    const { value } = e.target;
    const updatedNewNestedOptions = [...newNestedOptions];
    updatedNewNestedOptions[index] = value;
    setNewNestedOptions(updatedNewNestedOptions);
  };

  const [editedAttributes, setEditedAttributes] = useState({});
  const [activeItem, setActiveItem] = useState(null);

  const handleEditClick = (itemName, value) => {
    setActiveItem(itemName);
    setEditedAttributes(value.groupsName);
    setEditMode(true);
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setEditedAttributes((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        nametoDisplay: value,
      },
    }));
  };

  const handleSave = () => {
    let shouldShowToast = false; // Flag to track if toast should be shown
    const updatedAttSets = { ...displayImagesAttributes }; // Copy the state

    if (activeItem) {
      const updatedGroupsName = { ...editedAttributes };

      // Trim and check for empty nametoDisplay in each group
      Object.keys(updatedGroupsName).forEach((groupKey) => {
        const trimmedValue = updatedGroupsName[groupKey].nametoDisplay.trim();
        if (trimmedValue === "") {
          // Set flag to true if any nametoDisplay is empty
          shouldShowToast = true;
          return; // Skip saving this group
        }
        updatedGroupsName[groupKey] = {
          ...updatedGroupsName[groupKey], // Ensure immutability
          nametoDisplay: trimmedValue,
        };
      });

      updatedAttSets[activeItem] = {
        ...updatedAttSets[activeItem],
        groupsName: updatedGroupsName,
      };
    }

    // Show toast warning if any nametoDisplay was empty
    if (shouldShowToast) {
      // Code to display toast warning goes here
      toast.warning("The set name can not be empty");
      return; // Exit handleSave to prevent further execution
    }

    // Update state with the modified attributes
    setDisplayImagesAttributes(updatedAttSets);
    setShowSetUpdateBox(!setShowSetUpdateBox);
    setActiveItem(null);
  };

  // console.log(activeItem);
  // console.log(editedAttributes);

  const [refreshEffect, setRefreshEffect] = useState(false);
  const handelClose = () => {
    handelsingleAttributeGroupToggle("");
    document.body.style.overflow = "unset";
    setRefreshEffect((prevState) => !prevState);
  };

  const handleCancle = useCallback(() => {
    setEditMode(false);
    setShowUpdateBox(false);
  }, []);

  useEffect(() => {
    if (sendGroupName.length > 0 || refreshEffect) {
      dispatch(GetSingleGroupAPI(sendGroupName));
      // Reset refreshEffect to false after dispatching the action
      setRefreshEffect(false);
    }
  }, [dispatch, sendGroupName, GetSingleGroupAPI, refreshEffect]);

  const { data, status, error } = useSelector(GetSingleGroupDataAPI);
  const {
    groupAttributes,
    [receiveAdditionalAttributeAs]: AdditionalAttributes,
    imagesAttributes,
  } = data;
  // console.log(data);

  const [displayGroupAttributes, setDisplayGroupAttributes] = useState({});
  const [displayAdditionalAttributes, setDisplayAdditionalAttributes] =
    useState({});
  const [displayImagesAttributes, setDisplayImagesAttributes] = useState({});

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      if (groupAttributes && Object.keys(groupAttributes).length > 0) {
        setDisplayGroupAttributes({ ...groupAttributes });
      } else {
        setDisplayGroupAttributes({});
      }
      if (
        AdditionalAttributes &&
        Object.keys(AdditionalAttributes).length > 0
      ) {
        setDisplayAdditionalAttributes({ ...AdditionalAttributes });
      } else {
        setDisplayAdditionalAttributes({});
      }
      if (imagesAttributes && Object.keys(imagesAttributes).length > 0) {
        setDisplayImagesAttributes({ ...imagesAttributes });
      } else {
        setDisplayImagesAttributes({});
      }
    } else {
      // If status is not SUCCESS, reset display attributes to empty objects
      setDisplayGroupAttributes({});
      setDisplayAdditionalAttributes({});
      setDisplayImagesAttributes({});
    }
  }, [
    groupAttributes,
    AdditionalAttributes,
    imagesAttributes,
    status,
    sendGroupName,
  ]);

  // console.log("displayGroupAttributes == ", displayGroupAttributes)
  // console.log("displayAdditionalAttributes == ", displayAdditionalAttributes)
  // console.log("displayImagesAttributes == ", displayImagesAttributes)

  const submitUpdateData = () => {
    const dataToSave = {
      groupName: sendGroupName,
      groupAttributes: displayGroupAttributes,
      [receiveAdditionalAttributeAs]: displayAdditionalAttributes,
      imagesAttributes: displayImagesAttributes,
    };
    dispatch(SaveGroupAPIName(dataToSave));
    console.log(dataToSave);
  };

  const {
    data: updateAttributeData,
    status: updateAttributeStatus,
    error: updateAttributeError,
  } = useSelector(getGroupAPIName);

  useEffect(() => {
    if (updateAttributeStatus === STATUSES.SUCCESS) {
      toast.success(
        updateAttributeStatus ? updateAttributeStatus : "Updated Successfully"
      );
      dispatch(ResetGroupAPIName({}));
    }
    if (updateAttributeStatus === STATUSES.ERROR) {
      toast.error(
        updateAttributeError ? updateAttributeError : "Something Went Wrong"
      );
      dispatch(ResetGroupAPIName({}));
    }
  }, [
    updateAttributeStatus,
    updateAttributeError,
    updateAttributeData,
    dispatch,
    ResetGroupAPIName,
  ]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(Object.entries(displayGroupAttributes));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newOrder = Object.fromEntries(items);
    setDisplayGroupAttributes(newOrder);
  };

  return (
    <div className={"single_attribute_group"}>
      <div
        className={
          singleAttributeGroupToggle
            ? "single_attribute_group_container"
            : "single_attribute_group_container close"
        }
      >
        <i>
          <FontAwesomeIcon icon={faClose} onClick={handelClose} />
        </i>
        {status === STATUSES.LOADING ? (
          <Loader />
        ) : status === STATUSES.SUCCESS ? (
          <>
            <h6>
              {groupHeadingName} Attributes -{" "}
              <span>{data && data.groupName}</span>
            </h6>
            <div
              className={
                editMode
                  ? "single_attribute_group_content"
                  : "single_attribute_group_content close"
              }
            >
              <div className="sub_title">
                <h5>Group Description - {data && data.groupDescription}</h5>
                {Object.keys(displayGroupAttributes).length > 0 ||
                Object.keys(displayAdditionalAttributes).length > 0 ||
                Object.keys(displayImagesAttributes).length > 0 ? (
                  userDetails?.authorities[0]?.authority === "ROLE_ADMIN" ? (
                    <button className="common_btn group_btn" onClick={handleEditMode} >{editMode ? "Hide edit" : "Edit"}</button>
                  ) : (
                    <button className="common_btn group_btn" data-tooltip-id="tooltip" data-tooltip-content="Only admin can access this feature." disabled>{"Edit"}</button>
                  )
                ) : null}
              </div>

              <div className="boxes">
                {/* Main Attributes */}
                <div className="col1">
                  <h6>Main Attributes</h6>
                  {Object.keys(displayGroupAttributes).length === 0 ? (
                    <NoDataInAttributes NodataHeading="No data Found" />
                  ) : (
                    <div className="drop_group_att_box">
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="groupAttributes">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {Object.keys(displayGroupAttributes).map(
                                (item, index) => (
                                  <Draggable
                                    key={item}
                                    draggableId={item}
                                    index={index}
                                    isDragDisabled={!editMode}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="single_attribute"
                                        style={{
                                          ...provided.draggableProps.style,
                                          maxWidth: "100%",
                                          boxSizing: "border-box",
                                          position: snapshot.isDragging
                                            ? "relative"
                                            : provided.draggableProps.style
                                                .position,
                                          top: snapshot.isDragging
                                            ? "auto"
                                            : provided.draggableProps.style.top,
                                          left: snapshot.isDragging
                                            ? "auto"
                                            : provided.draggableProps.style
                                                .left,
                                        }}
                                      >
                                        {editMode && (
                                          <span {...provided.dragHandleProps}>
                                            <FontAwesomeIcon
                                              icon={faGripVertical}
                                              style={{ paddingRight: 5 }}
                                            />
                                          </span>
                                        )}
                                        <div className="att_name">
                                          {
                                            displayGroupAttributes[item]
                                              .nametoDisplay
                                          }
                                        </div>
                                        <div className="display_radio">
                                          {displayGroupAttributes[item]
                                            .repeat ? (
                                            <RepetableIcon />
                                          ) : (
                                            <NonRepetableIcon />
                                          )}
                                          {displayGroupAttributes[item]
                                            .unique ? (
                                            <UniqueIcon />
                                          ) : (
                                            <NonUniqueIcon />
                                          )}
                                          <div className="checkbox-wrapper-3">
                                            <input
                                              type="checkbox"
                                              checked={
                                                displayGroupAttributes[item]
                                                  .mandatory
                                              }
                                              disabled={true}
                                            />
                                            <label className="toggle">
                                              <span></span>
                                            </label>
                                          </div>
                                          <i
                                            title={`Data type- ${displayGroupAttributes[item].type}\nDescription- ${displayGroupAttributes[item].varDescription}`}
                                          >
                                            <img src={importicon} alt="description_image"/>
                                            {/* <FontAwesomeIcon
                                              icon={faCircleInfo}
                                            /> */}
                                          </i>
                                          {editMode && (
                                            <i className="wicon">
                                              <FontAwesomeIcon
                                                icon={faPencil}
                                                onClick={() => {
                                                  setUpdateData({
                                                    ...displayGroupAttributes[
                                                      item
                                                    ],
                                                    attributeType: "group",
                                                    attributeKey: item,
                                                  });
                                                  handlePencilClick();
                                                }}
                                              />
                                            </i>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </div>
                {/* New added Attributes */}
                <div className="col1">
                  <h6>New added Attributes</h6>
                  {Object.keys(displayAdditionalAttributes).length === 0 ? (
                    <NoDataInAttributes NodataHeading="No data Found" />
                  ) : (
                    Object.keys(displayAdditionalAttributes).map(
                      (item, index) => (
                        <div key={index} className="single_attribute">
                          <div className="att_name">
                            {displayAdditionalAttributes[item].nametoDisplay}
                          </div>

                          <div className="display_radio">
                            {displayAdditionalAttributes[item].repeat ? (
                              <RepetableIcon />
                            ) : (
                              <NonRepetableIcon />
                            )}
                            {displayAdditionalAttributes[item].unique ? (
                              <UniqueIcon />
                            ) : (
                              <NonUniqueIcon />
                            )}
                            <div className="checkbox-wrapper-3">
                              <input
                                type="checkbox"
                                checked={
                                  displayAdditionalAttributes[item].mandatory
                                }
                                disabled={true} // Set disabled attribute to true
                              />
                              <label className="toggle">
                                <span></span>
                              </label>
                            </div>

                            <i
                              title={`Data type- ${displayAdditionalAttributes[item].type}\nDescription- ${displayAdditionalAttributes[item].varDescription}`}
                            >
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </i>
                            {editMode && (
                              <i>
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  onClick={() => {
                                    setUpdateData({
                                      ...displayAdditionalAttributes[item],
                                      attributeType: "additional",
                                      attributeKey: item,
                                    });
                                    handlePencilClick();
                                  }}
                                />
                              </i>
                            )}
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
                {/* Sets of Attributes */}
                <div className="col1">
                  <h6>Sets of Attributes</h6>
                  {Object.keys(displayImagesAttributes).length === 0 ? (
                    <NoDataInAttributes NodataHeading="No data Found" />
                  ) : (
                    Object.entries(displayImagesAttributes).map(
                      ([itemName, value]) => (
                        <div key={itemName} className="att_set_container">
                          {editMode && (
                            <i
                              className="edit_set_name"
                              onClick={() => {
                                handleEditClick(itemName, value);
                                setShowSetUpdateBox(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPencil}
                                style={{ float: "right" }}
                              />
                            </i>
                          )}
                          {Object.keys(value?.groupsName).map((name, index) => (
                            <div className="att_set_item" key={index}>
                              {value?.groupsName[name].nametoDisplay}
                            </div>
                          ))}
                          {Object.keys(value.attgroups).map((key, index) => (
                            <div className="att_sets_attribute" key={index}>
                              {value.attgroups[key].nametoDisplay}
                            </div>
                          ))}
                        </div>
                      )
                    )
                  )}
                </div>
              </div>

              {/* Update Attributes Box */}
              {showUpdateBox && editMode && (
                <div className="attributes_update_box">
                  <h6>Update Attributes</h6>
                  <div className="col">
                    <div className="_input_box">
                      <input
                        className="table_input"
                        type="text"
                        name="nametoDisplay"
                        value={updateData.nametoDisplay || ""}
                        onChange={handleUpdateInputChange}
                      />
                      <span>Attribute name</span>
                    </div>
                    <select
                      className="data_type_select"
                      type="text"
                      name="type"
                      value={updateData.type || ""}
                      onChange={handleUpdateInputChange}
                    >
                      <option value="" className="select_step_value">
                        Select the attribute data type
                      </option>
                      <option value="String" className="select_step_value">
                        Text - single line
                      </option>
                      <option value="Number" className="select_step_value">
                        Number
                      </option>
                      <option value="Decimal" className="select_step_value">
                        Decimal
                      </option>
                      <option value="Dropdown" className="select_step_value">
                        Dropdown
                      </option>
                      <option
                        value="NestedDropdown"
                        className="select_step_value"
                      >
                        Nested Dropdown
                      </option>
                      <option value="MultiSelect" className="select_step_value">
                        Multi-select
                      </option>
                      <option value="Image" className="select_step_value">
                        Image
                      </option>
                      <option value="Percent" className="select_step_value">
                        Percent
                      </option>
                      <option value="Date" className="select_step_value">
                        Date
                      </option>
                      <option value="DateTime" className="select_step_value">
                        Date and Time
                      </option>
                      <option value="Email" className="select_step_value">
                        Email
                      </option>
                      <option value="URL" className="select_step_value">
                        URL
                      </option>
                      <option value="Phone" className="select_step_value">
                        Phone
                      </option>
                    </select>
                  </div>
                  <div className="col">
                    <div className="_input_box">
                      <input
                        className="table_input"
                        type="text"
                        name="varDescription"
                        value={updateData.varDescription || ""}
                        onChange={handleUpdateInputChange}
                      />
                      <span>Attribute Description</span>
                    </div>
                    <div>
                      <div className="checkbox-wrapper-3">
                        <input
                          type="checkbox"
                          id="cbx-new-mandatory"
                          name="mandatory"
                          checked={updateData.mandatory}
                          onChange={handleUpdateInputChange}
                        />
                        <label htmlFor="cbx-new-mandatory" className="toggle">
                          <span></span>
                        </label>
                        <span className="custom-radio-button">
                          {updateData.mandatory ? "Mandatory" : "Non Mandatory"}
                        </span>
                      </div>
                      <div className="checkbox-wrapper-3">
                        <input
                          type="checkbox"
                          id="cbx-new-repeat"
                          name="repeat"
                          checked={updateData.repeat}
                          onChange={handleUpdateInputChange}
                        />
                        <label htmlFor="cbx-new-repeat" className="toggle">
                          <span></span>
                        </label>
                        <span className="custom-radio-button">
                          {updateData.repeat ? (
                            <span>
                              <RepetableIcon /> <div>Repeatable</div>
                            </span>
                          ) : (
                            <span>
                              <NonRepetableIcon />
                              <div>Non repeatable</div>{" "}
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="checkbox-wrapper-3">
                        <input
                          type="checkbox"
                          id="cbx-new-unique"
                          name="unique"
                          checked={updateData.unique}
                          onChange={handleUpdateInputChange}
                        />
                        <label htmlFor="cbx-new-unique" className="toggle">
                          <span></span>
                        </label>
                        <span className="custom-radio-button">
                          {updateData.unique ? (
                            <span>
                              <UniqueIcon /> <div>Unique</div>
                            </span>
                          ) : (
                            <span>
                              <NonUniqueIcon />
                              <div>Non unique</div>{" "}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Options */}
                  {updateData.type === "Dropdown" ||
                  updateData.type === "MultiSelect" ? (
                    <div className="options-container">
                      <h6>Update the options</h6>
                      {updateData.options.map((option, index) => (
                        <div key={index} className="option-item">
                          <span>{option}</span>
                          <i onClick={() => handleRemoveOption(index)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </i>
                        </div>
                      ))}
                      <div className="search_box">
                        <input
                          className="table_input"
                          type="text"
                          placeholder="Option"
                          value={newOption || ""}
                          onChange={(e) => setNewOption(e.target.value)}
                        />
                        <span onClick={handleAddOption}>Add option</span>
                      </div>
                    </div>
                  ) : null}

                  {updateData.type === "NestedDropdown" && (
                    <>
                      <div className="options-container">
                        <div className="child_attribute_box">
                          <div className="_input_box">
                            <input
                              className="table_input"
                              type="text"
                              required
                              name="nametoDisplay2"
                              value={updateData.nametoDisplay2 || ""}
                              onChange={handleUpdateInputChange}
                            />
                            <span>Add child attribute name</span>
                          </div>
                          <select
                            className="data_type_select"
                            value={updateData.childDataType || ""}
                            onChange={(e) =>
                              handleUpdateInputChange({
                                target: {
                                  name: "childDataType",
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="" className="select_step_value">
                              Select the attribute data type
                            </option>
                            <option
                              value="String"
                              className="select_step_value"
                            >
                              Text - single line
                            </option>
                            <option
                              value="Number"
                              className="select_step_value"
                            >
                              Number
                            </option>
                            <option
                              value="Decimal"
                              className="select_step_value"
                            >
                              Decimal
                            </option>
                            <option
                              value="Dropdown"
                              className="select_step_value"
                            >
                              Dropdown
                            </option>
                            <option
                              value="Percent"
                              className="select_step_value"
                            >
                              Percent
                            </option>
                            <option value="Date" className="select_step_value">
                              Date
                            </option>
                            <option
                              value="DateTime"
                              className="select_step_value"
                            >
                              Date and Time
                            </option>
                            <option value="Email" className="select_step_value">
                              Email
                            </option>
                            <option value="URL" className="select_step_value">
                              URL
                            </option>
                            <option value="Phone" className="select_step_value">
                              Phone
                            </option>
                          </select>
                        </div>

                        {updateData.childDataType === "Dropdown" && (
                          <>
                            <h6>Add the options</h6>
                            <div className="nested_option_container">
                              {updateData.options.map((parentOption, index) => (
                                <div key={index} className="nested-option-item">
                                  <div className="nested-option-header">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        paddingRight: 10,
                                      }}
                                    >
                                      {parentOption}
                                    </span>
                                    <i
                                      onClick={() =>
                                        handleRemoveParentOption(index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </i>
                                  </div>
                                  <div className="nested-options">
                                    {updateData.nestedOptions[index]?.map(
                                      (nestedOption, nestedIndex) => (
                                        <div
                                          key={nestedIndex}
                                          className="nested-option"
                                        >
                                          <span
                                            style={{
                                              fontSize: 13,
                                              paddingRight: 10,
                                            }}
                                          >
                                            {nestedOption}
                                          </span>
                                          <i
                                            onClick={() =>
                                              handleRemoveNestedOption(
                                                index,
                                                nestedIndex
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                          </i>
                                        </div>
                                      )
                                    )}
                                    <div className="search_box">
                                      <input
                                        type="text"
                                        placeholder="Add child option"
                                        value={newNestedOptions[index] || ""}
                                        onChange={(e) =>
                                          handleNewNestedOptionChange(e, index)
                                        }
                                      />
                                      <span
                                        onClick={() =>
                                          handleAddNestedOption(index)
                                        }
                                      >
                                        Add
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="search_box">
                              <input
                                type="text"
                                placeholder=" Add parent option"
                                value={newParentOption}
                                onChange={(e) =>
                                  setNewParentOption(e.target.value)
                                }
                              />
                              <span onClick={handleAddParentOption}>
                                Parent Option
                              </span>
                            </div>
                          </>
                        )}
                        {updateData.childDataType !== "Dropdown" && (
                          <>
                            {/* Render parent options with functionality for non-Dropdown */}
                            <h6>Add the options</h6>
                            <div className="nested_option_container">
                              {updateData.options.map((parentOption, index) => (
                                <div key={index} className="nested-option-item">
                                  <div className="nested-option-header">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        paddingRight: 10,
                                      }}
                                    >
                                      {parentOption}
                                    </span>
                                    {/* Provide functionality to remove parent options */}
                                    <i
                                      onClick={() =>
                                        handleRemoveParentOption(index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </i>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {/* Provide functionality to add parent options */}
                            <div className="search_box">
                              <input
                                type="text"
                                placeholder=" Add parent option"
                                value={newParentOption}
                                onChange={(e) =>
                                  setNewParentOption(e.target.value)
                                }
                              />
                              <span onClick={handleAddParentOption}>
                                Parent Option
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  <div className="attributes_update_box_btn">
                    <button onClick={handleUpdate}>Update</button>
                  </div>
                </div>
              )}

              {showSetUpdateBox && editMode && (
                <div className="attributes_update_box">
                  <h6>Update set names</h6>
                  <div
                    className="col"
                    style={{ flexWrap: "wrap", marginBottom: 25 }}
                  >
                    {Object.keys(editedAttributes).map((name, index) => (
                      <div className="_input_box" key={index}>
                        <input
                          type="text"
                          className="table_input"
                          value={editedAttributes[name].nametoDisplay}
                          onChange={(e) => handleInputChange(e, name)}
                        />
                        <span>Set name {index + 1}</span>
                      </div>
                    ))}
                  </div>
                  <div className="attributes_update_box_btn">
                    <button onClick={handleSave}>Save</button>
                  </div>
                </div>
              )}
            </div>

            {editMode && (
              <div className="attributes_action_container">
                <button
                  className={
                    updateAttributeStatus === STATUSES.LOADING
                      ? "common_btn disabled"
                      : "common_btn"
                  }
                  onClick={submitUpdateData}
                  disabled={updateAttributeStatus === STATUSES.LOADING}
                >
                  {updateAttributeStatus === STATUSES.LOADING ? (
                    <LoaderCircle />
                  ) : (
                    "Save Changes"
                  )}
                </button>
                <button onClick={handleCancle} className="common_btn2">
                  Cancel
                </button>
              </div>
            )}
          </>
        ) : status === STATUSES.ERROR ? (
          <ErrorBox
            message={error?.message ? error?.message : "Something Went Wrong"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SingleAttributeGroup;

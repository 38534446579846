import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;



const getAllFilteredProductSlice = createSlice({
    name : "getAllFilteredProducts",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllFilteredProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllFilteredProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllFilteredProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllFilteredProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const getAllFilteredProduct = createAsyncThunk("product/getAllFiltered", async ({ classNameToFetch, params }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token; 
      const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }};
      
      // Construct the URL with parameters
      const urlParams = new URLSearchParams(params).toString();
      const response = await axios.get(`${serviceURL1}/product/getAllProducts/${classNameToFetch}?${urlParams}`, config);
      return response.data;
  
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});







const getAllFilteredRetailerSlice = createSlice({
    name : "getAllFilteredRetailers",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllFilteredRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllFilteredRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllFilteredRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllFilteredRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});



export const getAllFilteredRetailer = createAsyncThunk( "retailer/getAllFiltered", async ( { classNameToFetch, params } ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const urlParams = new URLSearchParams(params).toString();
        const responce = await axios.get(`${serviceURL2}/retailers/getAllRetailers/${classNameToFetch}?${urlParams}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








const getAllFilteredManufacturerSlice = createSlice({
    name : "getAllFilteredManufacturer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllFilteredManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllFilteredManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllFilteredManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllFilteredManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});



export const getAllFilteredManufacturer = createAsyncThunk( "manufacturers/getAllFiltered", async ( { classNameToFetch, params } ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const urlParams = new URLSearchParams(params).toString();
        const responce = await axios.get(`${serviceURL3}/manufacturers/getAllManufacturers/${classNameToFetch}?${urlParams}`,config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





const getAllFilteredSupplierSlice = createSlice({
    name : "getAllFilteredSupplier",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllFilteredSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllFilteredSupplier.pending, (state, action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllFilteredSupplier.fulfilled, (state, action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllFilteredSupplier.rejected, (state, action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const getAllFilteredSupplier = createAsyncThunk( "supplier/getAllFiltered", async ( { classNameToFetch, params } ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const urlParams = new URLSearchParams(params).toString();
        const responce = await axios.get(`${serviceURL4}/suppliers/getAllSuppliers/${classNameToFetch}?${urlParams}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




















// ========================== Get All program ================================ 
const getAllFilteredProgramSlice = createSlice({
    name : "getAllFilteredProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllFilteredProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllFilteredProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllFilteredProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllFilteredProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});





export const getAllFilteredProgram = createAsyncThunk( "program/getAllFiltered", async ( { classNameToFetch, params } ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const urlParams = new URLSearchParams(params).toString();

        const responce = await axios.get(`${serviceURL0}/programs/getAllPrograms/${classNameToFetch}?${urlParams}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





export {

    getAllFilteredProductSlice,
    getAllFilteredRetailerSlice,
    getAllFilteredManufacturerSlice,
    getAllFilteredSupplierSlice,
    getAllFilteredProgramSlice

}



export const {resetGetAllFilteredProductState} = getAllFilteredProductSlice.actions;
export const {resetGetAllFilteredRetailerState} = getAllFilteredRetailerSlice.actions;
export const {resetGetAllFilteredManufacturerState} = getAllFilteredManufacturerSlice.actions;
export const {resetGetAllFilteredSupplierState} = getAllFilteredSupplierSlice.actions;

export const {resetGetAllFilteredProgramState} = getAllFilteredProgramSlice.actions;